import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import Header from "@gatsby-plugin-generic-page/components/Header"
import { SquidexTableWithContentCellType } from "@gatsby-plugin-generic-page/fragments/tableWithContentRow"
import type { TableWithContentSectionFlatData } from "@gatsby-plugin-generic-page/fragments/tableWithContentSection"
import TableMolWrapper from "~/components/TableMolWrapper"

function TableWithContentSection({
  header,
  sectionSettings,
  table,
}: TableWithContentSectionFlatData): ReactElement<TableWithContentSectionFlatData> {
  const { label, columns, groups } = table

  const groupsData = groups.map(({ groupLabel, rows }) => {
    const rowsData = rows.map((row) => {
      return {
        label: row.label,
        values: row.values.map((value) => {
          if (SquidexTableWithContentCellType.TEXT === value.type) {
            return value.text
          }

          if (SquidexTableWithContentCellType.DEFAULT_ICONS === value.type) {
            return value.isAvailable
          }

          return undefined
        }),
      }
    })

    return {
      groupLabel,
      rows: rowsData,
    }
  })

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        {header ? (
          <PushOrg bottomSpace={10}>
            <AlignContentOrg
              horizontalAlign={{
                small: "left",
                medium: "center",
                large: "center",
              }}
            >
              <Header {...header} />
            </AlignContentOrg>
          </PushOrg>
        ) : null}

        <TableMolWrapper
          showMoreDetails={{
            text: _t("table.expandButton"),
            title: _t("table.expandButton"),
          }}
          header={{ label: label, columns: columns }}
          content={groupsData}
        />
      </BaseContainer>
    </BaseSection>
  )
}

export default TableWithContentSection

import React from "react"
import type { GeneralField } from "@formily/core"
import { connect, mapProps } from "@formily/react"
import SelectMol from "@onestore/hel/dist/components/molecules/SelectMol"
import { Size } from "./Input"

interface SelectProps {
  onChange: () => void
  size?: string
}

interface SelectField {
  dataSource?: {
    label?: string
    value?: string
  }[]
}

export const SelectLayout = ({
  dataSource,
  onChange,
  size,
}: SelectProps & SelectField) => {
  if (!dataSource) {
    return null
  }

  const updatedDataSource = dataSource
    .filter((option) => option.label && option.value)
    .map((option) => ({
      label: option.label || "",
      value: option.value || "",
    }))

  return (
    <SelectMol
      options={updatedDataSource}
      onChange={onChange}
      size={size === Size.SMALL ? Size.SMALL : Size.DEFAULT}
    />
  )
}

const Select = connect(
  SelectLayout,
  mapProps({}, (props: SelectProps, field: SelectField & GeneralField) => {
    return {
      dataSource: field.dataSource,
      onChange: props.onChange,
      size: props.size,
    }
  })
)

export default Select

import type { ReactElement } from "react"
import React from "react"
import { typeMatches } from "@onestore-graphql"
import type {
  CenteredDomainSearchSectionFlatData,
  ImagesTileLinkSectionFlatData,
} from "@onestore-graphql/Model"
import type { GenericSection } from "@onestore-graphql/Sections"
import type { AccordionSectionFlatData } from "@gatsby-plugin-generic-page/fragments/accordionSection"
import type { BannerSectionFlatData } from "@gatsby-plugin-generic-page/fragments/bannerSection"
import type { BundleSectionFlatData } from "@gatsby-plugin-generic-page/fragments/bundleSection"
import type { ButtonsAnimatedWithImageSectionFlatData } from "@gatsby-plugin-generic-page/fragments/buttonsAnimatedWithImageSection"
import type { CardsWithInfoSectionFlatData } from "@gatsby-plugin-generic-page/fragments/cardsWithInfoSection"
import type { ColorContentWithBoxSectionFlatData } from "@gatsby-plugin-generic-page/fragments/colorContentWithBoxSection"
import type { ColorContentWithImageSectionFlatData } from "@gatsby-plugin-generic-page/fragments/colorContentWithImageSection"
import type { ContentWithFormSectionFlatData } from "@gatsby-plugin-generic-page/fragments/contentWithFormSection"
import type { ContentWithImageAndFormSectionFlatData } from "@gatsby-plugin-generic-page/fragments/contentWithImageAndFormSection"
import type { ContentWithImageSectionFlatData } from "@gatsby-plugin-generic-page/fragments/contentWithImageSection"
import type { ContentWithItemsSectionFlatData } from "@gatsby-plugin-generic-page/fragments/contentWithItemsSection"
import type { ContentWithProductBoxSectionFlatData } from "@gatsby-plugin-generic-page/fragments/contentWithProductBoxSection"
import type { CoreProductsSectionFlatData } from "@gatsby-plugin-generic-page/fragments/coreProductsSection"
import type { CounterSectionFlatData } from "@gatsby-plugin-generic-page/fragments/counterSection"
import type { CtaSectionFlatData } from "@gatsby-plugin-generic-page/fragments/ctaSection"
import type { DescriptionBoxSectionFlatData } from "@gatsby-plugin-generic-page/fragments/descriptionBoxSection"
import type { DomainsLinksSectionFlatData } from "@gatsby-plugin-generic-page/fragments/domainsLinksSection"
import type { FaqSectionFlatData } from "@gatsby-plugin-generic-page/fragments/faqSection"
import type { FloatingCtaElementSectionFlatData } from "@gatsby-plugin-generic-page/fragments/floatingCtaElementSection"
import type { FormSurveySectionFlatData } from "@gatsby-plugin-generic-page/fragments/formSurveySection"
import type { FullScreenContainerSectionFlatData } from "@gatsby-plugin-generic-page/fragments/fullScreenContainerSection"
import type { IntegrationTilesSectionFlatData } from "@gatsby-plugin-generic-page/fragments/integrationTilesSection"
import type { LogoSectionFlatData } from "@gatsby-plugin-generic-page/fragments/logoSection"
import type { MarketplaceCategoryProductCardSectionFlatData } from "@gatsby-plugin-generic-page/fragments/marketplaceCategoryProductCardSection"
import type { ParallaxSectionFlatData } from "@gatsby-plugin-generic-page/fragments/parallaxSection"
import type { ProductBoxSectionFlatData } from "@gatsby-plugin-generic-page/fragments/productBoxSection"
import type { ProductCardsSectionFlatData } from "@gatsby-plugin-generic-page/fragments/productCardsSection"
import type { PromoSectionFlatData } from "@gatsby-plugin-generic-page/fragments/promoSection"
import type { PromoTilesSectionFlatData } from "@gatsby-plugin-generic-page/fragments/promoTilesSection"
import type { PromoVideoSectionFlatData } from "@gatsby-plugin-generic-page/fragments/promoVideoSection"
import type { RecommendedProductSectionFlatData } from "@gatsby-plugin-generic-page/fragments/recommendedProductsSection"
import type { SearchSectionFlatData } from "@gatsby-plugin-generic-page/fragments/searchSection"
import type { SearchWithImageSectionFlatData } from "@gatsby-plugin-generic-page/fragments/searchWithImageSection"
import type { SliderSectionFlatData } from "@gatsby-plugin-generic-page/fragments/sliderSection"
import type { StepByStepSectionFlatData } from "@gatsby-plugin-generic-page/fragments/stepByStepSection"
import type { TableHorizontalBoxesSectionFlatData } from "@gatsby-plugin-generic-page/fragments/tableHorizontalBoxesSection"
import type { TableWithContentSectionFlatData } from "@gatsby-plugin-generic-page/fragments/tableWithContentSection"
import type { TabsSectionFlatData } from "@gatsby-plugin-generic-page/fragments/tabsSection"
import type { TilesLinkSectionFlatData } from "@gatsby-plugin-generic-page/fragments/tilesLinkSection"
import type { TilesProductsPriceSectionFlatData } from "@gatsby-plugin-generic-page/fragments/tilesProductsPriceSection"
import type { TilesSectionFlatData } from "@gatsby-plugin-generic-page/fragments/tilesSection"
import type { VideoSectionFlatData } from "@gatsby-plugin-generic-page/fragments/videoSection"
import type { WebTemplatesSectionFlatData } from "@gatsby-plugin-generic-page/fragments/webTemplatesSection"
import AccordionSection from "@gatsby-plugin-generic-page/sections/AccordionSection"
import AdvancedDomainItemsSection from "@gatsby-plugin-generic-page/sections/AdvancedDomainItemsSection"
import BannerSection from "@gatsby-plugin-generic-page/sections/BannerSection"
import BreadcrumbsSection from "@gatsby-plugin-generic-page/sections/BreadcrumbsSection"
import BundleSection from "@gatsby-plugin-generic-page/sections/BundleSection"
import ButtonsAnimatedWithImageSection from "@gatsby-plugin-generic-page/sections/ButtonsAnimatedWithImageSection"
import CardsWithInfoSection from "@gatsby-plugin-generic-page/sections/CardsWithInfoSection"
import CenteredContentWithImageSection from "@gatsby-plugin-generic-page/sections/CenteredContentWithImageSection"
import CenteredDomainSearchSection from "@gatsby-plugin-generic-page/sections/CenteredDomainSearchSection"
import ColorContentWithBoxSection from "@gatsby-plugin-generic-page/sections/ColorContentWithBoxSection"
import ColorContentWithImageSection from "@gatsby-plugin-generic-page/sections/ColorContentWithImageSection"
import ComparisonTilesSection from "@gatsby-plugin-generic-page/sections/ComparisonTilesSection"
import ContactSection from "@gatsby-plugin-generic-page/sections/ContactSection"
import ContentWithFormSection from "@gatsby-plugin-generic-page/sections/ContentWithFormSection"
import ContentWithImageAndFormSection from "@gatsby-plugin-generic-page/sections/ContentWithImageAndFormSection"
import ContentWithImageSection from "@gatsby-plugin-generic-page/sections/ContentWithImageSection"
import ContentWithItemsSection from "@gatsby-plugin-generic-page/sections/ContentWithItemsSection"
import ContentWithProductBoxSection from "@gatsby-plugin-generic-page/sections/ContentWithProductBoxSection"
import ContentWithSimpleProductBoxSection from "@gatsby-plugin-generic-page/sections/ContentWithSimpleProductBoxSection"
import CoreProductsSection from "@gatsby-plugin-generic-page/sections/CoreProductsSection/CoreProductsSection"
import CounterSection from "@gatsby-plugin-generic-page/sections/CounterSection"
import CtaSection from "@gatsby-plugin-generic-page/sections/CtaSection"
import DescriptionBoxSection from "@gatsby-plugin-generic-page/sections/DescriptionBoxSection"
import DomainItemsSection from "@gatsby-plugin-generic-page/sections/DomainItemsSection"
import DomainsLinksSection from "@gatsby-plugin-generic-page/sections/DomainsLinksSection"
import DomainTransferSection from "@gatsby-plugin-generic-page/sections/DomainTransferSection"
import FaqSection from "@gatsby-plugin-generic-page/sections/FaqSection"
import FloatingCtaElementSection from "@gatsby-plugin-generic-page/sections/FloatingCtaElementSection"
import FormSurveySection from "@gatsby-plugin-generic-page/sections/FormSurveySection/FormSurveySection"
import FullScreenContainerSection from "@gatsby-plugin-generic-page/sections/FullScreenContainerSection"
import HeaderWithTextSection from "@gatsby-plugin-generic-page/sections/HeaderWithTextSection"
import ImagesTileLinkSection from "@gatsby-plugin-generic-page/sections/ImagesTileLinkSection"
import ImageWithButtonsSection from "@gatsby-plugin-generic-page/sections/ImageWithButtonSection"
import IntegrationTilesSection from "@gatsby-plugin-generic-page/sections/IntegrationTilesSection"
import LinksAnimatedImageSection from "@gatsby-plugin-generic-page/sections/LinksAnimatedImageSection"
import LogoSection from "@gatsby-plugin-generic-page/sections/LogoSection"
import PackagesComparisonSection from "@gatsby-plugin-generic-page/sections/PackagesComparisonSection"
import ParallaxSection from "@gatsby-plugin-generic-page/sections/ParallaxSection"
import ParametersTableSection from "@gatsby-plugin-generic-page/sections/ParametersTableSection"
import PostsSection from "@gatsby-plugin-generic-page/sections/PostsSection"
import ProductBoxSection from "@gatsby-plugin-generic-page/sections/ProductBoxSection"
import ProductCardsSection from "@gatsby-plugin-generic-page/sections/ProductCardsSection"
import PromoSection from "@gatsby-plugin-generic-page/sections/PromoSection"
import PromoTilesSection from "@gatsby-plugin-generic-page/sections/PromoTilesSection"
import PromoVideoSection from "@gatsby-plugin-generic-page/sections/PromoVideoSection"
import RecommendedProductSection from "@gatsby-plugin-generic-page/sections/RecommendedProductSection"
import SearchSection from "@gatsby-plugin-generic-page/sections/SearchSection"
import SearchWithImageSection from "@gatsby-plugin-generic-page/sections/SearchWithImageSection"
import SeoContentSection from "@gatsby-plugin-generic-page/sections/SeoContentSection"
import SliderSection from "@gatsby-plugin-generic-page/sections/SliderSection"
import StaticTableSection from "@gatsby-plugin-generic-page/sections/StaticTableSection"
import StepByStepSection from "@gatsby-plugin-generic-page/sections/StepByStepSection"
import TableHorizontalBoxesSection from "@gatsby-plugin-generic-page/sections/TableHorizontalBoxesSection"
import TableWithContentSection from "@gatsby-plugin-generic-page/sections/TableWithContentSection"
import TabsSection from "@gatsby-plugin-generic-page/sections/TabsSection"
import TilesLinkSection from "@gatsby-plugin-generic-page/sections/TilesLinkSection"
import TilesProductsPriceSection from "@gatsby-plugin-generic-page/sections/TilesProductsPriceSection"
import TilesSection from "@gatsby-plugin-generic-page/sections/TilesSection"
import VideoSection from "@gatsby-plugin-generic-page/sections/VideoSection"
import WebTemplatesSection from "@gatsby-plugin-generic-page/sections/WebTemplatesSection"
import type {
  BreadcrumbsSectionFlatData,
  CenteredContentWithImageSectionFlatData,
  ComparisonTilesSectionFlatData,
  ContactSectionFlatData,
  ContentWithSimpleProductBoxSectionFlatData,
  DomainItemsSectionFlatData,
  DomainTransferSectionFlatData,
  ImageWithButtonsSectionFlatData,
  LinksAnimatedImageSectionFlatData,
  PackagesComparisonSectionFlatData,
  ParametersTableSectionFlatData,
  PostsSectionFlatData,
  SeoContentSectionFlatData,
  StaticTableSectionFlatData,
} from "@gatsby-plugin-generic-page/types/sectionType"
import { SectionType } from "@gatsby-plugin-generic-page/types/sectionType"
import CategoryAdvancedProductCardsSection from "~/sections/CategoryAdvancedProductCardsSection"
import type { AdvancedDomainItemsSectionFlatData } from "../../fragments/advancedDomainItemsSection"
import type { HeaderWithTextSectionFlatData } from "../../fragments/headerWithTextSection"

interface SectionProps {
  data: GenericSection
}

function Section({ data }: SectionProps): ReactElement<SectionProps> | null {
  const sectionType = data.__typename
  const props = data.flatData
  const id = data.id

  if (typeMatches(sectionType, SectionType.CONTENT_WITH_PRODUCT_BOX_SECTION)) {
    return (
      <ContentWithProductBoxSection
        {...(props as ContentWithProductBoxSectionFlatData)}
      />
    )
  }

  if (typeMatches(sectionType, SectionType.CONTENT_WITH_IMAGE_SECTION)) {
    return (
      <ContentWithImageSection
        {...(props as ContentWithImageSectionFlatData)}
      />
    )
  }

  if (
    typeMatches(sectionType, SectionType.CONTENT_WITH_IMAGE_AND_FORM_SECTION)
  ) {
    return (
      <ContentWithImageAndFormSection
        {...(props as ContentWithImageAndFormSectionFlatData)}
      />
    )
  }

  if (typeMatches(sectionType, SectionType.CTA_SECTION)) {
    return <CtaSection {...(props as CtaSectionFlatData)} />
  }

  if (typeMatches(sectionType, SectionType.STEP_BY_STEP_SECTION)) {
    return <StepByStepSection {...(props as StepByStepSectionFlatData)} />
  }

  if (typeMatches(sectionType, SectionType.FAQ_SECTION)) {
    return <FaqSection {...(props as FaqSectionFlatData)} />
  }

  if (typeMatches(sectionType, SectionType.CONTACT_SECTION)) {
    return <ContactSection {...(props as ContactSectionFlatData)} />
  }

  if (typeMatches(sectionType, SectionType.VIDEO_SECTION)) {
    return <VideoSection {...(props as VideoSectionFlatData)} />
  }

  if (typeMatches(sectionType, SectionType.PRODUCT_BOX_SECTION)) {
    return <ProductBoxSection {...(props as ProductBoxSectionFlatData)} />
  }

  if (typeMatches(sectionType, SectionType.IMAGES_TILE_LINK_SECTION)) {
    return (
      <ImagesTileLinkSection {...(props as ImagesTileLinkSectionFlatData)} />
    )
  }

  if (typeMatches(sectionType, SectionType.PARAMETERS_TABLE_SECTION)) {
    return (
      <ParametersTableSection {...(props as ParametersTableSectionFlatData)} />
    )
  }

  if (typeMatches(sectionType, SectionType.LINKS_ANIMATED_IMAGE_SECTION)) {
    return (
      <LinksAnimatedImageSection
        {...(props as LinksAnimatedImageSectionFlatData)}
      />
    )
  }

  if (typeMatches(sectionType, SectionType.COLOR_CONTENT_WITH_IMAGE_SECTION)) {
    return (
      <ColorContentWithImageSection
        {...(props as ColorContentWithImageSectionFlatData)}
      />
    )
  }

  if (typeMatches(sectionType, SectionType.RECOMMENDED_PRODUCT_SECTION)) {
    return (
      <RecommendedProductSection
        {...(props as RecommendedProductSectionFlatData)}
      />
    )
  }

  if (
    typeMatches(sectionType, SectionType.BUTTONS_ANIMATED_WITH_IMAGE_SECTION)
  ) {
    return (
      <ButtonsAnimatedWithImageSection
        {...(props as ButtonsAnimatedWithImageSectionFlatData)}
      />
    )
  }

  if (
    typeMatches(sectionType, SectionType.CENTERED_CONTENT_WITH_IMAGE_SECTION)
  ) {
    return (
      <CenteredContentWithImageSection
        {...(props as CenteredContentWithImageSectionFlatData)}
      />
    )
  }

  if (typeMatches(sectionType, SectionType.CENTERED_DOMAIN_SEARCH_SECTION)) {
    return (
      <CenteredDomainSearchSection
        {...(props as CenteredDomainSearchSectionFlatData)}
      />
    )
  }

  if (typeMatches(sectionType, SectionType.SEARCH_SECTION)) {
    return <SearchSection {...(props as SearchSectionFlatData)} />
  }

  if (typeMatches(sectionType, SectionType.SEO_CONTENT_SECTION)) {
    return <SeoContentSection {...(props as SeoContentSectionFlatData)} />
  }

  if (typeMatches(sectionType, SectionType.FLOATING_CTA_ELEMENT_SECTION)) {
    return (
      <FloatingCtaElementSection
        {...(props as FloatingCtaElementSectionFlatData)}
      />
    )
  }

  if (typeMatches(sectionType, SectionType.IMAGE_WITH_BUTTONS_SECTION)) {
    return (
      <ImageWithButtonsSection
        {...(props as ImageWithButtonsSectionFlatData)}
      />
    )
  }

  if (typeMatches(sectionType, SectionType.DESCRIPTION_BOX_SECTION)) {
    return (
      <DescriptionBoxSection {...(props as DescriptionBoxSectionFlatData)} />
    )
  }

  if (typeMatches(sectionType, SectionType.TILES_SECTION)) {
    return <TilesSection {...(props as TilesSectionFlatData)} />
  }

  if (typeMatches(sectionType, SectionType.SEARCH_WITH_IMAGE_SECTION)) {
    return (
      <SearchWithImageSection {...(props as SearchWithImageSectionFlatData)} />
    )
  }

  if (typeMatches(sectionType, SectionType.COUNTER_SECTION)) {
    return <CounterSection {...(props as CounterSectionFlatData)} />
  }

  if (typeMatches(sectionType, SectionType.CORE_PRODUCTS_SECTION)) {
    return <CoreProductsSection {...(props as CoreProductsSectionFlatData)} />
  }

  if (typeMatches(sectionType, SectionType.BANNER_SECTION)) {
    return <BannerSection {...(props as BannerSectionFlatData)} />
  }

  if (typeMatches(sectionType, SectionType.LOGO_SECTION)) {
    return <LogoSection {...(props as LogoSectionFlatData)} />
  }

  if (typeMatches(sectionType, SectionType.DOMAINS_LINKS_SECTION)) {
    return <DomainsLinksSection {...(props as DomainsLinksSectionFlatData)} />
  }

  if (typeMatches(sectionType, SectionType.DOMAIN_TRANSFER_SECTION)) {
    return (
      <DomainTransferSection {...(props as DomainTransferSectionFlatData)} />
    )
  }

  if (typeMatches(sectionType, SectionType.PROMO_SECTION)) {
    return <PromoSection {...(props as PromoSectionFlatData)} />
  }

  if (typeMatches(sectionType, SectionType.SLIDER_SECTION)) {
    return <SliderSection {...(props as SliderSectionFlatData)} />
  }

  if (typeMatches(sectionType, SectionType.ACCORDION_SECTION)) {
    return <AccordionSection {...(props as AccordionSectionFlatData)} />
  }

  if (typeMatches(sectionType, SectionType.BREADCRUMBS_SECTION)) {
    return <BreadcrumbsSection {...(props as BreadcrumbsSectionFlatData)} />
  }

  if (typeMatches(sectionType, SectionType.COMPARISON_TILES_SECTION)) {
    return (
      <ComparisonTilesSection {...(props as ComparisonTilesSectionFlatData)} />
    )
  }

  if (typeMatches(sectionType, SectionType.STATIC_TABLE_SECTION)) {
    return <StaticTableSection {...(props as StaticTableSectionFlatData)} />
  }

  if (typeMatches(sectionType, SectionType.FORM_SURVEY_SECTION)) {
    return <FormSurveySection {...(props as FormSurveySectionFlatData)} />
  }

  if (typeMatches(sectionType, SectionType.TABS_SECTION)) {
    return <TabsSection {...(props as TabsSectionFlatData)} id={id} />
  }

  if (typeMatches(sectionType, SectionType.CONTENT_WITH_FORM_SECTION)) {
    return (
      <ContentWithFormSection {...(props as ContentWithFormSectionFlatData)} />
    )
  }

  if (typeMatches(sectionType, SectionType.INTEGRATION_TILES_SECTION)) {
    return (
      <IntegrationTilesSection
        {...(props as IntegrationTilesSectionFlatData)}
      />
    )
  }

  if (typeMatches(sectionType, SectionType.BUNDLE_SECTION)) {
    return <BundleSection {...(props as BundleSectionFlatData)} />
  }

  if (typeMatches(sectionType, SectionType.DOMAIN_ITEMS_SECTION)) {
    return <DomainItemsSection {...(props as DomainItemsSectionFlatData)} />
  }

  if (
    typeMatches(
      sectionType,
      SectionType.CONTENT_WITH_SIMPLE_PRODUCT_BOX_SECTION
    )
  ) {
    return (
      <ContentWithSimpleProductBoxSection
        {...(props as ContentWithSimpleProductBoxSectionFlatData)}
      />
    )
  }

  if (typeMatches(sectionType, SectionType.POSTS_SECTION)) {
    return <PostsSection {...(props as PostsSectionFlatData)} />
  }

  if (typeMatches(sectionType, SectionType.PACKAGES_COMPARISON_SECTION)) {
    return (
      <PackagesComparisonSection
        {...(props as PackagesComparisonSectionFlatData)}
      />
    )
  }

  if (typeMatches(sectionType, SectionType.TABLE_WITH_CONTENT_SECTION)) {
    return (
      <TableWithContentSection
        {...(props as TableWithContentSectionFlatData)}
      />
    )
  }

  if (typeMatches(sectionType, SectionType.WEB_TEMPLATES_SECTION)) {
    return <WebTemplatesSection {...(props as WebTemplatesSectionFlatData)} />
  }

  if (typeMatches(sectionType, SectionType.PRODUCT_CARDS_SECTION)) {
    return <ProductCardsSection {...(props as ProductCardsSectionFlatData)} />
  }

  if (typeMatches(sectionType, SectionType.CONTENT_WITH_ITEMS_SECTION)) {
    return (
      <ContentWithItemsSection
        {...(props as ContentWithItemsSectionFlatData)}
      />
    )
  }

  if (typeMatches(sectionType, SectionType.TILES_LINK_SECTION)) {
    return <TilesLinkSection {...(props as TilesLinkSectionFlatData)} />
  }

  if (typeMatches(sectionType, SectionType.TILES_PRODUCTS_PRICE_SECTION)) {
    return (
      <TilesProductsPriceSection
        {...(props as TilesProductsPriceSectionFlatData)}
      />
    )
  }

  if (typeMatches(sectionType, SectionType.HEADER_WITH_TEXT_SECTION)) {
    return (
      <HeaderWithTextSection {...(props as HeaderWithTextSectionFlatData)} />
    )
  }

  if (typeMatches(sectionType, SectionType.ADVANCED_DOMAIN_ITEMS_SECTION)) {
    return (
      <AdvancedDomainItemsSection
        {...(props as AdvancedDomainItemsSectionFlatData)}
      />
    )
  }

  if (
    typeMatches(
      sectionType,
      SectionType.MARKETPLACE_CATEGORY_PRODUCT_CARD_SECTION
    )
  ) {
    return (
      <CategoryAdvancedProductCardsSection
        {...(props as MarketplaceCategoryProductCardSectionFlatData)}
      />
    )
  }

  if (typeMatches(sectionType, SectionType.TABLE_HORIZONTAL_BOXES_SECTION)) {
    return (
      <TableHorizontalBoxesSection
        {...(props as TableHorizontalBoxesSectionFlatData)}
      />
    )
  }

  if (typeMatches(sectionType, SectionType.FULL_SCREEN_CONTAINER_SECTION)) {
    return (
      <FullScreenContainerSection
        {...(props as FullScreenContainerSectionFlatData)}
      />
    )
  }

  if (typeMatches(sectionType, SectionType.PROMO_TILES_SECTION)) {
    return <PromoTilesSection {...(props as PromoTilesSectionFlatData)} />
  }

  if (typeMatches(sectionType, SectionType.PROMO_VIDEO_SECTION)) {
    return <PromoVideoSection {...(props as PromoVideoSectionFlatData)} />
  }

  if (typeMatches(sectionType, SectionType.CARDS_WITH_INFO_SECTION)) {
    return <CardsWithInfoSection {...(props as CardsWithInfoSectionFlatData)} />
  }

  if (typeMatches(sectionType, SectionType.COLOR_CONTENT_WITH_BOX_SECTION)) {
    return (
      <ColorContentWithBoxSection
        {...(props as ColorContentWithBoxSectionFlatData)}
      />
    )
  }

  if (typeMatches(sectionType, SectionType.PARALLAX_SECTION)) {
    return <ParallaxSection {...(props as ParallaxSectionFlatData)} />
  }

  return null
}

export default Section

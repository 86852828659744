import type { ReactElement } from "react"
import React from "react"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import ItemDescriptionBoxMol from "@onestore/hel/dist/components/molecules/ItemDescriptionBoxMol"
import type { Props as ItemDescriptionBoxMolProps } from "@onestore/hel/dist/components/molecules/ItemDescriptionBoxMol/ItemDescriptionBoxMol.props"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import CtaElement from "@gatsby-plugin-generic-page/components/CtaElement"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import Media from "@gatsby-plugin-generic-page/components/Media"
import type { DescriptionBoxSectionFlatData } from "@gatsby-plugin-generic-page/fragments/descriptionBoxSection"

function DescriptionBoxSection({
  title,
  text,
  image,
  buttons,
  themeBackgroundColor,
  isReversed,
  hasImageStickyToBottom,
  hasStickingOutImage,
  sectionSettings,
}: DescriptionBoxSectionFlatData): ReactElement<ItemDescriptionBoxMolProps> {
  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        <ItemDescriptionBoxMol
          backgroundColor={themeBackgroundColor.background}
          imageVisibilityBreakpoint="medium"
          imagePosition={isReversed ? "left" : "right"}
          hasImageStickyToBottom={hasImageStickyToBottom}
          imagePullUpSpaceOnLarge={hasStickingOutImage ? 10 : undefined}
          image={<Media media={image} />}
        >
          <>
            <TextAtm typography="great" htmlTag="h2" pushSpace={1.5}>
              {title}
            </TextAtm>

            <MarkdownText
              typography={{ default: "medium1_h", bold: "medium3_h" }}
            >
              {text}
            </MarkdownText>

            {buttons ? (
              <PushOrg topSpace={3}>
                <CtaElement ctaElement={buttons} />
              </PushOrg>
            ) : null}
          </>
        </ItemDescriptionBoxMol>
      </BaseContainer>
    </BaseSection>
  )
}

export default DescriptionBoxSection

import React, { ReactNode, ReactElement, memo } from "react"
import { Helmet } from "react-helmet"
import ErrorBoundary from "~/components/ErrorBoundary"
import CheckoutContext from "~/context/CheckoutContext"

interface BaseProps {
  children: ReactNode
  skipBasketContext?: boolean
}

function Checkout({
  children,
  skipBasketContext = false,
}: BaseProps): ReactElement<BaseProps> {
  return (
    <ErrorBoundary>
      <Helmet
        bodyAttributes={{
          className: "_is-checkout _is-onestore",
        }}
      />
      <CheckoutContext skipBasketContext={skipBasketContext}>
        {children}
      </CheckoutContext>
    </ErrorBoundary>
  )
}

export default memo(Checkout)

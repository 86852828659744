import type { ReactElement } from "react"
import React from "react"
import type { LinkButtonType } from "@onestore/hel/dist/components/atoms/LinkButtonAtm"
import ToggleWithLinkButtonsMol from "@onestore/hel/dist/components/molecules/ToggleWithLinkButtonsMol"
import type { LinkElementWithIcon } from "@gatsby-plugin-generic-page/fragments/linkElementWithIcon"
import type { ToggleWithLinkButtonsProps } from "@gatsby-plugin-generic-page/fragments/toggleWithLinkButtons"
import getLinkProps from "@gatsby-plugin-generic-page/helpers/getLinkProps"
import Media from "../Media"

function ToggleWithLinkButtons({
  values,
  initActiveIndex,
  hasDarkMode,
}: ToggleWithLinkButtonsProps): ReactElement {
  const mappedValues = values.map(
    (value: LinkElementWithIcon): LinkButtonType => {
      const { link, ...valueProps } = value
      const linkElement = link ? getLinkProps(link) : undefined

      return {
        ...valueProps,
        ...linkElement,
        color: valueProps.color.color,
        icon: valueProps.icon ? <Media media={valueProps.icon} /> : undefined,
        text: link.text,
        title: linkElement?.title || "",
      }
    }
  )

  return (
    <ToggleWithLinkButtonsMol
      values={[mappedValues[0], mappedValues[1]]}
      initActiveIndex={initActiveIndex ? 0 : 1}
      hasDarkMode={hasDarkMode}
    />
  )
}

export default ToggleWithLinkButtons

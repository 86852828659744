export class EmptyTokenException extends Error {
  readonly name: string = "EmptyTokenException"
  readonly message: string = "Próba wysłania żądania do API z pustym tokenem"
}

export class ResponseError extends Error {
  readonly response: Response

  constructor(response: Response) {
    super(response.statusText)
    this.response = response
  }
}

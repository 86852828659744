import type { VideoAtmProps } from "@onestore/hel/dist/components/atoms/VideoAtm"
import type { Video } from "@gatsby-plugin-generic-page/fragments/video"
import assetsSquidexToImageObject from "~/lib/assetsSquidexToImageObject"
import assetsSquidexToVideoObject from "~/lib/assetsSquidexToVideoObject"
import isEmpty from "~/lib/isEmpty"

export const useVideoProps = (video?: Video[]): VideoAtmProps | null => {
  const videoData = !isEmpty(video)
    ? {
        ...video[0].flatData,
        video: assetsSquidexToVideoObject(video[0].flatData.video),
        fallbackImg: assetsSquidexToImageObject(
          video[0].flatData.fallbackImage,
          ""
        ),
        poster: video[0].flatData.poster
          ? video[0].flatData.poster[0].url
          : undefined,
      }
    : null

  let videoProps: VideoAtmProps

  if (!isEmpty(videoData)) {
    const { fallbackImage, ...restProps } = videoData
    videoProps = restProps

    return videoProps
  }

  return null
}

import type { MarkdownToJSX } from "markdown-to-jsx"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import ListItem from "./components/ListItem"
import PlainLink from "./components/PlainLink"

export const TextAtmBoldError = {
  component: TextAtm,
  props: {
    isBold: true,
    color: "error",
  },
}
export const TextAtmBoldSuccess = {
  component: TextAtm,
  props: {
    isBold: true,
    color: "success",
  },
}

export const TextAtmBoldSecondary = {
  component: TextAtm,
  props: {
    isBold: true,
    color: "secondary",
  },
}

export const TextAtmBold = {
  component: TextAtm,
  props: {
    isBold: true,
  },
}

export const LongDescriptionTextAtmBold = {
  component: TextAtm,
  props: {
    isBold: true,
    typography: "small1",
  },
}

export const TextAtmBonusContentHeading = {
  component: TextAtm,
  props: {
    isBold: true,
    emphasis: "high",
  },
}

export const LongDescriptionTextAtmBonusContentHeading = {
  component: TextAtm,
  props: {
    isBold: true,
    emphasis: "high",
    typography: "small3",
  },
}

export const TextAtmBonusContentParagraph = {
  component: PushOrg,
  props: {
    bottomSpace: 2,
  },
}

export const LongDescriptionTextAtmBonusContentParagraph = {
  component: TextAtm,
  props: {
    typography: "small1",
    pushSpace: 2,
  },
}

export const TextAtmBonusContentList = {
  component: PushOrg,
  props: {
    topSpace: 2,
    bottomSpace: 2,
  },
}

export const TextAtmBonusContentLi = {
  component: ListItem,
}

export const TextAtmBonusContentLink = {
  component: PlainLink,
}

export const genericOverrides: MarkdownToJSX.Overrides = {
  strong: TextAtmBold,
  li: TextAtmBonusContentLi,
  ul: TextAtmBonusContentList,
  ol: TextAtmBonusContentList,
}

export const bonusBoxContentOverrides: MarkdownToJSX.Overrides = {
  strong: TextAtmBold,
  h1: TextAtmBonusContentHeading,
  p: TextAtmBonusContentParagraph,
  li: TextAtmBonusContentLi,
  ul: TextAtmBonusContentList,
  ol: TextAtmBonusContentList,
}

export const bonusBoxLongDescriptionOverrides: MarkdownToJSX.Overrides = {
  strong: LongDescriptionTextAtmBold,
  h1: LongDescriptionTextAtmBonusContentHeading,
  p: LongDescriptionTextAtmBonusContentParagraph,
  li: TextAtmBonusContentLi,
  ul: TextAtmBonusContentList,
  ol: TextAtmBonusContentList,
}

export const bonusBoxTitleOverrides: MarkdownToJSX.Overrides = {
  strong: TextAtmBold,
  a: TextAtmBonusContentLink,
}

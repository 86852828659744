import type { ReactElement, SyntheticEvent } from "react"
import React from "react"
import _t from "@core/i18n"
import Search from "@onestore-icons/search.svg"
import type { DomainCheck } from "@onestore/api/domainSearch"
import type { PlanId } from "@onestore/api/types"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import SubmitInputMol from "@onestore/hel/dist/components/molecules/SubmitInputMol"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import { useAuthinfoState } from "~/components/DomainTransferInputField/useAuthinfoState"
import { md } from "~/lib/i18n"
import ModalTemplateWrapper from "../ModalTemplateWrapper"

interface TransferAuthInfoModalProps {
  isOpen: boolean
  onCloseButtonClick: (event: SyntheticEvent) => void
  domain: DomainCheck.FQDN | null
  extension: DomainCheck.Extension | null
  planId: PlanId | null
}

export function TransferAuthInfoModal({
  isOpen,
  onCloseButtonClick,
  domain,
  extension,
  planId,
}: TransferAuthInfoModalProps): ReactElement<TransferAuthInfoModalProps> {
  const [
    value,
    valid,
    isLoading,
    isRedirecting,
    errorMessage,
    onChange,
    onSubmit,
    onKeyPress,
    onClose,
  ] = useAuthinfoState(domain, extension, planId)

  const isNask = extension === "pl"

  return (
    <ModalTemplateWrapper
      isLoading={isRedirecting}
      content={
        <>
          <TextAtm typography="great" pushSpace={3}>
            {_t("transfer.transferAuthinfoModalTitle")}
          </TextAtm>

          <TextAtm typography="medium2_h" pushSpace={2}>
            {md("transfer.transferAuthinfoModalContent", { fqdn: domain })}
          </TextAtm>

          <TextAtm typography="medium2_h" pushSpace={2}>
            {isNask
              ? md("transfer.transferAuthinfoModalContentNask")
              : md("transfer.transferAuthinfoModalContentGlobal")}
          </TextAtm>

          <TextAtm typography="medium2_h" pushSpace={2}>
            {md("transfer.transferAuthinfoModalContentInfo")}
          </TextAtm>

          <PushOrg topSpace={4}>
            <SubmitInputMol
              buttonMobileIcon={Search}
              buttonColor="success"
              buttonText={_t("transfer.authinfoButton")}
              buttonTitle={_t("transfer.authinfoButton")}
              placeholder={_t("transfer.authinfoInput")}
              value={value}
              onChange={onChange}
              isLoading={isLoading}
              onSubmit={onSubmit}
              onKeyPress={onKeyPress}
              hasError={!valid}
              errorMessage={errorMessage}
            />
          </PushOrg>
        </>
      }
      isOpen={isOpen}
      onCloseButtonClick={(event) => {
        onClose()
        onCloseButtonClick(event)
      }}
    />
  )
}

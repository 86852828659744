import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import getBaseMarkdownOverrides from "@gatsby-plugin-generic-page/helpers/getBaseMarkdownOverrides"
import ModalTemplateWrapper from "../ModalTemplateWrapper"
import type { DataProps } from "./types"

interface ModalProps extends Required<Pick<DataProps, "modalData">> {
  isOpen: boolean
  onCloseButtonClick?: () => void
}

function Modal({
  modalData,
  isOpen,
  onCloseButtonClick,
}: ModalProps): ReactElement<ModalProps> {
  return (
    <ModalTemplateWrapper
      isOpen={isOpen}
      onCloseButtonClick={onCloseButtonClick}
      content={
        <>
          <TextAtm pushSpace={2} typography="large3">
            {modalData.title}
          </TextAtm>

          <MarkdownText
            overrides={getBaseMarkdownOverrides({
              typography: "medium1_h",
              pushSpace: 2,
              boldTypography: "medium4_h",
            })}
          >
            {modalData.modalText}
          </MarkdownText>
        </>
      }
      buttons={[
        {
          title: _t("button.acknowledge"),
          text: _t("button.acknowledge"),
          onClick: onCloseButtonClick,
        },
      ]}
    />
  )
}

export default Modal

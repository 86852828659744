/* eslint-disable no-param-reassign */
import type { Draft } from "immer"
import { produce } from "immer"
import { forEach } from "lodash"
import type { OfficeState } from "@onestore/onestore-store-common"
import { HTTP_STATUS } from "@onestore/onestore-store-common/http"
import { OfficeStatus } from "~/store/office/constants"
import type { OfficeAction } from "./actions"
import { OfficeActionTypes } from "./actions"

export const initialState: OfficeState = {
  // status sprawdzenia domeny konta Microsoft Office
  domain_available: undefined,
  domain_request: HTTP_STATUS.NONE,
  domain_name: "",

  // status sparawdzenia konta Microsoft Office
  errors: [],
  account_request: HTTP_STATUS.NONE,
  clicked_on_confirm: false,
  logged_in: false,
  accepted: false,
}

export default function reducer(
  state: OfficeState = initialState,
  action: OfficeAction
): OfficeState {
  return produce<OfficeState, OfficeState>(
    state,
    (nextState: Draft<OfficeState>) => {
      switch (action.type) {
        case OfficeActionTypes.CHECK_DOMAIN_PENDING:
          nextState.domain_request = HTTP_STATUS.CONTINUE

          return

        case OfficeActionTypes.CHECK_DOMAIN_FAILURE:
          nextState.domain_request = action.response_status_code
          nextState.domain_available = undefined
          nextState.domain_name = ""

          return

        case OfficeActionTypes.CHECK_DOMAIN_SUCCESS:
          nextState.domain_request = HTTP_STATUS.OK
          nextState.domain_available = action.available
          nextState.domain_name = action.domain

          return

        case OfficeActionTypes.CHECK_DOMAIN_RESET:
          nextState.domain_request = HTTP_STATUS.NONE
          nextState.domain_available = undefined
          nextState.domain_name = ""

          return

        case OfficeActionTypes.CHECK_ACCOUNT_PENDING:
          nextState.account_request = HTTP_STATUS.CONTINUE
          nextState.errors = []
          nextState.domain_name = action.domain

          return
        case OfficeActionTypes.CHECK_ACCOUNT_FAILURE:
          nextState.account_request = action.response_status_code
          nextState.logged_in = false
          nextState.accepted = false

          return

        case OfficeActionTypes.CHECK_ACCOUNT_SUCCESS:
          nextState.account_request = HTTP_STATUS.OK
          nextState.errors = action.account_status.errors || []
          nextState.logged_in =
            action.account_status.status !== OfficeStatus.ERROR
          nextState.accepted =
            action.account_status.status === OfficeStatus.ACCEPTED

          return

        case OfficeActionTypes.CHECK_ACCOUNT_RESET:
          forEach(initialState, (value, key) => {
            nextState[key] = value
          })

          return

        case OfficeActionTypes.CHECK_ACCOUNT_CLICKED_ON_CONFIRM:
          nextState.clicked_on_confirm = true

          return
      }
    }
  )
}

import { mapValues } from "lodash"
import type {
  BasketParamsResponse,
  BasketParamListItem,
  BasketParamResponseItem,
} from "../../lib/api/basket"

function ItemParamsCreate({
  item_id,
  plan_id,
  type,
  params = {},
}: BasketParamListItem) {
  return {
    item_id,
    plan_id,
    type,
    params: mapValues(params, (param: BasketParamResponseItem) => ({
      ...param,
      id: param.paramId,
      name: param.paramName,
    })),
  }
}

export const BasketParamsCollection = (paramsList: BasketParamsResponse) =>
  mapValues(paramsList, ItemParamsCreate)

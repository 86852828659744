const SESSION_STORAGE_SCHEMA_VERSION = 1

interface SerializedDataInterface {
  version?: number
  data?: any
  ttl?: number
}

export default class SessionStorage {
  /**
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Feature-detecting_sessionStorage
   */
  static isAvailable(): boolean {
    if (typeof window === "undefined") {
      return false
    }

    try {
      const x: string = "__storage_test__"
      window.sessionStorage.setItem(x, x)
      window.sessionStorage.removeItem(x)

      return true
    } catch (e) {
      return (
        e instanceof DOMException &&
        // everything except Firefox
        (e.code === 22 ||
          // Firefox
          e.code === 1014 ||
          // test name field too, because code might not be present
          // everything except Firefox
          e.name === "QuotaExceededError" ||
          // Firefox
          e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
        // acknowledge QuotaExceededError only if there's something already stored
        window.sessionStorage.length !== 0
      )
    }
  }

  static get(key: string): string | null {
    if (!SessionStorage.isAvailable()) {
      return null
    }

    return window.sessionStorage.getItem(key)
  }

  static set(key: string, value: string | undefined): void {
    if (!SessionStorage.isAvailable()) {
      return
    }

    if (undefined === value) {
      this.remove(key)
    } else {
      window.sessionStorage.setItem(key, value)
    }
  }

  static getBool(key: string): boolean | null {
    if (!SessionStorage.isAvailable()) {
      return null
    }
    const value = window.sessionStorage.getItem(key)

    if (value == null) {
      return null
    }

    return value === "1"
  }

  static setBool(key: string, value: boolean | undefined): void {
    if (!SessionStorage.isAvailable()) {
      return
    }

    if (undefined === value) {
      this.remove(key)
    } else {
      window.sessionStorage.setItem(key, value ? "1" : "0")
    }
  }

  static remove(key: string): void {
    if (!SessionStorage.isAvailable()) {
      return
    }

    window.sessionStorage.removeItem(key)
  }

  static setArray(key: string, values: string[]): void {
    SessionStorage.set(key, values.join("|"))
  }

  static setSerialized<T = any>(
    key: string,
    object: T,
    exipreAfterSeconds?: number
  ): void {
    SessionStorage.set(
      key,
      JSON.stringify(<SerializedDataInterface>{
        version: SESSION_STORAGE_SCHEMA_VERSION,
        data: object,
        ttl: exipreAfterSeconds
          ? Date.now() / 1000 + exipreAfterSeconds
          : undefined,
      })
    )
  }

  static getArray(key: string): string[] {
    const value = SessionStorage.get(key)

    return value === null ? [] : value.split("|")
  }

  static unserializeStoredData(key: string): any {
    const data: SerializedDataInterface = JSON.parse(
      SessionStorage.get(key) || "{}"
    )

    if ("version" in data && data.version !== SESSION_STORAGE_SCHEMA_VERSION) {
      SessionStorage.remove(key)

      return {}
    }

    if (
      "ttl" in data &&
      data.ttl !== undefined &&
      data.ttl < Date.now() / 1000
    ) {
      SessionStorage.remove(key)

      return {}
    }

    return data.data || {}
  }

  static getSerialized<T = Record<string, string>, D = any>(
    key: string,
    defaultValue: D
  ): T | D {
    try {
      return SessionStorage.unserializeStoredData(key)
    } catch (e) {
      return defaultValue
    }
  }

  static keep(key: string, generator: { (): string }): string {
    let value = SessionStorage.get(key)

    if (!value) {
      value = generator()
      SessionStorage.set(key, value)
    }

    return value
  }
}

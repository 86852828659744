import React, { useEffect, useState } from "react"
import type { FeedbackMessage, GeneralField } from "@formily/core"
import { connect, mapProps } from "@formily/react"
import { get } from "lodash"
import SelectMol from "@onestore/hel/dist/components/molecules/SelectMol"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import isEmpty from "~/lib/isEmpty"
import type { Size } from "./Input"

type TreeSelectProps = {
  onChange: (newValue: string) => void
  hasError?: boolean
  size?: Size
  errorMessage?: string
}

type DataSourceData = {
  label?: string
  value?: string
}

type TreeSelectField = {
  dataSource?: (DataSourceData & { children?: DataSourceData[] })[]
  errors?: {
    messages?: FeedbackMessage
  }[]
}

const getSelectOptions = (dataSource: TreeSelectField["dataSource"]) => {
  if (isEmpty(dataSource)) {
    return []
  }

  return dataSource
    .filter((option) => option.label && option.value)
    .map((option) => ({
      label: option.label || "",
      value: option.value || "",
    }))
}

export const TreeSelectLayout = ({
  dataSource,
  onChange,
  size,
  hasError,
  errorMessage,
}: TreeSelectProps & TreeSelectField) => {
  const [parentData, setParentData] = useState(
    !isEmpty(dataSource) ? dataSource[0] : undefined
  )

  const handleOnChange = (newValue: string) => {
    if (!isEmpty(dataSource)) {
      const newParentData = dataSource.find((data) => data.value === newValue)

      if (!isEmpty(newParentData)) {
        setParentData(newParentData)
      }

      if (!isEmpty(newParentData) && !isEmpty(newParentData.children)) {
        onChange(newParentData.children[0].value || "")
      } else {
        onChange(newValue)
      }
    }
  }

  useEffect(() => {
    if (!isEmpty(dataSource) && dataSource[0].value) {
      handleOnChange(dataSource[0].value)
    }
  }, [])

  return (
    <>
      <SelectMol
        options={getSelectOptions(dataSource)}
        onChange={handleOnChange}
        size={size}
        hasError={hasError}
        errorMessage={errorMessage}
      />

      {!isEmpty(parentData) && !isEmpty(parentData.children) ? (
        <PushOrg topSpace={2}>
          <SelectMol
            options={getSelectOptions(parentData.children)}
            onChange={handleOnChange}
            size={size}
            hasError={hasError}
            errorMessage={errorMessage}
          />
        </PushOrg>
      ) : null}
    </>
  )
}

const TreeSelect = connect(
  TreeSelectLayout,
  mapProps(
    {},
    (props: TreeSelectProps, field: TreeSelectField & GeneralField) => {
      return {
        dataSource: field.dataSource,
        onChange: props.onChange,
        size: props.size,
        errorMessage: get(field, "errors[0].messages[0]", ""),
        hasError: !isEmpty(field.errors),
      }
    }
  )
)

export default TreeSelect

import type { ReactElement } from "react"
import React from "react"
import ImageLinkAtm from "@onestore/hel/dist/components/atoms/ImageLinkAtm"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import GridOrg from "@onestore/hel/dist/components/organisms/GridOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import RelativeContainerOrg from "@onestore/hel/dist/components/organisms/RelativeContainerOrg"
import SurfaceOrg from "@onestore/hel/dist/components/organisms/SurfaceOrg"
import WidthOrg from "@onestore/hel/dist/components/organisms/WidthOrg"
import ConditionalWrapper from "@onestore/hel/dist/helpers/ConditionalWrapper"
import useBreakpoint from "@onestore/hel/dist/hooks/useBreakpoint"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import Buttons from "@gatsby-plugin-generic-page/components/Buttons"
import Header from "@gatsby-plugin-generic-page/components/Header"
import Media from "@gatsby-plugin-generic-page/components/Media"
import type { BannerSectionFlatData } from "@gatsby-plugin-generic-page/fragments/bannerSection"
import type { CtaElement } from "@gatsby-plugin-generic-page/fragments/ctaElement"
import getBorderRadiusSize from "@gatsby-plugin-generic-page/helpers/getBorderRadiusSize"
import { getCtaLinkFlatData } from "@gatsby-plugin-generic-page/helpers/getCtaElementFlatData"
import getCustomBackgroundColor from "@gatsby-plugin-generic-page/helpers/getCustomBackgroundColor"
import markdownOverrides from "@gatsby-plugin-generic-page/markdown/bannerSection"
import isEmpty from "~/lib/isEmpty"
import { CustomTextColors } from "~/types/squidex"
import Features from "./components/Features"

const contentWrapperGutterSpace = 4

function BannerSection({
  header,
  textColor,
  responsiveImages,
  features,
  buttons,
  surfaceColor,
  isImageVisibleOnSmall,
  isImageCentered,
  borderRadiusSize,
  sectionSettings,
}: BannerSectionFlatData): ReactElement<BannerSectionFlatData> {
  const isLargeUp = useBreakpoint("large")
  const customColor = surfaceColor && getCustomBackgroundColor(surfaceColor)
  const borderRadiusSizeByType = borderRadiusSize
    ? getBorderRadiusSize(borderRadiusSize)
    : undefined

  const getImage = () => {
    if (!isEmpty(buttons)) {
      const { title, href, openInNewTab, pages } = getCtaLinkFlatData(
        buttons[0]
      )

      return (
        <ImageLinkAtm
          title={title}
          image={<Media media={responsiveImages} />}
          to={!isEmpty(pages) ? pages[0].flatData.url : undefined}
          href={href}
          openInNewTab={openInNewTab}
        />
      )
    }

    return <Media media={responsiveImages} />
  }

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        <RelativeContainerOrg>
          <SurfaceOrg
            customColor={customColor}
            color={CustomTextColors[textColor]}
            spacingSpace={contentWrapperGutterSpace}
            borderRadiusSize={borderRadiusSizeByType || "none"}
          >
            <GridOrg
              columns={{ small: 1, medium: 1, large: 2 }}
              gutterSpace={4}
            >
              <AlignContentOrg
                horizontalAlign="center"
                verticalAlign={{
                  small: "top",
                  medium: "top",
                  large: "center",
                }}
              >
                <WidthOrg widthSpace={72}>
                  <Header {...header} titleOverrides={markdownOverrides} />
                </WidthOrg>

                {!isEmpty(features) && (
                  <PushOrg topSpace={3}>
                    <Features features={features} />
                  </PushOrg>
                )}

                {!isEmpty(buttons) && (
                  <PushOrg topSpace={3}>
                    <Buttons
                      buttons={buttons as CtaElement[]}
                      isOverlappingRelativeContainer
                    />
                  </PushOrg>
                )}
              </AlignContentOrg>

              <AlignContentOrg
                horizontalAlign="center"
                verticalAlign={isImageCentered ? "center" : "bottom"}
              >
                <BreakpointVisibilityOrg
                  from={isImageVisibleOnSmall ? "small" : "medium"}
                >
                  <ConditionalWrapper
                    condition={isLargeUp ? !isImageCentered : false}
                    wrapper={(children) => (
                      <PushOrg
                        bottomSpace={-1 * contentWrapperGutterSpace}
                        hasNoReset
                      >
                        {children}
                      </PushOrg>
                    )}
                  >
                    {getImage()}
                  </ConditionalWrapper>
                </BreakpointVisibilityOrg>
              </AlignContentOrg>
            </GridOrg>
          </SurfaceOrg>
        </RelativeContainerOrg>
      </BaseContainer>
    </BaseSection>
  )
}

export default BannerSection

import type { MarkdownToJSX } from "markdown-to-jsx"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import type { TextAtmProps } from "@onestore/hel/dist/components/atoms/TextAtm/TextAtm.props"
import Paragraph from "@gatsby-plugin-generic-page/markdown/components/Paragraph"
import ListItem from "./components/ListItem"

const markdownOverrides: MarkdownToJSX.Overrides = {
  p: {
    component: Paragraph,
    props: {
      typography: "medium1_h",
      pushSpace: 1,
    },
  },
  strong: {
    component: TextAtm,
    props: {
      typography: "medium3_h",
    } as TextAtmProps,
  },
  li: {
    component: ListItem,
  },
}

export default markdownOverrides

import { useState } from "react"
import {
  HTTP_STATUS,
  isResponseNone,
} from "@onestore/onestore-store-common/http"
import useDomainActionsDispatch from "@gatsby-plugin-domain-search/hooks/useDomainActionsDispatch"
import { getBasketDomains } from "@gatsby-plugin-domain-search/store/selectors"
import StoreAPI from "~/lib/api"
import { getDomainParts } from "~/lib/domain"
import { sendSiteSearchResultEvent } from "~/lib/ga4"
import isEmpty from "~/lib/isEmpty"
import { useAppSelector } from "~/store/hooks"
import type { CloudBlueDomain } from "~/types/squidex"
import type {
  CheckDomainsResponse,
  CheckMainDomainResponse,
} from "../../../../../lib/api/types"

interface Hook {
  phrase: string
  mainDomain: CheckMainDomainResponse | undefined
  results: CheckDomainsResponse
  setPhrase: { (phrase: string): void }
  onSearchClicked: { (phrase: string): void }
  basketDomains: string[]
  requestStatus: number
  hasContent: boolean
  onBasketAdd: {
    (domain: string, domainId: number, buttonId: string): void
  }
}

export default function useEmbeddedDomainSearchActions(
  domains: CloudBlueDomain[],
  ignoreHook: boolean = false
): Hook {
  const [mainDomain, setMainDomain] = useState<CheckMainDomainResponse>()
  const [results, setResults] = useState<CheckDomainsResponse>([])
  const [requestStatus, setRequestStatus] = useState(HTTP_STATUS.NONE)
  const [phrase, setPhrase] = useState("")
  const { addDomainsToBasket } = useDomainActionsDispatch()

  const resultsWithoutMainDomain = mainDomain
    ? results.filter((result) => {
        const [, extension] = getDomainParts(mainDomain.phrase)

        return result.extension !== extension
      })
    : []

  const basketDomains = useAppSelector(getBasketDomains)
  const onSearchClicked = (): void => {
    if (isEmpty(phrase)) {
      return
    }

    setRequestStatus(HTTP_STATUS.CONTINUE)
    setResults([])
    const [name] = getDomainParts(phrase)

    Promise.all([
      StoreAPI.checkMainDomain(phrase, [], null),
      StoreAPI.checkDomains([
        ...domains.map((domain) => `${name}.${domain.flatData.extension}`),
      ]),
    ])
      .catch(() => {
        setRequestStatus(HTTP_STATUS.NOT_FOUND)
        setResults([])
      })
      .then((results) => {
        sendSiteSearchResultEvent(phrase)
        setRequestStatus(HTTP_STATUS.OK)
        setMainDomain(results[0])
        setResults(results[1])
      })
  }

  const onBasketAdd = (domain: string, domainId: number, buttonId: string) => {
    addDomainsToBasket(
      [{ fqdn: domain, plan: domainId }],
      [],
      buttonId,
      ignoreHook
    )
  }

  return {
    phrase,
    mainDomain,
    results: resultsWithoutMainDomain,
    setPhrase,
    onSearchClicked,
    basketDomains,
    requestStatus,
    onBasketAdd,
    hasContent: !(isResponseNone(requestStatus) || !results),
  }
}

import type { ReactElement, ReactNode } from "react"
import React from "react"
import _t from "@core/i18n"
import type { UsePrices } from "@core/period-info"
import { PeriodInfo } from "@core/period-info"
import { PriceType } from "@core/types"
import { typeMatches } from "@onestore-graphql"
import { omit } from "lodash"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import ModalItemMol from "@onestore/hel/dist/components/molecules/ModalItemMol"
import { StyledLabelAtm } from "@onestore/hel/dist/components/molecules/ModalItemMol/ModalItemMol.styled"
import { CustomTextColor } from "@onestore/hel/dist/utils/getCustomColor"
import type { CloudBluePeriod } from "@gatsby-plugin-definitions/fragments/CloudBluePeriod"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import BaseMarkdown from "@gatsby-plugin-generic-page/components/Markdown/Base"
import { CustomTextColors } from "@gatsby-plugin-generic-page/fragments/customColor"
import type { ModalItemFlatData } from "@gatsby-plugin-generic-page/fragments/modalItem"
import { ModalItemProductType } from "@gatsby-plugin-generic-page/fragments/modalItem"
import getBonusUrl from "@gatsby-plugin-generic-page/helpers/getBonusUrl"
import getLinkProps from "@gatsby-plugin-generic-page/helpers/getLinkProps"
import {
  getStaticLowestPrice,
  getStaticPrices,
} from "@gatsby-plugin-generic-page/helpers/staticPrices"
import useSiteMetadataQuery from "~/hooks/useSiteMetadataQuery"
import isEmpty from "~/lib/isEmpty"

type LinkElement = {
  href?: string
  to?: string
  title: string
  openInNewTab?: boolean
}

export default function ModalItem({
  title,
  description,
  productType,
  productSaleConfiguration,
  domain,
  staticPrice,
  prefixAlternativeText,
  alternativeText,
  link,
  priceBoxBackgroundColor,
  priceBoxTextColor,
}: ModalItemFlatData): ReactElement<ModalItemFlatData> | null {
  const { defaultPriceType } = useSiteMetadataQuery()
  const externalLink = !isEmpty(link)
  const hasProduct =
    typeMatches(productType, ModalItemProductType.PLAN) &&
    !isEmpty(productSaleConfiguration) &&
    !isEmpty(productSaleConfiguration[0].flatData)
  const hasDomain =
    typeMatches(productType, ModalItemProductType.DOMAIN) && !isEmpty(domain)
  const hasStaticPrice =
    typeMatches(productType, ModalItemProductType.STATIC_PRICE) &&
    !isEmpty(staticPrice)
  const hasIndividualPrice =
    typeMatches(productType, ModalItemProductType.INDIVIDUAL_PRICE) &&
    !isEmpty(alternativeText)
  let period: CloudBluePeriod | undefined
  let linkElement: LinkElement = {
    href: "",
    to: "",
    title: "",
    openInNewTab: false,
  }
  let product: CloudBluePlan
  let prices: Omit<UsePrices, "renewPrice"> | undefined
  let periodText: string = ""
  let alternativeContent: ReactNode

  if (hasProduct) {
    product = productSaleConfiguration[0].flatData.plan[0]
    period = product?.flatData.periods.find((period) => {
      if (!isEmpty(productSaleConfiguration[0].flatData.period)) {
        return (
          period.period_name ===
          productSaleConfiguration[0].flatData.period.period
        )
      } else {
        return period.default
      }
    })
  } else if (hasDomain) {
    period = domain[0].flatData.periods.find((period) => period.default)
  } else if (hasStaticPrice) {
    const staticRegisterPrice = getStaticPrices(staticPrice, defaultPriceType)
    const staticLowestPrice = getStaticLowestPrice(
      staticPrice,
      defaultPriceType
    )

    const periodName = Object.keys(staticRegisterPrice)[0]

    const priceValue = staticRegisterPrice[periodName].price.value

    const lowestPrice = staticLowestPrice
      ? staticLowestPrice[periodName].price
      : priceValue

    const lowestPricePercent = staticLowestPrice
      ? staticLowestPrice[periodName].percent
      : "0%"

    prices = {
      priceValue,
      lowestPrice,
      lowestPricePercent,
    }

    periodText = staticRegisterPrice[periodName].price.suffix
  } else if (hasIndividualPrice) {
    alternativeContent = (
      <>
        <TextAtm
          typography={{ large: "large2", medium: "large2", small: "big2" }}
          color="white"
        >
          {`${prefixAlternativeText} `}

          <BaseMarkdown
            options={{
              wrapper: ({ children }) => (
                <TextAtm typography="nano2" color="white">
                  {children}
                </TextAtm>
              ),
              forceWrapper: true,
            }}
          >
            {alternativeText}
          </BaseMarkdown>
        </TextAtm>
      </>
    )
  }

  if (hasProduct || hasDomain) {
    const periodInfo = period ? new PeriodInfo(period) : undefined

    const periodPrices = periodInfo ? periodInfo.usePrices() : undefined

    const priceTypeText =
      defaultPriceType === PriceType.GROSS
        ? _t("prices.gross")
        : _t("prices.netto")

    const periodInfoText = periodInfo
      ? `${priceTypeText} ${periodInfo.periodText()}`
      : ""

    prices = {
      priceValue: periodPrices?.priceValue || "",
      lowestPrice: periodPrices?.lowestPrice || "",
      lowestPricePercent: periodPrices?.lowestPricePercent || "",
    }

    periodText = periodInfoText
  }

  const lowestPriceText = !isEmpty(prices) ? (
    <>
      {_t("prices.lowerPriceText") + " "}
      <TextAtm typography="nano2" hasNonBreakingSpace hasInheritedColor>
        {prices.lowestPrice}
        <StyledLabelAtm variant="dark" size="small" htmlTag="span">
          {prices.lowestPricePercent}
        </StyledLabelAtm>
      </TextAtm>
    </>
  ) : null

  if (externalLink) {
    linkElement = omit(getLinkProps(link), ["text", "onClick"])
  } else if (hasProduct) {
    linkElement.href = getBonusUrl(
      productSaleConfiguration[0].flatData,
      productSaleConfiguration[0].url
    )
    linkElement.title = title
  }

  const hasDarkText = !isEmpty(priceBoxTextColor)
    ? typeMatches(CustomTextColors[priceBoxTextColor], CustomTextColor.DARK)
    : false

  return (
    <ModalItemMol
      title={title}
      description={description}
      pricePrefix={_t("prices.promotion")}
      price={prices?.priceValue || ""}
      priceSuffix={periodText}
      color={
        !isEmpty(priceBoxBackgroundColor)
          ? priceBoxBackgroundColor.color
          : "#ffffff"
      }
      lowestPrice={lowestPriceText}
      alternativeContent={alternativeContent}
      link={{
        href: linkElement.href,
        to: linkElement.to,
        title: linkElement.title,
        openInNewTab: linkElement.openInNewTab,
      }}
      externalLink={externalLink}
      hasDarkText={hasDarkText}
    />
  )
}

import type { ReactElement, ReactNode } from "react"
import { useContext } from "react"
import React from "react"
import { typeMatches } from "@onestore-graphql"
import type { PlaceholderSection } from "@onestore-graphql/Sections"
import { graphql } from "gatsby"
import ParallaxWrapperOrg from "@onestore/hel/dist/components/organisms/ParallaxWrapperOrg"
import ConditionalWrapper from "@onestore/hel/dist/helpers/ConditionalWrapper"
import Section from "@gatsby-plugin-generic-page/components/Section"
import { SectionType } from "@gatsby-plugin-generic-page/types/sectionType"
import ExternalScripts from "~/components/ExternalScripts"
import Metadata from "~/components/Metadata"
import HasFullScreenContainerContext from "~/context/HasFullScreenContainerContext"
import { SwitchContentContextProvider } from "~/context/SwitchContentContext"
import isEmpty from "~/lib/isEmpty"
import type { GenericPage as SquidexGenericPage } from "~/types/squidex"
import SquidexToolbarSectionWrapper from "../../../gatsby-plugin-squidex/src/components/SquidexToolbarSectionWrapper"

interface GenericPageRendererProps {
  page: SquidexGenericPage
  onSectionRender?: (sectionId: string) => ReactNode
}

export function GenericPageRenderer({
  page,
  onSectionRender,
}: GenericPageRendererProps): ReactElement<GenericPageProps> {
  const { sections } = page.flatData

  return (
    <>
      <ExternalScripts page={page} />

      <Metadata
        metadata={page}
        isHomepage={page.flatData.url === "/"}
        noIndex={page.flatData.noIndex}
      />

      <SwitchContentContextProvider>
        <GenericPageSectionsRenderer
          pageId={page.id}
          sections={sections}
          onSectionRender={onSectionRender}
        />
      </SwitchContentContextProvider>
    </>
  )
}

interface SectionsRendererProps {
  pageId: string
  sections: SquidexGenericPage["flatData"]["sections"] | null
  onSectionRender?: (sectionId: string) => ReactNode
}

export function GenericPageSectionsRenderer({
  sections,
  pageId,
  onSectionRender,
}: SectionsRendererProps) {
  const hasFullScreenContainer = useContext(HasFullScreenContainerContext)

  if (isEmpty(sections)) {
    return null
  }

  const hasParallaxSection = sections.some((section) =>
    typeMatches(section.__typename, SectionType.PARALLAX_SECTION)
  )

  return (
    <ConditionalWrapper
      condition={hasParallaxSection ? hasFullScreenContainer : false}
      wrapper={(children) => (
        <ParallaxWrapperOrg>{children}</ParallaxWrapperOrg>
      )}
    >
      {sections.map((section) => {
        if (
          typeMatches(section.__typename, SectionType.PLACEHOLDER_SECTION) &&
          onSectionRender
        ) {
          const { flatData, url, id } = section as PlaceholderSection

          return (
            <SquidexToolbarSectionWrapper urlToSection={url} key={id}>
              {onSectionRender(flatData.id)}
            </SquidexToolbarSectionWrapper>
          )
        }

        return (
          <SquidexToolbarSectionWrapper
            urlToSection={section.url}
            /** key with page ID needed when we perform A/B test with the same section on either page */
            key={`${pageId}-${section.id}`}
          >
            <Section data={section} />
          </SquidexToolbarSectionWrapper>
        )
      })}
    </ConditionalWrapper>
  )
}

interface GenericPageProps {
  data: {
    GraphCmsSquidex: {
      page: SquidexGenericPage
    }
  }
}

interface PartialGenericPageRendererProps {
  page: SquidexGenericPage
  sectionTypes: SectionType[]
}

export function PartialGenericPageRenderer({
  page,
  sectionTypes,
}: PartialGenericPageRendererProps): ReactElement<PartialGenericPageRendererProps> {
  const { sections } = page.flatData

  return (
    <GenericPageSectionsRenderer
      pageId={page.id}
      sections={sections.filter((section) =>
        sectionTypes.some((type) => typeMatches(section.__typename, type))
      )}
    />
  )
}

export default function GenericPage({
  data: {
    GraphCmsSquidex: { page },
  },
}: GenericPageProps): ReactElement<GenericPageProps> {
  return <GenericPageRenderer page={page} />
}

export const query = graphql`
  query ($genericPageId: String!) {
    GraphCmsSquidex {
      page: findGenericPageContent(id: $genericPageId) {
        ...GenericPage
      }
    }
  }
`

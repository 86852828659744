export default function getPeriodUntillNow(
  period: {
    from: string
    to?: string
  }[]
): { from: string; to?: string } | undefined {
  const now = new Date().toISOString()
  const dates = period.filter((date) => date.from <= now)

  if (dates.length === 0) {
    return
  }

  if (dates.length === 1) {
    return dates[0]
  }

  return dates.reduce((prevDate, nextDate) =>
    new Date(prevDate.from) > new Date(nextDate.from) ? prevDate : nextDate
  )
}

import type { ReactElement } from "react"
import React from "react"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"

export interface StaticPriceProps {
  prefix?: string
  integer: string
  fraction: string
  currency: string
  suffix: string
  priceHasPrimaryColor?: boolean
}

export default function StaticPrice({
  prefix,
  integer,
  fraction,
  currency,
  suffix,
  priceHasPrimaryColor,
}: StaticPriceProps): ReactElement<StaticPriceProps> {
  return (
    <TextAtm
      typography="big3"
      data-test-id="advanced-product-card-regular-price"
    >
      {prefix ? (
        <TextAtm typography="tiny2" emphasis="medium">
          {prefix}{" "}
        </TextAtm>
      ) : null}
      <TextAtm color={priceHasPrimaryColor ? "primary" : undefined}>
        {integer}
      </TextAtm>
      {fraction} {currency}{" "}
      <TextAtm typography="tiny2" emphasis="medium">
        {suffix}
      </TextAtm>
    </TextAtm>
  )
}

import type { ReactElement } from "react"
import React from "react"
import type { MarkdownToJSX } from "markdown-to-jsx"
import Markdown from "markdown-to-jsx"

export interface BaseMarkdownProps {
  children: string
  options?: MarkdownToJSX.Options
}

/**
 * @info use markdown components from `/components/Markdown` if possible.
 */
function BaseMarkdown({
  options,
  children,
}: BaseMarkdownProps): ReactElement<BaseMarkdownProps> {
  return <Markdown options={options}>{children}</Markdown>
}

export default BaseMarkdown

import type { ReactElement } from "react"
import React from "react"
import ButtonAtm from "@onestore/hel/dist/components/atoms/ButtonAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import useTextToBeCopied from "@gatsby-plugin-generic-page/hooks/useTextToBeCopied"
import type { SquidexCopyToClipboardButtonWithText } from "./../../fragments/copyToClipboardButtonWithText"

function CopyToClipboardButtonWithText({
  text,
  textsToBeCopied,
  defaultButtonText,
  buttonIsClickedText,
}: SquidexCopyToClipboardButtonWithText): ReactElement<SquidexCopyToClipboardButtonWithText> {
  const { isButtonClicked, textToCopy, onButtonCLick } =
    useTextToBeCopied(textsToBeCopied)

  return (
    <>
      {text && <TextAtm typography="medium2_h">{text}</TextAtm>}

      <TextAtm typography="huge" htmlTag="h3" color="primary" pushSpace={4}>
        {textToCopy}
      </TextAtm>

      <ButtonAtm
        title={defaultButtonText}
        onClick={onButtonCLick}
        color={isButtonClicked ? "success" : "primary"}
      >
        {isButtonClicked ? buttonIsClickedText : defaultButtonText}
      </ButtonAtm>
    </>
  )
}

export default CopyToClipboardButtonWithText

import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import PremiumLogo from "@onestore-icons/domain-search/premium-logo.svg"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import FlexItemOrg from "@onestore/hel/dist/components/organisms/FlexItemOrg"
import WidthOrg from "@onestore/hel/dist/components/organisms/WidthOrg"

const PremiumAdditionalInfoBox = (): ReactElement => {
  const content = (
    <>
      <FlexItemOrg flexGrow={1}>
        <TextAtm typography="small4">{_t("domainSearch.nothingLost")}</TextAtm>
        <WidthOrg widthSpace={50}>
          <TextAtm typography="tiny1" pushSpace={{ bottom: 1 }}>
            {_t("domainSearch.additionalInfo")}
          </TextAtm>
        </WidthOrg>
      </FlexItemOrg>

      <PremiumLogo />
    </>
  )

  return (
    <>
      <BreakpointVisibilityOrg from="small" to="medium">
        {content}
      </BreakpointVisibilityOrg>

      <BreakpointVisibilityOrg from="medium">
        <FlexContainerOrg alignItems="center">{content}</FlexContainerOrg>
      </BreakpointVisibilityOrg>
    </>
  )
}

export default PremiumAdditionalInfoBox

import type { ReactElement } from "react"
import React from "react"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import CtaElement from "@gatsby-plugin-generic-page/components/CtaElement"
import Header from "@gatsby-plugin-generic-page/components/Header"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import StaticTable from "@gatsby-plugin-generic-page/components/StaticTable"
import type { StaticTableSectionFlatData } from "@gatsby-plugin-generic-page/types/sectionType"
import isEmpty from "~/lib/isEmpty"

export default function StaticTableSection({
  header,
  staticTable,
  text,
  ctaElements,
  sectionSettings,
}: StaticTableSectionFlatData): ReactElement<StaticTableSectionFlatData> {
  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer
        {...sectionSettings?.containerSettings}
        containerWidth={
          isEmpty(sectionSettings?.containerSettings?.containerWidth)
            ? undefined
            : "medium"
        }
      >
        <FlexContainerOrg direction="column" alignItems="center">
          {header ? <Header {...header} /> : null}

          <PushOrg topSpace={5} hasForcedFullWidth>
            <StaticTable rows={staticTable[0].flatData.rows} />
          </PushOrg>

          {text ? (
            <PushOrg topSpace={3}>
              <MarkdownText>{text}</MarkdownText>
            </PushOrg>
          ) : null}

          {!isEmpty(ctaElements) && (
            <PushOrg topSpace={5}>
              <CtaElement ctaElement={ctaElements} />
            </PushOrg>
          )}
        </FlexContainerOrg>
      </BaseContainer>
    </BaseSection>
  )
}

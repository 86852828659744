import type { MarkdownToJSX } from "markdown-to-jsx"
import type { NestedLinkAtmProps } from "@onestore/hel/dist/components/atoms/NestedLinkAtm"
import NestedLinkAtm from "@onestore/hel/dist/components/atoms/NestedLinkAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import type { TextAtmProps } from "@onestore/hel/dist/components/atoms/TextAtm/TextAtm.props"

const text = {
  bold: {
    component: TextAtm,
    props: {
      typography: "small4",
    } as TextAtmProps,
  },
  link: {
    component: NestedLinkAtm,
    props: {
      openInNewTab: true,
    } as NestedLinkAtmProps,
  },
}

export const infoBar: MarkdownToJSX.Overrides = {
  strong: text.bold,
  a: text.link,
}

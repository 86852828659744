import type { ReactElement, ReactNode } from "react"
import React from "react"
import { createContext } from "react"
import isServer from "~/lib/isServer"
import type { SearchUrlParameters } from "~/lib/storage"
import Storage from "~/lib/storage"

export const SearchUrlParametersContext = createContext<
  SearchUrlParameters | {}
>({})

export default SearchUrlParametersContext

export function SearchUrlParametersContextProvider({
  children,
}: {
  children: ReactNode
}): ReactElement {
  if (isServer()) {
    return <>{children}</>
  }

  const search = window.location.search

  if (search) {
    const hasUrlParameters = ["utm_source", "utm_medium", "utm_campaign"].some(
      (parameter) => search.includes(parameter)
    )

    if (hasUrlParameters) {
      const searchParams = new URLSearchParams(search)

      Storage.setSearchUrlParameters({
        utm_source: searchParams.get("utm_source"),
        utm_medium: searchParams.get("utm_medium"),
        utm_campaign: searchParams.get("utm_campaign"),
      })
    }
  }

  const currentSearchUrlParameters = Storage.getSearchUrlParameters()

  return (
    <SearchUrlParametersContext.Provider value={currentSearchUrlParameters}>
      {children}
    </SearchUrlParametersContext.Provider>
  )
}

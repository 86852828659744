import type { ReactElement } from "react"
import React from "react"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import GridOrg from "@onestore/hel/dist/components/organisms/GridOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import Header from "@gatsby-plugin-generic-page/components/Header"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import type { ComparisonTilesSectionFlatData } from "@gatsby-plugin-generic-page/types/sectionType"
import TextWithIndicator from "~/components/TextWithIndicator"
function ComparisonTilesSection({
  header,
  tiles,
  sectionSettings,
}: ComparisonTilesSectionFlatData): ReactElement<ComparisonTilesSectionFlatData> {
  return (
    <BaseSection {...sectionSettings} hasOverflowHidden>
      <BaseContainer {...sectionSettings?.containerSettings}>
        {header ? (
          <PushOrg bottomSpace={8}>
            <AlignContentOrg
              horizontalAlign={{
                small: "left",
                medium: "center",
                large: "center",
              }}
            >
              <Header {...header} />
            </AlignContentOrg>
          </PushOrg>
        ) : null}

        <GridOrg
          columns={{ small: 1, medium: 2, large: 3 }}
          gutterSpace={{ small: 5, medium: 10, large: 20 }}
          isCentered
        >
          {tiles.map(({ title, text, indicators }, index) => (
            <TextWithIndicator
              key={`text-with-indicator-${index}`}
              title={title}
              text={<MarkdownText>{text}</MarkdownText>}
              items={indicators}
              maximum={5}
            />
          ))}
        </GridOrg>
      </BaseContainer>
    </BaseSection>
  )
}

export default ComparisonTilesSection

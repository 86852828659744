import type { ReactElement } from "react"
import React from "react"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import GridOrg from "@onestore/hel/dist/components/organisms/GridOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import Header from "@gatsby-plugin-generic-page/components/Header"
import ProductCard from "@gatsby-plugin-generic-page/components/ProductCard"
import { ContainerWidthType } from "@gatsby-plugin-generic-page/fragments/containerSettings"
import type { ProductCardsSectionFlatData } from "@gatsby-plugin-generic-page/fragments/productCardsSection"
import isEmpty from "~/lib/isEmpty"

export default function ProductCardsSection({
  header,
  columns,
  cards,
  sectionSettings,
}: ProductCardsSectionFlatData): ReactElement<ProductCardsSectionFlatData> {
  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer
        {...sectionSettings?.containerSettings}
        containerWidth={
          isEmpty(sectionSettings?.containerSettings?.containerWidth)
            ? undefined
            : columns === 3
              ? ContainerWidthType.MEDIUM
              : undefined
        }
      >
        {!isEmpty(header) && (
          <PushOrg bottomSpace={{ small: 5, medium: 10, large: 10 }}>
            <AlignContentOrg
              horizontalAlign={{
                small: "left",
                medium: "left",
                large: "center",
              }}
            >
              <Header {...header} />
            </AlignContentOrg>
          </PushOrg>
        )}

        <GridOrg
          columns={{ small: 1, medium: 2, large: columns }}
          gutterSpace={4}
        >
          {cards.map((card) => (
            <ProductCard {...card} key={card.id} />
          ))}
        </GridOrg>
      </BaseContainer>
    </BaseSection>
  )
}

import type { ReactElement } from "react"
import { useContext } from "react"
import React, { useCallback } from "react"
import _t from "@core/i18n"
import { formatPrice } from "@core/pricing"
import { PriceType } from "@core/types"
import LabelAtm from "@onestore/hel/dist/components/atoms/LabelAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import type { Props } from "@onestore/hel/dist/components/molecules/DomainSearchResultItemMol"
import DomainSearchResultItemMol from "@onestore/hel/dist/components/molecules/DomainSearchResultItemMol"
import DomainPriceTypeContext from "@gatsby-plugin-domain-search/context/DomainPriceTypeContext"
import useDomainActionsDispatch from "@gatsby-plugin-domain-search/hooks/useDomainActionsDispatch"
import useDomainHookUrl from "@gatsby-plugin-domain-search/hooks/useDomainHookUrl"
import type { DomainBundleHook } from "@gatsby-plugin-domain-search/hooks/useMainDomainBundleQuery"
import useMainDomainBundleQuery from "@gatsby-plugin-domain-search/hooks/useMainDomainBundleQuery"
import type { DomainSearchBundleState } from "@gatsby-plugin-domain-search/store/selectors"
import type { DomainSearch } from "@gatsby-plugin-domain-search/types"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import Media from "@gatsby-plugin-generic-page/components/Media"
import { setAddCartSource } from "~/lib/basket"
import { getBrand } from "~/lib/config"
import isEmpty from "~/lib/isEmpty"

type BundleItemProps = {
  bundle: DomainSearchBundleState
  bundleIndex: number
}

export const getBundleAdditionalBox = (
  boxData: DomainBundleHook
): Props["additionalBox"] | undefined => {
  if (isEmpty(boxData.additionalBoxText)) {
    return undefined
  }

  return {
    text: (
      <MarkdownText
        typography={{ bold: "nano3", default: "nano1" }}
        emphasis="medium"
        hasOverridedSmallTypography
        pushSpace={0}
      >
        {boxData.additionalBoxText}
      </MarkdownText>
    ),
    icon: !isEmpty(boxData.additionalBoxIcon) ? (
      <Media media={boxData.additionalBoxIcon} />
    ) : undefined,
  }
}

export default function BundleItem({
  bundle,
  bundleIndex,
}: BundleItemProps): ReactElement | null {
  const { forDomainButtons } = useDomainHookUrl()
  const { addDomainsToBasket } = useDomainActionsDispatch()
  const bundlesDefinition = useMainDomainBundleQuery()
  const { currentPriceType } = useContext(DomainPriceTypeContext) || {}

  const bundleDefinition = bundlesDefinition.find(
    (definition) => definition.id === bundle.id
  )

  const insertedDomains: DomainSearch.DomainFqdnWithPlan[] = bundle.domains.map(
    (domain): DomainSearch.DomainFqdnWithPlan => {
      return {
        fqdn: domain.fqdn,
        plan: domain.plan_id,
      }
    }
  )

  const onClick = useCallback(() => {
    if (bundle.isWholeBundleInBasket) {
      document.location.replace(forDomainButtons)

      return
    }

    const mainDomains =
      getBrand() === "home" ? insertedDomains : insertedDomains.slice(0, 1)
    const childDomains = getBrand() === "home" ? [] : insertedDomains.slice(1)

    if (!isEmpty(mainDomains)) {
      const bundleDomainsExtensions = bundle.domains
        .map((domain) => domain.extension)
        .join("&")

      const sourceBundleDomains =
        `Domain Bundle ${bundleIndex + 1}&` + bundleDomainsExtensions

      setAddCartSource(mainDomains[0].plan, sourceBundleDomains)
    }

    addDomainsToBasket(mainDomains, childDomains, bundle.buttonId, false)
  }, [insertedDomains, bundle.isWholeBundleInBasket])

  if (bundle.pricing === null) {
    return null
  }

  const period = bundle.pricing.period_value
    ? ` / ${_t(`periodName.searchDomainPrice.${bundle.pricing.period_type}`, {
        smart_count: bundle.pricing.period_value,
      })}`
    : ""

  return (
    <DomainSearchResultItemMol
      domain={bundle.domains.map((domain) => ({
        name: domain.name,
        extension: `.${domain.extension}`,
      }))}
      price={
        <TextAtm typography="medium3_s">
          {_t("domainSearch.total")}{" "}
          {formatPrice(
            currentPriceType === PriceType.NETTO
              ? bundle.pricing.register_price
              : bundle.pricing.register_price_gross
          )}{" "}
          <TextAtm typography="xsmall1">{period}</TextAtm>
        </TextAtm>
      }
      isLoading={bundle.isLoading}
      hasSuccess={bundle.isWholeBundleInBasket}
      button={{
        text: bundle.isWholeBundleInBasket
          ? _t("button.success")
          : _t("domainSearch.button.addToCart"),
        title: bundle.isWholeBundleInBasket
          ? _t("button.success")
          : _t("domainSearch.button.addToCart"),
        onClick,
      }}
      caption={
        <LabelAtm size="small" variant="notifyLight">
          {bundleDefinition?.title || _t("domainSearch.groupHeaderText")}
        </LabelAtm>
      }
      additionalBox={
        !isEmpty(bundleDefinition)
          ? getBundleAdditionalBox(bundleDefinition)
          : undefined
      }
    />
  )
}

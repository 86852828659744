import type {
  CalculateBasketPayload,
  JSONResponse,
  PeriodName,
  PeriodType,
  PeriodValue,
  ProductPriceResponse,
  Query,
} from "./types"

function apiUrl(path: string, query: Query = {}) {
  const url = new URL(path, process.env.GATSBY_ONESTORE_API_HOST)

  Object.entries(query).forEach(([key, value]) => {
    if (value) {
      url.searchParams.append(key, value)
    }
  })

  return url.toString()
}

export function parseJSON<T extends JSONResponse>(
  response: Response
): Promise<T> {
  return response.json().catch((error) => {
    console.warn("Wystapił błąd podczas prasowania odpowiedzi z API", error)

    return {}
  })
}

export function errorJSON(json: any): any {
  return Promise.reject(json)
}

function fetchJSON<T extends JSONResponse>(
  url: string,
  opts: any = {},
  query: Query = {}
): Promise<T> {
  return fetch(apiUrl(url, query), opts)
    .then((response: Response): any => {
      if (response.ok) {
        return response
      }

      return response.json().then(errorJSON)
    })
    .then<T>(parseJSON)
}

export const calculateProduct = (
  items: CalculateBasketPayload
): Promise<ProductPriceResponse> => {
  return fetchJSON<ProductPriceResponse>("/products/calculate", {
    method: "POST",
    body: JSON.stringify(items),
    headers: {
      [`Accept`]: "application/json",
      [`Content-Type`]: "application/json",
    },
  })
}

export function sanitizePhrase(phrase: string): string {
  return encodeURIComponent(phrase.replace(/\//g, ""))
}

const periodTypes = ["h", "d", "m", "y"]

export enum PeriodTypeEnum {
  HOUR,
  DAY,
  MONTH,
  YEAR,
}

interface PeriodData {
  periodName: PeriodName
  periodType: PeriodType
  periodValue: PeriodValue
}

export function parsePeriod(periodName: string): PeriodData {
  const periodValue = parseInt(periodName, 10)

  const periodType = periodTypes.indexOf(periodName.slice(-1)) as PeriodType

  if (periodType < 0) {
    throw new Error(`PeriodParse error ${periodName}`)
  }

  return {
    periodName,
    periodType,
    periodValue,
  }
}

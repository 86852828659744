import { useStaticQuery, graphql } from "gatsby"
import type { CloudBluePeriod } from "@gatsby-plugin-definitions/fragments/CloudBluePeriod"
import isEmpty from "~/lib/isEmpty"

export interface DomainLink {
  extension: string
  to: string
  periods: CloudBluePeriod[]
}

interface Query {
  GraphCmsSquidex: {
    findDomainSearchPageSingleton: {
      id: string
      flatData: {
        domainLinks: {
          id: string
          flatData: {
            extension: string
            id: number
            periods: CloudBluePeriod[]
            pages?:
              | null
              | {
                  flatData: {
                    url: string
                  }
                }[]
          }
        }[]
      }
    }
  }
}

export default function useDomainLinksQuery(): DomainLink[] {
  const {
    GraphCmsSquidex: { findDomainSearchPageSingleton },
  } = useStaticQuery<Query>(graphql`
    {
      GraphCmsSquidex {
        findDomainSearchPageSingleton {
          id
          flatData {
            domainLinks {
              id
              flatData {
                id
                extension
                periods {
                  ...CloudBluePeriodValue
                }
                pages {
                  ... on GraphCmsSquidex_GenericPage {
                    id
                    flatData {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  if (isEmpty(findDomainSearchPageSingleton)) {
    return []
  }

  return findDomainSearchPageSingleton.flatData.domainLinks
    .filter((item) => !isEmpty(item.flatData.pages))
    .map(
      (item): DomainLink => ({
        to: item.flatData.pages![0].flatData.url,
        extension: item.flatData.extension,
        periods: item.flatData.periods,
      })
    )
}

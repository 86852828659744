import type { ReactElement } from "react"
import React from "react"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import ContentWithAsideOrg from "@onestore/hel/dist/components/organisms/ContentWithAsideOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import CtaElement from "@gatsby-plugin-generic-page/components/CtaElement"
import Header from "@gatsby-plugin-generic-page/components/Header"
import Paragraph from "@gatsby-plugin-generic-page/components/Paragraph"
import ProductBoxSlim from "@gatsby-plugin-generic-page/components/ProductBoxSlim"
import type { ContentWithProductBoxSectionFlatData } from "@gatsby-plugin-generic-page/fragments/contentWithProductBoxSection"
import TogglePriceType from "~/components/TogglePriceType"
import isEmpty from "~/lib/isEmpty"

function ContentWithProductBoxSection({
  header,
  paragraphs,
  ctaElements,
  boxes,
  hasTogglePriceType,
  isReversed,
  isCentered,
  sectionSettings,
}: ContentWithProductBoxSectionFlatData): ReactElement<ContentWithProductBoxSectionFlatData> {
  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        <ContentWithAsideOrg
          isReversed={isReversed}
          isCentered={isCentered}
          asideWidthSpace={50}
          gutterSizeSpace={22.5}
          content={
            <>
              {!isEmpty(header) ? (
                <PushOrg bottomSpace={4}>
                  <Header {...header} />
                </PushOrg>
              ) : null}

              {paragraphs.map((paragraph, index) => (
                <PushOrg key={index} bottomSpace={4}>
                  <Paragraph {...paragraph} />
                </PushOrg>
              ))}

              {!isEmpty(ctaElements) && <CtaElement ctaElement={ctaElements} />}
            </>
          }
          aside={
            <>
              {hasTogglePriceType ? (
                <PushOrg bottomSpace={{ small: 2, medium: 3, large: 3 }}>
                  <AlignContentOrg horizontalAlign="center">
                    <TogglePriceType />
                  </AlignContentOrg>
                </PushOrg>
              ) : null}

              <ProductBoxSlim key={boxes[0].id} productBox={boxes[0]} />
            </>
          }
        />
      </BaseContainer>
    </BaseSection>
  )
}

export default ContentWithProductBoxSection

import type { ReactElement, ReactNode } from "react"
import { useContext } from "react"
import React from "react"
import {
  FullScreenContainerPaddingUnitLarge,
  FullScreenContainerPaddingUnitSmall,
} from "@onestore/hel/dist/components/organisms/FullScreenContainerOrg"
import SectionOrg from "@onestore/hel/dist/components/organisms/SectionOrg"
import type { SectionOrgProps } from "@onestore/hel/dist/components/organisms/SectionOrg/SectionOrg.props"
import { headerHeight } from "@onestore/hel/dist/settings"
import type { SectionSettings } from "@gatsby-plugin-generic-page/fragments/sectionSettings"
import getBackground from "@gatsby-plugin-generic-page/helpers/getBackground"
import getHasZindex from "@gatsby-plugin-generic-page/helpers/getHasZindex"
import getResponsiveBackgroundImage from "@gatsby-plugin-generic-page/helpers/getResponsiveBackgroundImage"
import getSectionPadding from "@gatsby-plugin-generic-page/helpers/getSectionPadding"
import HasFullScreenContainerContext from "~/context/HasFullScreenContainerContext"
import isEmpty from "~/lib/isEmpty"

export interface BaseSectionProps
  extends SectionSettings,
    Omit<
      SectionOrgProps,
      | "id"
      | "spacing"
      | "backgroundImage"
      | "color"
      | "customColor"
      | "containerSettings"
      | "zIndex"
      | "isFullViewportHeight"
      | "isContentVerticalCenter"
      | "children"
    > {
  children: ReactNode
}

export default function BaseSection({
  color = "light",
  customColor,
  id,
  padding,
  backgroundImage,
  children,
  containerSettings,
  zIndex,
  isFullViewportHeight,
  viewportHeightOffsetUnit,
  isContentVerticalCenter,
  ...restProps
}: BaseSectionProps): ReactElement<BaseSectionProps> {
  const hasBackgroundImage = !isEmpty(backgroundImage)
  const backgroundImageObject = hasBackgroundImage
    ? backgroundImage[0]
    : undefined

  const responsiveBackgroundImage = backgroundImageObject
    ? getResponsiveBackgroundImage(
        backgroundImageObject.images,
        backgroundImageObject.position,
        backgroundImageObject.isCover
      )
    : undefined

  const background = getBackground(customColor)
  const spacing = getSectionPadding(padding)
  const index = zIndex
    ? zIndex
    : getHasZindex(containerSettings)
      ? 2
      : undefined

  const hasFullScreenContainerInFrame = useContext(
    HasFullScreenContainerContext
  )
  const viewportHeightOffset = hasFullScreenContainerInFrame
    ? {
        small: headerHeight + 2 * FullScreenContainerPaddingUnitSmall,
        medium: headerHeight + 2 * FullScreenContainerPaddingUnitLarge,
        large: headerHeight + 2 * FullScreenContainerPaddingUnitLarge,
      }
    : viewportHeightOffsetUnit

  return (
    <SectionOrg
      {...restProps}
      color={color}
      customColor={background.customColor}
      id={id}
      spacing={spacing}
      backgroundImage={
        hasBackgroundImage ? responsiveBackgroundImage : undefined
      }
      zIndex={index}
      isFullViewportHeight={isFullViewportHeight}
      viewportHeightOffsetUnit={viewportHeightOffset}
      isContentVerticalCenter={isContentVerticalCenter}
    >
      {children}
    </SectionOrg>
  )
}

import React, { ReactNode, ReactElement, memo } from "react"
import ErrorBoundary from "~/components/ErrorBoundary"
import Notification from "~/components/Notification"
import NotificationBar from "~/components/NotificationBar"
import UserdataContext from "~/context/UserdataContext"

interface LayoutProps {
  children: ReactNode
  path?: string
  pageContext?: {
    isBasket?: boolean
  }
}

function Invoices({
  children,
  pageContext,
}: LayoutProps): ReactElement<LayoutProps> {
  return (
    <ErrorBoundary>
      <UserdataContext>
        <Notification />

        <NotificationBar location="productPage" />

        {children}
      </UserdataContext>
    </ErrorBoundary>
  )
}

export default memo(Invoices)

import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import ButtonAtm from "@onestore/hel/dist/components/atoms/ButtonAtm"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import { scrollToAnchor } from "~/lib/dom"
import { COMPONENT_ID } from "~/types/component"
import AsideButtonWrapper from "./AsideButtonWrapper"

type ScrollToCategoriesButtonProps = {
  isExpandedOnMobile?: boolean
  isWider?: boolean
}

export default function ScrollToCategoriesButton({
  isExpandedOnMobile,
  isWider,
}: ScrollToCategoriesButtonProps): ReactElement<ScrollToCategoriesButtonProps> {
  const buttonText = isWider ? (
    <PushOrg leftSpace={1} rightSpace={1} hasNoReset>
      {_t("domainSearch.button.seeOtherExtensions")}
    </PushOrg>
  ) : (
    <>{_t("domainSearch.button.seeOtherExtensions")}</>
  )

  return (
    <AsideButtonWrapper>
      <ButtonAtm
        title={_t("domainSearch.button.seeOtherExtensions")}
        onClick={() => {
          scrollToAnchor(COMPONENT_ID.DOMAIN_SEARCH_SECTION_SECONDARY)
        }}
        color="success"
        size="small"
        isExpandedOnMobile={isExpandedOnMobile}
        isWider={isWider}
      >
        {buttonText}
      </ButtonAtm>
    </AsideButtonWrapper>
  )
}

import type { SyntheticEvent } from "react"
import { navigate } from "gatsby"
import { scrollToAnchor } from "~/lib/dom"
import useInternalSitesQuery from "./useInternalSitesQuery"
import type { LinkPropertiesHook } from "./useLinkProperties"

export type LinkPropertiesCollectionHook = Record<string, LinkPropertiesHook>

/**
 * @deprecated
 */
export default function useLinkPropertiesCollection(
  urls: string[]
): LinkPropertiesCollectionHook {
  const internalSites = useInternalSitesQuery()

  const linksProps: LinkPropertiesCollectionHook = {}

  urls.forEach((url) => {
    const href: string = url
    const isInternal = internalSites.includes(url.replace(/#.*$/, ""))
    const onClick = async (
      event: SyntheticEvent<Element, KeyboardEvent>
    ): Promise<void> => {
      if (
        event.isDefaultPrevented() ||
        event.nativeEvent.metaKey ||
        event.nativeEvent.ctrlKey
      ) {
        return
      }
      event.preventDefault()

      if (isInternal) {
        await navigate(url)
        const hash = url.slice(url.indexOf("#"))

        if (!hash) {
          return
        }

        scrollToAnchor(hash)
      } else {
        window.location.assign(url)
      }
    }

    linksProps[url] = {
      href,
      onClick,
    }
  })

  return linksProps
}

import { graphql } from "gatsby"
import type { CtaButtonElement } from "@gatsby-plugin-generic-page/fragments/ctaButtonElement"
import type { EmbeddedAssetSquidexMetadata } from "~/fragments/image"
import type { CtaSectionsModal } from "./ctaSectionsModal"

export enum IconPosition {
  prawo = "right",
  lewo = "left",
}

export type IconPositionKeys = keyof typeof IconPosition

export type FloatingCtaElementSectionFlatData = {
  ctaButton: CtaButtonElement[] | CtaSectionsModal[]
  sectionIdFrom?: string
  sectionIdTo?: string
  icon?: EmbeddedAssetSquidexMetadata[]
  iconPosition?: IconPositionKeys
  additionalTexts?: {
    text: string
    textIcon?: EmbeddedAssetSquidexMetadata[]
  }[]
}

export const query = graphql`
  fragment FloatingCtaElementSection on GraphCmsSquidex_FloatingCtaElementSection {
    __typename
    id
    url
    flatData {
      ctaButton {
        ...CtaButtonElement
        ...CtaSectionsModal
      }
      sectionIdFrom
      sectionIdTo
      icon {
        ...Image
      }
      iconPosition
      additionalTexts {
        text
        textIcon: icon {
          ...Image
        }
      }
    }
  }
`

export const FRAGMENT_FLOATING_CTA_ELEMENT_SECTION = `
  fragment FloatingCtaElementSection on FloatingCtaElementSection {
    __typename
    id
    url
    flatData {
      ctaButton {
        ...CtaButtonElement
        ...CtaSectionsModal
      }
      sectionIdFrom
      sectionIdTo
      icon {
        ...Image
      }
      iconPosition
      additionalTexts {
        text
        textIcon: icon {
          ...Image
        }
      }
    }
  }
`

import React from "react"
import { createContext, useState } from "react"
import type { PriceType } from "@core/types"
import { useIsomorphicLayoutEffect } from "@gatsby-plugin-generic-page/hooks/useIsomorphicLayoutEffect"
import useSiteMetadataQuery from "~/hooks/useSiteMetadataQuery"
import { productBoxClickEvent } from "~/lib/ga4"
import Storage from "~/lib/storage"

interface PriceTypeProps {
  currentPriceType: PriceType
  setPriceType: { (priceType: PriceType): void }
}

export const PriceTypeContext = createContext<PriceTypeProps | null>(null)

export default PriceTypeContext

export function PriceTypeContextProvider({ children }) {
  const { defaultPriceType } = useSiteMetadataQuery()
  const [currentPriceType, setCurrentPriceType] = useState(defaultPriceType)
  const storagePriceType = Storage.getPriceType()

  useIsomorphicLayoutEffect()(() => {
    if (storagePriceType) {
      setCurrentPriceType(storagePriceType)
    }
  }, [storagePriceType])

  const setPriceType = (priceType) => {
    Storage.setPriceType(priceType)

    productBoxClickEvent({
      productbox_type: "toggle",
      productbox_option: priceType,
    })
    setCurrentPriceType(priceType)
  }

  return (
    <PriceTypeContext.Provider
      value={{
        currentPriceType,
        setPriceType,
      }}
    >
      {children}
    </PriceTypeContext.Provider>
  )
}

/**
 * @module useNumberInputMolState
 * @category Hooks
 */

import { SyntheticEvent, useState } from "react"
import { NumberInputMolProps } from "@onestore/hel/dist/components/molecules/NumberInputMol"

export type Hook = {
  props: NumberInputMolProps
  currentValue: number
}

/**
 * Hook containing NumberInputMol state logic.
 * Handles component events like increment, decrement, change and data validation.
 *
 * @example
 * const {props, currentValue} = useNumberInputMolState(0, 10, 1)
 */

export default function useNumberInputMolState(
  minValue: number,
  maxValue: number,
  initialValue?: number
): Hook {
  const [value, setValue] = useState(initialValue ?? minValue)

  const handleDecrementButtonClick = (): void => {
    const newValue = value > minValue ? value - 1 : value

    setValue(newValue)
  }

  const handleIncrementButtonClick = (): void => {
    const newValue = value < maxValue ? value + 1 : value

    setValue(newValue)
  }

  const handleOnChange = (event: SyntheticEvent<HTMLInputElement>): void => {
    const currentValue = parseInt(event.currentTarget.value, 10)

    if (isNaN(currentValue)) {
      setValue(minValue)

      return
    }

    if (
      currentValue < minValue ||
      currentValue > maxValue ||
      currentValue === value
    ) {
      return
    }
    setValue(currentValue)
  }

  return {
    props: {
      value: `${value}`,
      isDecrementButtonDisabled: false,
      isIncrementButtonDisabled: false,
      onBlur: undefined,
      onChange: handleOnChange,
      onDecrementButtonClick: handleDecrementButtonClick,
      onIncrementButtonClick: handleIncrementButtonClick,
    },
    currentValue: value,
  }
}

import type { ReactElement } from "react"
import React from "react"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import GridOrg from "@onestore/hel/dist/components/organisms/GridOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import SurfaceOrg from "@onestore/hel/dist/components/organisms/SurfaceOrg"
import WidthOrg from "@onestore/hel/dist/components/organisms/WidthOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import Header from "@gatsby-plugin-generic-page/components/Header"
import TileProductPrice from "@gatsby-plugin-generic-page/components/TileProductPrice"
import type { TilesProductsPriceSectionFlatData } from "@gatsby-plugin-generic-page/fragments/tilesProductsPriceSection"
import getBackground from "@gatsby-plugin-generic-page/helpers/getBackground"
import isEmpty from "~/lib/isEmpty"

export default function TilesProductsPriceSection({
  header,
  tiles,
  isTilesActive,
  tilesBackground,
  background,
  sectionSettings,
}: TilesProductsPriceSectionFlatData): ReactElement<TilesProductsPriceSectionFlatData> {
  const { backgroundThemeColor, customColor, customOnColorText } =
    getBackground(background)
  const { customColor: tileCustomColor } = getBackground(tilesBackground)

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        <FlexContainerOrg justifyContent="center">
          <WidthOrg widthSpace={140}>
            <SurfaceOrg
              color={backgroundThemeColor || customOnColorText}
              customColor={customColor?.background}
              spacingSpace={{
                small: {
                  vertical: 4,
                  horizontal: 2,
                },
                medium: 8,
                large: 8,
              }}
            >
              {!isEmpty(header) && (
                <PushOrg bottomSpace={{ small: 4, medium: 4, large: 6 }}>
                  <AlignContentOrg horizontalAlign="center">
                    <Header {...header} />
                  </AlignContentOrg>
                </PushOrg>
              )}

              <GridOrg
                columns={{
                  small: 1,
                  medium: 2,
                  large: tiles.length,
                }}
                gutterSpace={{ small: 1, medium: 3, large: 3 }}
              >
                {tiles.map((tile, index) => (
                  <TileProductPrice
                    key={`${tile.title}-${index}`}
                    {...tile}
                    customColor={tileCustomColor}
                    isActive={isTilesActive}
                  />
                ))}
              </GridOrg>
            </SurfaceOrg>
          </WidthOrg>
        </FlexContainerOrg>
      </BaseContainer>
    </BaseSection>
  )
}

import type { ReactElement } from "react"
import React from "react"
import type { MarkdownToJSX } from "markdown-to-jsx"
import type { Typography } from "@onestore/hel/dist/contexts/TypographyContext"
import type Theme from "@onestore/hel/dist/typings/theme"
import getBaseMarkdownOverrides from "@gatsby-plugin-generic-page/helpers/getBaseMarkdownOverrides"
import BaseMarkdown from "../Base"

interface Props {
  children: string
  overrides?: MarkdownToJSX.Overrides
  typography?: Typography
  boldTypography?: Typography
  emphasis?: Theme.Emphasis
  hasNotPushSpace?: boolean
}

function Title({
  children,
  overrides,
  typography,
  boldTypography,
  emphasis,
  hasNotPushSpace,
}: Props): ReactElement<Props> {
  const baseMarkdownOverrides = getBaseMarkdownOverrides({
    typography: typography || "xhuge",
    boldTypography: boldTypography,
    emphasis: emphasis,
    pushSpace: hasNotPushSpace ? undefined : 1,
  })

  return (
    <BaseMarkdown
      options={{
        overrides: overrides || baseMarkdownOverrides,
        forceBlock: true,
      }}
    >
      {children}
    </BaseMarkdown>
  )
}

export default Title

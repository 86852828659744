import type { ReactElement } from "react"
import React from "react"
import { typeMatches } from "@onestore-graphql"
import { navigate } from "gatsby"
import SelectButtonAtm from "@onestore/hel/dist/components/atoms/SelectButtonAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import GridOrg from "@onestore/hel/dist/components/organisms/GridOrg"
import Picture from "@onestore/hel/dist/components/quarks/Picture"
import { SquidexComponentType } from "@gatsby-plugin-generic-page/types/componentType"
import ModalTemplateWrapper from "~/components/ModalTemplateWrapper"
import type { ModalButtonsData } from "~/fragments/modalButtos"
import type { ExternalLink } from "~/types/squidex"

interface LanguageModalProps {
  isOpen: boolean
  modalContentData: ModalButtonsData
  onCloseButtonClick?: () => void
}

export default function LanguageModal({
  isOpen,
  modalContentData,
  onCloseButtonClick,
}: LanguageModalProps): ReactElement<LanguageModalProps> {
  function handleClick(event, href) {
    event.preventDefault()
    navigate(href)
  }

  const buttons = modalContentData.buttons.map((button) => {
    let href

    if (
      typeMatches(button.link.__typename, SquidexComponentType.EXTERNAL_LINK)
    ) {
      const { href: hrefLink } = button.link as ExternalLink
      href = hrefLink
    }

    return (
      <SelectButtonAtm
        onClick={(event) => handleClick(event, href)}
        before={
          <Picture
            srcData={{
              src: button.image[0].url,
              alt: button.image[0].alt || "",
              width: button.image[0].width,
              height: button.image[0].height,
            }}
          />
        }
      >
        {button.name}
      </SelectButtonAtm>
    )
  })

  return (
    <ModalTemplateWrapper
      onCloseButtonClick={onCloseButtonClick}
      isOpen={isOpen}
      content={
        <>
          <TextAtm htmlTag="h2" typography="large2" pushSpace={2}>
            {modalContentData.title}
          </TextAtm>

          <TextAtm typography="medium1_h" pushSpace={2}>
            {modalContentData.text}
          </TextAtm>

          <GridOrg columns={{ small: 1, medium: 2, large: 2 }} gutterSpace={2}>
            {buttons}
          </GridOrg>
        </>
      }
    />
  )
}

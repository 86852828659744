import type { ReactElement } from "react"
import React from "react"
import type { MarkdownToJSX } from "markdown-to-jsx"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import type { TextAtmProps } from "@onestore/hel/dist/components/atoms/TextAtm/TextAtm.props"
import UnderlineLinkAtm from "@onestore/hel/dist/components/atoms/UnderlineLinkAtm"
import type { Props as ListProps } from "@onestore/hel/dist/components/molecules/ListMol"
import ListMol from "@onestore/hel/dist/components/molecules/ListMol"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import type { Typography } from "@onestore/hel/dist/contexts/TypographyContext"
import type Theme from "@onestore/hel/dist/typings/theme"
import type { NestedLinkProps } from "@gatsby-plugin-generic-page/components/NestedLink"
import NestedLink from "@gatsby-plugin-generic-page/components/NestedLink"
import type { LinkProps } from "@gatsby-plugin-generic-page/markdown/components/Link"
import Link from "@gatsby-plugin-generic-page/markdown/components/Link"
import Paragraph from "@gatsby-plugin-generic-page/markdown/components/Paragraph"

interface BaseMarkdownOverridesOptions {
  typography: Typography
  boldTypography?: Typography
  emphasis?: Theme.Emphasis
  listSize?: ListProps["size"]
  pushSpace?: number
}

function getBaseMarkdownOverrides({
  typography,
  boldTypography,
  emphasis,
  listSize,
  pushSpace,
}: BaseMarkdownOverridesOptions): MarkdownToJSX.Overrides {
  const strongHTMLTag = boldTypography && {
    strong: {
      component: TextAtm,
      props: {
        typography: boldTypography,
        emphasis,
        pushSpace,
      } as TextAtmProps,
    },
  }

  return {
    ...strongHTMLTag,
    h1: {
      component: TextAtm,
      props: {
        htmlTag: "h1",
        pushSpace,
        typography,
        emphasis,
      } as TextAtmProps,
    },
    h2: {
      component: TextAtm,
      props: {
        htmlTag: "h2",
        pushSpace,
        typography,
        emphasis,
      } as TextAtmProps,
    },
    h3: {
      component: TextAtm,
      props: {
        htmlTag: "h3",
        pushSpace,
        typography,
        emphasis,
      } as TextAtmProps,
    },
    p: {
      component: Paragraph,
      props: {
        typography,
        emphasis,
        pushSpace,
      },
    },
    span: {
      component: TextAtm,
      props: {
        typography,
        emphasis,
        pushSpace,
      } as TextAtmProps,
    },
    a: {
      component: UnderlineLinkAtm,
      props: {
        typography,
        emphasis,
        openInNewTab: true,
      },
    },
    Link: {
      component: Link,
      props: {
        typography,
      } as LinkProps,
    },
    NestedLink: {
      component: NestedLink,
      props: {
        typography,
      } as NestedLinkProps,
    },
    ul: {
      component: ({ children }) => (
        <PushOrg bottomSpace={2}>
          <ListMol
            items={children.map((child: ReactElement) => child.props.children)}
            type="tick"
            size={listSize || "medium"}
            emphasis={emphasis}
          />
        </PushOrg>
      ),
    },
  }
}

export default getBaseMarkdownOverrides

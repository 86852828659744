import type { ReactElement } from "react"
import React from "react"
import type { CtaModal as CtaModalType } from "@gatsby-plugin-generic-page/fragments/ctaModal"
import ModalContainer from "~/components/ModalContainer"
import ModalTemplateWrapper from "~/components/ModalTemplateWrapper"
import Texts from "../Texts"

interface Props extends Omit<CtaModalType, "__typename"> {
  isOpen: boolean
  onCloseButtonClick?: () => void
}

function CtaModal({
  texts,
  title,
  isOpen,
  onCloseButtonClick,
}: Props): ReactElement<Props> {
  return (
    <ModalContainer>
      <ModalTemplateWrapper
        isOpen={isOpen}
        onCloseButtonClick={onCloseButtonClick}
        title={title}
        content={<Texts {...texts} />}
      />
    </ModalContainer>
  )
}

export default CtaModal

import type {
  BasicNestedPageSearchData,
  BasicPageSearchData,
} from "@onestore-graphql/Model"
import { useStaticQuery, graphql } from "gatsby"

interface SearchData {
  queryGenericPageContents: BasicPageSearchData[]
  queryPitCategoryContents: BasicNestedPageSearchData[]
}
interface Query {
  GraphCmsSquidex: SearchData
}

export type CoreSearchResults = BasicPageSearchData[]

export default function useAllCoreProductsSearchQuery(): CoreSearchResults {
  const {
    GraphCmsSquidex: { queryGenericPageContents, queryPitCategoryContents },
  } = useStaticQuery<Query>(graphql`
    {
      GraphCmsSquidex {
        queryGenericPageContents(
          filter: "data/searchMetadata/iv ne null"
          top: 1000
          orderby: "data/searchMetadata/iv desc"
        ) {
          id
          flatData {
            searchMetadata {
              ...SearchMetadata
            }
            url
          }
        }
        queryPitCategoryContents(
          filter: "data/searchMetadata/iv ne null"
          top: 1000
          orderby: "data/searchMetadata/iv desc"
        ) {
          id
          flatData {
            searchMetadata {
              ...SearchMetadata
            }
            pages {
              flatData {
                url
              }
            }
          }
        }
      }
    }
  `)

  const normalizedPit: BasicPageSearchData[] = queryPitCategoryContents.map(
    (item: BasicNestedPageSearchData): BasicPageSearchData => ({
      id: item.id,
      flatData: {
        searchMetadata: item.flatData.searchMetadata,
        url: item.flatData.pages[0].flatData.url,
      },
    })
  )

  return [...queryGenericPageContents, ...normalizedPit]
}

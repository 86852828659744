import type { ReactElement } from "react"
import React from "react"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import Link from "@onestore/hel/dist/components/quarks/Link"
import ConditionalWrapper from "@onestore/hel/dist/helpers/ConditionalWrapper"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import Media from "@gatsby-plugin-generic-page/components/Media"
import type { PromoSectionFlatData } from "@gatsby-plugin-generic-page/fragments/promoSection"
import { useLinkReplacer } from "@gatsby-plugin-generic-page/hooks/useLinkReplacer"
import { SquidexPromoSectionLinkTypes } from "@gatsby-plugin-generic-page/types/promoSectionTypes"
import { getAnchorProperties } from "~/lib/dom"
import { sendEvent } from "~/lib/ga4"

function PromoSection({
  responsiveImages,
  linkType,
  pages,
  href,
  anchorId,
  linkTitle,
  gaEvent,
  sectionSettings,
}: PromoSectionFlatData): ReactElement<PromoSectionFlatData> {
  const to =
    linkType === SquidexPromoSectionLinkTypes.INTERNAL_LINK && pages
      ? pages[0].flatData.url
      : undefined
  const link = useLinkReplacer(href)
  const linkHref =
    linkType === SquidexPromoSectionLinkTypes.EXTERNAL_LINK && link
      ? link
      : undefined
  const anchorProperties = SquidexPromoSectionLinkTypes.SCROLL_TO_SECTION
    ? anchorId && getAnchorProperties(anchorId)
    : undefined

  const eventGa = gaEvent ? () => sendEvent(gaEvent) : undefined
  const eventScroll =
    linkType === SquidexPromoSectionLinkTypes.SCROLL_TO_SECTION
      ? (event) => anchorProperties && anchorProperties.onClick(event)
      : undefined

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        <AlignContentOrg horizontalAlign="center">
          <ConditionalWrapper
            condition={linkType !== SquidexPromoSectionLinkTypes.WITH_NO_LINK}
            wrapper={(children) => (
              <Link
                title={linkTitle || ""}
                to={to}
                href={anchorProperties ? anchorProperties.href : linkHref}
                onClick={(event) => {
                  eventGa && eventGa()
                  eventScroll && eventScroll(event)
                }}
              >
                {children}
              </Link>
            )}
          >
            {responsiveImages ? <Media media={responsiveImages} /> : null}
          </ConditionalWrapper>
        </AlignContentOrg>
      </BaseContainer>
    </BaseSection>
  )
}

export default PromoSection

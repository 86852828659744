import type { ReactElement } from "react"
import React from "react"
import ReactDOMServer from "react-dom/server"
import { Helmet } from "react-helmet"
import { helmetJsonLdProp } from "react-schemaorg"
import type { WithContext, FAQPage } from "schema-dts"

interface FaqItem {
  title: string
  text: any
  textHtml?: string
  isFaqPage?: boolean
}

interface FaqPageSchemaProps {
  faqContent: FaqItem[]
}

export default function FaqPageSchema({
  faqContent,
}: FaqPageSchemaProps): ReactElement<FaqPageSchemaProps> | null {
  const filteredFaqContent = faqContent.filter((faq) => faq.isFaqPage)
  const faqElements =
    filteredFaqContent.length > 0 ? filteredFaqContent : faqContent

  const item: WithContext<FAQPage> = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: faqElements.map((faq) => {
      const text = faq.textHtml || ReactDOMServer.renderToString(faq.text)

      return {
        "@type": "Question",
        name: faq.title,
        acceptedAnswer: {
          "@type": "Answer",
          text: text,
        },
      }
    }),
  }

  return <Helmet script={[helmetJsonLdProp<FAQPage>(item)]} />
}

import type { ReactElement } from "react"
import React from "react"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import CtaElement from "@gatsby-plugin-generic-page/components/CtaElement"
import Media from "@gatsby-plugin-generic-page/components/Media"
import { ContainerWidthType } from "@gatsby-plugin-generic-page/fragments/containerSettings"
import type { ImageWithButtonsSectionFlatData } from "@gatsby-plugin-generic-page/types/sectionType"
import isEmpty from "~/lib/isEmpty"

export default function ImageWithButtonsSection({
  title,
  responsiveImages,
  buttons,
  sectionSettings,
}: ImageWithButtonsSectionFlatData): ReactElement<ImageWithButtonsSectionFlatData> {
  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer
        {...sectionSettings?.containerSettings}
        containerWidth={
          isEmpty(sectionSettings?.containerSettings?.containerWidth)
            ? undefined
            : ContainerWidthType.MEDIUM
        }
      >
        <FlexContainerOrg direction="column" alignItems="center">
          <TextAtm htmlTag="h2" typography="xhuge" align="center">
            {title}
          </TextAtm>

          <PushOrg topSpace={5} bottomSpace={5}>
            <Media media={responsiveImages} />
          </PushOrg>

          <PushOrg topSpace={3}>
            <CtaElement ctaElement={buttons} />
          </PushOrg>
        </FlexContainerOrg>
      </BaseContainer>
    </BaseSection>
  )
}

import { get } from "lodash"
import { createWrapperWithStore } from "~/wrappers"
import { wrapWithLayout, wrapWithCheckoutLayout } from "~/wrappers"
import { saleConfigurationInit } from "../store/actions"

export default createWrapperWithStore(
  (store) =>
    ({ element, props }, options) => {
      if (
        props.data &&
        props.data.GraphCmsSquidex &&
        props.data.GraphCmsSquidex.product
      ) {
        const product = props.data.GraphCmsSquidex.product
        const hasPlan = !!get(
          product,
          "flatData.saleConfigurations[0].flatData.defaultCloudBluePlan[0].id"
        )

        if (hasPlan) {
          store.dispatch(
            saleConfigurationInit(
              product.flatData.saleConfigurations[0].id,
              product.flatData.saleConfigurations[0]
            )
          )
        }
      }

      if (get(props, "pageContext.checkout")) {
        return wrapWithCheckoutLayout(element, props)
      }

      if (get(props, "pageContext.wrapperLayout")) {
        return element
      }

      return wrapWithLayout(element, props, ["/_preview/", "/__debug/"])
    }
)

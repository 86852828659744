import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import { chunk } from "lodash"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import ExpandableContentOrg from "@onestore/hel/dist/components/organisms/ExpandableContentOrg"
import GridOrg from "@onestore/hel/dist/components/organisms/GridOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import ConditionalWrapper from "@onestore/hel/dist/helpers/ConditionalWrapper"
import useBreakpoint from "@onestore/hel/dist/hooks/useBreakpoint"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import BaseMarkdown from "@gatsby-plugin-generic-page/components/Markdown/Base"
import type {
  SeoContentSectionFlatData,
  SeoParagraph,
} from "@gatsby-plugin-generic-page/types/sectionType"
import { sendContentShowEvent } from "~/lib/ga4"
import { sectionParagraphText } from "../markdown/sectionParagraphText"

interface ColumnProps {
  column: SeoParagraph[]
}

function Column({ column }: ColumnProps): ReactElement<ColumnProps> {
  return (
    <div>
      {column.map(({ title, text }) => (
        <PushOrg bottomSpace={4} key={title}>
          <TextAtm typography="medium3_h">{title}</TextAtm>

          <BaseMarkdown
            options={{
              wrapper: ({ children }) => (
                <TextAtm typography="medium1_h">{children}</TextAtm>
              ),
              forceWrapper: true,
              overrides: sectionParagraphText,
            }}
          >
            {text}
          </BaseMarkdown>
        </PushOrg>
      ))}
    </div>
  )
}

export default function SeoContentSection({
  title,
  paragraphs,
  sectionSettings,
}: SeoContentSectionFlatData): ReactElement<SeoContentSectionFlatData> {
  const isSmallOnly = useBreakpoint("small", "medium")
  const isLargeUp = useBreakpoint("large")

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        <TextAtm typography="large2" pushSpace={isSmallOnly ? 4 : 5}>
          {title}
        </TextAtm>

        <ConditionalWrapper
          condition={!isLargeUp}
          wrapper={(children) => (
            <ExpandableContentOrg
              showMoreDetails={{
                text: _t("showMore"),
                title: _t("showMore"),
                onClick: sendContentShowEvent,
              }}
              heightBreakpoint="short"
              breakpoint={{ from: "small", to: "medium" }}
            >
              {children}
            </ExpandableContentOrg>
          )}
        >
          <GridOrg
            columns={{ small: 1, medium: 1, large: 2 }}
            gutterSpace={{ small: 3, medium: 3, large: { x: 10, y: 3 } }}
          >
            {chunk(paragraphs, Math.ceil(paragraphs.length / 2)).map(
              (column, index) => (
                <Column column={column} key={index} />
              )
            )}
          </GridOrg>
        </ConditionalWrapper>
      </BaseContainer>
    </BaseSection>
  )
}

import React, { ReactNode, ReactElement } from "react"

interface ContentSwitcherProps {
  items: {
    [key: string]: ItemProps
  }
  selected: string
  children: { (args: ItemProps): ReactNode }
}

interface ItemProps {
  [key: string]: string
}

export default function ContentSwitcher({
  items,
  selected,
  children,
}: ContentSwitcherProps): ReactElement<ContentSwitcherProps> | null {
  if (!selected) {
    return null
  }

  return <>{children(items[selected])}</>
}

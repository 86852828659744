import type { ResponsiveBackgroundImage } from "@onestore/hel/dist/typings/types"
import assetsSquidexToImageObject from "~/lib/assetsSquidexToImageObject"
import type { BackgroundImagePosition, ResponsiveImages } from "~/types/squidex"

export default function getResponsiveBackgroundImage(
  images: ResponsiveImages,
  position?: BackgroundImagePosition,
  isCover?: boolean
): ResponsiveBackgroundImage {
  return {
    position,
    isCover,
    large: assetsSquidexToImageObject(images.large, ""),
    medium: images.medium
      ? assetsSquidexToImageObject(images.medium, "")
      : undefined,
    small: images.small
      ? assetsSquidexToImageObject(images.small, "")
      : undefined,
  }
}

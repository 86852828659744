import React from "react"
import type { ReactElement } from "react"
import Picture from "@onestore/hel/dist/components/quarks/Picture"
import type { ImageProps } from "@onestore/hel/dist/components/quarks/Picture/Picture.types"
import assetsSquidexToImageObject from "~/lib/assetsSquidexToImageObject"
import isEmpty from "~/lib/isEmpty"
import type { ResponsiveImages } from "~/types/squidex"

interface Props {
  images: ResponsiveImages
}
function ResponsiveImage({ images }: Props): ReactElement<ImageProps> {
  const largeImageObject: ImageProps = assetsSquidexToImageObject(
    images.large,
    ""
  )

  const mediumImageObject: ImageProps | undefined = !isEmpty(images.medium)
    ? assetsSquidexToImageObject(images.medium, "")
    : undefined

  const smallImageObject: ImageProps | undefined = !isEmpty(images.small)
    ? assetsSquidexToImageObject(images.small, "")
    : undefined

  return (
    <Picture
      srcData={{
        small: smallImageObject,
        medium: mediumImageObject,
        large: largeImageObject,
      }}
    />
  )
}

export default ResponsiveImage

import type { ReactElement } from "react"
import React from "react"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import ItemDescriptionBoxMol from "@onestore/hel/dist/components/molecules/ItemDescriptionBoxMol"
import { LinkBox } from "@onestore/hel/dist/components/molecules/TileMol"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import TilesOrg from "@onestore/hel/dist/components/organisms/TilesOrg"
import ConditionalWrapper from "@onestore/hel/dist/helpers/ConditionalWrapper"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import CtaElement from "@gatsby-plugin-generic-page/components/CtaElement"
import Header from "@gatsby-plugin-generic-page/components/Header"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import Media from "@gatsby-plugin-generic-page/components/Media"
import type {
  Tile,
  TilesSectionFlatData,
} from "@gatsby-plugin-generic-page/fragments/tilesSection"
import { getActionTypeData } from "@gatsby-plugin-generic-page/helpers/getCtaElementFlatData"
import isEmpty from "~/lib/isEmpty"
import type { Pages } from "~/types/squidex"

interface CtaLinkProps {
  title?: string
  href?: string
  openInNewTab?: boolean
  pages?: Pages
  isClickable: boolean
}

export default function TilesSection({
  header,
  tiles,
  themeBackgroundColor,
  sectionSettings,
}: TilesSectionFlatData): ReactElement<TilesSectionFlatData> {
  const getLinkProps = (tile: Tile): CtaLinkProps => {
    if (isEmpty(tile.ctaElements) || tile.ctaElements.length > 1) {
      return { isClickable: false }
    }

    const { ctaType, title } = tile.ctaElements[0].flatData
    const { href, pages, openInNewTab } = getActionTypeData(ctaType.actionType)

    if (isEmpty(pages) && isEmpty(href)) {
      return { isClickable: false }
    }

    return { title, href, pages, openInNewTab, isClickable: true }
  }

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        {!isEmpty(header) && (
          <PushOrg bottomSpace={{ small: 4, medium: 6, large: 10 }}>
            <AlignContentOrg
              horizontalAlign={{
                small: "left",
                medium: "center",
                large: "center",
              }}
            >
              <Header {...header} />
            </AlignContentOrg>
          </PushOrg>
        )}

        <TilesOrg>
          {tiles.map((tile) => {
            const { title, href, pages, openInNewTab, isClickable } =
              getLinkProps(tile)

            return (
              <ItemDescriptionBoxMol
                key={tile.images[0].id}
                backgroundColor={themeBackgroundColor.background}
                image={<Media media={tile.images} />}
                hasImageStickyToBottom
                cssPosition={isClickable ? "relative" : undefined}
              >
                <ConditionalWrapper
                  condition={isClickable}
                  wrapper={(children) => (
                    <LinkBox
                      to={!isEmpty(pages) ? pages[0].flatData.url : undefined}
                      href={href}
                      title={title as string}
                      openInNewTab={openInNewTab}
                    >
                      {children}
                    </LinkBox>
                  )}
                >
                  <TextAtm typography="xlarge" htmlTag="h3" pushSpace={3}>
                    {tile.title}
                  </TextAtm>
                </ConditionalWrapper>
                <PushOrg bottomSpace={3}>
                  <MarkdownText
                    typography={{ default: "small1", bold: "small3" }}
                  >
                    {tile.text}
                  </MarkdownText>
                </PushOrg>

                {!isEmpty(tile.ctaElements) ? (
                  <CtaElement ctaElement={tile.ctaElements} />
                ) : undefined}
              </ItemDescriptionBoxMol>
            )
          })}
        </TilesOrg>
      </BaseContainer>
    </BaseSection>
  )
}

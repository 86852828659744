import type { ReactElement, ReactNode } from "react"
import React from "react"
import FloatingButtonMol from "@onestore/hel/dist/components/molecules/FloatingButtonMol"
import { IconAlign } from "@onestore/hel/dist/components/molecules/FloatingButtonMol"
import OverlayContainerOrg, {
  sizes,
  startPositions,
} from "@onestore/hel/dist/components/organisms/OverlayContainerOrg"
import type { CtaSectionsModal } from "@gatsby-plugin-generic-page/fragments/ctaSectionsModal"
import type { IconPositionKeys } from "@gatsby-plugin-generic-page/fragments/floatingCtaElementSection"
import { IconPosition } from "@gatsby-plugin-generic-page/fragments/floatingCtaElementSection"
import { GenericPageSectionsRenderer } from "@gatsby-plugin-generic-page/GenericPage"
import getBaseMarkdownOverrides from "@gatsby-plugin-generic-page/helpers/getBaseMarkdownOverrides"
import useSectionsModal from "@gatsby-plugin-generic-page/hooks/useSectionsModal"
import MarkdownTitle from "../Markdown/Title"

export interface FloatingCtaSectionsModalProps {
  ctaSectionsModal: CtaSectionsModal
  isVisible?: boolean
  icon?: ReactNode
  iconPosition?: IconPositionKeys
}

function FloatingCtaSectionsModal({
  ctaSectionsModal,
  isVisible,
  icon,
  iconPosition,
}: FloatingCtaSectionsModalProps): ReactElement<FloatingCtaSectionsModalProps> {
  const { header, title, text, sections, background, gaEvent } =
    ctaSectionsModal.flatData
  const { isOpen, handleOpenOverlay, handleCloseOverlay } =
    useSectionsModal(gaEvent)

  return (
    <>
      <FloatingButtonMol
        icon={icon}
        iconPosition={
          iconPosition ? IconPosition[iconPosition] : IconAlign.LEFT
        }
        title={title}
        text={text}
        onClick={handleOpenOverlay}
        isVisible={!!isVisible}
      />

      {isOpen ? (
        <OverlayContainerOrg
          hasSections
          size={sizes.FULL}
          startPosition={startPositions.RIGHT}
          header={
            <MarkdownTitle
              overrides={getBaseMarkdownOverrides({
                typography: "xlarge",
              })}
            >
              {header}
            </MarkdownTitle>
          }
          backgroundColor={background?.background}
          onCloseButtonClick={handleCloseOverlay}
        >
          <GenericPageSectionsRenderer sections={sections} pageId={""} />
        </OverlayContainerOrg>
      ) : null}
    </>
  )
}

export default FloatingCtaSectionsModal

import type { ReactElement, ReactNode } from "react"
import React from "react"
import FaqMol from "@onestore/hel/dist/components/molecules/FaqMol"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import FaqPageSchema from "~/components/FaqPageSchema"
import type { Faq } from "~/fragments/faq"
import { sendFaqShowEvent } from "~/lib/ga4"

export interface FaqProps {
  title: string
  text: string | ReactNode
  isOpen?: boolean
  textHtml?: string
}

export interface FaqContentProps {
  content?: FaqProps[]
  faqs?: Faq[]
  hasFaqSchema?: boolean
  hasDarkMode?: boolean
}

export default function FaqContent({
  content,
  faqs,
  hasFaqSchema,
  hasDarkMode,
}: FaqContentProps): ReactElement<FaqContentProps> | null {
  const faqsContent =
    (faqs &&
      faqs.map((faq) => ({
        title: faq.flatData.title,
        text: <MarkdownText>{faq.flatData.text}</MarkdownText>,
        isOpen: faq.flatData.isOpen,
        textHtml: faq.data ? faq.data.text.iv : undefined,
      }))) ||
    content

  if (!faqsContent) {
    return null
  }

  const handleOnClick = (isOpen: boolean, title: string) => {
    if (isOpen) {
      return
    }

    sendFaqShowEvent(title)
  }

  return (
    <>
      {hasFaqSchema ? <FaqPageSchema faqContent={faqsContent} /> : null}

      {faqsContent.map((element, index) => (
        <PushOrg bottomSpace={2} key={`${element.title}_${index}`}>
          <FaqMol
            data-test-id="faq-row"
            title={element.title}
            content={element.text}
            isOpen={element.isOpen}
            onClick={(isOpen: boolean) => handleOnClick(isOpen, element.title)}
            isDarkMode={hasDarkMode}
          />
        </PushOrg>
      ))}
    </>
  )
}

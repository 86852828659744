import type { ReactElement, ReactNode } from "react"
import React, { createContext, useEffect } from "react"
import type { Basket } from "@onestore/api/basket"
import { getBasket } from "@gatsby-plugin-basket/store/actions"
import { getBasketToken } from "@gatsby-plugin-basket/store/selectors"
import { useAppSelector, useAppDispatch } from "~/store/hooks"
import { getUserDataReady } from "~/store/selectors"

const CheckoutContext = createContext(false)

interface CheckoutContextProps {
  children: ReactNode
  skipBasketContext: boolean
}
export default function ({
  children,
  skipBasketContext,
}: CheckoutContextProps): ReactElement {
  const dispatch = useAppDispatch()
  const token = useAppSelector<Basket.Token>(getBasketToken)
  const isReady = useAppSelector<boolean>(getUserDataReady)

  useEffect(() => {
    if (skipBasketContext) {
      return
    }
    dispatch(getBasket(token))
  }, [])

  return (
    <CheckoutContext.Provider value={isReady}>
      {children}
    </CheckoutContext.Provider>
  )
}

import React from "react"
import type { ReactElement, SyntheticEvent } from "react"
import _t from "@core/i18n"
import { PriceType } from "@core/types"
import Tick from "@onestore-icons/tick.svg"
import ButtonAtm from "@onestore/hel/dist/components/atoms/ButtonAtm"
import PriceMol from "@onestore/hel/dist/components/molecules/PriceMol"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import type { Props as PushOrgProps } from "@onestore/hel/dist/components/organisms/PushOrg"
import ConditionalWrapper from "@onestore/hel/dist/helpers/ConditionalWrapper/index"
import useBasketActions from "@gatsby-plugin-basket/hooks/useBasketActions"
import type { BundleButtonWithPrice as BundleButtonWithPriceProps } from "@gatsby-plugin-generic-page/fragments/bundleButtonWithPrice"
import useSiteMetadataQuery from "~/hooks/useSiteMetadataQuery"
import { sendEvent } from "~/lib/ga4"
import isEmpty from "~/lib/isEmpty"
import { getBundlePricesFromDefinitionForType } from "~/lib/pricing"

export interface Props extends BundleButtonWithPriceProps {
  hasTextColor?: boolean
  pushSpace: Pick<
    PushOrgProps,
    "bottomSpace" | "topSpace" | "leftSpace" | "rightSpace"
  >
}

function CtaBundleButtonWithPrice({
  flatData: {
    plan,
    calculatedPrice,
    bundlePlan,
    code,
    text,
    hidePrice,
    title,
    gaEvent,
    buttonColor,
    buttonVariant,
  },
  hasTextColor,
  pushSpace,
}: Props): ReactElement<Props> | null {
  const siteMetadata = useSiteMetadataQuery()
  const priceType =
    siteMetadata.defaultPriceType === "netto"
      ? PriceType.NETTO
      : PriceType.GROSS

  const price = getBundlePricesFromDefinitionForType(
    calculatedPrice[0],
    priceType
  )
  const { addProductToBasket, isLoading, isDone, goToBasket } =
    useBasketActions(plan[0], bundlePlan[0], code ?? undefined)

  if (isEmpty(calculatedPrice)) {
    return null
  }

  const handleOnClick = (event: SyntheticEvent) => {
    event.preventDefault()

    if (gaEvent) {
      sendEvent(gaEvent)
    }

    addProductToBasket()
  }

  return (
    <ConditionalWrapper
      condition={!hidePrice}
      wrapper={(children) => (
        <FlexContainerOrg
          alignItems="center"
          display="flex"
          justifyContent="space-between"
        >
          <PushOrg {...pushSpace} hasNoReset>
            <PriceMol
              integer={
                price.primaryTypePromoPrice?.decimals ??
                price.primaryTypeRegularPrice.decimals
              }
              fraction={`,${
                price.primaryTypePromoPrice?.cents ??
                price.primaryTypeRegularPrice.cents
              }`}
              currency={price.currency}
              suffix={
                priceType === PriceType.GROSS
                  ? _t("prices.gross")
                  : _t("prices.netto")
              }
              hasTextColor={hasTextColor}
              priceTypography={{
                large: "big3",
                medium: "big3",
                small: "small3",
              }}
              currencyTypography={{
                large: "nano1",
                medium: "nano1",
                small: "xsmall2",
              }}
              suffixTypography={{
                large: "nano2",
                medium: "nano2",
                small: "nano1",
              }}
            />
          </PushOrg>

          {children}
        </FlexContainerOrg>
      )}
    >
      <ButtonAtm
        icon={isDone ? Tick : undefined}
        isLoading={isLoading}
        color={buttonColor?.color}
        variant={buttonVariant?.variant}
        title={isDone ? _t("button.genericPageSuccess") : title}
        onClick={isDone ? goToBasket : handleOnClick}
      >
        {isDone ? _t("button.genericPageSuccess") : text}
      </ButtonAtm>
    </ConditionalWrapper>
  )
}

export default CtaBundleButtonWithPrice

import type { MarkOptional } from "ts-essentials"
import type { CloudBluePeriod } from "@gatsby-plugin-definitions/fragments/CloudBluePeriod"
import type { Price } from "../api/types"

export enum PriceFormat {
  NO_PROMO_GROSS_CENTS = "{no_promo.gross.cents}",
  NO_PROMO_NETTO_CENTS = "{no_promo.netto.cents}",
  REGISTER_GROSS_CENTS = "{register.gross.cents}",
  REGISTER_GROSS_DOT_SEPARATED = "{register.gross.decimals}.{register.gross.cents}",
  REGISTER_NETTO_DOT_SEPARATED = "{register.netto.decimals}.{register.netto.cents}",
  SEPARATED_REGISTER_GROSS_CENTS = "{separator}{register.gross.cents}",
  SEPARATED_REGISTER_NETTO_CENTS = "{separator}{register.netto.cents}",
  REGISTER_GROSS_CENTS_WITH_CURRENCY = "{register.gross.cents} {register.gross.currency}",
  REGISTER_NETTO_CENTS = "{register.netto.cents}",
  REGISTER_NETTO_CENTS_WITH_CURRENCY = "{register.netto.cents} {register.netto.currency}",
  NO_PROMO_GROSS_DECIMALS = "{no_promo.gross.decimals}",
  NO_PROMO_GROSS_DECIMALS_WITH_CURRENCY = "{no_promo.gross.decimals} {register.gross.currency}",
  NO_PROMO_NETTO_DECIMALS = "{no_promo.netto.decimals}",
  REGISTER_GROSS_DECIMALS = "{register.gross.decimals}",
  REGISTER_NETTO_DECIMALS = "{register.netto.decimals}",
  NO_PROMO_GROSS = "{no_promo.gross.formatted} / {period.name}",
  NO_PROMO_NETTO = "{no_promo.netto.formatted} / {period.name}",
  REGISTER_GROSS = "{register.gross.formatted} / {period.name}",
  DISCOUNT_PERCENT_WITH_SIGN = "- {discount.percent}",
  RENEWAL_DISCOUNT_PERCENT_WITH_SIGN = "- {renewal_discount.percent}",
  DISCOUNT_PERCENT = "{discount.percent}",
  DISCOUNT_NETTO = "{discount.netto.formatted} / {period.name}",
  DISCOUNT_GROSS = "{discount.gross.formatted} / {period.name}",
  DISCOUNT_NETTO_NO_PERIOD = "{discount.netto.formatted}",
  RENEWAL_DISCOUNT_NETTO_NO_PERIOD = "{renewal_discount.netto.formatted}",
  DISCOUNT_GROSS_NO_PERIOD = "{discount.gross.formatted}",
  RENEWAL_DISCOUNT_GROSS_NO_PERIOD = "{renewal_discount.gross.formatted}",
  REGISTER_GROSS_SHORT = "{register.gross.short} / {period.name}",
  REGISTER_GROSS_SHORT_WITH_CURRENCY = "{register.gross.short} {register.gross.currency} / {period.name}",
  REGISTER_GROSS_SHORT_WITH_CURRENCY_NO_PERIOD = "{register.gross.short} {register.gross.currency}",
  REGISTER_GROSS_WITH_PRICE_TYPE = "{register.gross.formatted} {type.gross} / {period.name}",
  REGISTER_GROSS_NO_PERIOD = "{register.gross.formatted}",
  REGISTER_NETTO = "{register.netto.formatted} / {period.name}",
  REGISTER_NETTO_NO_PERIOD = "{register.netto.formatted}",
  NO_PROMO_NETTO_NO_PERIOD = "{no_promo.netto.formatted}",
  NO_PROMO_GROSS_NO_PERIOD = "{no_promo.gross.formatted}",
  RENEW_NETTO_NO_PERIOD = "{renew.netto.formatted}",
  RENEW_GROSS_NO_PERIOD = "{renew.gross.formatted}",
  RENEW_NETTO_NO_CURRENCY_NO_PERIOD = "{renew.netto.full}",
  RENEW_GROSS_NO_CURRENCY_NO_PERIOD = "{renew.gross.full}",
  RENEW_GROSS_DECIMALS = "{renew.gross.decimals}",
  RENEW_NETTO_DECIMALS = "{renew.netto.decimals}",
  RENEW_GROSS_CENTS = "{renew.gross.cents}",
  RENEW_NETTO_CENTS = "{renew.netto.cents}",
  SEPARATED_RENEW_GROSS_CENTS = "{separator}{renew.gross.cents}",
  SEPARATED_RENEW_NETTO_CENTS = "{separator}{renew.netto.cents}",
  REGISTER_NETTO_NO_PERIOD_WITH_PRICE_TYPE = "{register.netto.formatted} {type.netto}",
  REGISTER_NETTO_WITH_PRICE_TYPE = "{register.netto.formatted} {type.netto} / {period.name}",
  REGISTER_GROSS_NO_PERIOD_WITH_PRICE_TYPE = "{register.gross.formatted} {type.gross}",
  NO_PROMO_GROSS_NO_CURRENCY = "{no_promo.gross.full} / {period.name}",
  NO_PROMO_GROSS_NO_CURRENCY_WITH_PRICE_TYPE = "{no_promo.gross.full} {type.gross} / {period.name}",
  NO_PROMO_GROSS_WITH_PRICE_TYPE = "{no_promo.gross.formatted} {type.gross} / {period.name}",
  NO_PROMO_NETTO_NO_CURRENCY = "{no_promo.netto.full} / {period.name}",
  NO_PROMO_NETTO_NO_CURRENCY_WITH_PRICE_TYPE = "{no_promo.netto.full} {type.netto} / {period.name}",
  REGISTER_GROSS_NO_CURRENCY = "{register.gross.full} / {period.name}",
  REGISTER_GROSS_NO_CURRENCY_WITH_PRICE_TYPE = "{register.gross.full} {type.gross} / {period.name}",
  REGISTER_NETTO_NO_CURRENCY = "{register.netto.full} / {period.name}",
  NO_PROMO_GROSS_NO_CURRENCY_NO_PERIOD = "{no_promo.gross.full}",
  NO_PROMO_NETTO_NO_CURRENCY_NO_PERIOD = "{no_promo.netto.full}",
  REGISTER_GROSS_NO_CURRENCY_NO_PERIOD = "{register.gross.full}",
  REGISTER_NETTO_NO_CURRENCY_NO_PERIOD = "{register.netto.full}",
  LOWEST_PURCHASE_PRICE_GROSS_NO_PERIOD = "{lowest_purchase_price.gross.formatted}",
  LOWEST_PURCHASE_PRICE_NETTO_NO_PERIOD = "{lowest_purchase_price.netto.formatted}",
  LOWEST_PURCHASE_PRICE_GROSS_NO_CURRENCY_NO_PERIOD = "{lowest_purchase_price.gross.full}",
  LOWEST_PURCHASE_PRICE_NETTO_NO_CURRENCY_NO_PERIOD = "{lowest_purchase_price.netto.full}",
  LOWEST_PURCHASE_PRICE_PERCENT = "{lowest_purchase_price.percent}",
  NETTO_PRICE_TYPE_WITH_PERIOD_NAME = "{type.netto} / {period.name}",
  GROSS_PRICE_TYPE_WITH_PERIOD_NAME = "{type.gross} / {period.name}",
  GROSS_WITH_PERIOD = "{type.gross} / {period.name}",
  NETTO_WITH_PERIOD = "{type.netto} / {period.name}",
  CURRENCY = "{register.gross.currency}",
  PERIOD_WITH_SEPARATOR = "/ {period.name}",
  PERIOD = "{period.name}",
  GROSS = "{type.gross}",
  NETTO = "{type.netto}",
}

export enum PriceType {
  NETTO = "netto",
  GROSS = "gross",
}

export type PriceWithOptionalTaxRate = MarkOptional<Price, "taxRate">

export type PriceTypeValues = Pick<
  PriceWithOptionalTaxRate,
  "netto" | "gross" | "currency"
>

export interface FormattedPriceInfo {
  formatted: string
  full: string
  cents: string
  decimals: string
  currency: string
  short: string
  priceType: PriceType
  raw: number
}

export interface FormattedPricesObject {
  register: {
    netto: FormattedPriceInfo
    gross: FormattedPriceInfo
  }
  renew: {
    netto: FormattedPriceInfo
    gross: FormattedPriceInfo
  }
  no_promo: {
    netto: FormattedPriceInfo
    gross: FormattedPriceInfo
  }
  discount: {
    netto: FormattedPriceInfo
    gross: FormattedPriceInfo
    percent?: string
  }
  renewal_discount: {
    netto: FormattedPriceInfo
    gross: FormattedPriceInfo
    percent: string
  }
  lowest_purchase_price: {
    netto: FormattedPriceInfo
    gross: FormattedPriceInfo
    percent: string
  } | null
}

export interface RtbFlatData {
  flatData: {
    product: {
      flatData: {
        id: string
        periods: CloudBluePeriod
      }
    }
    productName: string
    description: string
    pages: {
      flatData: {
        url: string
      }
    }
    image: {
      url: string
      id: string
    }
    availability: boolean
    saleStartDate?: string
    saleEndDate?: string
    googleProductCategory: string
    brand: string
    gtin: string
  }
}

export interface ResultData {
  data?: {
    GraphCmsSquidex: { queryRtbFeedContents: RtbFlatData[] }
  }
}

export interface SiteData {
  data?: {
    site: {
      siteMetadata: {
        siteUrl: string
      }
    }
  }
}

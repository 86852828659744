import type { ReactElement } from "react"
import React from "react"
import { typeMatches } from "@onestore-graphql"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import Picture from "@onestore/hel/dist/components/quarks/Picture"
import type { BreakpointMax } from "@onestore/hel/dist/utils/breakpoint"
import FixedBottomCtaElement from "@gatsby-plugin-generic-page/components/FixedBottomCtaElement"
import FloatingBox from "@gatsby-plugin-generic-page/components/FloatingBox"
import FloatingCtaElement from "@gatsby-plugin-generic-page/components/FloatingCtaElement"
import FloatingCtaSectionsModal from "@gatsby-plugin-generic-page/components/FloatingCtaSectionsModal"
import type { CtaSectionsModal } from "@gatsby-plugin-generic-page/fragments/ctaSectionsModal"
import type { FloatingCtaElementSectionFlatData } from "@gatsby-plugin-generic-page/fragments/floatingCtaElementSection"
import useIsFloatingElementVisible from "@gatsby-plugin-generic-page/hooks/useIsFloatingElementVisible"
import { SquidexReferenceType } from "@gatsby-plugin-generic-page/types/referenceType"
import assetsSquidexToImageObject from "~/lib/assetsSquidexToImageObject"
import isEmpty from "~/lib/isEmpty"

type CommonProps = Pick<FloatingCtaElementSectionFlatData, "ctaButton"> & {
  isVisible: boolean
}

export default function FloatingCtaElementSection({
  ctaButton,
  sectionIdFrom,
  sectionIdTo,
  icon,
  iconPosition,
  additionalTexts,
}: FloatingCtaElementSectionFlatData): ReactElement<FloatingCtaElementSectionFlatData> | null {
  const isVisible = useIsFloatingElementVisible(sectionIdFrom, sectionIdTo)
  const breakpointMax: BreakpointMax = isEmpty(additionalTexts)
    ? "medium"
    : "large"

  if (isEmpty(ctaButton)) {
    return null
  }

  if (
    typeMatches(
      ctaButton[0].__typename,
      SquidexReferenceType.CTA_SECTIONS_MODAL
    )
  ) {
    return (
      <FloatingCtaSectionsModal
        ctaSectionsModal={ctaButton[0] as CtaSectionsModal}
        isVisible={isVisible}
        icon={
          !isEmpty(icon) ? (
            <Picture srcData={assetsSquidexToImageObject(icon, "")} />
          ) : null
        }
        iconPosition={iconPosition}
      />
    )
  }

  const commonProps: CommonProps = {
    isVisible,
    ctaButton,
  }

  return (
    <>
      <BreakpointVisibilityOrg from="small" to={breakpointMax}>
        {isVisible ? <FixedBottomCtaElement ctaButton={ctaButton} /> : null}
      </BreakpointVisibilityOrg>

      <BreakpointVisibilityOrg from={breakpointMax}>
        {isEmpty(additionalTexts) ? (
          <FloatingCtaElement
            {...commonProps}
            icon={icon}
            iconPosition={iconPosition}
          />
        ) : (
          <FloatingBox {...commonProps} additionalTexts={additionalTexts} />
        )}
      </BreakpointVisibilityOrg>
    </>
  )
}

import Cookies from "js-cookie"

export default class CookieStorage {
  static get(key: string): string | null {
    return Cookies.get(key) || null
  }

  static set(
    key: string,
    value: string,
    options: Cookies.CookieAttributes
  ): void {
    Cookies.set(key, value, options)
  }

  static remove(key: string): void {
    Cookies.remove(key)
  }
}

import type { ReactElement } from "react"
import React from "react"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import ContentWithImageOrg from "@onestore/hel/dist/components/organisms/ContentWithImageOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import WidthOrg from "@onestore/hel/dist/components/organisms/WidthOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import CtaElement from "@gatsby-plugin-generic-page/components/CtaElement"
import Header from "@gatsby-plugin-generic-page/components/Header"
import Media from "@gatsby-plugin-generic-page/components/Media"
import ToggleWithLinkButtons from "@gatsby-plugin-generic-page/components/ToggleWithLinkButtons"
import type { CenteredContentWithImageSectionFlatData } from "@gatsby-plugin-generic-page/types/sectionType"
import isEmpty from "~/lib/isEmpty"
import List from "./components/List"

function CenteredContentWithImageSection({
  header,
  toggleWithLinkButtons,
  list,
  responsiveImages,
  buttons,
  sectionSettings,
}: CenteredContentWithImageSectionFlatData): ReactElement<CenteredContentWithImageSectionFlatData> {
  const jsxContent = (
    <AlignContentOrg horizontalAlignType="flex" horizontalAlign="center">
      <WidthOrg widthSpace={120}>
        <PushOrg bottomSpace={3}>
          <AlignContentOrg horizontalAlign="center">
            {!isEmpty(toggleWithLinkButtons) ? (
              <PushOrg bottomSpace={{ small: 4, medium: 4, large: 5 }}>
                <ToggleWithLinkButtons {...toggleWithLinkButtons} />
              </PushOrg>
            ) : null}

            <Header {...header} />
          </AlignContentOrg>
        </PushOrg>

        <AlignContentOrg
          horizontalAlign={{
            small: "default",
            medium: "center",
            large: "center",
          }}
        >
          {!isEmpty(list) ? <List list={list} /> : null}

          {!isEmpty(buttons) ? (
            <PushOrg topSpace={3}>
              <CtaElement ctaElement={buttons} />
            </PushOrg>
          ) : null}
        </AlignContentOrg>
      </WidthOrg>
    </AlignContentOrg>
  )

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        {!isEmpty(responsiveImages) ? (
          <ContentWithImageOrg
            hasImageUnderContent
            hasImageStickyToBottom
            content={jsxContent}
            image={<Media media={responsiveImages} />}
          />
        ) : (
          jsxContent
        )}
      </BaseContainer>
    </BaseSection>
  )
}

export default CenteredContentWithImageSection

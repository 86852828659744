import { useState } from "react"
import SessionStorage from "~/lib/storage/SessionStorage"

type SessionStorageHook<T> = [T, { (data: T): void }]

export function useSessionStorage<T>(
  name: string,
  defaultValue: T | null = null
): SessionStorageHook<T> {
  const [data, setData] = useState(
    JSON.parse(SessionStorage.get(name) || JSON.stringify(defaultValue))
  )
  const set = (data: T) => {
    SessionStorage.set(name, JSON.stringify(data))
    setData(data)
  }

  return [data, set]
}

import type { MarkdownToJSX } from "markdown-to-jsx"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import type { TextAtmProps } from "@onestore/hel/dist/components/atoms/TextAtm/TextAtm.props"

const markdownOverrides: MarkdownToJSX.Overrides = {
  h1: {
    component: TextAtm,
    props: {
      typography: "huge",
      htmlTag: "h1",
    } as TextAtmProps,
  },
  h2: {
    component: TextAtm,
    props: {
      typography: "great",
      htmlTag: "h2",
    } as TextAtmProps,
  },
  h3: {
    component: TextAtm,
    props: {
      typography: "xlarge",
      htmlTag: "h3",
    } as TextAtmProps,
  },
}

export default markdownOverrides

import type { ReactElement } from "react"
import React from "react"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import GridOrg from "@onestore/hel/dist/components/organisms/GridOrg"
import ImageWrapperOrg from "@onestore/hel/dist/components/organisms/ImageWrapperOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import RelativeContainerOrg from "@onestore/hel/dist/components/organisms/RelativeContainerOrg"
import SurfaceOrg from "@onestore/hel/dist/components/organisms/SurfaceOrg"
import WidthOrg from "@onestore/hel/dist/components/organisms/WidthOrg"
import useBreakpoint from "@onestore/hel/dist/hooks/useBreakpoint"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import CtaElement from "@gatsby-plugin-generic-page/components/CtaElement"
import ImageBox from "@gatsby-plugin-generic-page/components/ImageBox"
import Media from "@gatsby-plugin-generic-page/components/Media"
import Paragraph from "@gatsby-plugin-generic-page/components/Paragraph"
import type { ColorContentWithImageSectionFlatData } from "@gatsby-plugin-generic-page/fragments/colorContentWithImageSection"
import { verticalPosition } from "@gatsby-plugin-generic-page/fragments/imagePosition"
import getBorderRadiusSize from "@gatsby-plugin-generic-page/helpers/getBorderRadiusSize"
import getCustomBackgroundColor from "@gatsby-plugin-generic-page/helpers/getCustomBackgroundColor"
import markdownOverrides from "@gatsby-plugin-generic-page/markdown/colorContentWithImageSection"
import isEmpty from "~/lib/isEmpty"
import { CustomTextColors } from "~/types/squidex"

export default function ColorContentWithImageSection({
  caption,
  title,
  paragraphs,
  textColor,
  surfaceColor,
  buttons,
  responsiveImages,
  imageBox,
  isReversed,
  isAsymmetricDivided,
  hasTopScaledImage,
  borderRadiusSize,
  imagePosition,
  sectionSettings,
}: ColorContentWithImageSectionFlatData): ReactElement<ColorContentWithImageSectionFlatData> {
  const customColor = surfaceColor && getCustomBackgroundColor(surfaceColor)
  const isSmallAndMediumScreen = useBreakpoint("small", "large")
  const borderRadiusSizeByType = borderRadiusSize
    ? getBorderRadiusSize(borderRadiusSize)
    : undefined
  const contentSpacing = { small: 4, medium: 4, large: 10 }
  const content = (
    <>
      {caption ? (
        <TextAtm typography="xsmall2_u" pushSpace={1.5}>
          {caption}
        </TextAtm>
      ) : null}

      <TextAtm htmlTag="h2" typography="xhuge" pushSpace={paragraphs ? 3 : 5}>
        {title}
      </TextAtm>

      {paragraphs
        ? paragraphs.map((paragraph, index) => (
            <Paragraph
              key={index}
              markdownOverrides={markdownOverrides}
              {...paragraph}
            />
          ))
        : null}

      {buttons ? (
        <PushOrg topSpace={3}>
          <CtaElement ctaElement={buttons} />
        </PushOrg>
      ) : null}
    </>
  )

  if (!isEmpty(imagePosition) && imagePosition.vertical) {
    console.log(verticalPosition[imagePosition.vertical])
    console.log(imagePosition.vertical)
  }

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        <SurfaceOrg
          spacingSpace={0}
          customColor={customColor}
          borderRadiusSize={borderRadiusSizeByType || "none"}
          color={CustomTextColors[textColor]}
          hasCenteredChildren
        >
          <GridOrg
            isReversed={isSmallAndMediumScreen ? false : isReversed}
            isAsymmetricDivided={isAsymmetricDivided}
            gutterSpace={0}
            columns={{ small: 1, medium: 1, large: 2 }}
            hasOverflowHidden
            hasBorderRadiusInherited
          >
            <PushOrg
              hasNoReset
              bottomSpace={contentSpacing}
              topSpace={contentSpacing}
              leftSpace={contentSpacing}
              rightSpace={contentSpacing}
            >
              <BreakpointVisibilityOrg from="large">
                <WidthOrg widthSpace={isAsymmetricDivided ? 90 : 60}>
                  {content}
                </WidthOrg>
              </BreakpointVisibilityOrg>

              <BreakpointVisibilityOrg from="small" to="large">
                {content}
              </BreakpointVisibilityOrg>
            </PushOrg>

            <ImageWrapperOrg
              hasTopScaledImage={!!hasTopScaledImage}
              hasImageBox={!!imageBox}
              horizontalPosition={
                !isEmpty(imagePosition) ? imagePosition.horizontal : undefined
              }
              verticalPosition={
                !isEmpty(imagePosition) && imagePosition.vertical
                  ? verticalPosition[imagePosition.vertical]
                  : undefined
              }
            >
              <RelativeContainerOrg>
                <Media media={responsiveImages} />
                {imageBox ? <ImageBox {...imageBox} /> : null}
              </RelativeContainerOrg>
            </ImageWrapperOrg>
          </GridOrg>
        </SurfaceOrg>
      </BaseContainer>
    </BaseSection>
  )
}

import type { ReactElement, ReactNode } from "react"
import React from "react"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import ConditionalWrapper from "@onestore/hel/dist/helpers/ConditionalWrapper"
import useBreakpoint from "@onestore/hel/dist/hooks/useBreakpoint"

interface AsideButtonWrapperProps {
  children: ReactNode
}
export default function AsideButtonWrapper({
  children,
}: AsideButtonWrapperProps): ReactElement {
  const isSmallOnly = useBreakpoint("small", "medium")

  return (
    <ConditionalWrapper
      condition={isSmallOnly}
      wrapper={(contentJsx) => (
        <AlignContentOrg horizontalAlign="center">{contentJsx}</AlignContentOrg>
      )}
    >
      {children}
    </ConditionalWrapper>
  )
}

/* eslint-disable no-param-reassign */
import type { Draft } from "immer"
import { produce } from "immer"
import { uniqBy } from "lodash"
import { HTTP_STATUS } from "@onestore/onestore-store-common/http"
import type { BonusDomainBoxActions } from "@gatsby-plugin-bonus/store/actions-domain-box"
import { BonusDomainsActionType } from "@gatsby-plugin-bonus/store/actions-domain-box"
import type { BonusState } from "@gatsby-plugin-bonus/types"
import { initialState } from "./selectors"

export default function reducer(
  state: BonusState = initialState,
  action: BonusDomainBoxActions
) {
  return produce(state, (nextState: Draft<BonusState>) => {
    switch (action.type) {
      case BonusDomainsActionType.BONUS_UPSELL_DOMAINS_SEARCH_REQUEST:
        nextState.upsellDomains.status = HTTP_STATUS.CONTINUE
        nextState.upsellDomains.results = uniqBy([...action.domains], "fqdn")

        return

      case BonusDomainsActionType.BONUS_UPSELL_DOMAINS_SEARCH_SUCCESS:
        nextState.upsellDomains.status = HTTP_STATUS.OK
        nextState.upsellDomains.results = uniqBy([...action.domains], "fqdn")

        return

      case BonusDomainsActionType.BONUS_UPSELL_DOMAINS_SEARCH_FAILURE:
        nextState.upsellDomains.status = HTTP_STATUS.INTERNAL_SERVER_ERROR
        break
      default:
        break
    }
  })
}

import type { ReactElement } from "react"
import React from "react"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import TilesLinkOrg from "@onestore/hel/dist/components/organisms/TilesLinkOrg"
import ConditionalWrapper from "@onestore/hel/dist/helpers/ConditionalWrapper"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import Header from "@gatsby-plugin-generic-page/components/Header"
import TileLink from "@gatsby-plugin-generic-page/components/TileLink"
import type { TilesLinkSectionFlatData } from "@gatsby-plugin-generic-page/fragments/tilesLinkSection"
import isEmpty from "~/lib/isEmpty"

export default function TilesLinkSection({
  header,
  tiles,
  columns,
  sectionSettings,
}: TilesLinkSectionFlatData): ReactElement<TilesLinkSectionFlatData> {
  const childWithImage = tiles.find((tile) => !isEmpty(tile.responsiveImages))

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        {!isEmpty(header) && (
          <PushOrg bottomSpace={{ small: 4, medium: 6, large: 10 }}>
            <AlignContentOrg
              horizontalAlign={{
                small: "left",
                medium: "center",
                large: "center",
              }}
            >
              <Header {...header} />
            </AlignContentOrg>
          </PushOrg>
        )}

        <ConditionalWrapper
          condition={!isEmpty(childWithImage)}
          wrapper={(children) => (
            <PushOrg
              bottomSpace={{ small: 0.5, medium: 0.5, large: 15 }}
              hasNoReset
            >
              {children}
            </PushOrg>
          )}
        >
          <TilesLinkOrg columns={columns}>
            {tiles.map((tile) => (
              <TileLink key={tile.title} {...tile} isImageShow />
            ))}
          </TilesLinkOrg>
        </ConditionalWrapper>
      </BaseContainer>
    </BaseSection>
  )
}

import type { ReactElement } from "react"
import React from "react"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import FlexItemOrg from "@onestore/hel/dist/components/organisms/FlexItemOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import SurfaceOrg from "@onestore/hel/dist/components/organisms/SurfaceOrg"
import WidthOrg from "@onestore/hel/dist/components/organisms/WidthOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import CtaElement from "@gatsby-plugin-generic-page/components/CtaElement"
import Header from "@gatsby-plugin-generic-page/components/Header"
import MarkdownTitle from "@gatsby-plugin-generic-page/components/Markdown/Title"
import Media from "@gatsby-plugin-generic-page/components/Media"
import type { ColorContentWithBoxSectionFlatData } from "@gatsby-plugin-generic-page/fragments/colorContentWithBoxSection"
import getBackground from "@gatsby-plugin-generic-page/helpers/getBackground"
import isEmpty from "~/lib/isEmpty"

export default function ColorContentWithBoxSection({
  header,
  background,
  box,
  ctaElements,
  sectionSettings,
}: ColorContentWithBoxSectionFlatData): ReactElement<ColorContentWithBoxSectionFlatData> {
  const spacings = { small: 3, medium: 5, large: 5 }
  const {
    backgroundThemeColor,
    customColor,
    customOnColorText,
    imageBackground,
  } = getBackground(background)

  const headerJSX = (
    <PushOrg
      rightSpace={spacings}
      leftSpace={spacings}
      topSpace={spacings}
      bottomSpace={spacings}
      hasNoReset
    >
      <WidthOrg widthSpace={74}>
        <Header {...header} />
      </WidthOrg>
    </PushOrg>
  )

  const boxJSX = (
    <FlexItemOrg flexGrow={1}>
      <SurfaceOrg
        spacingSpace={spacings}
        borderRadiusSize="mlarge"
        shadow="tiny"
      >
        {box.map((box) => (
          <FlexContainerOrg key={box.text} gapSpace={1}>
            {!isEmpty(box.icon) ? <Media media={box.icon} /> : null}

            <FlexItemOrg flexGrow={1} flexShrink={1} flexBasis={0}>
              <MarkdownTitle typography="small2">{box.text}</MarkdownTitle>
            </FlexItemOrg>
          </FlexContainerOrg>
        ))}

        {!isEmpty(ctaElements) ? (
          <PushOrg topSpace={4}>
            <CtaElement ctaElement={ctaElements} expandFromMedium />
          </PushOrg>
        ) : null}
      </SurfaceOrg>
    </FlexItemOrg>
  )

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        <SurfaceOrg
          spacingSpace={{ small: 1.5, medium: 3, large: 3 }}
          customColor={customColor?.background}
          color={backgroundThemeColor || customOnColorText}
          backgroundImage={imageBackground}
          borderRadiusSize="xlarge"
          shadow="nano"
        >
          <BreakpointVisibilityOrg from="small" to="large">
            <FlexContainerOrg direction="column">
              {headerJSX}

              {boxJSX}
            </FlexContainerOrg>
          </BreakpointVisibilityOrg>

          <BreakpointVisibilityOrg from="large">
            <FlexContainerOrg direction="row" alignItems="center">
              {headerJSX}

              {boxJSX}
            </FlexContainerOrg>
          </BreakpointVisibilityOrg>
        </SurfaceOrg>
      </BaseContainer>
    </BaseSection>
  )
}

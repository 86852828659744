import type { ReactElement } from "react"
import React from "react"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import CheckboxWithLabelMol from "@onestore/hel/dist/components/molecules/CheckboxWithLabelMol"
import type { TooltipMolProps } from "@onestore/hel/dist/components/molecules/TooltipMol"
import TooltipMol from "@onestore/hel/dist/components/molecules/TooltipMol"
import FlexItemOrg from "@onestore/hel/dist/components/organisms/FlexItemOrg"
import SpaceBetweenOrg from "@onestore/hel/dist/components/organisms/SpaceBetweenOrg"
import WrapOrg from "@onestore/hel/dist/components/organisms/WrapOrg"
import { productBoxClickEvent } from "~/lib/ga4"

interface ProductBoxFooterProps {
  label: string
  value?: string
  tooltip?: TooltipMolProps["children"]
  setIsChecked: () => void
  bundlePriceFormat: string
  isChecked: boolean
  isBundle?: boolean
}

export default function ProductBoxFooter({
  label,
  value,
  tooltip,
  setIsChecked,
  bundlePriceFormat,
  isChecked,
  isBundle,
}: ProductBoxFooterProps): ReactElement<ProductBoxFooterProps> | null {
  const onChange = () => {
    if (!isChecked) {
      productBoxClickEvent({
        productbox_type: "checkbox",
        productbox_option: label,
      })
    }
    setIsChecked()
  }

  return (
    <>
      {isBundle ? (
        <SpaceBetweenOrg>
          <CheckboxWithLabelMol
            typography="small2"
            text={label || ""}
            tooltipText={tooltip}
            isChecked={isChecked}
            onChange={onChange}
            gutterSpace={1.5}
          />

          <FlexItemOrg flexShrink={0} alignSelf="center">
            <TextAtm
              typography="small4"
              color={isChecked ? "success" : undefined}
            >
              {value || (bundlePriceFormat && `+ ${bundlePriceFormat}`)}
            </TextAtm>
          </FlexItemOrg>
        </SpaceBetweenOrg>
      ) : (
        <SpaceBetweenOrg>
          <WrapOrg gutterSpace={1} alignCenter="horizontal">
            <TextAtm typography="small2">{label}</TextAtm>

            {tooltip ? <TooltipMol>{tooltip}</TooltipMol> : null}
          </WrapOrg>

          <FlexItemOrg flexShrink={0} alignSelf="center">
            <TextAtm typography="small4" color="success">
              {value}
            </TextAtm>
          </FlexItemOrg>
        </SpaceBetweenOrg>
      )}
    </>
  )
}

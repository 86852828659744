import { sendToplayerShowEvent } from "~/lib/ga4"
import { ACTIONS } from "../actions"

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (next) => (action) => {
  if (action.type === ACTIONS.CHANGE_ITEM_PERIOD_MODAL_SHOW) {
    sendToplayerShowEvent()
  }

  return next(action)
}

import type { ReactElement } from "react"
import { useState } from "react"
import React from "react"
import { get } from "lodash"
import IframeAtm from "@onestore/hel/dist/components/atoms/IframeAtm"
import VideoAtm from "@onestore/hel/dist/components/atoms/VideoAtm"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import Header from "@gatsby-plugin-generic-page/components/Header"
import { ContainerWidthType } from "@gatsby-plugin-generic-page/fragments/containerSettings"
import type { VideoSectionFlatData } from "@gatsby-plugin-generic-page/fragments/videoSection"
import { SquidexVideoType } from "@gatsby-plugin-generic-page/fragments/videoSection"
import { useIsomorphicLayoutEffect } from "@gatsby-plugin-generic-page/hooks/useIsomorphicLayoutEffect"
import { useVideoProps } from "@gatsby-plugin-generic-page/hooks/useVideoProps"
import isEmpty from "~/lib/isEmpty"

export default function VideoSection({
  header,
  sectionSettings,
  video,
  url,
  type,
  iframeName,
  iframeWidth,
}: VideoSectionFlatData): ReactElement<VideoSectionFlatData> {
  const [hasCmpId, setHasCmpId] = useState(false)
  const videoProps = useVideoProps(video)

  useIsomorphicLayoutEffect()(() => {
    if (window && get(window, "cmp_id")) {
      setHasCmpId(true)
    }
  }, [])

  const cmpProps = hasCmpId
    ? { "data-cmp-vendor": "s30", "data-cmp-src": url }
    : undefined

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer
        {...sectionSettings?.containerSettings}
        containerWidth={
          isEmpty(sectionSettings?.containerSettings?.containerWidth)
            ? undefined
            : ContainerWidthType.MEDIUM
        }
      >
        {!isEmpty(header) && (
          <PushOrg bottomSpace={3}>
            <AlignContentOrg horizontalAlign="center">
              <Header {...header} />
            </AlignContentOrg>
          </PushOrg>
        )}

        <AlignContentOrg horizontalAlign="center">
          {!isEmpty(videoProps) && type === SquidexVideoType.FILE ? (
            <VideoAtm {...videoProps} disablePictureInPicture />
          ) : null}

          {url && type === SquidexVideoType.URL ? (
            <IframeAtm
              src={hasCmpId ? "about:blank" : url}
              title={iframeName || ""}
              width={iframeWidth}
              allowFullScreen
              isCmpLazyLoad
              {...cmpProps}
            />
          ) : null}
        </AlignContentOrg>
      </BaseContainer>
    </BaseSection>
  )
}

import type { ReactElement } from "react"
import React from "react"
import type { MarkdownToJSX } from "markdown-to-jsx"
import getBaseMarkdownOverrides from "@gatsby-plugin-generic-page/helpers/getBaseMarkdownOverrides"
import BaseMarkdown from "../Base"

interface Props {
  children: string
  overrides?: MarkdownToJSX.Overrides
}

function Caption({ children, overrides }: Props): ReactElement<Props> {
  const baseMarkdownOverrides = getBaseMarkdownOverrides({
    typography: { small: "tiny2", medium: "xsmall2_u", large: "xsmall2_u" },
    boldTypography: "xsmall3",
    pushSpace: 1.5,
  })

  return (
    <BaseMarkdown
      options={{
        overrides: overrides || baseMarkdownOverrides,
        forceBlock: true,
      }}
    >
      {children}
    </BaseMarkdown>
  )
}

export default Caption

import type { ReactElement } from "react"
import React from "react"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import type { ConfigsHookResult } from "@gatsby-plugin-generic-page/hooks/useTableConfigs"
import isEmpty from "~/lib/isEmpty"
import SelectConfigElement from "./SelectConfigElement"
import ToggleConfigElement from "./ToggleConfigElement"

interface Props {
  resourceCategoryId: string
  configLabel: string
  configsHookResult: ConfigsHookResult
}

export default function ResourceSelect({
  resourceCategoryId,
  configLabel,
  configsHookResult,
}: Props): ReactElement<Props> {
  const {
    isCalculateProductsLoading,
    handleResourcesRemove,
    handleResourceChange,
    getResourceCategory,
    getConfigResourceCategoryPriceText,
  } = configsHookResult

  const resourceCategory = getResourceCategory(resourceCategoryId)

  if (isEmpty(resourceCategory)) {
    return <></>
  }

  const defaultResourceId = resourceCategory.default_resource_id

  const { resources } = resourceCategory

  if (isEmpty(resources)) {
    return <></>
  }

  const defaultResource =
    resources.find((resource) => resource.id === defaultResourceId) ??
    resources[0]

  if (isEmpty(defaultResource)) {
    return <></>
  }

  const resourceCategoryPriceElement = (
    <TextAtm typography="small3" align="right">
      {getConfigResourceCategoryPriceText(resourceCategoryId)}
    </TextAtm>
  )

  const handleToggleSwitch = (isActive: boolean): void => {
    if (isActive) {
      handleResourceChange(resourceCategoryId, defaultResource.id as string, 1)
    } else {
      handleResourcesRemove(resourceCategoryId)
    }
  }

  const handleOnChange = (resourceId: string): void => {
    const currentResource = resources.find(
      (resource) => resource.id.toString() === resourceId
    )

    if (currentResource) {
      handleResourcesRemove(resourceCategoryId)
      handleResourceChange(resourceCategoryId, currentResource.id as string, 1)
    }
  }

  const selectOptions = resources.map((resource) => ({
    label: resource.name,
    value: resource.id.toString(),
    isSelected: resource.id === defaultResource.id,
  }))

  const expandedSelect = (
    <SelectConfigElement
      options={selectOptions}
      onChange={handleOnChange}
      isDisable={isCalculateProductsLoading}
      isExpandedContent
    />
  )

  const expandedContent = (
    <>
      {expandedSelect}
      <PushOrg topSpace={3}>{resourceCategoryPriceElement}</PushOrg>
    </>
  )

  return (
    <ToggleConfigElement
      elementId={`resource-${resourceCategoryId}-toggle`}
      configLabel={configLabel}
      expandedContent={expandedContent}
      handleToggleSwith={handleToggleSwitch}
    />
  )
}

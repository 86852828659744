import {
  getPriceObject,
  getPriceObjectWithTaxRate,
  getTaxRate,
} from "@core/pricing"
import type { Draft } from "immer"
import { isEmpty, pickBy, reduce, intersection, get } from "lodash"
import type {
  BasketLastItem,
  BasketParamResponseItem,
  BasketResponse,
  BasketResponseItem,
  BasketResponseResource,
  PriceValue,
} from "@onestore/api/basket"
import type {
  PeriodName,
  PlanPeriodNumericId,
  Resource,
  ResourceId,
} from "@onestore/api/types"
import { normalizeMaxQuantity } from "@gatsby-plugin-bonus/lib/normalizers"
import type { BonusChangeProductResourceRequestAction } from "@gatsby-plugin-bonus/store/actions-product"
import type { BonusUpsellChangeResourceRequestAction } from "@gatsby-plugin-bonus/store/actions-upsell"
import type {
  BonusPeriod,
  BonusProduct,
  BonusProductParameter,
  BonusResource,
  BonusResourceCategory,
  BonusResources,
  DomainBonusProduct,
} from "@gatsby-plugin-bonus/types"
import type { ResourceData } from "@gatsby-plugin-definitions/fragments/CloudBluePeriod"
import type { NormalizedBonusResource } from "@gatsby-plugin-definitions/lib/normalizers"
import type {
  PeriodResourceRate,
  ProductDefinition,
} from "@gatsby-plugin-definitions/store/actions"
import { safeFind } from "~/lib/collection"
import { getResource } from "../lib/bonus-definitions"

export function handleProductResourceRequest(
  action:
    | BonusChangeProductResourceRequestAction
    | BonusUpsellChangeResourceRequestAction,
  stateProduct: BonusProduct,
  nextStateProduct: Draft<BonusProduct>
) {
  const newQuantity =
    get(stateProduct, ["resources", action.resource.id, "quantity"]) +
    action.quantity

  nextStateProduct.isChangeResourceLoading = true
  nextStateProduct.changeResourceStatus = action.status
  nextStateProduct.resources[action.resource.id].quantity = newQuantity
  nextStateProduct.resources[action.resource.id].basketQuantity =
    newQuantity - action.resource.includedValue
}

/**
 * Wyliczenia stanu zasobów w UI po usunieciu pojedynczego zasobu z koszyka
 */
export function applyResourceChangesFromBasket(
  items: BasketResponse["items"],
  currentBonusResources: BonusResources,
  defaultResources: BonusResources,
  resourceCategories: BonusResourceCategory[],
  basketItemId?: number
): {
  resources: BonusResources
  configurationChanged: boolean
} {
  if (!basketItemId) {
    return { resources: currentBonusResources, configurationChanged: false }
  }

  const resourcesForItems: Record<number, number[]> = {}
  items.forEach((item) => {
    resourcesForItems[item.id] = item.resources.map(
      (resource: BasketResponseResource) => resource.resource_id
    )
    item.children.forEach((child) => {
      resourcesForItems[child.id] = item.resources.map(
        (resource: BasketResponseResource) => resource.resource_id
      )
    })
  })

  let configurationChanged = false

  const resources = pickBy(currentBonusResources, (resource: BonusResource) => {
    // konwertujemy id zasobu na string ponieważ id zasobu jest number|string
    // gdy jest pobierane z kluczy obiektu i jest stringiem, gdy przychodzi z API jest typu number
    return (
      (resourcesForItems[basketItemId] ?? []).map((itemId) => `${itemId}`) ?? []
    ).includes(`${resource.id}`)
  })

  const resourcesInUse = Object.values(resources).map(
    (resource) => `${resource.id}`
  )

  const oneResourceOfGroupNotInUse = resourceCategories
    .filter((category) => category.displayType === 1) // tylko jeden zasób mozna być kupiony w tym samym czasie
    .map((category) => category.resources.map((resource) => `${resource.id}`))
    .filter((resources) => intersection(resources, resourcesInUse).length === 0)

  Object.values(defaultResources).forEach((resource) => {
    const shouldBeAdded =
      oneResourceOfGroupNotInUse.filter((group) =>
        group.includes(`${resource.id}`)
      ).length > 0

    if (shouldBeAdded && resource.basketQuantity > 0) {
      resources[`${resource.id}`] = resource

      configurationChanged = true
    }
  })

  return {
    resources,
    configurationChanged,
  }
}

function getNormalizedChosenPeriod(
  periods: BonusPeriod[],
  chosenPeriodId: number
): BonusPeriod {
  let chosenPeriod = periods.find((period) => period.id === chosenPeriodId)

  if (!chosenPeriod) {
    throw Error(`Missing chosenPeriod (${chosenPeriodId}) in periods array`)
  }

  const resource_data: ResourceData[] = []

  chosenPeriod.resource_data.forEach((rate) => {
    resource_data.push({
      ...rate,
      upper_limit: normalizeMaxQuantity(rate.upper_limit),
    })
  })

  return {
    ...chosenPeriod,
    resource_data,
  }
}

export function defaultCheckedResources(
  categories: BonusResourceCategory[],
  chosenPeriodId: PlanPeriodNumericId,
  periods: BonusPeriod[],
  currentValues?: BonusResources | undefined
): BonusResources {
  const result: BonusResources = {}

  let chosenPeriod = getNormalizedChosenPeriod(periods, chosenPeriodId)

  const remoteDefaultResources: BonusResources = {}

  categories
    .filter(
      (category) =>
        category.displayType === 1 &&
        category.defaultResourceId !== null &&
        category.resources
          .map((resource) => resource.id)
          .includes(category.defaultResourceId)
    )
    .forEach((category) => {
      if (category.defaultResourceId === null) {
        return
      }

      remoteDefaultResources[`${category.defaultResourceId}`] = {
        id: category.defaultResourceId,
        basketQuantity: 1,
        quantity: 1,
        maxQuantity: 1,
        minQuantity: 0,
        includedValue: 0,
      }
    })

  const selectedValues: BonusResources = isEmpty(currentValues)
    ? remoteDefaultResources
    : (currentValues as BonusResources)

  const currentValuesId = Object.keys(selectedValues)
  const resources: Record<ResourceId, NormalizedBonusResource> = {}

  categories.forEach((category: BonusResourceCategory) => {
    if (
      category.displayType === 1 &&
      category.defaultResourceId !== null &&
      category.resources
        .map((resource) => resource.id)
        .includes(category.defaultResourceId)
    ) {
      const option = category.resources.find(
        (resource) => resource.id === category.defaultResourceId
      )

      if (option) {
        result[option.id] = getResource(
          option.id,
          chosenPeriod.resource_data.find(
            (item) => item.resource_id === option.id
          )
        )
      }
    } else if (category.displayType === 1 && !category.noneOption) {
      const option = category.resources.slice(0, 1).pop()

      if (option) {
        result[option.id] = getResource(
          option.id,
          chosenPeriod.resource_data.find(
            (item) => item.resource_id === option.id
          )
        )
      }
    } else {
      category.resources.forEach((option) => {
        result[option.id] = getResource(
          option.id,
          chosenPeriod.resource_data.find(
            (item) => item.resource_id === option.id
          )
        )
      })
    }

    category.resources.forEach((resource) => {
      if (currentValuesId.indexOf(`${resource.id}`) > -1) {
        resources[resource.id] = resource
      }
    })
  })

  Object.keys(selectedValues).forEach((key) => {
    const definition: NormalizedBonusResource | undefined = resources[key]

    if (!definition) {
      return
    }

    const periodRate: PeriodResourceRate | undefined =
      chosenPeriod.resource_data.find(
        (item) => item.resource_id === parseInt(key, 10)
      )

    if (!periodRate) {
      return
    }

    let quantityValidated =
      selectedValues[key].basketQuantity + periodRate.included_value >
      periodRate.upper_limit
        ? periodRate.upper_limit - periodRate.included_value
        : selectedValues[key].basketQuantity

    if (
      quantityValidated <
      periodRate.lower_limit - periodRate.included_value
    ) {
      quantityValidated = periodRate.lower_limit - periodRate.included_value
    }

    if (!result[key]) {
      result[key] = {
        id: key,
      }
    }

    result[key].basketQuantity = quantityValidated
    result[key].quantity = quantityValidated + periodRate.included_value
  })

  return result
}

export function getProductParameters(
  params: Record<string, BasketParamResponseItem>
): Record<string, BonusProductParameter> {
  const parameters = {}

  if (params) {
    Object.values(params).forEach((parameter) => {
      parameters[parameter.paramId] = {
        id: parameter.paramId,
        name: parameter.paramName,
        description: parameter.description,
        required: parameter.required,
        type: parameter.paramType,
        alias: parameter.paramAlias,
      }
    })
  }

  return parameters
}

export function getBonusItemTotalPrices(
  definition: ProductDefinition,
  bonusItem: BonusProduct
) {
  const periodDefinition = definition.periods
    .filter((period) => period.id === bonusItem.chosenPeriodId)
    .pop()

  const resourcesPrice = reduce(
    bonusItem.resources,
    (price, resource) => {
      const resourcePrice =
        periodDefinition?.resource_data?.find(
          (item) => item.resource_id === resource.id
        )?.price ?? 0

      return price + resource.basketQuantity * resourcePrice
    },
    0
  )

  return {
    regularPrice: getPriceObject(
      periodDefinition!.price_no_promo + resourcesPrice,
      1,
      getTaxRate()
    ),
    promoPrice: getPriceObject(
      periodDefinition!.price_register + resourcesPrice,
      1,
      getTaxRate()
    ),
  }
}

export function getBasketItemStateFromLastItems(
  basketItemId: number,
  lastItems: BasketLastItem[]
): BasketResponseItem | undefined {
  const items: BasketResponseItem[] = lastItems.filter(
    (item: BasketLastItem) => !("resource_id" in item)
  ) as BasketResponseItem[]

  if (!items) {
    return undefined
  }

  return items.find((item: BasketResponseItem) => item?.id === basketItemId)
}

export function getInitialChosenPeriod(
  periods: BonusPeriod[],
  chosenPeriodId: PlanPeriodNumericId | undefined,
  defaultPlanPeriod: PeriodName | null
): PlanPeriodNumericId {
  const result = periods.find((item) => item.id === chosenPeriodId)

  if (result) {
    return result.id
  }

  const defaultResult = periods.find(
    (item) => item.period_name === defaultPlanPeriod
  )

  if (defaultResult) {
    return defaultResult.id
  }

  return periods[0].id
}

export function getResourceLabels(
  resources: Record<string, Resource> | Resource[]
): Record<ResourceId, string> {
  const results = {}

  Object.values(resources).forEach((resource) => {
    results[resource.id] = resource.description
  })

  return results
}

export function getChosenPeriod(
  product: BonusProduct | DomainBonusProduct
): BonusPeriod {
  return safeFind(
    product.periods,
    (period) => period.id === product.chosenPeriodId
  )
}

export function updatePeriodPriceFromExternalSource(
  period: BonusPeriod,
  basketItemPrice: PriceValue,
  quantityDivider: number
): BonusPeriod {
  // Zabezpieczenie przed złym formatem stawki podatku (ONESTORE-6501)
  const taxRate =
    period.price.regularPrice.taxRate < 1
      ? period.price.regularPrice.taxRate * 100
      : period.price.regularPrice.taxRate

  const promoPrice = getPriceObjectWithTaxRate(
    basketItemPrice,
    taxRate,
    quantityDivider
  )

  return {
    ...period,
    price: {
      ...period.price,
      promoPrice,
    },
  }
}

import type { ReactElement } from "react"
import React, { useState } from "react"
import VisibilitySensor from "react-visibility-sensor"
import type { Props as ButtonAtmProps } from "@onestore/hel/dist/components/atoms/ButtonAtm"
import { useFormHook } from "~/hooks/useFormHook"
import useIsServer from "~/hooks/useIsServer"
import { EventCategory, sendGAFormEvent } from "~/lib/ga4"
import FormBuilder from "./FormBuilder"

interface FormWrapperProps {
  id: string
  buttonTitle?: string
  buttonText?: string
  buttonSize?: ButtonAtmProps["size"]
  buttonColor?: ButtonAtmProps["color"]
  additionalData?: Record<string, string>
}

export default function FormWrapper({
  id,
  buttonTitle,
  buttonText,
  buttonSize,
  buttonColor,
  additionalData = {},
}: FormWrapperProps): ReactElement<FormWrapperProps> {
  const { fetchData, result } = useFormHook(id)
  const [visible, setVisible] = useState(false)

  if (useIsServer()) {
    return <></>
  }

  const onChange = (isVisible: boolean): void => {
    if (isVisible && visible === false) {
      fetchData()
      sendGAFormEvent({
        event: EventCategory.FORM_VIEW,
        form_id: id,
      })
      setVisible(true)
    }
  }

  return (
    <VisibilitySensor onChange={onChange}>
      <FormBuilder
        name={id}
        form={{
          data: result,
          buttonTitle,
          buttonText,
          buttonColor,
          buttonSize,
        }}
        additionalData={additionalData}
      />
    </VisibilitySensor>
  )
}

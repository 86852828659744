import type { ReactElement } from "react"
import React from "react"
import type { PeriodInfo } from "@core/period-info"
import type { PriceType } from "@core/types"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import TooltipMol from "@onestore/hel/dist/components/molecules/TooltipMol"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import MarkdownTile from "@gatsby-plugin-generic-page/components/Markdown/Tile"
import LowestPriceInfo from "~/components/LowestPriceInfo"

interface Props {
  prefix?: string
  periodInfo: PeriodInfo
  priceType: PriceType
  periodText?: string
  tooltip?: string
  priceHasPrimaryColor?: boolean
}

export default function Price({
  prefix,
  periodInfo,
  priceType,
  periodText,
  tooltip,
  priceHasPrimaryColor,
}: Props): ReactElement<Props> {
  const hasPromotion = periodInfo.hasPromotion()
  const { priceValue, lowestPrice, lowestPricePercent } =
    periodInfo.usePrices(priceType)

  return (
    <>
      <PushOrg bottomSpace={0.5}>
        <FlexContainerOrg alignItems="center">
          <TextAtm
            typography="big3"
            data-test-id="advanced-product-card-regular-price"
          >
            {!hasPromotion && prefix ? (
              <TextAtm typography="tiny2" emphasis="medium">
                {prefix}{" "}
              </TextAtm>
            ) : null}
            <TextAtm
              color={
                hasPromotion || priceHasPrimaryColor ? "primary" : undefined
              }
            >
              {priceValue}
            </TextAtm>{" "}
            <TextAtm typography="tiny2" emphasis="medium">
              {periodText}
            </TextAtm>
          </TextAtm>

          {tooltip && hasPromotion ? (
            <PushOrg leftSpace={0.5}>
              <TooltipMol
                zIndex={2}
                iconSize="large"
                data-testid="advanced-product-card-tooltip"
              >
                <MarkdownTile>{tooltip}</MarkdownTile>
              </TooltipMol>
            </PushOrg>
          ) : null}
        </FlexContainerOrg>
      </PushOrg>

      {hasPromotion ? (
        <PushOrg topSpace={0.5}>
          <LowestPriceInfo
            value={lowestPrice}
            percent={lowestPricePercent}
            data-testid="lowest-price"
          />
        </PushOrg>
      ) : null}
    </>
  )
}

import type { ReactElement } from "react"
import React from "react"
import UnderlineLinkAtm from "@onestore/hel/dist/components/atoms/UnderlineLinkAtm"
import type { CtaElement } from "@gatsby-plugin-generic-page/fragments/ctaElement"
import { getCtaButtonFlatData } from "@gatsby-plugin-generic-page/helpers/getCtaElementFlatData"

export interface ProductBoxOuterFooterProps {
  outerFooter?: CtaElement[]
}

export default function ProductBoxOuterFooter({
  outerFooter,
}: ProductBoxOuterFooterProps): ReactElement<ProductBoxOuterFooterProps> | null {
  if (!outerFooter || !outerFooter.length) {
    return null
  }

  const { text, title, anchorId, href, openInNewTab, pages } =
    getCtaButtonFlatData(outerFooter[0])

  return (
    <UnderlineLinkAtm
      href={href}
      to={pages?.length ? pages[0].flatData.url : undefined}
      scrollTo={anchorId}
      typography="tiny1"
      title={title}
      openInNewTab={openInNewTab}
    >
      {text}
    </UnderlineLinkAtm>
  )
}

/* eslint-disable no-param-reassign */
import type { Draft } from "immer"
import { produce } from "immer"
import { get, merge } from "lodash"
import { HTTP_STATUS } from "@onestore/onestore-store-common/http"
import type { BonusProductActions } from "@gatsby-plugin-bonus/store/actions-product"
import { BonusProductActionsType } from "@gatsby-plugin-bonus/store/actions-product"
import {
  getBasketItemStateFromLastItems,
  handleProductResourceRequest,
  updatePeriodPriceFromExternalSource,
} from "@gatsby-plugin-bonus/store/utils"
import type { BonusState } from "@gatsby-plugin-bonus/types"
import { createFormValidationErrorsFromResponse } from "~/lib/forms"
import { initialState } from "./selectors"

export default function reducer(
  state: BonusState = initialState,
  action: BonusProductActions
) {
  return produce(state, (nextState: Draft<BonusState>) => {
    switch (action.type) {
      case BonusProductActionsType.BONUS_PRODUCT_CHANGE_BASKET_REQUEST:
        if (!nextState.product) {
          return
        }
        nextState.product.isPriceLoading = true

        return

      case BonusProductActionsType.BONUS_PRODUCT_CHANGE_BASKET_SUCCESS:
        if (!nextState.product) {
          return
        }
        nextState.product.isPriceLoading = false
        nextState.product.configurationSaved = true

        if (action.changedResources) {
          Object.keys(action.changedResources).forEach((key) => {
            if (nextState.product) {
              nextState.product.resources[key] = {
                ...nextState.product.resources[key],
                ...action.changedResources[key],
              }
            }
          })
        }

        return

      case BonusProductActionsType.BONUS_PRODUCT_CHANGE_ADVANCED_RESOURCE_REQUEST:
        if (!nextState.product) {
          return
        }
        nextState.product.isPriceLoading = true

        return

      case BonusProductActionsType.BONUS_PRODUCT_CHANGE_ADVANCED_RESOURCE_FAILURE:
        if (!nextState.product) {
          return
        }
        nextState.product.isPriceLoading = false

        return

      case BonusProductActionsType.BONUS_PRODUCT_CHANGE_ADVANCED_RESOURCE_SUCCESS: {
        if (!nextState.product) {
          return
        }
        nextState.product.isPriceLoading = false
        nextState.product.configurationSaved = false

        if (action.regularPrice) {
          nextState.product.totalPrices = {
            promoPrice: action.promoPrice,
            regularPrice: action.regularPrice,
          }
        }

        Object.values(action.resources).forEach((resource) => {
          if (nextState.product && state.product) {
            nextState.product.resources[resource.id] = {
              ...state.product.resources[resource.id],
              id: resource.id,
              quantity: resource.quantity,
              basketQuantity: resource.basketQuantity,
            }
          }
        })

        return
      }
      case BonusProductActionsType.BONUS_PRODUCT_ADD_TO_BASKET_REQUEST:
        if (!nextState.product) {
          return
        }
        nextState.product.addToBasketStatus = action.status
        nextState.product.isAddToBasketLoading = true
        nextState.product.autoAddedToBasket = action.automaticAdd

        return

      case BonusProductActionsType.BONUS_PRODUCT_ADD_TO_BASKET_SUCCESS:
        if (!nextState.product) {
          return
        }
        nextState.product.configurationSaved = true
        nextState.product.addedToBasket = true
        nextState.product.removedFromBasket = false
        nextState.product.isValid = true
        nextState.product.isAddToBasketLoading = false
        nextState.product.resources = merge(
          {},
          nextState.product.resources,
          action.resources
        )
        nextState.product.addToBasketStatus = action.status
        nextState.product.basketItemState = getBasketItemStateFromLastItems(
          action.basketItemId,
          action.lastItems
        )

        nextState.product.periods = nextState.product.periods.map((period) => {
          if (period.id === nextState.product?.chosenPeriodId) {
            return updatePeriodPriceFromExternalSource(
              period,
              action.basketItemPrice,
              nextState.product?.minQuantity || 1
            )
          }

          return period
        })

        return

      case BonusProductActionsType.BONUS_PRODUCT_ADD_TO_BASKET_FAILURE:
        if (!nextState.product) {
          return
        }
        nextState.product.addToBasketStatus = action.status
        nextState.product.isAddToBasketLoading = false
        nextState.product.autoAddedToBasket = false
        nextState.product.addToBasketError =
          createFormValidationErrorsFromResponse({
            form: get(action.error, `form.children.parameters`, undefined),
          })

        return
      case BonusProductActionsType.BONUS_CHANGE_PRODUCT_PERIOD_REQUEST:
        if (!nextState.product) {
          return
        }
        nextState.product.previousPeriodId = nextState.product.chosenPeriodId
        nextState.product.chosenPeriodId = action.period.id
        nextState.product.changePeriodStatus = action.status
        nextState.product.isChangePeriodLoading = true

        return

      case BonusProductActionsType.BONUS_CHANGE_PRODUCT_PERIOD_SUCCESS:
        if (!nextState.product) {
          return
        }

        // TODO - przekazywać w każdym dispatchu totalPrices ...
        if (action.regularPrice) {
          nextState.product.totalPrices.promoPrice = action.promoPrice
          nextState.product.totalPrices.regularPrice = action.regularPrice
        }

        if (action.basketItemId && action.lastItems) {
          nextState.product.basketItemState = getBasketItemStateFromLastItems(
            action.basketItemId,
            action.lastItems
          )
        }

        nextState.product.changePeriodStatus = action.status
        nextState.product.isChangePeriodLoading = false

        return

      case BonusProductActionsType.BONUS_CHANGE_PRODUCT_PERIOD_FAILURE:
        if (!nextState.product) {
          return
        }
        nextState.product.chosenPeriodId = nextState.product.previousPeriodId
        nextState.product.changePeriodStatus = action.status
        nextState.product.isChangePeriodLoading = false
        nextState.product.changePeriodError = action.error

        return

      case BonusProductActionsType.BONUS_CHANGE_PRODUCT_RESOURCE_REQUEST: {
        if (!nextState.product || !state.product) {
          return
        }
        handleProductResourceRequest(action, state.product, nextState.product)

        return
      }
      case BonusProductActionsType.BONUS_CHANGE_PRODUCT_RESOURCE_SUCCESS:
        if (!nextState.product) {
          return
        }
        nextState.product.isChangeResourceLoading = false
        nextState.product.changeResourceStatus = action.status
        nextState.product.resources = action.resources

        return

      case BonusProductActionsType.BONUS_GET_PRODUCT_PRICE_REQUEST:
        if (!nextState.product) {
          return
        }
        nextState.product.getPriceStatus = action.status
        nextState.product.isPriceLoading = true

        return

      case BonusProductActionsType.BONUS_GET_PRODUCT_PRICE_SUCCESS:
        if (!nextState.product) {
          return
        }
        nextState.product.getPriceStatus = action.status
        nextState.product.isPriceLoading = false

        return

      case BonusProductActionsType.BONUS_GET_PRODUCT_PRICE_FAILURE:
        if (!nextState.product) {
          return
        }
        nextState.product.getPriceStatus = action.status
        nextState.product.isPriceLoading = false
        nextState.product.getPriceError = action.error

        return

      case BonusProductActionsType.BONUS_CHANGE_PRODUCT_PRICE:
        if (!nextState.product) {
          return
        }

        nextState.product.periods = action.periods

        return

      case BonusProductActionsType.BONUS_VALIDATE_PRODUCT_SUCCESS:
        if (!nextState.product) {
          return
        }
        nextState.product.isValid = action.isValid
        nextState.product.addToBasketError = undefined

        return

      case BonusProductActionsType.BONUS_VALIDATE_PRODUCT_FAILURE:
        if (!nextState.product) {
          return
        }
        nextState.product.isValid = action.isValid
        // TODO, raczej nie używane sprawdzić
        // nextState.product.errors = action.errors
        nextState.product.addToBasketStatus = HTTP_STATUS.NONE
        nextState.product.isAddToBasketLoading = false

        return

      case BonusProductActionsType.BONUS_PRODUCT_NEEDS_UPDATE:
        if (!nextState.product) {
          return
        }
        nextState.product.configurationSaved = false
        nextState.product.addToBasketError = undefined

        return

      case BonusProductActionsType.BONUS_UPDATE_PRODUCT_PRICE_REQUEST:
        if (!nextState.product) {
          return
        }
        nextState.product.isPriceLoading = true

        return

      case BonusProductActionsType.BONUS_UPDATE_PRODUCT_PRICE_SUCCESS:
        if (!nextState.product) {
          return
        }
        nextState.product.totalPrices = action.totalPrices
        nextState.product.isPriceLoading = false

        return

      case BonusProductActionsType.BONUS_UPDATE_PRODUCT_PRICE_FAILURE:
        if (!nextState.product) {
          return
        }
        nextState.product.isPriceLoading = false

        return
    }
  })
}

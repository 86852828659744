import { typeMatches } from "@onestore-graphql"
import type { SectionOrgProps } from "@onestore/hel/dist/components/organisms/SectionOrg/SectionOrg.props"
import type { Spacing } from "@gatsby-plugin-generic-page/fragments/spacing"
import type { SpacingBreakpoints } from "@gatsby-plugin-generic-page/fragments/spacingBreakpoints"
import { SquidexComponentType } from "@gatsby-plugin-generic-page/types/componentType"
import isEmpty from "~/lib/isEmpty"

function getSectionPadding(
  spacingTab: Spacing | SpacingBreakpoints | undefined
): SectionOrgProps["spacing"] {
  if (isEmpty(spacingTab)) {
    return
  }

  if (typeMatches(spacingTab.__typename, SquidexComponentType.SPACING)) {
    const { top, bottom } = spacingTab as Spacing

    return {
      top,
      bottom,
    }
  }

  if (
    typeMatches(spacingTab.__typename, SquidexComponentType.SPACING_BREAKPOINTS)
  ) {
    const { small, medium, large } = spacingTab as SpacingBreakpoints

    return {
      small: {
        top: small.top,
        bottom: small.bottom,
      },
      medium: {
        top: medium.top,
        bottom: medium.bottom,
      },
      large: {
        top: large.top,
        bottom: large.bottom,
      },
    }
  }

  return
}

export default getSectionPadding

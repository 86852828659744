import { graphql } from "gatsby"
import type { CustomTextColors } from "@gatsby-plugin-generic-page/fragments/customColor"
import type { ProductSaleConfiguration } from "@gatsby-plugin-generic-page/fragments/productSaleConfiguration"
import type { StaticPrice } from "~/fragments/staticPrice"
import type { BackgroundColor, CloudBlueDomain } from "~/types/squidex"
import type { LinkElement } from "./linkElement"

export enum ModalItemProductType {
  PLAN = "plan",
  DOMAIN = "domena",
  STATIC_PRICE = "cena statyczna",
  INDIVIDUAL_PRICE = "cena indywidualna",
}

export type ModalItemFlatData = {
  title: string
  description: string
  productType: ModalItemProductType
  productSaleConfiguration?: ProductSaleConfiguration[]
  domain?: CloudBlueDomain[]
  staticPrice?: StaticPrice[]
  prefixAlternativeText?: string
  alternativeText?: string
  link?: LinkElement
  priceBoxBackgroundColor?: BackgroundColor
  priceBoxTextColor?: keyof typeof CustomTextColors
}

export type ModalItem = {
  id: string
  flatData: ModalItemFlatData
}

export const query = graphql`
  fragment SideModalItem on GraphCmsSquidex_SideModalItem {
    __typename
    flatData {
      title
      description
      productType
      productSaleConfiguration {
        ...ProductSaleConfiguration
      }
      domain {
        ...CloudBlueDomain
      }
      staticPrice {
        ...StaticPrice
      }
      prefixAlternativeText
      alternativeText
      link {
        ...LinkElement
      }
      priceBoxBackgroundColor {
        ... on GraphCmsSquidex_BackgroundColorComponent {
          __typename
          color
        }
      }
      priceBoxTextColor
    }
  }
`

export const FRAGMENT_SIDE_MODAL_ITEM = `
  fragment SideModalItem on SideModalItem {
    __typename
    flatData {
      title
      description
      productType
      productSaleConfiguration {
        ...ProductSaleConfiguration
      }
      domain {
        ...CloudBlueDomain
      }
      staticPrice {
        ...StaticPrice
      }
      prefixAlternativeText
      alternativeText
      link {
        ...LinkElement
      }
      priceBoxBackgroundColor {
        ... on BackgroundColorComponent {
          __typename
          color
        }
      }
      priceBoxTextColor
    }
  }
`

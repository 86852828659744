import type { FieldInputProps, FieldMetaState } from "react-final-form"
import { useFormState } from "react-final-form"

interface Result {
  hasError: boolean
  errorMessage: string | undefined
  errorPlaceholder: string | undefined
}

export function useFormFieldError(
  input: FieldInputProps<string>,
  meta: FieldMetaState<string>
): Result {
  const { submitErrors } = useFormState()

  const submitErrorKey = submitErrors
    ? Object.keys(submitErrors).find((key: string) => key === input.name)
    : undefined

  const submitError =
    submitErrors && submitErrorKey ? submitErrors[submitErrorKey] : undefined

  const hasSubmitError =
    (!!meta.submitError || !!submitError) && !meta.dirtySinceLastSubmit

  const hasError = (meta.error && meta.touched) || hasSubmitError

  const errorMessage = meta.error || meta.submitError || submitError

  const errorPlaceholder = hasSubmitError
    ? meta.submitError || submitError
    : undefined

  return {
    hasError,
    errorMessage: hasError ? errorMessage : undefined,
    errorPlaceholder: hasError ? errorPlaceholder : undefined,
  }
}

import type { ReactElement } from "react"
import React from "react"
import FlexibleTableOrg from "@onestore/hel/dist/components/organisms/FlexibleTableOrg"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import assetsSquidexToImageObject from "~/lib/assetsSquidexToImageObject"
import isEmpty from "~/lib/isEmpty"
import type { TableRow } from "~/types/squidex"

interface Props {
  rows: TableRow[]
}

export default function StaticTable({ rows }: Props): ReactElement<Props> {
  return (
    <FlexibleTableOrg
      rows={rows.map((row) => ({
        ...row,
        icon: !isEmpty(row.icon)
          ? assetsSquidexToImageObject(row.icon, "")
          : undefined,
        cells: row.cells.map((cell) => ({
          ...cell,
          text: (
            <MarkdownText typography={{ default: "big1", bold: "big3" }}>
              {cell.text}
            </MarkdownText>
          ),
        })),
      }))}
    />
  )
}

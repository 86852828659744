import type { ReactElement } from "react"
import React from "react"
import type { MarkdownToJSX } from "markdown-to-jsx"
import ListMol from "@onestore/hel/dist/components/molecules/ListMol"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import getBaseMarkdownOverrides from "@gatsby-plugin-generic-page/helpers/getBaseMarkdownOverrides"
import BaseMarkdown from "../Base"

interface Props {
  children: string
  overrides?: MarkdownToJSX.Overrides
}

function Tile({ children, overrides }: Props): ReactElement<Props> {
  const markdownOverrides = {
    ...getBaseMarkdownOverrides({
      typography: "small1",
      boldTypography: "small4",
      pushSpace: 2.5,
    }),
    ul: {
      component: ({ children }) => (
        <PushOrg bottomSpace={2}>
          <ListMol
            items={children.map((child: ReactElement) => child.props.children)}
            type="dot"
            size="small"
          />
        </PushOrg>
      ),
    },
  }

  return (
    <BaseMarkdown
      options={{
        overrides: overrides || markdownOverrides,
        forceBlock: true,
      }}
    >
      {children}
    </BaseMarkdown>
  )
}

export default Tile

import type { ReactElement } from "react"
import React from "react"
import AdvancedProductCardMol from "@onestore/hel/dist/components/molecules/AdvancedProductCardMol"
import type { ProductCardItem } from "@gatsby-plugin-marketplace/fragments/marketplaceProductCard"
import useAdvancedProductCard from "~/hooks/useAdvancedProductCard"
import type { LinkPropertiesCollectionHook } from "~/hooks/useLinkPropertiesCollection"

interface Props {
  productData: ProductCardItem
  link: LinkPropertiesCollectionHook
  onClick?: { (): void }
}

function AdvancedProductCard({
  productData,
  link,
}: Props): ReactElement<Props> {
  return (
    <AdvancedProductCardMol {...useAdvancedProductCard(productData, link)} />
  )
}

export default AdvancedProductCard

import type { ReactElement, ReactNode } from "react"
import { useEffect } from "react"
import { useCallback } from "react"
import React, { useState } from "react"
import type { FeedbackMessage, GeneralField } from "@formily/core"
import { connect, mapProps, useForm } from "@formily/react"
import { get } from "lodash"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import CheckboxWithLabelMol from "@onestore/hel/dist/components/molecules/CheckboxWithLabelMol"
import GridOrg from "@onestore/hel/dist/components/organisms/GridOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import isEmpty from "~/lib/isEmpty"
import checkboxMaxValidator from "./validators/checkboxMaxValidator"

type CheckboxProps = {
  children: ReactNode
}

type CheckboxElement = {
  label: string
  value: string
  description?: string
}

type CheckboxGroupProps = {
  value?: string[]
  errorMessage?: string
}

type CheckboxGroupField = {
  name?: string
  dataSource?: CheckboxElement[]
  setValue?: (value: string[]) => void
  required?: boolean
  errors?: {
    messages?: FeedbackMessage
  }[]
  gridColWidth?: number
  max?: number
}

type CheckboxWithLabelProps = {
  checkbox: CheckboxElement
  currentValue?: string[]
  setValue: CheckboxGroupField["setValue"]
  required?: boolean
  hasError?: boolean
  isInHorizontalGroup?: boolean
}

type ComposedCheckbox = React.FC<CheckboxProps> & {
  Group?: React.FC
}

const CheckboxWithLabel = ({
  checkbox,
  currentValue,
  setValue,
  required,
  hasError,
  isInHorizontalGroup,
}: CheckboxWithLabelProps): ReactElement<CheckboxWithLabelProps> | null => {
  const form = useForm()
  const [isChecked, setIsChecked] = useState(
    currentValue?.includes(checkbox.value) || false
  )

  const handleOnChange = useCallback(
    (value?: string): void => {
      if (!setValue || !value) {
        return
      }

      const updatedValue = isChecked
        ? (currentValue || []).filter(
            (currentCheckboxValue: string) => currentCheckboxValue !== value
          )
        : [...(currentValue || []), value]

      setValue(updatedValue)
      setIsChecked((isChecked) => !isChecked)

      if (!isEmpty(form) && form.submitting) {
        form.validate().catch(() => {})
      }
    },
    [isChecked, currentValue, setValue, form]
  )

  if (!setValue) {
    return null
  }

  const requiredJSX = required ? (
    <TextAtm typography="small2" color="primary" pushSpace={{ right: 0.5 }}>
      *
    </TextAtm>
  ) : null

  return (
    <CheckboxWithLabelMol
      text={
        <>
          <>{requiredJSX}</>
          <>{checkbox.label}</>
        </>
      }
      typography="small2"
      gutterSpace={1}
      onChange={() => handleOnChange(checkbox.value)}
      isChecked={isChecked}
      hasError={hasError}
      isInHorizontalGroup={isInHorizontalGroup}
    />
  )
}

export const CheckboxGroupLayout = ({
  name,
  dataSource,
  value,
  setValue,
  required,
  errorMessage,
  gridColWidth,
  max,
}: CheckboxGroupProps & CheckboxGroupField) => {
  const form = useForm()
  const isGroup = !isEmpty(dataSource) && dataSource.length > 1

  useEffect(() => {
    if (isGroup && !isEmpty(value) && name && max) {
      checkboxMaxValidator(form, name, value, max)
    }
  }, [value, isGroup])

  const CheckboxElement = ({ checkbox }: { checkbox: CheckboxElement }) => (
    <>
      <CheckboxWithLabel
        checkbox={checkbox}
        currentValue={value}
        setValue={setValue}
        required={required && !isGroup}
        hasError={!isEmpty(errorMessage)}
        isInHorizontalGroup={isGroup && !!gridColWidth}
      />

      {checkbox.description ? (
        <TextAtm typography="tiny1" emphasis="low" pushSpace={{ left: 3.5 }}>
          {checkbox.description}
        </TextAtm>
      ) : null}

      {!isEmpty(errorMessage) && !isGroup ? (
        <TextAtm
          typography="tiny1"
          pushSpace={{ left: 3.5, top: 1 }}
          color="error"
        >
          {errorMessage}
        </TextAtm>
      ) : null}
    </>
  )

  return (
    <>
      {!isEmpty(errorMessage) && isGroup ? (
        <TextAtm
          typography="tiny1"
          pushSpace={{ top: 1, bottom: 1 }}
          color="error"
        >
          {errorMessage}
        </TextAtm>
      ) : null}

      {gridColWidth ? (
        <GridOrg autoFitColWidth={gridColWidth} gutterSpace={1.5}>
          {!isEmpty(dataSource)
            ? dataSource.map((checkbox: CheckboxElement, index: number) => (
                <CheckboxElement checkbox={checkbox} />
              ))
            : null}
        </GridOrg>
      ) : (
        <>
          {!isEmpty(dataSource)
            ? dataSource.map((checkbox: CheckboxElement, index: number) => (
                <PushOrg
                  key={`${checkbox.label}-${index}`}
                  bottomSpace={1}
                  topSpace={1}
                >
                  <CheckboxElement checkbox={checkbox} />
                </PushOrg>
              ))
            : null}
        </>
      )}
    </>
  )
}

const Checkbox: ComposedCheckbox = ({ children }: CheckboxProps) => (
  <>{children}</>
)

Checkbox.Group = connect(
  CheckboxGroupLayout,
  mapProps(
    {},
    (props: CheckboxGroupProps, field: CheckboxGroupField & GeneralField) => {
      const validator = get(field, "validator", [])
      const checkboxMaxValidatorIndex = validator.findIndex((obj) =>
        Object.keys(obj).includes("checkboxMax")
      )
      const checkboxMaxObject = validator[checkboxMaxValidatorIndex]
      const checkboxMax = get(checkboxMaxObject, "checkboxMax")

      return {
        name:
          typeof field.props?.name === "string" ? field.props.name : undefined,
        dataSource: field.dataSource,
        value: props.value,
        setValue: field.setValue,
        required: field.required,
        errorMessage: get(field, "errors[0].messages[0]", ""),
        gridColWidth: get(field, "decorator[1].gridSpan"),
        max: checkboxMax,
      }
    }
  )
)

export default Checkbox

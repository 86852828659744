/**
 * @Module checkout/actions/office
 */
import { get } from "lodash"
import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import StoreAPI from "~/lib/api"
import { forceRedirectToIndex } from "~/lib/browser"
import { AppState } from "~/store/reducer"

export enum OfficeActionTypes {
  SET_DOMAIN = "CHECKOUT.ACTIONS.OFFICE.SET_DOMAIN",
  CHECK_DOMAIN_PENDING = "CHECKOUT.ACTIONS.OFFICE.CHECK_DOMAIN_PENDING",
  CHECK_DOMAIN_SUCCESS = "CHECKOUT.ACTIONS.OFFICE.CHECK_DOMAIN_SUCCESS",
  CHECK_DOMAIN_FAILURE = "CHECKOUT.ACTIONS.OFFICE.CHECK_DOMAIN_FAILURE",
  CHECK_DOMAIN_RESET = "CHECKOUT.ACTIONS.OFFICE.CHECK_DOMAIN_RESET",
  CHECK_ACCOUNT_PENDING = "CHECKOUT.ACTIONS.OFFICE.CHECK_ACCOUNT_PENDING",
  CHECK_ACCOUNT_SUCCESS = "CHECKOUT.ACTIONS.OFFICE.CHECK_ACCOUNT_SUCCESS",
  CHECK_ACCOUNT_FAILURE = "CHECKOUT.ACTIONS.OFFICE.CHECK_ACCOUNT_FAILURE",
  CHECK_ACCOUNT_RESET = "CHECKOUT.ACTIONS.OFFICE.CHECK_ACCOUNT_RESET",
  CHECK_ACCOUNT_CLICKED_ON_CONFIRM = "CHECKOUT.ACTIONS.OFFICE.CHECK_ACCOUNT_CLICKED_ON_CONFIRM",
}

export type OfficeAction = AnyAction

export type OfficeThunkAction = ThunkAction<
  void,
  AppState,
  undefined,
  OfficeAction
>

/**
 * Akcja sprawdzająca w API, czy podana domena do konta Microsoft jest dostepna.
 *
 * @function
 * @param    {string}  domain Domena do sprawdzenia
 * @returns  {Promise}
 */
export function checkDomain(domain) {
  return (dispatch, getState) => {
    dispatch({
      type: OfficeActionTypes.CHECK_DOMAIN_PENDING,
      domain,
    })

    const token = getState().basket.token

    if (!token) {
      forceRedirectToIndex()
    }

    return StoreAPI.checkOfficeDomain(token, domain)
      .catch((e) => {
        dispatch({
          type: OfficeActionTypes.CHECK_DOMAIN_FAILURE,
          domain,
          response_status_code: get(e, ["error", "code"]),
          message: e.message,
        })
      })
      .then((response) => {
        if (!response) {
          return
        }

        dispatch({
          type: OfficeActionTypes.CHECK_DOMAIN_SUCCESS,
          domain,
          available: response.available,
        })
      })
  }
}

/**
 * Akcja do resetowania stanu sprawdzenia domeny
 *
 * @function
 * @returns  {Promise}
 */
export function resetDomainCheck() {
  return (dispatch) => {
    dispatch({
      type: OfficeActionTypes.CHECK_DOMAIN_RESET,
    })
  }
}

/**
 * Akcja do sprawdzania (logowania) konta Microsoft w API
 *
 * @function
 * @param    {string}  login    Login użytkownika Microsoft
 * @param    {string}  password Hasło do konta Microsoft
 * @returns  {Promise}
 */
export function checkAccountOwnership(domain: string) {
  return (dispatch, getState) => {
    dispatch({
      type: OfficeActionTypes.CHECK_ACCOUNT_PENDING,
      domain,
    })

    const token = getState().basket.token

    if (!token) {
      forceRedirectToIndex()
    }

    return StoreAPI.checkOfficeDomainOwnership(token, domain)
      .catch((e) => {
        dispatch({
          type: OfficeActionTypes.CHECK_ACCOUNT_FAILURE,
          response_status_code: get(e, ["error", "code"]),
          message: e.message,
        })
      })
      .then((response) => {
        if (!response) {
          return
        }

        dispatch({
          type: OfficeActionTypes.CHECK_ACCOUNT_SUCCESS,
          account_status: response,
        })
      })
  }
}

/**
 * Resetuję flagę autoryzacja (logowania się) kontem Microsoft
 *
 * @function
 * @returns  {Promise}
 */
export function resetAccountCheck() {
  return (dispatch) => {
    dispatch({
      type: OfficeActionTypes.CHECK_ACCOUNT_RESET,
    })
  }
}

/**
 * Ustawia flagę, czy użytkownik już kliknął na przycisk aby potwierdzić autoryzację konta Microsoft
 *
 * @function
 * @returns {Promise}
 */
export function clickOnConfirm() {
  return (dispatch) => {
    dispatch({
      type: OfficeActionTypes.CHECK_ACCOUNT_CLICKED_ON_CONFIRM,
    })
  }
}

import type { ReactElement } from "react"
import React from "react"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import Picture from "@onestore/hel/dist/components/quarks/Picture"
import assetsSquidexToImageObject from "~/lib/assetsSquidexToImageObject"
import type { List as SquidexList } from "~/types/squidex"

interface Props {
  list: SquidexList[]
}

function List({ list }: Props): ReactElement<Props> {
  const listItems = list.map(({ text, icon }, index, array) => {
    const iconProps = assetsSquidexToImageObject(icon, text)

    const isLastItem = index === array.length - 1

    return (
      <PushOrg
        key={`${index}-${text}`}
        rightSpace={isLastItem ? 0 : 3}
        bottomSpace={{ small: 1.5, medium: 0, large: 0 }}
      >
        <FlexContainerOrg alignItems="center" key={`${index}-${text}`}>
          <PushOrg rightSpace={1}>
            <Picture srcData={iconProps} />
          </PushOrg>

          <TextAtm typography="medium2_h">{text}</TextAtm>
        </FlexContainerOrg>
      </PushOrg>
    )
  })

  return (
    <PushOrg bottomSpace={3}>
      <BreakpointVisibilityOrg from="small" to="medium">
        <FlexContainerOrg direction="column">{listItems}</FlexContainerOrg>
      </BreakpointVisibilityOrg>

      <BreakpointVisibilityOrg from="medium">
        <FlexContainerOrg direction="row">{listItems}</FlexContainerOrg>
      </BreakpointVisibilityOrg>
    </PushOrg>
  )
}

export default List

import type { ReactElement } from "react"
import React, { useState } from "react"
import PlainLinkAtm from "@onestore/hel/dist/components/atoms/PlainLinkAtm"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import BarOrg from "@onestore/hel/dist/components/organisms/BarOrg"
import WidthOrg from "@onestore/hel/dist/components/organisms/WidthOrg"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import { CustomTextColors } from "@gatsby-plugin-generic-page/fragments/customColor"
import getBaseMarkdownOverrides from "@gatsby-plugin-generic-page/helpers/getBaseMarkdownOverrides"
import getCustomBackgroundColor from "@gatsby-plugin-generic-page/helpers/getCustomBackgroundColor"
import { BackgroundType } from "~/hooks/useNotificationBarQuery"
import Modal from "./Modal"
import stylesByType from "./stylesByType"
import type { BaseBarProps } from "./types"
import { BarType } from "./types"

function BaseBar({ data, type }: BaseBarProps): ReactElement {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const {
    content,
    modalData,
    linkData,
    textColor,
    image,
    backgroundColor,
    backgroundType,
    onCloseClick,
  } = data

  const { color, spacingSpace } = stylesByType[type]
  const isPromoBar = type === BarType.PROMOTION

  const customBackgroundColor = backgroundColor
    ? getCustomBackgroundColor(backgroundColor)
    : undefined

  let linkJsx
  let modalJsx
  const markdownContent = (
    <MarkdownText
      overrides={{
        ...getBaseMarkdownOverrides({
          typography: "tiny2",
          boldTypography: "tiny3",
        }),
      }}
    >
      {content}
    </MarkdownText>
  )

  if (modalData) {
    linkJsx = (
      <PlainLinkAtm
        title={modalData.title}
        typography="tiny2"
        type="plain"
        onClick={() => {
          setIsModalVisible(true)
        }}
      >
        {markdownContent}
      </PlainLinkAtm>
    )

    modalJsx = (
      <Modal
        isOpen={isModalVisible}
        modalData={modalData}
        onCloseButtonClick={() => setIsModalVisible(false)}
      />
    )
  }

  if (linkData) {
    linkJsx = (
      <PlainLinkAtm {...linkData} typography="tiny2" type="plain">
        {markdownContent}
      </PlainLinkAtm>
    )
  }

  if (!linkData && !modalData) {
    linkJsx = markdownContent
    modalJsx = null
  }

  return (
    <>
      <BarOrg
        color={color}
        customColor={
          isPromoBar &&
          backgroundType === BackgroundType.COLOR &&
          customBackgroundColor
            ? customBackgroundColor
            : undefined
        }
        customTextColor={
          isPromoBar && textColor ? CustomTextColors[textColor] : undefined
        }
        spacingSpace={spacingSpace}
        backgroundImage={
          isPromoBar && backgroundType === BackgroundType.IMAGE && image
            ? image
            : undefined
        }
        onCloseClick={onCloseClick}
      >
        <AlignContentOrg
          verticalAlign="center"
          horizontalAlign="center"
          horizontalAlignType="both"
        >
          <WidthOrg widthSpace={160}>{linkJsx}</WidthOrg>
        </AlignContentOrg>
      </BarOrg>

      {modalJsx}
    </>
  )
}

export default BaseBar

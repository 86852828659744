import type { ReactElement } from "react"
import React, { useState } from "react"
import SelectSquareButtonAtm from "@onestore/hel/dist/components/atoms/SelectSquareButtonAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import ContentToggleMol from "@onestore/hel/dist/components/molecules/ContentToggleMol"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import ContentWithImageOrg from "@onestore/hel/dist/components/organisms/ContentWithImageOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import WidthOrg from "@onestore/hel/dist/components/organisms/WidthOrg"
import WrapOrg from "@onestore/hel/dist/components/organisms/WrapOrg"
import ConditionalWrapper from "@onestore/hel/dist/helpers/ConditionalWrapper"
import useBreakpoint from "@onestore/hel/dist/hooks/useBreakpoint"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import Media from "@gatsby-plugin-generic-page/components/Media"
import type { ButtonsAnimatedWithImageSectionFlatData } from "@gatsby-plugin-generic-page/fragments/buttonsAnimatedWithImageSection"

export default function ButtonsAnimatedWithImageSection({
  title,
  text,
  buttonsTitle,
  isReversed,
  toggleButtonsWithImage,
  sectionSettings,
}: ButtonsAnimatedWithImageSectionFlatData): ReactElement<ButtonsAnimatedWithImageSectionFlatData> {
  const [activeElement, setActiveElement] = useState(0)
  const isSmallOnly = useBreakpoint("small", "medium")

  const buttonImages = toggleButtonsWithImage.map(({ buttonImage }) => (
    <Media media={buttonImage} key={buttonImage[0].id} />
  ))

  const assets = toggleButtonsWithImage.map(({ image }) => (
    <Media media={image} key={image[0].id} />
  ))

  const titleWithTextJSX = (
    <>
      <TextAtm htmlTag="h2" typography="xhuge" pushSpace={text ? 3 : 5}>
        {title}
      </TextAtm>

      {text ? (
        <PushOrg bottomSpace={2.5}>
          <MarkdownText>{text}</MarkdownText>
        </PushOrg>
      ) : null}
    </>
  )

  const contentJSX = (
    <ContentToggleMol content={assets} activeIndex={activeElement} />
  )

  const toggleButtonsJSX = (
    <>
      <TextAtm typography="medium3_h" pushSpace={3}>
        {buttonsTitle}
      </TextAtm>

      <ConditionalWrapper
        condition={buttonImages.length === 4 && isSmallOnly}
        wrapper={(content) => (
          <AlignContentOrg horizontalAlign="center">
            <WidthOrg widthSpace={27}>{content}</WidthOrg>
          </AlignContentOrg>
        )}
      >
        <WrapOrg gutterSpace={{ small: 1, medium: 3, large: 3 }}>
          {buttonImages.map((buttonImage, index) => (
            <SelectSquareButtonAtm
              onClick={() => setActiveElement(index)}
              isActive={activeElement === index}
              key={index}
            >
              {buttonImage}
            </SelectSquareButtonAtm>
          ))}
        </WrapOrg>
      </ConditionalWrapper>
    </>
  )

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        <BreakpointVisibilityOrg from="large">
          <ContentWithImageOrg
            content={
              <>
                {titleWithTextJSX}

                {toggleButtonsJSX}
              </>
            }
            image={contentJSX}
            isReversedOnLarge={isReversed}
          />
        </BreakpointVisibilityOrg>

        <BreakpointVisibilityOrg from="small" to="medium">
          <AlignContentOrg
            horizontalAlign={{ small: "left", medium: "center", large: "left" }}
          >
            {titleWithTextJSX}

            <PushOrg bottomSpace={2.5} hasNoReset>
              {contentJSX}
            </PushOrg>
          </AlignContentOrg>

          <AlignContentOrg horizontalAlign="center">
            {toggleButtonsJSX}
          </AlignContentOrg>
        </BreakpointVisibilityOrg>
      </BaseContainer>
    </BaseSection>
  )
}

import type { ReactElement } from "react"
import React from "react"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import Header from "@gatsby-plugin-generic-page/components/Header"
import type { FaqSectionFlatData } from "@gatsby-plugin-generic-page/fragments/faqSection"
import FaqContent from "~/components/FaqContent"
import isEmpty from "~/lib/isEmpty"

export default function FaqSection({
  header,
  faqs,
  hasDarkMode,
  sectionSettings,
}: FaqSectionFlatData): ReactElement {
  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        {!isEmpty(header) ? (
          <PushOrg bottomSpace={8}>
            <AlignContentOrg horizontalAlign="center">
              <Header {...header} />
            </AlignContentOrg>
          </PushOrg>
        ) : null}

        <AlignContentOrg horizontalAlign="left" horizontalAlignType="text">
          <FaqContent faqs={faqs} hasDarkMode={hasDarkMode} hasFaqSchema />
        </AlignContentOrg>
      </BaseContainer>
    </BaseSection>
  )
}

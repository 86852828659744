import { pushErrorMessage } from "@onestore/onestore-store-common/debug"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import type { ProductSaleConfigurationFlatData } from "@gatsby-plugin-generic-page/fragments/productSaleConfiguration"
import isEmpty from "~/lib/isEmpty"
import url from "~/lib/url"
import type { CloudBlueDomain } from "~/types/squidex"

function getUpsellElements(
  upsell: CloudBluePlan[] | undefined,
  domainExtensions: CloudBlueDomain[] | undefined
): string[] | undefined {
  let upsellAliases: Array<string> | undefined

  upsellAliases = !isEmpty(upsell)
    ? upsell.map((upsell) => upsell.flatData.alias)
    : undefined

  if (!isEmpty(domainExtensions)) {
    const domain = `DOMAINS(extensions:${domainExtensions
      .map((extension) => extension.flatData.extension)
      .join("|")})`
    !isEmpty(upsellAliases)
      ? upsellAliases.unshift(domain)
      : (upsellAliases = [domain])
  }

  return upsellAliases
}

const getBonusUrl = (
  saleConfiguration: ProductSaleConfigurationFlatData,
  errorUrl: string
): string => {
  const {
    plan,
    bundle,
    period,
    upsell,
    domainSearch,
    checkoutFastMode,
    domainExtensions,
  } = saleConfiguration

  if (isEmpty(plan)) {
    pushErrorMessage(`CloudBluePlan not found. See: ${errorUrl}`)
  }

  return url.generateBonusUrl({
    p: plan[0].flatData.alias,
    b: !isEmpty(bundle) ? bundle[0].flatData.alias : undefined,
    ax: getUpsellElements(upsell, domainExtensions),
    period: !isEmpty(period) ? period.period : undefined,
    domainSearch: domainSearch,
    checkoutFastMode: checkoutFastMode ? "finalize" : undefined,
  })
}

export default getBonusUrl

import LocalStorage from "~/lib/storage/LocalStorage"

export enum LockType {
  ORDER_EVENT = "order_event",
}

function lockKey(type: LockType, key: string) {
  return `_lock_${type}_${key}`
}

export function hasLock(type: LockType, key: string): boolean {
  return LocalStorage.getBool(lockKey(type, key)) === true
}

export function removeLock(type: LockType, key: string): void {
  LocalStorage.remove(lockKey(type, key))
}

function removeLocksOfType(type: LockType) {
  LocalStorage.getKeyNames().forEach((key: string): void => {
    if (key.indexOf(lockKey(type, "")) === 0) LocalStorage.remove(key)
  })
}

export function setLock(
  type: LockType,
  key: string,
  onlyOneOfType: boolean = false
) {
  if (onlyOneOfType) {
    removeLocksOfType(type)
  }
  LocalStorage.setBool(lockKey(type, key), true)
}

import type { ReactElement } from "react"
import React from "react"
import useNotificationBars from "~/hooks/useNotificationBars"
import Bar from "./Bar"
import type { NotificationBarProps } from "./types"

function NotificationBar({
  location,
}: NotificationBarProps): ReactElement<NotificationBarProps> | null {
  const firstActiveBar = useNotificationBars(location)

  if (!firstActiveBar) {
    return null
  }

  return <Bar data={firstActiveBar.flatData} key={firstActiveBar.id} />
}

export default NotificationBar

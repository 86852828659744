import React from "react"
import type { ReactElement } from "react"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import SurfaceOrg from "@onestore/hel/dist/components/organisms/SurfaceOrg"
import WrapOrg from "@onestore/hel/dist/components/organisms/WrapOrg"
import isEmpty from "~/lib/isEmpty"
import type { Post as PostType } from "~/types/squidex"
import CtaElement from "../CtaElement"
import MarkdownText from "../Markdown/Text"
import Media from "../Media"

function Post({
  icon,
  texts,
  title,
  iconText,
  ctaElements,
}: PostType): ReactElement<PostType> {
  return (
    <SurfaceOrg color="backgroundLight" shadow="tiny" spacingSpace={6}>
      <PushOrg bottomSpace={2}>
        <WrapOrg gutterSpace={{ x: 1, y: 0 }} alignCenter="vertical">
          <Media media={icon} />

          <TextAtm typography="medium2_h">{iconText}</TextAtm>
        </WrapOrg>
      </PushOrg>

      <TextAtm typography="xlarge" htmlTag="h2" pushSpace={4}>
        {title}
      </TextAtm>

      {texts.map(({ text }, index) => (
        <PushOrg key={index} bottomSpace={4}>
          <MarkdownText>{text}</MarkdownText>
        </PushOrg>
      ))}

      {!isEmpty(ctaElements) && <CtaElement ctaElement={ctaElements} />}
    </SurfaceOrg>
  )
}

export default Post

import type { ReactElement } from "react"
import React from "react"
import ContentWithImageOrg from "@onestore/hel/dist/components/organisms/ContentWithImageOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import Picture from "@onestore/hel/dist/components/quarks/Picture"
import type { ThankYouMessageElement } from "@gatsby-plugin-generic-page/fragments/thankYouMessage"
import getBaseMarkdownOverrides from "@gatsby-plugin-generic-page/helpers/getBaseMarkdownOverrides"
import assetsSquidexToImageObject from "~/lib/assetsSquidexToImageObject"
import isEmpty from "~/lib/isEmpty"
import CtaElement from "../CtaElement"
import Texts from "../Texts"

function ThankYouMessage({
  texts,
  ctaElements,
  images,
}: ThankYouMessageElement): ReactElement {
  const titleMarkdownOverrides = getBaseMarkdownOverrides({
    typography: "great",
  })

  const textMarkdownOverrides = getBaseMarkdownOverrides({
    typography: "small2",
    pushSpace: 2,
  })

  return (
    <ContentWithImageOrg
      content={
        <>
          <Texts
            {...texts}
            titleMarkdownOverrides={titleMarkdownOverrides}
            textMarkdownOverrides={textMarkdownOverrides}
          />

          {!isEmpty(ctaElements) && (
            <PushOrg topSpace={3}>
              <CtaElement ctaElement={ctaElements} />
            </PushOrg>
          )}
        </>
      }
      image={<Picture srcData={assetsSquidexToImageObject(images, "")} />}
    />
  )
}

export default ThankYouMessage

import { useState, useEffect } from "react"

export default function useIsServer() {
  const [isHidden, setIsHidden] = useState(true)

  useEffect(() => {
    setIsHidden(false)
  }, [])

  return isHidden
}

import { graphql } from "gatsby"
import type { SpacingBase } from "@onestore/hel/dist/components/organisms/SectionOrg/SectionOrg.types"

export enum ContainerWidthType {
  TINY = "tiny",
  SMALL = "small",
  MEDIUM = "medium",
  DEFAULT = "default",
  FULL = "full",
}

export enum MarginType {
  SMALL = "small",
  DEFAULT = "default",
  HUGE = "huge",
  LARGE = "large",
  BIG = "big",
  STICKY = "sticky",
}

export interface ContainerSettings {
  containerWidth?: "small" | "tiny" | "medium" | "full" | "default"
  isFullWidthOnMobile?: boolean
  negativeMarginTop?: SpacingBase
  negativeMarginBottom?: SpacingBase
}

export const query = graphql`
  fragment ContainerSettings on GraphCmsSquidex_ContainerSettingsComponent {
    containerWidth
    isFullWidthOnMobile
    negativeMarginTop
    negativeMarginBottom
  }
`

export const FRAGMENT_CONTAINER_SETTINGS = `
  fragment ContainerSettings on ContainerSettingsComponent {
    containerWidth
    isFullWidthOnMobile
    negativeMarginTop
    negativeMarginBottom
  }
`

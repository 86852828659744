import React from "react"
import type { GeneralField } from "@formily/core"
import { connect, mapProps } from "@formily/react"
import RadioButtonWithLabelMol from "@onestore/hel/dist/components/molecules/RadioButtonWithLabelMol"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import isEmpty from "~/lib/isEmpty"

interface RadioElement {
  label?: string
  value?: string
}

export interface RadioGroupProps {
  value: string
}

interface RadioGroupField {
  dataSource?: RadioElement[]
  setValue?: (value?: string[]) => void
}

type ComposedRadio = React.FC & {
  Group?: React.FC
}

export const RadioGroupLayout = ({
  dataSource,
  value,
  setValue,
}: RadioGroupProps & RadioGroupField) => {
  if (!dataSource || !setValue) {
    return <></>
  }

  return (
    <>
      {dataSource.map((radio: RadioElement, index: number) => {
        if (!radio.label || !radio.value) {
          return null
        }

        const radioValueTab = [radio.value]

        return (
          <PushOrg key={`${radio.label}-${index}`} bottomSpace={2}>
            <RadioButtonWithLabelMol
              text={radio.label}
              typography="small1"
              gutterSpace={1}
              onChange={() => setValue(radioValueTab)}
              isChecked={!isEmpty(value) && value[0] === radio.value}
            />
          </PushOrg>
        )
      })}
    </>
  )
}

const Radio: ComposedRadio = () => <></>

Radio.Group = connect(
  RadioGroupLayout,
  mapProps(
    {},
    (props: RadioGroupProps, field: RadioGroupField & GeneralField) => {
      return {
        dataSource: field.dataSource,
        value: props.value,
        setValue: field.setValue,
      }
    }
  )
)

export default Radio

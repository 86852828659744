import _t from "@core/i18n"
import type { TableGroup } from "@onestore-graphql/Parameters"
import { has } from "lodash"
import type { ComparableProduct } from "~/fragments/comparableProduct"
import { compareProductParameters } from "~/lib/parameters"

export default function useProductParameters(
  products: ComparableProduct[],
  hasHiddenParametersName?: boolean
): TableGroup[] | null {
  if (products === null || !products.length) {
    return null
  }

  const parameters = compareProductParameters(
    products.filter((product: ComparableProduct): boolean =>
      has(product, "flatData.parameters")
    )
  )

  if (hasHiddenParametersName) {
    parameters[0].name = ""
  }

  if (parameters[0] && parameters[0].name === "_") {
    parameters[0].name = _t("marketplace.basicDifferences")
  }

  return parameters
}

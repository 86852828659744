import type { ReactElement } from "react"
import React from "react"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import WidthOrg from "@onestore/hel/dist/components/organisms/WidthOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import Header from "@gatsby-plugin-generic-page/components/Header"
import Post from "@gatsby-plugin-generic-page/components/Post"
import type { PostsSectionFlatData } from "@gatsby-plugin-generic-page/types/sectionType"
import isEmpty from "~/lib/isEmpty"

export default function PostsSection({
  header,
  posts,
  sectionSettings,
}: PostsSectionFlatData): ReactElement<PostsSectionFlatData> {
  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        {!isEmpty(header) && (
          <PushOrg bottomSpace={4} topSpace={4}>
            <AlignContentOrg
              horizontalAlign={{
                small: "left",
                medium: "center",
                large: "center",
              }}
            >
              <Header {...header} />
            </AlignContentOrg>
          </PushOrg>
        )}

        <WidthOrg widthSpace={106}>
          {posts.map((post, index) => (
            <PushOrg key={`post-${index}`} bottomSpace={4}>
              <Post {...post} />
            </PushOrg>
          ))}
        </WidthOrg>
      </BaseContainer>
    </BaseSection>
  )
}

import { Price } from "@core/price"
import { get } from "lodash"
import {
  isResponsePending,
  isResponseSuccessful,
} from "@onestore/onestore-store-common/http"
import { BASKET_STATUS } from "@gatsby-plugin-basket/store/constants"
import type { AppState } from "~/store/reducer"
import type { PriceValue } from "../../lib/api/basket"

export const isBasketLoading = (state: AppState): boolean =>
  isResponsePending(get(state, ["basket", "request_state"]))

export const isBasketDone = (state: AppState): boolean =>
  isResponseSuccessful(get(state, ["basket", "request_state"]))

export const getBasketItemsCount = (state: AppState): number =>
  get(state, ["basket", "itemsCount"])

export const getBasketVatValue = (state: AppState): number =>
  get(state, ["basket", "vatValue"], 0)

export const getBasketSavings = (state: AppState): PriceValue =>
  get(state, ["basket", "savings"], Price.zero())

export const getBasketNotArchived = (state: AppState): boolean =>
  state.basket &&
  isResponseSuccessful(state.basket.request_state) &&
  BASKET_STATUS.ARCHIVED !== state.basket.status

export const getPromoCode = (state: AppState): string | undefined =>
  state.basket.promo_code

export const getUserDataReady = (state: AppState): boolean =>
  state.basket.userDataReady

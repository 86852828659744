/* eslint-disable no-param-reassign */
import { produce } from "immer"
import type { UpdateBonusParametersFormStateAction } from "@gatsby-plugin-bonus/store/actions-bonus"
import { BonusProductActionsType } from "@gatsby-plugin-bonus/store/actions-product"
import type { BonusState } from "@gatsby-plugin-bonus/types"
import { initialState } from "./selectors"

export default function reducer(
  state: BonusState = initialState,
  action: UpdateBonusParametersFormStateAction
) {
  return produce(state, (nextState: BonusState) => {
    switch (action.type) {
      case BonusProductActionsType.BONUS_PARAMETERS_FORM_UPDATE:
        nextState.forms[action.alias] = {
          values: action.values,
          valid: action.valid,
        }
    }
  })
}

import { useStaticQuery, graphql } from "gatsby"

interface Query {
  allSitePage: {
    nodes: {
      path: string
    }[]
  }
}

/**
 * @deprecated
 */
export default function useInternalSitesQuery(): string[] {
  const { allSitePage } = useStaticQuery<Query>(graphql`
    {
      allSitePage {
        nodes {
          path
        }
      }
    }
  `)

  return allSitePage.nodes.map((site) => site.path)
}

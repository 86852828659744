import type { ReactElement } from "react"
import React from "react"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import SliderTemplate from "@onestore/hel/dist/components/templates/SliderTemplate"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import CtaElement from "@gatsby-plugin-generic-page/components/CtaElement"
import Header from "@gatsby-plugin-generic-page/components/Header"
import Media from "@gatsby-plugin-generic-page/components/Media"
import type { SliderSectionFlatData } from "@gatsby-plugin-generic-page/fragments/sliderSection"
import getLinkProps from "@gatsby-plugin-generic-page/helpers/getLinkProps"

function SliderSection({
  header,
  slides,
  hasArrowsOnMobile,
  ratio,
  ctaElements,
  sectionSettings,
}: SliderSectionFlatData): ReactElement<SliderSectionFlatData> {
  const sliderItems = slides.map((slide) => {
    const { responsiveImages, title, text, link } = slide

    const linkElement = link && getLinkProps(link)

    return {
      image: <Media media={responsiveImages} />,
      title,
      text,
      link: linkElement,
    }
  })

  return (
    <BaseSection {...sectionSettings} hasOverflowHidden>
      <BaseContainer {...sectionSettings?.containerSettings}>
        {header ? (
          <PushOrg bottomSpace={8}>
            <AlignContentOrg horizontalAlign="center">
              <Header {...header} />
            </AlignContentOrg>
          </PushOrg>
        ) : null}

        <BreakpointVisibilityOrg from="small" to="medium">
          <SliderTemplate
            ratio={ratio || "default"}
            hasArrowsOnMobile={hasArrowsOnMobile}
            slides={sliderItems}
          />
        </BreakpointVisibilityOrg>

        <BreakpointVisibilityOrg from="medium">
          <SliderTemplate ratio={ratio || "default"} slides={sliderItems} />
        </BreakpointVisibilityOrg>

        {ctaElements ? (
          <PushOrg topSpace={8}>
            <AlignContentOrg horizontalAlign="center">
              <CtaElement ctaElement={ctaElements} />
            </AlignContentOrg>
          </PushOrg>
        ) : null}
      </BaseContainer>
    </BaseSection>
  )
}

export default SliderSection

import type { ReactElement, SyntheticEvent } from "react"
import React from "react"
import AdvancedProductCardsTemplateBase from "@onestore/hel/dist/components/templates/AdvancedProductCardsTemplate"
import type { ProductCardItem } from "@gatsby-plugin-marketplace/fragments/marketplaceProductCard"
import useAdvancedProductCards from "~/hooks/useAdvancedProductCards"

interface AdvancedProductCardsTemplateProps {
  link?: {
    text: string
    href: string
    title: string
    rel?: "nofollow"
    onClick: { (event: SyntheticEvent): void }
  }
  heading?: {
    caption?: string
    title: string
  }
  products: ProductCardItem[]
}

export default function AdvancedProductCardsTemplate({
  link,
  heading,
  products,
}: AdvancedProductCardsTemplateProps): ReactElement<AdvancedProductCardsTemplateProps> {
  const cards = useAdvancedProductCards(products)

  return (
    <AdvancedProductCardsTemplateBase
      link={link}
      heading={heading}
      cards={cards}
    />
  )
}

import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import LabelAtm from "@onestore/hel/dist/components/atoms/LabelAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"

interface Props {
  value: string
  percent?: string
}

export default function LowestPriceInfo({
  value,
  percent,
  ...restProps
}: Props): ReactElement<Props> | null {
  return (
    <TextAtm typography="nano1" emphasis="medium" {...restProps}>
      {_t("prices.lowerPriceText")} {value}
      {percent ? (
        <TextAtm pushSpace={{ left: 0.5 }}>
          <LabelAtm
            size="small"
            variant="notifyLight"
            emphasis="high"
            htmlTag="span"
          >
            {percent}
          </LabelAtm>
        </TextAtm>
      ) : null}
    </TextAtm>
  )
}

import type { ReactElement } from "react"
import React from "react"
import { omit } from "lodash"
import ImageLinkAtm from "@onestore/hel/dist/components/atoms/ImageLinkAtm"
import ItemDescriptionMol from "@onestore/hel/dist/components/molecules/ItemDescriptionMol"
import TitledImageLinkMol from "@onestore/hel/dist/components/molecules/TitledImageLinkMol"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import WrapOrg from "@onestore/hel/dist/components/organisms/WrapOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import Header from "@gatsby-plugin-generic-page/components/Header"
import Media from "@gatsby-plugin-generic-page/components/Media"
import type { LogoSectionFlatData } from "@gatsby-plugin-generic-page/fragments/logoSection"
import getLinkProps from "@gatsby-plugin-generic-page/helpers/getLinkProps"
import assetsSquidexToImageObject from "~/lib/assetsSquidexToImageObject"
import isEmpty from "~/lib/isEmpty"

export default function LogoSection({
  header,
  logos,
  logosGutterSpace,
  sectionSettings,
}: LogoSectionFlatData): ReactElement<LogoSectionFlatData> {
  const gutterSpace = {
    large: 15,
    medium: 12,
    small: 10,
    extra_small: 8,
    full_width: 0,
  }

  const logosJSX = logos.map((logo, index) => {
    const imageElement = assetsSquidexToImageObject(logo.image, "")

    if (!isEmpty(logo.link)) {
      const linkElement = logo.link && omit(getLinkProps(logo.link), "text")

      if (!isEmpty(logo.title)) {
        return (
          <TitledImageLinkMol
            key={`image-with-title-${index}`}
            image={
              <PushOrg
                bottomSpace={1}
                topSpace={1}
                leftSpace={3}
                rightSpace={3}
                hasNoReset
              >
                <Media media={logo.image} />
              </PushOrg>
            }
            title={logo.title}
            to={linkElement.to}
            href={linkElement.href}
            hrefTitle={linkElement.title}
            onClick={linkElement.onClick}
            openInNewTab={linkElement.openInNewTab}
          />
        )
      }

      return (
        <ImageLinkAtm
          key={`image-without-title-${index}`}
          href={linkElement.href}
          onClick={linkElement.onClick}
          openInNewTab={linkElement.openInNewTab}
          to={linkElement.to}
          title={linkElement.title}
          image={<Media media={logo.image} />}
          hasOpacity
        />
      )
    }

    if (!isEmpty(logo.title)) {
      return (
        <ItemDescriptionMol
          key={`item-description-${index}`}
          image={imageElement}
          title={logo.title}
          isCentered
        />
      )
    }

    return <Media key={`media-${index}`} media={logo.image} />
  })

  const contentJSX =
    logosGutterSpace === "full_width" ? (
      <>
        <BreakpointVisibilityOrg from="large">
          <FlexContainerOrg justifyContent="space-between" wrap="wrap">
            {logosJSX}
          </FlexContainerOrg>
        </BreakpointVisibilityOrg>
        <BreakpointVisibilityOrg from="small" to="large">
          <FlexContainerOrg justifyContent="space-around" wrap="wrap">
            {logosJSX}
          </FlexContainerOrg>
        </BreakpointVisibilityOrg>
      </>
    ) : (
      <WrapOrg
        alignCenter="horizontal"
        gutterSpace={gutterSpace[logosGutterSpace]}
      >
        {logosJSX}
      </WrapOrg>
    )

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        {!isEmpty(header) ? (
          <PushOrg bottomSpace={8}>
            <AlignContentOrg
              horizontalAlign={{
                small: "left",
                medium: "center",
                large: "center",
              }}
            >
              <Header {...header} />
            </AlignContentOrg>
          </PushOrg>
        ) : null}
        {contentJSX}
      </BaseContainer>
    </BaseSection>
  )
}

import type { Hub, Scope } from "@sentry/types"

export function pushErrorMessage(
  message: string,
  context: Record<string, any> = {}
) {
  const Sentry: Hub = window["Sentry"]

  if (Sentry) {
    Sentry.withScope((scope: Scope) => {
      scope.setExtras(context)
      scope.setLevel("error")
      Sentry.captureMessage(message)
    })

    return
  }

  console.error(message, context)
}

export function captureErrorMessage(
  message: string,
  context: Record<string, any> = {}
) {
  console.error(message, context)
  pushErrorMessage(message, context)
}

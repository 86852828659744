import type { ReactElement } from "react"
import React from "react"
import { typeMatches } from "@onestore-graphql"
import Countdown from "@gatsby-plugin-generic-page/components/Countdown"
import { SquidexComponentType } from "@gatsby-plugin-generic-page/types/componentType"
import type { Features as FeaturesTypes } from "@gatsby-plugin-generic-page/types/sectionType"

interface Props {
  features: FeaturesTypes[]
}

function Features({ features }: Props): ReactElement {
  return (
    <>
      {features.map((feature) => {
        if (typeMatches(feature.__typename, SquidexComponentType.COUNTDOWN)) {
          return (
            <Countdown
              {...feature}
              labelTypography="tiny1"
              digitTypography="xlarge"
              key={`${feature.__typename}`}
            />
          )
        }

        return null
      })}
    </>
  )
}

export default Features

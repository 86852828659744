import type { BasketState } from "@onestore/onestore-store-common"
import { getBasket } from "@gatsby-plugin-basket/store/actions"
import {
  getBasketDomains,
  getBasketToken,
  getBasket as getBasketSelector,
} from "@gatsby-plugin-basket/store/selectors"
import { useAppDispatch, useAppSelector } from "~/store/hooks"

interface BasketHook {
  basket: BasketState
  basketDomains: string[]
  getBasket: { (): void }
}

export default function useBasket(): BasketHook {
  const token = useAppSelector(getBasketToken)

  const dispatch = useAppDispatch()

  const basket = useAppSelector(getBasketSelector)

  const basketDomains = useAppSelector(getBasketDomains)

  const getBasketDispatcher = () => {
    dispatch(getBasket(token))
  }

  return {
    basket,
    basketDomains,
    getBasket: getBasketDispatcher,
  }
}

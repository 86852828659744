import { useEffect, useRef } from "react"
import log from "~/lib/log"

interface ThuliumChatProps {
  id: string
}

declare global {
  interface Window {
    _tc?: object
  }
}

export default function ThuliumChat({ id }: ThuliumChatProps): null {
  const ref = useRef<HTMLScriptElement>()

  useEffect(() => {
    if (id) {
      log(`Setting up widget with ID: ${id}`, "ThuliumChat")

      const cmpVendor = process.env.GATSBY_THULIUM_CHAT_CMP_VENDOR

      const script = document.createElement("script")
      const style = document.createElement("style")
      style.setAttribute("type", "text/css")

      script.async = true

      if (cmpVendor) {
        script.type = "text/plain"
        script.setAttribute("class", "cmplazyload")
        script.setAttribute("data-cmp-vendor", cmpVendor)
        script.setAttribute("data-cmp-purpose", "c51")
        script.setAttribute(
          "data-cmp-src",
          `https://cdn.thulium.com/apps/chat-widget/chat-loader.js?hash=${id}`
        )
      } else {
        script.type = "text/javascript"
        script.src = `https://cdn.thulium.com/apps/chat-widget/chat-loader.js?hash=${id}`
      }

      style.innerHTML = ".thulium-chat-wrapper { z-index: 8999889 !important; }"

      document.head.append(script)
      document.head.append(style)

      ref.current = script
    }

    return () => {
      log(`Cleaning up widget ID: ${id}`, "ThuliumChat")

      document
        .querySelectorAll(".thulium-chat-wrapper")
        .forEach((element) => element.remove())

      if (window._tc) {
        delete window._tc
      }

      const currentRef = ref.current

      if (!currentRef) {
        return
      }

      currentRef.remove()
    }
  }, [id])

  return null
}

import type { ReactElement } from "react"
import React from "react"
import ButtonAtm from "@onestore/hel/dist/components/atoms/ButtonAtm"
import type { FormModalButtonFlatData } from "@gatsby-plugin-generic-page/fragments/formModalButton"

export default function FormModalButton({
  text,
  title,
  color,
  variant,
}: FormModalButtonFlatData): ReactElement<FormModalButtonFlatData> {
  return (
    <ButtonAtm
      data-dsa-id="contact"
      onClick={() =>
        //@ts-ignore
        window.inpl.dsa.open({
          type: "S2",
          service: "strefa",
          resource_author: "-",
          resource_id: "-",
          resource_type: "Konto",
        })
      }
      color={color.color}
      variant={variant.variant}
      title={title}
    >
      {text}
    </ButtonAtm>
  )
}

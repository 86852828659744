import type { ReactElement, ReactNode } from "react"
import React from "react"
import _t from "@core/i18n"
import { registerValidateLocale, setValidateLanguage } from "@formily/core"
import type { ISchema } from "@formily/json-schema"
import { FormProvider } from "@formily/react"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import type { FormButton } from "@gatsby-plugin-generic-page/fragments/formButton"
import type { FormilyTexts } from "@gatsby-plugin-generic-page/fragments/formilyTexts"
import useFormilyForm from "@gatsby-plugin-generic-page/hooks/useFormilyForm"
import isEmpty from "~/lib/isEmpty"
import FormSubmit from "./FormSubmit"
import SchemaFields from "./SchemaFields"

export type FormProps = {
  schema: ISchema
  captchaEnabled: boolean
  target: string
  formId: string
  title?: string
  button: FormButton
  formilyTexts?: FormilyTexts[]
  additionalData?: Record<string, string>
  customThankYouPage?: ReactNode
  onSubmitSuccess?: () => void
}

export default function Formily({
  schema,
  target,
  captchaEnabled,
  formId,
  title,
  button,
  formilyTexts,
  additionalData = {},
  customThankYouPage,
  onSubmitSuccess,
}: FormProps): ReactElement<FormProps> {
  const {
    text,
    buttonVariant,
    buttonColor,
    isWider,
    isExpanded,
    isExpandedOnMobile,
    gaEvent,
  } = button

  const {
    form,
    buttonText,
    isSubmitSuccess,
    isFormLoading,
    recaptchaLoaded,
    captchaNode,
    formErrorMessage,
    handleOnSubmit,
    handleOnSubmitSuccess,
    handleOnSubmitFailed,
  } = useFormilyForm(
    text,
    target,
    formId,
    additionalData,
    captchaEnabled,
    gaEvent
  )

  setValidateLanguage("en")
  registerValidateLocale({
    en: {
      required: _t("form.errors.isRequired"),
      email: _t("formFields.global.email.errors.invalid"),
      max: _t("validator.valueTooLong"),
    },
  })

  if (isSubmitSuccess && customThankYouPage) {
    return <>{customThankYouPage}</>
  }

  if (isSubmitSuccess && onSubmitSuccess) {
    onSubmitSuccess()
  }

  return (
    <FormProvider form={form}>
      {title ? (
        <TextAtm htmlTag="h2" typography="xlarge" pushSpace={4}>
          {title}
        </TextAtm>
      ) : null}

      <SchemaFields schema={schema} />

      <FormSubmit
        onSubmit={handleOnSubmit}
        onSubmitSuccess={handleOnSubmitSuccess}
        onSubmitFailed={handleOnSubmitFailed}
        size="big"
        variant={buttonVariant?.variant}
        color={buttonColor?.color}
        text={buttonText}
        title={buttonText}
        isWider={isWider}
        isExpanded={isExpanded}
        isExpandedOnMobile={isExpandedOnMobile}
        isLoading={!recaptchaLoaded || isFormLoading}
        isDisabled={!recaptchaLoaded || isFormLoading || isSubmitSuccess}
      />

      {formErrorMessage ? (
        <PushOrg topSpace={1}>
          <TextAtm typography="tiny1" color="error">
            {formErrorMessage}
          </TextAtm>
        </PushOrg>
      ) : null}

      {captchaEnabled ? captchaNode : null}

      {!isEmpty(formilyTexts) ? (
        <PushOrg topSpace={5}>
          {formilyTexts.map((formilyText) => (
            <PushOrg topSpace={1.5} key={`formily-text-${formilyText.text}`}>
              <AlignContentOrg
                horizontalAlign={{
                  large: formilyText.alignText?.horizontalAlign || "left",
                  medium: "left",
                  small: "left",
                }}
              >
                {formilyText.text ? (
                  <MarkdownText
                    typography={{ bold: "small4", default: "small2" }}
                  >
                    {formilyText.text}
                  </MarkdownText>
                ) : null}

                {formilyText.description ? (
                  <MarkdownText
                    typography={{ bold: "tiny3", default: "tiny1" }}
                    emphasis="low"
                  >
                    {formilyText.description}
                  </MarkdownText>
                ) : null}
              </AlignContentOrg>
            </PushOrg>
          ))}
        </PushOrg>
      ) : null}
    </FormProvider>
  )
}

import { SearchUrlParameters } from "~/lib/storage"

const getParameter = (tag: string, parameter: string): string | undefined => {
  const regex = new RegExp(`${parameter}='(.*?)'`)
  const format = tag.match(regex)

  return format ? format[1] : undefined
}

export function parseLink(
  tag: string,
  searchUrlParameters?: SearchUrlParameters | {}
): string {
  const name = getParameter(tag, "name")
  const defaultValue = getParameter(tag, "default")

  if (searchUrlParameters && name && searchUrlParameters[name]) {
    return searchUrlParameters[name]
  }

  return defaultValue || ""
}

export function replaceLinkValues(
  data: string,
  searchUrlParameters?: SearchUrlParameters | {}
): string {
  return data.replace(/\[var(.*?)\]/g, (tag) =>
    parseLink(tag, searchUrlParameters)
  )
}

import type { ReactElement } from "react"
import React from "react"
import type { PageWithExternalScripts } from "@onestore-graphql/Model"
import { get } from "lodash"
import log from "~/lib/log"
import ContactLeader from "../ContactLeader"
import ThuliumChat from "../ThuliumChat"

interface ExternalScriptsProps {
  page: PageWithExternalScripts
}

export default function ExternalScripts({
  page,
}: ExternalScriptsProps): ReactElement {
  log(page, "ExternalScripts")

  const contactLeader = get<
    PageWithExternalScripts,
    "flatData",
    "contactLeaders",
    number
  >(page, ["flatData", "contactLeaders", 0])

  const thuliumChat = get<
    PageWithExternalScripts,
    "flatData",
    "thuliumChats",
    number
  >(page, ["flatData", "thuliumChats", 0])

  return (
    <>
      {contactLeader && contactLeader.flatData.enabled ? (
        <ContactLeader id={contactLeader.flatData.id} />
      ) : null}

      {thuliumChat && thuliumChat.flatData.enabled ? (
        <ThuliumChat id={thuliumChat.flatData.id} />
      ) : null}
    </>
  )
}

import type { ReactElement } from "react"
import React from "react"
import { typeMatches } from "@onestore-graphql"
import type { ButtonAtmProps } from "@onestore/hel/dist/components/atoms/ButtonAtm"
import FormilyFormButton from "@gatsby-plugin-generic-page/components/FormilyFormButton"
import type { Formily as FormilyType } from "@gatsby-plugin-generic-page/fragments/formily"
import { SquidexFormType } from "@gatsby-plugin-generic-page/types/formType"
import CerbFormButton from "~/components/FormButton"
import type { Form } from "~/fragments/form"
import type { GaEvent } from "~/fragments/gaEvent"
import { getAdditionalDataFromSquidex } from "~/lib/forms"
import type { Forms } from "~/types/squidex"

interface Props
  extends Pick<
    ButtonAtmProps,
    | "color"
    | "variant"
    | "title"
    | "text"
    | "isWider"
    | "rel"
    | "size"
    | "isExpanded"
    | "isExpandedOnMobile"
  > {
  form: Forms[] | FormilyType[]
  gaEvent?: GaEvent
}

function FormButton({
  color = "primary",
  form,
  text,
  title,
  isWider,
  isExpanded,
  isExpandedOnMobile,
  variant,
  rel,
  size,
  gaEvent,
}: Props): ReactElement<Props> | null {
  const { __typename, flatData } = form[0]

  if (typeMatches(__typename, SquidexFormType.FORM)) {
    const {
      title: formTitle,
      button: formButton,
      id,
      additionalData,
    } = flatData as Form["flatData"]

    return (
      <CerbFormButton
        formId={id}
        button={{
          color,
          text,
          title,
          isWider,
          isExpanded,
          isExpandedOnMobile,
          variant,
          rel,
          size,
        }}
        formModal={{
          additionalData: getAdditionalDataFromSquidex(additionalData || []),
          headingText: formTitle,
          button: {
            title: formButton[0].title,
            text: formButton[0].text,
            buttonColor: { color: formButton[0].buttonColor.color },
          },
        }}
        gaEvent={gaEvent}
      />
    )
  }

  if (typeMatches(__typename, SquidexFormType.FORMILY_FORM)) {
    return (
      <FormilyFormButton
        button={{
          color,
          text,
          title,
          isWider,
          isExpanded,
          isExpandedOnMobile,
          variant,
          rel,
          size,
        }}
        formilyForm={form[0] as FormilyType}
      />
    )
  }

  return null
}

export default FormButton

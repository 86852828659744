import { z } from "zod"

export const CompanyResponseSchema = z.object({
  address: z.string(),
  street_name: z.string(),
  street_number: z.string(),
  apartment_number: z.string(),
  city: z.string(),
  company_name: z.string(),
  country: z.string(),
  electronic_invoice: z.boolean(),
  marketing_agreement: z.boolean(),
  personal_data_processing: z.boolean(),
  email: z.string(),
  first_name: z.string(),
  postal_code: z.string(),
  tax: z.array(
    z.object({
      eu_id: z.string(),
      type: z.enum(["vat", "regon"]),
      country_code: z.string(),
      id: z.string(),
    })
  ),
  type: z.string(),
})

export const UserRegistrationResponseSchema = z.object({
  signature: z.string(),
  hitId: z.string().nullable(),
  messages: z.array(
    z.object({
      class: z.string().optional(),
      type: z.string(),
      message: z.string(),
      id: z.string(),
    })
  ),
})

export type UserRegistrationResponse = z.infer<
  typeof UserRegistrationResponseSchema
>

export type CompanyResponse = z.infer<typeof CompanyResponseSchema>

import _t, { hasValue } from "@core/i18n"
import { withPrefix } from "gatsby"
import type { PaymentMethod, PaymentMethodWithLogo } from "@onestore/api/basket"
import {
  PAYMENT_VARIANT_BANK_TRANSFER,
  PAYMENT_VARIANT_BLIK,
  PAYMENT_VARIANT_MASTERCARD,
  PAYMENT_VARIANT_NEW_CARD,
  PAYMENT_VARIANT_ONLINE_PAYMENT,
  PAYMENT_VARIANT_VISA,
} from "@onestore/onestore-store-common/api/payments"
import { getLocale } from "~/lib/config"

export const PAYMENT_ONETIME_CARD_ID = "PayUOneTimeCard.Modal"
export const PAYMENT_RECURRING_CARD_ID = "PayUCard.Modal"
export const PAYMENT_RECURRING_REMEMBERED_CARD_ID = "PayUCard.Token"

export const cardBasicPaymentsMethodsIds = [
  PAYMENT_ONETIME_CARD_ID,
  PAYMENT_RECURRING_CARD_ID,
]
export const cardRecurringMethodsIds = [
  PAYMENT_RECURRING_REMEMBERED_CARD_ID,
  PAYMENT_RECURRING_CARD_ID,
]
export const cardPaymentMethodsIds = [
  PAYMENT_ONETIME_CARD_ID,
  PAYMENT_RECURRING_REMEMBERED_CARD_ID,
  PAYMENT_RECURRING_CARD_ID,
]

function getLogo(item: PaymentMethod): string {
  if (item.variant === PAYMENT_VARIANT_BLIK) {
    return withPrefix("/img/common/payments/method-blik.png")
  }

  if (item.variant === PAYMENT_VARIANT_BANK_TRANSFER) {
    return withPrefix("/img/common/payments/method-traditional.png")
  }

  if (item.variant === PAYMENT_VARIANT_ONLINE_PAYMENT) {
    return withPrefix("/img/common/payments/method-payu.png")
  }

  if (item.variant === PAYMENT_VARIANT_VISA) {
    return withPrefix("/img/common/payments/method-visa.png")
  }

  if (item.variant === PAYMENT_VARIANT_MASTERCARD) {
    return withPrefix("/img/common/payments/method-mastercard.png")
  }

  if (
    item.variant === PAYMENT_VARIANT_NEW_CARD ||
    item.system_id === PAYMENT_ONETIME_CARD_ID
  ) {
    return withPrefix("/img/common/payments/method-card.png")
  }

  return withPrefix("/img/common/payments/method-traditional.png")
}

export function enhancePaymentMethods(
  result: PaymentMethod[]
): PaymentMethodWithLogo[] {
  return result.map((item: PaymentMethod): PaymentMethodWithLogo => {
    const {
      description,
      id,
      name,
      system_id,
      variant: apiVariant,
      selected,
      use_for_auto_payments,
    } = item

    let variant = apiVariant

    // W przypadku brandów ionosowych ignorujemy variant bank transfer
    // brandy zagraniczne nie posiadaja opcji płatności przelewem - Check/Cash
    // TODO wyprostować varianty po przejsciu na nowe API (ONESTORE-5873)
    if (variant === PAYMENT_VARIANT_BANK_TRANSFER && getLocale() !== "pl") {
      variant = ""
    }

    const methodNames: string[] = [
      hasValue(`paymentMethod.${id}.name`)
        ? _t(`paymentMethod.${id}.name`)
        : "",
      hasValue(`paymentMethod.${system_id}.name`)
        ? _t(`paymentMethod.${system_id}.name`, { number: description })
        : "",
      hasValue(`paymentMethod.${variant}`)
        ? _t(`paymentMethod.${variant}`)
        : "",
      name,
    ].filter((value) => value.length > 0)

    const methodDescriptions: string[] = [
      hasValue(`paymentMethod.${id}.desc`)
        ? _t(`paymentMethod.${id}.desc`)
        : "",
      hasValue(`paymentMethod.${system_id}.desc`)
        ? _t(`paymentMethod.${system_id}.desc`)
        : "",
      variant === "new-card" && hasValue("paymentMethod.recurringDescription")
        ? _t("paymentMethod.recurringDescription")
        : "",
      variant === "blik" && hasValue("paymentMethod.blikDescription")
        ? _t("paymentMethod.blikDescription")
        : "",
      description,
    ].filter((value) => value.length > 0)

    return {
      logo: getLogo(item),
      selected,
      use_for_auto_payments,
      id,
      system_id,
      variant: id < 0 ? "" : variant,
      name: methodNames[0] || "",
      description: methodDescriptions[0] || "",
      subtext: hasValue(`paymentMethod.${id}.subtext`)
        ? _t(`paymentMethod.${id}.subtext`)
        : undefined,
    }
  })
}

import React from "react"
import { connect, mapProps } from "@formily/react"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import MarkdownTitle from "@gatsby-plugin-generic-page/components/Markdown/Title"

export interface TextProps {
  content: string
}

const TextLayout = ({ content }: TextProps) => (
  <PushOrg bottomSpace={2}>
    <MarkdownTitle children={content} typography="tiny1" />
  </PushOrg>
)

const Text = connect(
  TextLayout,
  mapProps({}, (props: TextProps) => {
    return {
      content: props.content,
    }
  })
)

export default Text

import type { ReactElement, ReactNode } from "react"
import React from "react"
import ContainerOrg from "@onestore/hel/dist/components/organisms/ContainerOrg"
import type { ContainerSettings } from "@gatsby-plugin-generic-page/fragments/containerSettings"
import {
  ContainerWidthType,
  MarginType,
} from "@gatsby-plugin-generic-page/fragments/containerSettings"
import isEmpty from "~/lib/isEmpty"

export interface BaseContainerProps extends ContainerSettings {
  children: ReactNode
}

export default function BaseContainer({
  containerWidth,
  isFullWidthOnMobile,
  children,
  negativeMarginTop,
  negativeMarginBottom,
  ...restProps
}: BaseContainerProps): ReactElement<BaseContainerProps> {
  const isFullWidth: boolean =
    !isEmpty(containerWidth) && containerWidth === ContainerWidthType.FULL
  const width =
    !isEmpty(containerWidth) && containerWidth !== ContainerWidthType.FULL
      ? containerWidth
      : ContainerWidthType.DEFAULT
  const negativeMargins =
    !isEmpty(negativeMarginTop) || !isEmpty(negativeMarginBottom)
      ? {
          top: !isEmpty(negativeMarginTop)
            ? negativeMarginTop
            : MarginType.STICKY,
          bottom: !isEmpty(negativeMarginBottom)
            ? negativeMarginBottom
            : MarginType.STICKY,
        }
      : undefined

  return (
    <ContainerOrg
      {...restProps}
      isFullWidth={isFullWidth}
      isFullWidthOnMobile={isFullWidthOnMobile}
      width={width}
      negativeMargins={negativeMargins}
    >
      {children}
    </ContainerOrg>
  )
}

import type { ReactElement, SyntheticEvent } from "react"
import React from "react"
import _t from "@core/i18n"
import ButtonAtm from "@onestore/hel/dist/components/atoms/ButtonAtm"
import LoaderAtm from "@onestore/hel/dist/components/atoms/LoaderAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import { TextAtmProps } from "@onestore/hel/dist/components/atoms/TextAtm/TextAtm.props"
import UnderlineLinkAtm from "@onestore/hel/dist/components/atoms/UnderlineLinkAtm"
import ModalWithImageMol from "@onestore/hel/dist/components/molecules/ModalWithImageMol"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import WrapOrg from "@onestore/hel/dist/components/organisms/WrapOrg"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import type { ChangePeriodModal as ChangePeriodModalType } from "@gatsby-plugin-generic-page/fragments/changePeriodModal"
import getBaseMarkdownOverrides from "@gatsby-plugin-generic-page/helpers/getBaseMarkdownOverrides"
import ModalContainer from "~/components/ModalContainer"
import assetsSquidexToImageObject from "~/lib/assetsSquidexToImageObject"

export type ChangePeriodModalProps = ChangePeriodModalType["flatData"] & {
  isModalOpen: boolean
  onConfirmedAction: () => void
  onClose: (event: SyntheticEvent) => void
  isModalLoading?: boolean
}

export function ChangePeriodModal({
  isModalOpen,
  image,
  content,
  additionalInfo,
  buttonText,
  onClose,
  onConfirmedAction,
  isModalLoading,
}: ChangePeriodModalProps): ReactElement<ChangePeriodModalProps> {
  return (
    <ModalContainer>
      <ModalWithImageMol
        image={assetsSquidexToImageObject(image, "")}
        content={
          <>
            {content ? (
              <MarkdownText
                overrides={{
                  ...getBaseMarkdownOverrides({
                    typography: "medium1_h",
                    boldTypography: "medium3_h",
                    emphasis: "medium",
                    pushSpace: 3,
                  }),
                  h1: {
                    component: TextAtm,
                    props: {
                      htmlTag: "h1",
                      typography: "great",
                      pushSpace: 3,
                    } as TextAtmProps,
                  },
                }}
              >
                {content}
              </MarkdownText>
            ) : null}

            {!isModalLoading ? (
              <>
                <WrapOrg gutterSpace={3} alignCenter="vertical">
                  <ButtonAtm
                    onClick={onConfirmedAction}
                    isWider
                    title={buttonText ? buttonText : _t("domainModal.button")}
                    color="success"
                  >
                    {buttonText ? buttonText : _t("domainModal.button")}
                  </ButtonAtm>

                  <UnderlineLinkAtm
                    title={_t("domainModal.link")}
                    typography="medium3_s"
                    onClick={onClose}
                  >
                    {_t("domainModal.link")}
                  </UnderlineLinkAtm>
                </WrapOrg>

                {additionalInfo ? (
                  <PushOrg topSpace={2}>
                    <MarkdownText
                      overrides={getBaseMarkdownOverrides({
                        typography: "nano1",
                        boldTypography: "nano3",
                      })}
                    >
                      {additionalInfo}
                    </MarkdownText>
                  </PushOrg>
                ) : null}
              </>
            ) : (
              <AlignContentOrg horizontalAlign="center">
                <LoaderAtm size="small" />
              </AlignContentOrg>
            )}
          </>
        }
        isOpen={isModalOpen}
        onCloseButtonClick={onClose}
        contentPaddingSpace={{ top: 5, bottom: 5, left: 5, right: 4 }}
        notHideOnClose
      />
    </ModalContainer>
  )
}

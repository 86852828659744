import { formatPriceObject, getPriceObjectWithTaxRate } from "@core/pricing"
import whoisPrices from "_/res/whois.json"
import type { ResourceData } from "@gatsby-plugin-definitions/fragments/CloudBluePeriod"
import type { PeriodName, Price, TaxRate } from "../../lib/api/types"

interface Period {
  period_name: PeriodName
  resource_data: ResourceData[]
  tax_rate: TaxRate
}

interface WhoisPriceItem {
  extension: string
  periods: Period[]
  resourceId: number | null
}

interface ExtensionData {
  periods: undefined | Period[]
  resourceId: number | undefined
}

function getDataForExtension(extension: string): ExtensionData {
  const item = (whoisPrices as WhoisPriceItem[]).find(
    (item) => item.extension === extension
  )

  if (item) {
    return {
      periods: item.periods as Period[],
      resourceId: item.resourceId || undefined,
    }
  }

  return {
    periods: undefined,
    resourceId: undefined,
  }
}

export function getWhois(
  extension: string,
  periodName: PeriodName
): { priceObject: Price | undefined; resourceId: number | undefined } {
  const { periods, resourceId } = getDataForExtension(extension)

  if (!periods) {
    return { priceObject: undefined, resourceId }
  }

  const period = Object.values(periods).find(
    (item) => item.period_name === periodName
  )

  if (!period) {
    return { priceObject: undefined, resourceId }
  }

  const resource = period.resource_data.find(
    (resource) => resource.alias === "WHOIS"
  )

  if (resource === undefined) {
    return { priceObject: undefined, resourceId }
  }

  const priceObject = getPriceObjectWithTaxRate(
    resource.purchase_price,
    period.tax_rate
  )

  return { priceObject, resourceId }
}

export function getWhoisPrice(
  extension: string,
  periodName: PeriodName
): string {
  const { priceObject } = getWhois(extension, periodName)

  return priceObject ? formatPriceObject(priceObject) : "-"
}

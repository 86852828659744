import React from "react"
import _t from "@core/i18n"
import type { DomainCheck } from "@onestore/api/domainSearch"
import LabelAtm from "@onestore/hel/dist/components/atoms/LabelAtm"
import DomainNameMol from "@onestore/hel/dist/components/molecules/DomainNameMol"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import SpaceBetweenOrg from "@onestore/hel/dist/components/organisms/SpaceBetweenOrg"
import ConditionalWrapper from "@onestore/hel/dist/helpers/ConditionalWrapper"
import useBreakpoint from "@onestore/hel/dist/hooks/useBreakpoint"
import DomainAddButton from "@gatsby-plugin-domain-search/components/Button/DomainAddButton"
import { SpecialSubItemPrice } from "@gatsby-plugin-domain-search/components/MainResultBox/SpecialSubItemPrice"

interface SpecialSubItemProps {
  domain: DomainCheck.Result
}

function SpecialSubItem({ domain }: SpecialSubItemProps) {
  const isSmallOnly = useBreakpoint("small", "medium")
  const isMediumUp = useBreakpoint("medium")

  return (
    <ConditionalWrapper
      condition={isMediumUp}
      wrapper={(contentJsx) => (
        <SpaceBetweenOrg align="center">{contentJsx}</SpaceBetweenOrg>
      )}
    >
      <PushOrg bottomSpace={isSmallOnly ? 2.5 : 0}>
        <DomainNameMol
          typography="medium2_s"
          name={domain.name}
          extension={domain.extension}
          extensionColor="success"
          isExtensionBolded
        />
      </PushOrg>

      <SpecialSubItemPrice domain={domain} />
    </ConditionalWrapper>
  )
}

export function getSpecialSubItemElement(domain: DomainCheck.Result) {
  return {
    label: (
      <LabelAtm size="small" variant="notifyLight">
        {_t("domainSearch.specialDomainLabel")}
      </LabelAtm>
    ),
    main: <SpecialSubItem domain={domain} />,
    aside: <DomainAddButton domain={domain} color="dark" variant="outlined" />,
  }
}

import type { ReactElement, ReactNode } from "react"
import React from "react"
import { typeMatches } from "@onestore-graphql"
import ImageLinkAtm from "@onestore/hel/dist/components/atoms/ImageLinkAtm"
import FormilyFormButton from "@gatsby-plugin-generic-page/components/FormilyFormButton"
import type { Formily as FormilyType } from "@gatsby-plugin-generic-page/fragments/formily"
import { SquidexFormType } from "@gatsby-plugin-generic-page/types/formType"
import CerbFormButton from "~/components/FormButton"
import type { Form } from "~/fragments/form"
import type { GaEvent } from "~/fragments/gaEvent"
import { getAdditionalDataFromSquidex } from "~/lib/forms"
import type { Forms } from "~/types/squidex"

interface Props {
  image: ReactNode
  title: string
  form: Forms[]
  gaEvent?: GaEvent
}

function ImageFormButton({
  image,
  title,
  form,
  gaEvent,
}: Props): ReactElement<Props> | null {
  const { __typename, flatData } = form[0]

  if (typeMatches(__typename, SquidexFormType.FORM)) {
    const {
      title: formTitle,
      button: formButton,
      id,
      additionalData,
    } = flatData as Form["flatData"]

    return (
      <CerbFormButton
        formId={id}
        imageButton={{
          image,
          title,
        }}
        formModal={{
          additionalData: getAdditionalDataFromSquidex(additionalData || []),
          headingText: formTitle,
          button: {
            title: formButton[0].title,
            text: formButton[0].text,
            buttonColor: { color: formButton[0].buttonColor.color },
          },
        }}
        gaEvent={gaEvent}
      />
    )
  }

  if (typeMatches(__typename, SquidexFormType.FORMILY_FORM)) {
    return (
      <FormilyFormButton
        button={(onClick) => (
          <ImageLinkAtm image={image} title={title} onClick={onClick} />
        )}
        formilyForm={form[0] as FormilyType}
      />
    )
  }

  return null
}

export default ImageFormButton

import type { ReactElement } from "react"
import React from "react"
import type { LinkAtmProps } from "@onestore/hel/dist/components/atoms/LinkAtm"
import LinkAtm from "@onestore/hel/dist/components/atoms/LinkAtm"
import type Theme from "@onestore/hel/dist/typings/theme"
import hasTelOrMailInLink from "@gatsby-plugin-generic-page/helpers/hasTelOrMailInLink"

export interface LinkProps extends LinkAtmProps {
  typography: Theme.Typography
  children: string
}

function Link({
  typography,
  children,
  to,
  href,
  ...restProps
}: LinkProps): ReactElement<LinkProps> {
  const hrefWithMailOrTel = hasTelOrMailInLink(to)

  return (
    <LinkAtm
      to={hrefWithMailOrTel ? undefined : to}
      href={hrefWithMailOrTel || href}
      typography={typography}
      {...restProps}
    >
      {children}
    </LinkAtm>
  )
}

export default Link

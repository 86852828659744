import type { ReactElement } from "react"
import React, { memo } from "react"
import type { FieldInputProps, FieldMetaState } from "react-final-form"
import styled from "styled-components"
import CheckboxAtm from "@onestore/hel/dist/components/atoms/CheckboxAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import { useFormFieldError } from "~/hooks/useFormFieldError"

interface CheckboxRowFieldProps {
  input: FieldInputProps<string>
  meta: FieldMetaState<string>
  label: string
  isRequired: boolean
}

const Text = styled.span`
  a {
    color: black;
  }
`

export interface ErrorInfoProps {
  text: string
}

function ErrorInfo({ text }): ReactElement<ErrorInfoProps> {
  return (
    <PushOrg topSpace={1}>
      <TextAtm typography="tiny1" color="error">
        {text}
      </TextAtm>
    </PushOrg>
  )
}

function CheckboxRowField({
  input,
  meta,
  label,
  isRequired,
}: CheckboxRowFieldProps): ReactElement<CheckboxRowFieldProps> {
  const { hasError, errorMessage } = useFormFieldError(input, meta)

  return (
    <FlexContainerOrg>
      <CheckboxAtm
        hasError={hasError}
        isRequired={isRequired}
        {...input}
        isChecked={input.checked || false}
      />

      <PushOrg leftSpace={3.5}>
        <TextAtm typography="tiny1">
          <Text dangerouslySetInnerHTML={{ __html: label }} />
        </TextAtm>
        {hasError ? <ErrorInfo text={errorMessage} /> : null}
      </PushOrg>
    </FlexContainerOrg>
  )
}

export default memo(CheckboxRowField)

import _t from "@core/i18n"
import { PeriodInfo } from "@core/period-info"
import { formatPrice } from "@core/pricing"
import { first, get, sortBy } from "lodash"
import { createSelector } from "reselect"
import type { PaymentMethodWithLogo, PriceValue } from "@onestore/api/basket"
import { ProductType, DefinitionType } from "@onestore/api/basket"
import type { DomainCheck } from "@onestore/api/domainSearch"
import type { PeriodName } from "@onestore/api/types"
import type {
  BasketState,
  BasketStateItem,
  BasketStateResource,
} from "@onestore/onestore-store-common"
import { ItemRemoval } from "@onestore/onestore-store-common"
import {
  PAYMENT_VARIANT_BLIK,
  PAYMENT_VARIANT_FREE,
  PAYMENT_VARIANT_SMS,
} from "@onestore/onestore-store-common/api/payments"
import {
  isResponseNone,
  isResponsePending,
  isResponseSuccessful,
} from "@onestore/onestore-store-common/http"
import getSortedHackItems from "@gatsby-plugin-basket/helpers/getSortedHackItems"
import type {
  BasketSummary,
  BasketViewItem,
  BasketViewResource,
} from "@gatsby-plugin-basket/types"
import {
  PAYMENT_RECURRING_CARD_ID,
  cardBasicPaymentsMethodsIds,
  cardPaymentMethodsIds,
  cardRecurringMethodsIds,
  enhancePaymentMethods,
} from "@gatsby-plugin-checkout/lib/payment"
import type { CloudBluePeriod } from "@gatsby-plugin-definitions/fragments/CloudBluePeriod"
import { isItemEnabled } from "~/lib/basket"
import {
  getHookPlans,
  getHostingHookPlanId,
  getSecondaryHookPlanId,
} from "~/lib/config"
import isEmpty from "~/lib/isEmpty"
import { sortPlanPeriods } from "~/lib/plan"
import { getWhois } from "~/lib/price-whois"
import { applyDefaultTaxRate } from "~/lib/pricing"
import type { AppState } from "~/store/reducer"
import { parsePeriod } from "../../../../lib/api"
import { BASKET_STATUS } from "./constants"

export const getBasket = (state: AppState): BasketState => state.basket

export const getBasketToken = createSelector(
  getBasket,
  (basket: BasketState) => basket.token
)

export const getBasketStatus = createSelector(
  getBasket,
  (basket: BasketState) => basket.status
)

export const getBasketMessages = createSelector(
  getBasket,
  (basket: BasketState) => basket.messages || []
)

export const needsParameters = createSelector(
  getBasket,
  (basket: BasketState) => basket.needsParameters
)

/**
 * @deprecated
 */
export const isBasketLoading = createSelector(
  getBasket,
  (basket: AppState["basket"]) => isResponsePending(basket.request_state)
)

export const isBasketLoadingOrNone = createSelector(
  getBasket,
  (basket: AppState["basket"]) =>
    isResponsePending(basket.request_state) ||
    isResponseNone(basket.request_state)
)

export const isBasketLoaded = createSelector(
  getBasket,
  (basket: AppState["basket"]) => isResponseSuccessful(basket.request_state)
)

export const hasBasketWithTransfer = createSelector(
  getBasket,
  (basket: BasketState): boolean => hasTransferInBasket(basket)
)

export const getItemsStatuses = createSelector(
  getBasket,
  (basket) => basket.items_statuses
)

export const getBasketItemsCount = createSelector(
  getBasket,
  (basket) => basket.itemsCount
)

export const hasBasket = createSelector(
  getBasket,
  (basket) =>
    -1 === [BASKET_STATUS.NONE, BASKET_STATUS.PENDING].indexOf(basket.status)
)

export const hasFullBasket = createSelector(
  getBasket,
  (basket) =>
    -1 === [BASKET_STATUS.NONE, BASKET_STATUS.PENDING].indexOf(basket.status) &&
    basket.basketReady
)

export const getDomainBasketItems = createSelector(
  (state: AppState) => state.basket.items,
  (items) =>
    items.filter((item) => item.type === "domain" && isItemEnabled(item))
)

export const getBasketGrossTotalValue = createSelector(
  getBasket,
  (basket) => basket.totalValue + basket.vatValue
)

export const basketHasDomains = createSelector(
  getDomainBasketItems,
  (items) => items.length > 0
)

export const getBasketDomains = createSelector(
  (state: AppState) => state.basket,
  (result) => result.domains
)

export const getSelectedPaymentMethod = createSelector(
  getBasket,
  (basket) => basket.paymentMethod ?? null
)

export const checkoutNeedsRedirect = (state: AppState) => {
  return (
    ![BASKET_STATUS.HAS_USER_DATA, BASKET_STATUS.ASSIGNED].includes(
      getBasket(state).status
    ) || !getBasket(state).needsParameters
  )
}

export const getBasketItems = createSelector(
  getBasket,
  (basket) => basket.items
)

interface ItemWithChildren {
  children: this[]
}

export function flattenItems<T extends ItemWithChildren>(items: T[]): T[] {
  const result: T[] = []

  items.forEach((item) => {
    if (item.children) {
      item.children.forEach((childItem) => {
        result.push(childItem)
      })
    }
    result.push({ ...item, children: [] })
  })

  return result
}

export function hasTransferInBasket(basket: Pick<BasketState, "items">) {
  return flattenItems<BasketStateItem>(basket.items).some(
    (item) =>
      ProductType.DOMAIN === item.type &&
      "transfer" in item.parameters &&
      !!item.parameters.transfer
  )
}

export const getFlatBasketItems = createSelector(
  getBasketItems,
  (items: BasketState["items"]): BasketStateItem[] =>
    flattenItems<BasketStateItem>(items)
)

export const isBasketTrialCheckout = createSelector(
  getFlatBasketItems,
  (items: BasketStateItem[]) =>
    1 === items.length &&
    1 === items.filter((item: BasketStateItem) => item.is_trial).length
)

export const getBasketHookItems = createSelector(
  getFlatBasketItems,
  (items: BasketStateItem[]): BasketStateItem[] =>
    items.filter((item) =>
      [getHostingHookPlanId(), getSecondaryHookPlanId()].includes(item.plan_id)
    )
)

export const getBasketRequestState = createSelector(
  getBasket,
  (basket) => basket.request_state
)

export const hasHookInBasket = createSelector(getFlatBasketItems, (items) =>
  items
    .map((item) => item.plan_id)
    .some((plan_id) =>
      [getHostingHookPlanId(), getSecondaryHookPlanId()].includes(plan_id)
    )
)

export interface WhoisBasketItem {
  isChecked: boolean
  domain: DomainCheck.FQDN
  basketResourceId: number | null
  basketParentId: number
  periodName: PeriodName
  resourceId: number
  regularPrice: number
  price: number
  taxRate: number
}

/**
 * Zwraca informacje o elementach koszyka posiadających możliwość zamówienia albo posiadających już zasób WHOIS.
 */
export const getWhoisBasketItems = createSelector(
  getBasketItems,
  (items: BasketStateItem[]) =>
    items
      .filter((item) => item.type === "domain")
      .map((basketItem: BasketStateItem): WhoisBasketItem | null => {
        const { resourceId: whoisResourceId, priceObject: whoisPrice } =
          getWhois(basketItem.extension as string, basketItem.period_name)

        if (!whoisResourceId) {
          return null
        }

        const basketResource =
          basketItem.resources.length > 0
            ? first(
                basketItem.resources.filter(
                  (resource) => resource.resource_id === whoisResourceId
                )
              )
            : null

        const domain = Array.isArray(basketItem.parameters)
          ? null
          : basketItem.parameters.domain

        const isChecked = null !== basketResource
        const selectedPeriod = first<CloudBluePeriod>(
          sortPlanPeriods(basketItem.periods).filter(
            (period) => period.id === basketItem.plan_period_id
          )
        ) as CloudBluePeriod

        return {
          isChecked,
          domain,
          basketResourceId: isChecked ? basketResource?.id || null : null,
          basketParentId: basketItem.id,
          periodName: selectedPeriod.period_name,
          resourceId: whoisResourceId,
          // Gdy zasób WHOIS jest w koszyku wyświetamy cenę koszykową w przeciwnym wypadku bazujemy na cenach z definicji.
          // Na obecną chwilę API nie zwraca nam bazowej ceny dla zasobów.
          regularPrice: isChecked
            ? basketResource?.regular_price
            : whoisPrice?.netto,
          price: isChecked ? basketResource?.price : whoisPrice?.netto,
          taxRate: isChecked
            ? basketResource?.tax_rate
            : selectedPeriod.tax_rate,
        } as WhoisBasketItem
      })
      .filter((item): item is WhoisBasketItem => item !== null)
)

export const getPaymentMethods = createSelector(getBasket, (basket) =>
  enhancePaymentMethods(basket.paymentMethods || [])
)

export const isBlikPaymentMethod = createSelector(
  getPaymentMethods,
  getSelectedPaymentMethod,
  (paymentMethods, selectedMethod) => {
    const blikId = first(
      paymentMethods
        .filter((item) => item.variant === PAYMENT_VARIANT_BLIK)
        .map((item) => item.id)
    )

    return blikId === selectedMethod
  }
)

const filterPaymentMethodsByIds = (
  methods: PaymentMethodWithLogo[],
  ids: string[]
): PaymentMethodWithLogo[] =>
  methods.filter((method) => ids.includes(method.system_id))

export const getCardPymentMethods = createSelector(
  getPaymentMethods,
  (paymentMethods) =>
    filterPaymentMethodsByIds(paymentMethods, cardPaymentMethodsIds)
)
export const getPaymentMethodsWithoutCards = createSelector(
  getPaymentMethods,
  (paymentMethods) =>
    paymentMethods.filter(
      (method) => !cardPaymentMethodsIds.includes(method.system_id)
    )
)

export const getRecurringCardPaymentMethod = createSelector(
  getPaymentMethods,
  (paymentMethods) =>
    filterPaymentMethodsByIds(paymentMethods, cardBasicPaymentsMethodsIds)
)

export const getSortedRememberedCardPaymentMethod = createSelector(
  getPaymentMethods,
  (paymentMethods) =>
    filterPaymentMethodsByIds(paymentMethods, cardRecurringMethodsIds).sort(
      (a, b) => {
        if (a.system_id === PAYMENT_RECURRING_CARD_ID) return 1
        if (b.system_id === PAYMENT_RECURRING_CARD_ID) return -1
        return 0
      }
    )
)

export const isFreeOrder = createSelector(
  getPaymentMethods,
  (paymentMethods) => {
    if (paymentMethods.length !== 1) {
      return false
    }

    const method = first(paymentMethods)

    return (
      method &&
      [PAYMENT_VARIANT_FREE, PAYMENT_VARIANT_SMS].indexOf(
        method.variant ?? ""
      ) !== -1
    )
  }
)

export const getEnabledFlatBasketItems = createSelector(
  getFlatBasketItems,
  (items: BasketState["items"]): BasketState["items"] =>
    items.filter(isItemEnabled)
)

export const getEnabledItemsCount = createSelector(
  getEnabledFlatBasketItems,
  (items: BasketState["items"]): number => items.length
)

function getItemRemovalStatus(
  item: BasketStateItem,
  isSubItem: boolean,
  domains: BasketState["domains"],
  type: DefinitionType | string,
  hasHookInBasket: boolean
) {
  if (
    hasHookInBasket &&
    type === DefinitionType.DOMAIN &&
    domains.length === 1
  ) {
    return {
      itemRemoval: ItemRemoval.CONFIRM_HACK_DOMAIN,
    }
  }

  if (
    item.plan_id === getHostingHookPlanId() ||
    item.plan_id === getSecondaryHookPlanId()
  ) {
    return {
      itemRemoval: ItemRemoval.CONFIRM_HACK_ITEM,
    }
  }

  if (item.children.length > 0 || isSubItem) {
    return {
      itemRemoval: ItemRemoval.CONFIRM_BUNDLE_ITEM,
    }
  }

  return {
    itemRemoval: ItemRemoval.ALLOW,
  }
}

function handleBasketItems(
  item: BasketStateItem,
  statuses: BasketState["items_statuses"],
  domains: BasketState["domains"],
  hasHookInBasket: boolean,
  isSubItem
): BasketViewItem {
  const planIsDomain = DefinitionType.DOMAIN === item.definition_type

  let type: ProductType | string = item.type

  if (planIsDomain) {
    type = ProductType.DOMAIN
  }
  const isTransfer =
    planIsDomain && "transfer" in item.parameters && !!item.parameters.transfer

  const showZeroPeriod = !planIsDomain || isTransfer
  const periodsArray = Object.values(item.periods) // ONESTORE-5813

  const periods = periodsArray
    .filter((period: CloudBluePeriod) => {
      const { periodValue } = parsePeriod(period.period_name)

      return periodValue !== 0 || (periodValue === 0 && showZeroPeriod)
    })
    .map((period: CloudBluePeriod) => {
      const periodInfo = new PeriodInfo(period)

      return {
        value: `${period.id}`,
        label: periodInfo.getPeriodBasketLabel(isTransfer),
        labelAx: periodInfo.periodText(isTransfer),
        commitmentLabel: periodInfo.getCommitmentLabel(),
        isSelected: period.id === item.plan_period_id,
        price: {
          register: formatPrice(periodInfo.getRegisterPriceValue().gross),
          noPromo: formatPrice(
            periodInfo.getRegularPriceValue(planIsDomain).gross
          ),
          hasPromoPrice: periodInfo.hasPromotion(),
        },
      }
    })

  const domainExtension = get(item, "parameters.domain", false)
    ? item.alias
    : undefined

  const period = periodsArray.filter(
    (period) => period.id === item.plan_period_id
  )[0]

  const currentPeriodInfo = new PeriodInfo(period)

  let regularPrice =
    currentPeriodInfo.getRegularPriceValue(planIsDomain).netto >
    item.regular_price
      ? currentPeriodInfo.getRegularPriceValue(planIsDomain).netto
      : item.regular_price

  if (planIsDomain) {
    const renewalPrice = currentPeriodInfo.getRenewalPriceValue(1)

    regularPrice = renewalPrice
      ? renewalPrice.netto
      : currentPeriodInfo.getRegularPriceValue(planIsDomain).netto
  }

  return {
    ...getItemRemovalStatus(
      item,
      isSubItem,
      domains,
      item.definition_type,
      hasHookInBasket
    ),
    parameters: isEmpty(item.parameters)
      ? {}
      : (item.parameters as Record<string, string | null>),
    linkedDomainNames: item.linked_domain_names ?? [],
    disabledReason: item.disabled_reason,
    measure_unit: item.measure_unit || undefined,
    plan_has_promo_limit: item.plan_has_promo_limit,
    itemId: item.id,
    planId: item.plan_id,
    key: `${item.id}`,
    domainName: planIsDomain
      ? (get(item.parameters, "domain") || "").replace(
          new RegExp(`.${domainExtension}$`),
          ""
        )
      : undefined,
    domainExtension,
    name: item.name,
    discount: regularPrice > item.price ? regularPrice - item.price : 0,
    periods,
    period_name: item.period_name,
    quantity: item.quantity,
    price: item.price,
    regularPrice,
    sku: item.sku,
    type,
    definitionType: item.definition_type,
    children: item.children.map((item) =>
      handleBasketItems(item, statuses, domains, hasHookInBasket, true)
    ),
    taxRate: item.tax_rate,
    loading: isResponsePending(statuses[item.id] || 0),
    removable: true,

    resources: item.resources.map(
      (resource: BasketStateResource): BasketViewResource => {
        return {
          parameters: isEmpty(item.parameters)
            ? {}
            : (item.parameters as Record<string, string | null>),
          disabledReason: item.disabled_reason,
          itemRemoval: ItemRemoval.ALLOW,
          key: `${item.id}-${resource.id}`,
          parentItemId: item.id,
          resourceItemId: resource.id,
          discount:
            resource.regular_price > resource.price
              ? resource.regular_price - resource.price
              : 0,
          loading: isResponsePending(statuses[item.id] || 0),
          removable: resource.removable,
          name: resource.name,
          periods: periods.filter((period) => period.isSelected),
          price: resource.price,
          quantity: resource.quantity,
          measure_unit: resource.measure_unit,
          regularPrice: resource.regular_price,
          sku: resource.sku,
          taxRate: resource.tax_rate,
          definitionType: DefinitionType.RESOURCE,
        }
      }
    ),
  }
}

export const getBasketItemsStatuses = createSelector(
  getBasket,
  (basket: BasketState): BasketState["items_statuses"] => basket.items_statuses
)

export const getBasketViewItems = createSelector(
  getBasketItems,
  getBasketItemsStatuses,
  getBasketDomains,
  hasHookInBasket,
  getBasket,
  (
    items: BasketState["items"],
    statuses: BasketState["items_statuses"],
    domains: BasketState["domains"],
    hasHook: boolean
  ) =>
    items.map((item) =>
      handleBasketItems(item, statuses, domains, hasHook, false)
    )
)

export const getBasketHandlingFee = createSelector(
  getBasket,
  (basket) => basket.handlingFee
)

export const getBasketUpsellItems = createSelector(
  getBasket,
  (basket: BasketState) => basket.domainUpsell
)

export const getButtonsStates = createSelector(
  getBasket,
  (basket: BasketState) => basket.buttons
)

export const getBasketSummary = createSelector(
  getBasket,
  (basket): BasketSummary => ({
    itemsNet: basket.totalValue,
    itemsGross: basket.totalValue + basket.vatValue,
    totalGross:
      basket.totalValue +
      basket.vatValue +
      applyDefaultTaxRate(basket.handlingFee),
    totalNet: basket.totalValue + basket.vatValue + basket.handlingFee,
    totalVat:
      basket.vatValue +
      (applyDefaultTaxRate(basket.handlingFee) - basket.handlingFee),
    itemsVat: basket.vatValue,
    handling: basket.handlingFee,
    savings: basket.savings,
    itemsCount: basket.itemsCount,
  })
)

function appendWithLinkedDomainTooltip(item: BasketViewItem): BasketViewItem {
  return {
    ...item,
    tooltip:
      item.linkedDomainNames.length > 0
        ? {
            labelText: _t("basket.linkedDomainNamesInfo", {
              domain: item.linkedDomainNames.join(""),
            }),
          }
        : undefined,
  }
}
export const getFlatBasketViewItems = createSelector(
  getBasketViewItems,
  (items: BasketViewItem[] | BasketViewResource[]): BasketViewItem[] => {
    const result: BasketViewItem[] = []

    items.forEach((item) => {
      result.push({ ...item, children: [] })
      item.children.forEach((childItem) => {
        result.push(childItem)
      })
    })

    return sortBy<BasketViewItem>(
      result.map(appendWithLinkedDomainTooltip),
      (item) => {
        switch (item.definitionType) {
          case DefinitionType.DOMAIN:
            return 1

          default:
            return 2
        }
      }
    )
  }
)

function getMainHookItemId(
  items: BasketViewItem[],
  hackItems: BasketViewItem[]
): number | null {
  const linkedDomain: string | null = hackItems.reduce(
    (previousValue, currentValue) => {
      if (previousValue) {
        return previousValue
      }

      if (currentValue.linkedDomainNames.length > 0) {
        return currentValue.linkedDomainNames[0]
      }

      return null
    },
    null
  )

  const mainLinkedParentItem = items.find(
    (item) => `${item.domainName}.${item.domainExtension}` === linkedDomain
  )

  if (mainLinkedParentItem) {
    return mainLinkedParentItem.itemId
  }

  // W przypadku braku domeny zlinkowanej z SSL linkujemy pozycje hakowe do pierwszej domeny.
  const alternativeParentItem = items.find(
    (item) => item.definitionType === DefinitionType.DOMAIN
  )

  if (alternativeParentItem) {
    return alternativeParentItem.itemId
  }

  return null
}

export const getBasketItemsWithPlanLimits = createSelector(
  getFlatBasketViewItems,
  (basketItems: BasketViewItem[]): BasketViewItem[] => {
    return basketItems.filter((item) => item.plan_has_promo_limit)
  }
)

export const getFlatBasketViewItemsWithLinkedHook = createSelector(
  getFlatBasketViewItems,
  (basketItems: BasketViewItem[]): BasketViewItem[] => {
    const singleLinkedItems = basketItems.filter(
      (item) => item.linkedDomainNames.length === 1
    )

    const allItems = basketItems
      .filter((item) => item.linkedDomainNames.length !== 1)
      .map((item) => {
        const linkedItems =
          singleLinkedItems.filter((child) =>
            child.linkedDomainNames.includes(
              `${item.domainName}.${item.domainExtension}`
            )
          ) ?? []

        return {
          ...item,
          children: [...item.children, ...linkedItems],
        }
      })

    const hackItems = allItems.filter((item: BasketViewItem): boolean =>
      getHookPlans().includes(item.planId)
    )

    const mainHookItem = getMainHookItemId(allItems, hackItems)

    if (mainHookItem === null) {
      return allItems
    }

    return allItems
      .filter((item) => !getHookPlans().includes(item.planId))
      .map((item): BasketViewItem => {
        if (item.itemId === mainHookItem) {
          return {
            ...item,
            children: getSortedHackItems([...item.children, ...hackItems]),
          }
        }

        return item
      })
  }
)

export const getPromoCodeValue = createSelector(
  getBasket,
  (basket) => basket.promo_code
)
export const isPromoCodeValid = createSelector(
  getBasket,
  (basket) => !basket.promoCodeInvalid
)

function getBasketViewItemDiscount(item: BasketViewItem): PriceValue {
  const resourceDiscount = item.resources.reduce(
    (sum, resource) => sum + resource.discount,
    0
  )
  const childDiscount = item.children.reduce(
    (sum, child) => sum + getBasketViewItemDiscount(child),
    0
  )

  return item.discount + resourceDiscount + childDiscount
}

export const getBasketSavings = createSelector(getBasketViewItems, (items) => {
  return items.reduce((sum, item) => sum + getBasketViewItemDiscount(item), 0)
})

export function filterItemsByType(
  items: BasketViewItem[]
): Record<string, BasketViewItem[]> {
  const filtered = {}

  items.forEach((item) => {
    if (!Object.hasOwnProperty.call(filtered, item.type)) {
      filtered[item.type] = {}
    }

    filtered[item.type][item.itemId] = item
  })

  return filtered
}

export const isRemoveBasketItemModalOpen = createSelector(
  getBasket,
  (basket) => basket.modal.removeItem.isOpen
)

export const getRemovedBasketItemActionType = createSelector(
  getBasket,
  (basket) => basket.modal.removeItem.itemRemoval
)

export const getRemovedBasketItem = createSelector(
  getBasket,
  (basket) => basket.modal.removeItem
)

import type { Account } from "@onestore/api/account"
import type { Basket } from "@onestore/api/basket"
import { getBasketToken } from "@gatsby-plugin-basket/store/selectors"
import { logoutAccount } from "~/store/account/actions"
import { getAccount } from "~/store/account/selectors"
import { useAppSelector, useAppDispatch } from "~/store/hooks"

interface AccountHook {
  account: Account | null
  logout: { (onLogout?: CallableFunction): void }
}

export default function useAccount(): AccountHook {
  const account = useAppSelector<Account | null>(getAccount)
  const token = useAppSelector<Basket.Token>(getBasketToken)

  const dispatch = useAppDispatch()

  const logout = (onLogout?: CallableFunction) => {
    if (!token) {
      return
    }

    dispatch(logoutAccount(token, onLogout))
  }

  return {
    account,
    logout,
  }
}

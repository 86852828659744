import type { ReactElement } from "react"
import React from "react"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import { PromoTile } from "@gatsby-plugin-generic-page/components/PromoTile"
import type { PromoTilesSectionFlatData } from "@gatsby-plugin-generic-page/fragments/promoTilesSection"

export default function PromoTilesSection({
  tiles,
  sectionSettings,
}: PromoTilesSectionFlatData): ReactElement<PromoTilesSectionFlatData> {
  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        {tiles.map((tile, index) => (
          <PushOrg bottomSpace={8} key={`${tile.flatData.title}-${index}`}>
            <PromoTile {...tile.flatData} />
          </PushOrg>
        ))}
      </BaseContainer>
    </BaseSection>
  )
}

import { graphql } from "gatsby"
import { FlexVerticalPosition } from "@onestore/hel/dist/components/organisms/ImageWrapperOrg"
import type { FlexHorizontalPosition } from "@onestore/hel/dist/components/organisms/ImageWrapperOrg"

export type ImagePosition = {
  vertical?: FlexVerticalPosition
  horizontal?: FlexHorizontalPosition
}

export const verticalPosition = {
  top: FlexVerticalPosition.TOP,
  center: FlexVerticalPosition.CENTER,
  bottom: FlexVerticalPosition.BOTTOM,
} as const

export const query = graphql`
  fragment ImagePosition on GraphCmsSquidex_ImagePositionComponent {
    __typename
    vertical
    horizontal
  }
`

export const FRAGMENT_IMAGE_POSITION = `
  fragment ImagePosition on ImagePositionComponent  {
    __typename
    vertical
    horizontal
  }
`

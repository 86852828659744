import React from "react"
import { typeMatches } from "@onestore-graphql"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import type { MainContentProps } from "@onestore/hel/dist/components/molecules/AdvancedProductCardMol/MainContent"
import type { LogoImage } from "@gatsby-plugin-generic-page/fragments/logoImage"
import type { LogoText } from "@gatsby-plugin-generic-page/fragments/logoText"
import type { ThemeBackgroundColor } from "@gatsby-plugin-generic-page/fragments/themeBackgroundColor"
import { SquidexComponentType } from "@gatsby-plugin-generic-page/types/componentType"
import assetsSquidexToImageObject from "~/lib/assetsSquidexToImageObject"
import isEmpty from "~/lib/isEmpty"

function getProductCardLogo(
  logo: LogoImage | LogoText | undefined,
  logoBackgroundColor?: ThemeBackgroundColor
) {
  let logoElement: MainContentProps["logo"],
    textBox: MainContentProps["textBox"]

  if (!isEmpty(logo)) {
    if (typeMatches(logo.__typename, SquidexComponentType.LOGO_IMAGE)) {
      const { image } = logo as LogoImage

      logoElement = {
        image: assetsSquidexToImageObject(image, ""),
        backgroundColor: logoBackgroundColor?.background,
      }
    }

    if (typeMatches(logo.__typename, SquidexComponentType.LOGO_TEXT)) {
      const { text } = logo as LogoText

      textBox = {
        text: (
          <TextAtm typography="large2" color="white">
            {text}
          </TextAtm>
        ),
        backgroundColor: logoBackgroundColor?.background,
      }
    }
  }

  return { logoElement, textBox }
}

export default getProductCardLogo

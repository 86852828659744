import _t from "@core/i18n"
import { PeriodInfo } from "@core/period-info"
import { getCurrencySign } from "@core/pricing"
import { PriceType } from "@core/types"
import { typeMatches } from "@onestore-graphql"
import { get } from "lodash"
import type { FooterProps } from "@onestore/hel/dist/components/molecules/AdvancedProductCardMol/Footer"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import type { FooterText } from "@gatsby-plugin-generic-page/fragments/footerText"
import type { Price } from "@gatsby-plugin-generic-page/fragments/price"
import { SquidexComponentType } from "@gatsby-plugin-generic-page/types/componentType"
import { SquidexReferenceType } from "@gatsby-plugin-generic-page/types/referenceType"
import type { StaticPriceProps } from "~/components/AdvancedProductCard/components/StaticPrice"
import type { StaticPrice } from "~/fragments/staticPrice"
import type { CloudBlueDomain } from "~/types/squidex"
import { parsePeriod } from "../../../../lib/api"
import { convertPrice } from "./staticPrices"

interface Result {
  productPeriodInfo?: PeriodInfo
  staticPrice?: StaticPriceProps
  priceText?: FooterProps["priceText"]
  priceHasPrimaryColor?: boolean
  tooltip?: string
}

function getProductCardFooterData(
  footer: Price | FooterText,
  defaultPriceType: PriceType
): Result {
  let productPeriodInfo, staticPrice, priceText, priceHasPrimaryColor, tooltip

  if (typeMatches(footer.__typename, SquidexComponentType.PRICE)) {
    const { price: priceData, hasPrimaryColor } = footer as Price
    const currency = getCurrencySign()
    const isPriceTypeNetto = defaultPriceType === PriceType.NETTO
    priceHasPrimaryColor = hasPrimaryColor

    if (
      typeMatches(priceData[0].__typename, SquidexReferenceType.CLOUDBLUE_PLAN)
    ) {
      const { flatData } = priceData[0] as CloudBluePlan

      const defaultPeriod = flatData.periods.find((period) => period.default)

      const promotionPriceTooltip = get(
        flatData,
        "promotionPriceTooltip[0].flatData.text"
      )

      if (defaultPeriod) {
        const periodInfo = new PeriodInfo(defaultPeriod)

        productPeriodInfo = periodInfo
        tooltip = promotionPriceTooltip
      }
    }

    if (
      typeMatches(
        priceData[0].__typename,
        SquidexReferenceType.CLOUDBLUE_DOMAIN
      )
    ) {
      const { flatData } = priceData[0] as CloudBlueDomain
      const defaultPeriod = flatData.periods.find((period) => period.default)

      const promotionPriceTooltip = get(
        flatData,
        "promotionPriceTooltip[0].flatData.text"
      )

      if (defaultPeriod) {
        const periodInfo = new PeriodInfo(defaultPeriod)

        productPeriodInfo = periodInfo
        tooltip = promotionPriceTooltip
      }
    }

    if (
      typeMatches(priceData[0].__typename, SquidexReferenceType.STATIC_PRICE)
    ) {
      const { flatData } = priceData[0] as StaticPrice

      const { prefix, registerNetto, registerGross, period } = flatData

      const registerNettoPrice = convertPrice(registerNetto)
      const registerGrossPrice = convertPrice(registerGross)

      const registerPrice = isPriceTypeNetto
        ? registerNettoPrice
        : registerGrossPrice
      const splitedRegisterPrice = registerPrice.split(",")

      const integer = splitedRegisterPrice[0]
      const fraction = splitedRegisterPrice[1]

      const periodInfo = parsePeriod(period)
      const periodName =
        periodInfo.periodValue === 0
          ? _t("periodName.oneTimeFee")
          : _t(`periodName.short.${periodInfo.periodType}`, {
              smart_count: periodInfo.periodValue,
            })

      staticPrice = {
        prefix: prefix,
        integer: integer,
        fraction: `,${fraction}`,
        currency: currency,
        suffix: `${_t(
          `prices.${isPriceTypeNetto ? PriceType.NETTO : PriceType.GROSS}`
        )} / ${periodName}`,
      }
    }
  }

  if (typeMatches(footer.__typename, SquidexComponentType.FOOTER_TEXT)) {
    const { text } = footer as FooterText

    priceText = text
  }

  return {
    productPeriodInfo,
    staticPrice,
    priceText,
    priceHasPrimaryColor,
    tooltip,
  }
}

export default getProductCardFooterData

import type { ReactElement } from "react"
import React from "react"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import type { Typography } from "@onestore/hel/dist/contexts/TypographyContext"
import SectionHeaderTitle from "~/components/SectionHeaderTitle"
import type { TitleHtmlTag } from "~/types/squidex"

export type TitleTypography = Record<TitleHtmlTag, Typography>

interface TitleProps {
  text: string
  htmlTag: TitleHtmlTag
}

function Title({ text, htmlTag }: TitleProps): ReactElement {
  const typography: TitleTypography = {
    h1: { small: "xhuge", medium: "giant", large: "giant" },
    h2: "xhuge",
  }

  return (
    <PushOrg bottomSpace={3}>
      <SectionHeaderTitle typography={typography[htmlTag]} htmlTag={htmlTag}>
        {text}
      </SectionHeaderTitle>
    </PushOrg>
  )
}

export default Title

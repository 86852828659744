import type { ReactElement } from "react"
import React, { memo } from "react"
import type { FieldInputProps, FieldMetaState } from "react-final-form"
import styled from "styled-components"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import { useFormFieldError } from "~/hooks/useFormFieldError"

interface HiddenRowFieldProps {
  input: FieldInputProps<string>
  meta: FieldMetaState<string>
  label: string
}

const Text = styled.span`
  a {
    color: black;
  }
`

export interface ErrorInfoProps {
  text: string
}

function ErrorInfo({ text }): ReactElement<ErrorInfoProps> {
  return (
    <PushOrg topSpace={1}>
      <TextAtm typography="tiny1" color="error">
        {text}
      </TextAtm>
    </PushOrg>
  )
}

function HiddenRowField({
  input,
  meta,
  label,
}: HiddenRowFieldProps): ReactElement<HiddenRowFieldProps> {
  const { hasError, errorMessage } = useFormFieldError(input, meta)

  return (
    <>
      <input {...input} />

      <TextAtm typography="tiny1">
        <Text dangerouslySetInnerHTML={{ __html: label }} />
      </TextAtm>
      {hasError ? <ErrorInfo text={errorMessage} /> : null}
    </>
  )
}

export default memo(HiddenRowField)

import type { ReactElement } from "react"
import React, { useState } from "react"
import { useEffect } from "react"
import styled from "styled-components"
import LoaderAtm from "@onestore/hel/dist/components/atoms/LoaderAtm"

export interface SalesManagoFormProps {
  src: string
  id: string
}

const StyledIframe = styled.iframe`
  border: none;
  width: 100%;
  border: none;
  min-height: 100%;
`

export default function SalesManagoForm({
  src,
  id,
}: SalesManagoFormProps): ReactElement<SalesManagoFormProps> {
  const [isLoading, setIsLoading] = useState(true)

  let e
  const n = id,
    t = "SM_RESIZE_IFRAME:" + n,
    i = "SM_IFRAME_HEIGHT:" + n + ":"

  const o = () => {
    return e ? e : (e = document.getElementById(n))
  }

  const getResize = () => {
    o() && e.contentWindow.postMessage(t, "*")
  }

  const getMessage = (n) => {
    const t = n.data

    if (typeof t === "string") {
      0 === t.indexOf(i) &&
        o() &&
        (e.style.height = parseInt(t.replace(i, "")) + "px")
    }
  }

  useEffect(() => {
    window.addEventListener("onload", getMessage)
    window.addEventListener("resize", getResize)
    window.addEventListener("message", getMessage)

    setIsLoading(false)

    return () => {
      window.removeEventListener("onload", getMessage)
      window.removeEventListener("resize", getResize)
      window.removeEventListener("message", getMessage)
    }
  }, [])

  return (
    <>
      {isLoading ? (
        <LoaderAtm size="big" />
      ) : (
        <StyledIframe title={id} id={id} src={src} />
      )}
    </>
  )
}

/* eslint-disable no-param-reassign */
import type { Draft } from "immer"
import { produce } from "immer"
import type { CheckPmailResponse } from "@onestore/api/types"
import type { HTTP } from "@onestore/onestore-store-common"
import { HTTP_STATUS } from "@onestore/onestore-store-common/http"
import type { PmailActionsTypes } from "./actions"
import { PmailCheckAction } from "./constants"

export interface PmailState {
  readonly phrase: undefined | string
  readonly phraseSanitized: undefined | string
  readonly results: CheckPmailResponse["results"]
  readonly request_state: HTTP.Status
}

export const defaultState: PmailState = {
  phrase: undefined,
  phraseSanitized: undefined,
  results: [],
  request_state: HTTP_STATUS.NONE,
}

const reducer = (state = defaultState, action: PmailActionsTypes): PmailState =>
  produce(state, (nextState: Draft<PmailState>) => {
    switch (action.type) {
      case PmailCheckAction.SUCCESS:
        nextState.request_state = HTTP_STATUS.OK
        nextState.phrase = action.phrase
        nextState.phraseSanitized = action.phraseSanitized
        nextState.results = action.results

        return

      case PmailCheckAction.PENDING:
        nextState.request_state = HTTP_STATUS.CONTINUE
        nextState.phraseSanitized = action.phrase // na czas requesta zasilamy propsy komponentu frazą
        nextState.results = []

        return
      case PmailCheckAction.FAILURE:
        nextState.request_state = HTTP_STATUS.BAD_REQUEST
        nextState.results = []

        return
    }
  })

export default reducer

import type { ReactElement } from "react"
import React from "react"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import GridOrg from "@onestore/hel/dist/components/organisms/GridOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import type { ConfigsHookResult } from "@gatsby-plugin-generic-page/hooks/useTableConfigs"
import isEmpty from "~/lib/isEmpty"
import CounterConfigElement from "./CounterConfigElement"
import ToggleConfigElement from "./ToggleConfigElement"

interface Props {
  resourceCategoryId: string
  configLabel: string
  configsHookResult: ConfigsHookResult
}

export default function ResourceCounters({
  resourceCategoryId,
  configLabel,
  configsHookResult,
}: Props): ReactElement<Props> {
  const {
    isCalculateProductsLoading,
    getResourceCategory,
    getResourceLimits,
    handleResourcesRemove,
    handleResourceChange,
    getConfigResource,
    getConfigResourceCategoryPriceText,
  } = configsHookResult

  const { resources } = getResourceCategory(resourceCategoryId) || {}

  if (isEmpty(resources)) {
    return <></>
  }

  const resourceCategoryPriceElement = (
    <TextAtm typography="small3" align="right">
      {getConfigResourceCategoryPriceText(resourceCategoryId)}
    </TextAtm>
  )

  const handleToggleSwitch = (isActive: boolean): void => {
    if (isActive) {
      resources.forEach((resource) => {
        handleResourceChange(resourceCategoryId, resource.id as string, 0)
      })
    } else {
      handleResourcesRemove(resourceCategoryId)
    }
  }

  const handleCounterChange = (
    resourceId: string,
    resourceValue: number,
    isIncrement?: boolean
  ): void => {
    const resourceUpdateValue = isIncrement
      ? resourceValue + 1
      : resourceValue - 1

    handleResourceChange(resourceCategoryId, resourceId, resourceUpdateValue)
  }

  const countersElement = (
    <GridOrg columns={2} gutterSpace={1}>
      {resources.map((resource) => {
        const { name, id } = resource

        const getConfigResourceData = getConfigResource(id as string)

        const resourceValue = getConfigResourceData?.value || 0

        const limitsValues = getResourceLimits(id as string)

        return (
          <CounterConfigElement
            key={name}
            label={name}
            value={resourceValue}
            minValue={limitsValues.min}
            maxValue={limitsValues.max}
            isDisable={isCalculateProductsLoading}
            handleIncrementButton={() =>
              handleCounterChange(id as string, resourceValue, true)
            }
            handleDecrementButton={() =>
              handleCounterChange(id as string, resourceValue)
            }
          />
        )
      })}
    </GridOrg>
  )

  const expandedContent = (
    <>
      {countersElement}
      <PushOrg topSpace={3}>{resourceCategoryPriceElement}</PushOrg>
    </>
  )

  return (
    <ToggleConfigElement
      elementId={`resource-${resourceCategoryId}-toggle`}
      configLabel={configLabel}
      expandedContent={expandedContent}
      handleToggleSwith={handleToggleSwitch}
    />
  )
}

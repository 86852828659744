export enum SquidexCtaElementType {
  BUTTON = "Przycisk",
  LINK = "Link",
  TOOLTIP = "Tooltip",
}

export enum SquidexLinkType {
  UNDERLINE = "underline",
  DEFAULT = "default",
  ARROW = "arrow",
}

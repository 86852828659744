import type { ReactElement } from "react"
import React from "react"
import type { FieldInputProps, FieldMetaState } from "react-final-form"
import TextareaMol from "@onestore/hel/dist/components/molecules/TextareaMol"
import { useFormFieldError } from "~/hooks/useFormFieldError"

interface TextAreaRowFieldProps {
  input: FieldInputProps<string>
  meta: FieldMetaState<string>
  placeholder: string
}

function TextAreaRowField({
  input,
  meta,
  placeholder,
}: TextAreaRowFieldProps): ReactElement<TextAreaRowFieldProps> {
  const { hasError, errorMessage, errorPlaceholder } = useFormFieldError(
    input,
    meta
  )

  return (
    <TextareaMol
      heightSpace={3}
      {...input}
      hasError={hasError}
      errorMessage={errorMessage}
      placeholder={errorPlaceholder || placeholder}
    />
  )
}

export default React.memo(TextAreaRowField)

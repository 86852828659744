import type { ProductCardItem } from "@gatsby-plugin-marketplace/fragments/marketplaceProductCard"

export default function useSortOrderAndSliceCategoryProducts(
  category,
  slice
): ProductCardItem[] {
  const products = category.products
    .sort((a, b) => (b.order || 0) - (a.order || 0))
    .slice(0, slice)

  return products
}

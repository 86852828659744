import { typeMatches } from "@onestore-graphql"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import { SquidexReferenceType } from "@gatsby-plugin-generic-page/types/referenceType"
import type { DomainExtensionsUpsell } from "~/fragments/domainExtensionsUpsell"
import type { DomainsPoolUpsell } from "~/fragments/domainsPoolUpsell"
import isEmpty from "~/lib/isEmpty"
import type { PlanSettings } from "~/lib/url"

export const getUpsellData = (
  upsell?: (CloudBluePlan | DomainExtensionsUpsell | DomainsPoolUpsell)[]
): (string | PlanSettings)[] | undefined => {
  if (!upsell) {
    return undefined
  }

  const upsellArray: (string | PlanSettings)[] = []

  upsell.forEach(
    (
      upsellItem: CloudBluePlan | DomainExtensionsUpsell | DomainsPoolUpsell
    ) => {
      if (
        typeMatches(upsellItem.__typename, SquidexReferenceType.CLOUDBLUE_PLAN)
      ) {
        const plan = upsellItem as CloudBluePlan
        const upsellDefaultPeriod = plan.flatData.upsellDefaultPeriod

        if (!isEmpty(upsellDefaultPeriod)) {
          const planWithParam: PlanSettings = {
            alias: plan.flatData.alias,
            period: upsellDefaultPeriod.period,
          }

          upsellArray.push(planWithParam)
        } else {
          upsellArray.push(plan.flatData.alias)
        }
      }

      if (
        typeMatches(
          upsellItem.__typename,
          SquidexReferenceType.DOMAIN_EXTENSIONS_UPSELL
        )
      ) {
        const domainExtensionsUpsell = upsellItem as DomainExtensionsUpsell
        const domainExtensions =
          domainExtensionsUpsell.flatData.domainExtensions

        if (!isEmpty(domainExtensions)) {
          const extensions = domainExtensions.map(
            (domainExtension) => domainExtension.flatData.extension
          )

          upsellArray.push(`DOMAINS(extensions:${extensions.join("|")})`)
        }
      }

      if (
        typeMatches(
          upsellItem.__typename,
          SquidexReferenceType.DOMAINS_POOL_UPSELL
        )
      ) {
        const domainsPoolUpsell = upsellItem as DomainsPoolUpsell
        const domainsPoolId = domainsPoolUpsell.flatData.domainsPoolId

        if (domainsPoolId) {
          upsellArray.push(`DOMAINS(pool:${domainsPoolId})`)
        }
      }
    }
  )

  return upsellArray
}

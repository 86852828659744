import React from "react"
import type { ReactElement } from "react"
import VideoAtm from "@onestore/hel/dist/components/atoms/VideoAtm"
import type { ImageProps } from "@onestore/hel/dist/components/quarks/Picture/Picture.types"
import assetsSquidexToVideoObject from "~/lib/assetsSquidexToVideoObject"
import type { ResponsiveImages } from "~/types/squidex"

interface Props {
  video: ResponsiveImages
}

export interface VideoObject {
  src: string
  retinaSrc?: string
  width?: number
  height?: number
}

function ResponsiveVideo({ video }: Props): ReactElement<ImageProps> {
  return (
    <VideoAtm
      isLooped
      isAutoPlayed
      isMuted
      disablePictureInPicture
      video={{
        large: assetsSquidexToVideoObject(video.large),
        medium: video.medium
          ? assetsSquidexToVideoObject(video.medium)
          : undefined,
        small: video.small
          ? assetsSquidexToVideoObject(video.small)
          : undefined,
      }}
    />
  )
}

export default ResponsiveVideo

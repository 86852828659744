import { zIndex } from "@onestore/hel/dist/settings"
import { sendToplayerShowEvent } from "~/lib/ga4"
import type { PaymentModalData } from "../../api/types"

declare global {
  interface Window {
    oneStorePayUCardPluginIsLoaded: { (): void }
    oneStorePayUCardPluginPaymentCanceled: { (): void }
    oneStorePayUCardPluginPaymentCompleted: { (): void }
    oneStorePayUCardError: { (message: string): void }
  }
}

export const PAYMENT_VARIANT_SMS: string = "sms"
export const PAYMENT_VARIANT_FREE: string = "free"
export const PAYMENT_VARIANT_VISA: string = "visa"
export const PAYMENT_VARIANT_MASTERCARD: string = "mastercard"
export const PAYMENT_VARIANT_NEW_CARD: string = "new-card"
export const PAYMENT_VARIANT_ONLINE_PAYMENT: string = "bank-online"
export const PAYMENT_VARIANT_BANK_TRANSFER: string = "bank-transfer"
export const PAYMENT_VARIANT_OTHER_CARD_VENDOR: string = "card"
export const PAYMENT_VARIANT_PAYPAL: string = "paypal"
export const PAYMENT_VARIANT_BLIK: string = "blik"

export const EXISTING_PAYTOOL_TYPE: number = -1

export function bindModalFunctions(
  paymentCompleted: { (): void },
  paymentCanceled: { (): void },
  pluginLoaded: { (): void },
  errorMessage: { (message: string): void }
): void {
  window.oneStorePayUCardPluginIsLoaded = pluginLoaded
  window.oneStorePayUCardPluginPaymentCanceled = paymentCanceled
  window.oneStorePayUCardPluginPaymentCompleted = paymentCompleted
  window.oneStorePayUCardError = errorMessage
}

export function openModalForm({
  checkoutScriptSection,
  checkoutScriptHtml,
  checkoutScriptHandler,
  checkoutScriptSrc,
}: PaymentModalData) {
  sendToplayerShowEvent()

  const div: HTMLDivElement = document.createElement("div")
  const {
    document: { body },
  } = window
  div.setAttribute("id", checkoutScriptSection)
  div.style.display = "none"
  body.appendChild(div)

  if (checkoutScriptHtml) {
    div.innerHTML = checkoutScriptHtml
  }

  if (checkoutScriptHandler) {
    const checkoutHandler: HTMLScriptElement = document.createElement("script")
    checkoutHandler.async = true
    checkoutHandler.text = checkoutScriptHandler
    div.appendChild(checkoutHandler)
    div.style.zIndex = zIndex.ModalOrg.toString()
    body.appendChild(div)
  }

  if (checkoutScriptSrc) {
    const checkoutScript: HTMLScriptElement = document.createElement("script")
    checkoutScript.src = checkoutScriptSrc
  }
}

import type { Props as FullScreenContainerOrgProps } from "@onestore/hel/dist/components/organisms/FullScreenContainerOrg"
import isEmpty from "~/lib/isEmpty"
import type { ResponsiveImages } from "~/types/squidex"

const smallLogoMargin = 35
const defaultSmallLogoWidth = 162
const largeLogoMargin = 74
const defaultLargeLogoWidth = 340

const getLogoContainerWidth = (
  logo: ResponsiveImages
): FullScreenContainerOrgProps["width"] => {
  const largeLogoWidthValue = !isEmpty(logo.large[0].width)
    ? logo.large[0].width + largeLogoMargin
    : defaultLargeLogoWidth

  if (isEmpty(logo.small)) {
    return largeLogoWidthValue
  } else {
    const smallLogoWidthValue = !isEmpty(logo.small[0].width)
      ? logo.small[0].width + smallLogoMargin
      : defaultSmallLogoWidth
    const mediumLogoWidthValue =
      !isEmpty(logo.medium) && !isEmpty(logo.medium[0].width)
        ? logo.medium[0].width + largeLogoMargin
        : defaultLargeLogoWidth

    return {
      small: smallLogoWidthValue,
      medium: mediumLogoWidthValue,
      large: largeLogoWidthValue,
    }
  }
}

export default getLogoContainerWidth

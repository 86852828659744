import type { ReactNode } from "react"
import type { GeneralField } from "@formily/core"
import { connect, mapProps } from "@formily/react"
import { FormItemLayout } from "@gatsby-plugin-generic-page/components/Formily/FormItemLayout"

export interface FormItemProps {
  label?: string
  tooltip?: string
  description?: string
  required?: boolean
  children: ReactNode
}

interface FormItemField {
  title?: string
  description?: string
  required?: boolean
}

const FormItem = connect(
  FormItemLayout,
  mapProps({}, (props: FormItemProps, field: FormItemField & GeneralField) => {
    return {
      label: props.label || field.title,
      description: props.description || field.description,
      tooltip: props.tooltip,
      required: props.required || field.required,
      children: props.children,
    }
  })
)

export default FormItem

import { typeMatches } from "@onestore-graphql"
import type Theme from "@onestore/hel/dist/typings/theme"
import type { ResponsiveBackgroundImage } from "@onestore/hel/dist/typings/types"
import type {
  ColorFromPicker,
  CustomColorBackground,
} from "@onestore/hel/dist/utils/getCustomColor"
import type { BackgroundImages } from "@gatsby-plugin-generic-page/fragments/backgroundImages"
import type { CustomColor } from "@gatsby-plugin-generic-page/fragments/customColor"
import { CustomTextColors } from "@gatsby-plugin-generic-page/fragments/customColor"
import type { ThemeAccentColor } from "@gatsby-plugin-generic-page/fragments/themeAccentColor"
import type { ThemeBackgroundColor } from "@gatsby-plugin-generic-page/fragments/themeBackgroundColor"
import getResponsiveBackgroundImage from "@gatsby-plugin-generic-page/helpers/getResponsiveBackgroundImage"
import { SquidexComponentType } from "@gatsby-plugin-generic-page/types/componentType"
import isEmpty from "~/lib/isEmpty"
import {
  type CustomBackgroundColor,
  CustomTextColors as CustomOnColorText,
} from "~/types/squidex"
import getCustomBackgroundColor from "./getCustomBackgroundColor"

export type Background = {
  backgroundThemeColor?: Theme.BackgroundColor
  customColor?: CustomColorBackground
  customColorFromPicker?: ColorFromPicker
  customOnColorText?: CustomTextColors
  imageBackground?: ResponsiveBackgroundImage
  themeAccentColor?: Theme.AccentColor
}

const getBackground = (
  background:
    | ThemeBackgroundColor
    | CustomColor
    | BackgroundImages
    | CustomBackgroundColor
    | ThemeAccentColor
    | undefined
): Background => {
  let backgroundThemeColor: Theme.BackgroundColor | undefined,
    customColor: CustomColorBackground | undefined,
    customColorFromPicker: ColorFromPicker | undefined,
    customOnColorText: CustomTextColors | undefined,
    imageBackground: ResponsiveBackgroundImage | undefined,
    themeAccentColor: Theme.AccentColor | undefined

  if (
    !isEmpty(background) &&
    typeMatches(
      background.__typename,
      SquidexComponentType.THEME_BACKGROUND_COLOR
    )
  ) {
    const backgroundThemeColorData = background as ThemeBackgroundColor
    backgroundThemeColor = backgroundThemeColorData.background
  }

  if (
    !isEmpty(background) &&
    typeMatches(background.__typename, SquidexComponentType.CUSTOM_COLOR)
  ) {
    const customColorData = background as CustomColor

    customColor = customColorData
      ? ({
          background: getCustomBackgroundColor(
            customColorData.customBackground
          ),
          text: CustomTextColors[customColorData.text],
        } as CustomColorBackground)
      : undefined

    customOnColorText = CustomOnColorText[customColorData.text]
  }

  if (
    !isEmpty(background) &&
    typeMatches(background.__typename, SquidexComponentType.BACKGROUND_IMAGES)
  ) {
    const backgroundImage = background as BackgroundImages
    imageBackground = getResponsiveBackgroundImage(
      backgroundImage.images,
      backgroundImage.position,
      backgroundImage.isCover
    )
    customOnColorText = CustomOnColorText[backgroundImage.textColor]
  }

  if (
    !isEmpty(background) &&
    typeMatches(background.__typename, SquidexComponentType.BACKGROUND_COLOR)
  ) {
    customColorFromPicker = getCustomBackgroundColor(
      background as CustomBackgroundColor
    )
  }

  if (
    !isEmpty(background) &&
    typeMatches(background.__typename, SquidexComponentType.THEME_ACCENT_COLOR)
  ) {
    const themeBackgroundColor = background as ThemeAccentColor
    themeAccentColor = themeBackgroundColor.color
  }

  return {
    backgroundThemeColor,
    customColor,
    customColorFromPicker,
    customOnColorText,
    imageBackground,
    themeAccentColor,
  }
}

export default getBackground

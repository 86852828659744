import type { ReactElement } from "react"
import React from "react"
import VideoAtm from "@onestore/hel/dist/components/atoms/VideoAtm"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import WidthOrg from "@onestore/hel/dist/components/organisms/WidthOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import CtaElement from "@gatsby-plugin-generic-page/components/CtaElement"
import Header from "@gatsby-plugin-generic-page/components/Header"
import Media from "@gatsby-plugin-generic-page/components/Media"
import type { PromoVideoSectionFlatData } from "@gatsby-plugin-generic-page/fragments/promoVideoSection"
import getBaseMarkdownOverrides from "@gatsby-plugin-generic-page/helpers/getBaseMarkdownOverrides"
import assetsSquidexToVideoObject from "~/lib/assetsSquidexToVideoObject"
import isEmpty from "~/lib/isEmpty"

export default function PromoVideoSection({
  logo,
  header,
  ctaElement,
  responsiveVideo,
  hasHiddenLogoOnDesktop,
  hasHiddenCtaElementOnDesktop,
  sectionSettings,
}: PromoVideoSectionFlatData): ReactElement<PromoVideoSectionFlatData> {
  const logoIcon = !isEmpty(logo) ? (
    <AlignContentOrg horizontalAlign="center">
      <PushOrg bottomSpace={3} hasNoReset>
        <Media media={logo} />
      </PushOrg>
    </AlignContentOrg>
  ) : null

  const ctaElementJsx = !isEmpty(ctaElement) ? (
    <PushOrg topSpace={3}>
      <CtaElement ctaElement={ctaElement} />
    </PushOrg>
  ) : null

  const commonVideoProps = {
    isLooped: true,
    isAutoPlayed: true,
    isMuted: true,
    hasNoMaskImage: true,
    disablePictureInPicture: true,
  }

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        <AlignContentOrg horizontalAlign="center">
          <WidthOrg widthSpace={57}>
            {hasHiddenLogoOnDesktop ? (
              <BreakpointVisibilityOrg from="small" to="large">
                {logoIcon}
              </BreakpointVisibilityOrg>
            ) : (
              logoIcon
            )}

            <Header
              {...header}
              captionTextOverrides={getBaseMarkdownOverrides({
                typography: "medium2_h",
              })}
              titleOverrides={getBaseMarkdownOverrides({
                typography: {
                  small: "xhuge",
                  medium: "giant",
                  large: "giant",
                },
              })}
              textTypography={{ bold: "big3", default: "big1" }}
            />

            {hasHiddenCtaElementOnDesktop ? (
              <BreakpointVisibilityOrg from="small" to="large">
                {ctaElementJsx}
              </BreakpointVisibilityOrg>
            ) : (
              ctaElementJsx
            )}
          </WidthOrg>
        </AlignContentOrg>
      </BaseContainer>

      <AlignContentOrg horizontalAlign="center">
        {responsiveVideo.small ? (
          <BreakpointVisibilityOrg from="small" to="medium">
            <VideoAtm
              video={assetsSquidexToVideoObject(responsiveVideo.small)}
              {...commonVideoProps}
            />
          </BreakpointVisibilityOrg>
        ) : null}

        {responsiveVideo.medium ? (
          <BreakpointVisibilityOrg from="medium" to="large">
            <VideoAtm
              video={assetsSquidexToVideoObject(responsiveVideo.medium)}
              {...commonVideoProps}
            />
          </BreakpointVisibilityOrg>
        ) : null}

        {responsiveVideo.small && responsiveVideo.medium ? (
          <BreakpointVisibilityOrg from="large">
            <VideoAtm
              video={assetsSquidexToVideoObject(responsiveVideo.large)}
              {...commonVideoProps}
            />
          </BreakpointVisibilityOrg>
        ) : (
          <VideoAtm
            video={assetsSquidexToVideoObject(responsiveVideo.large)}
            {...commonVideoProps}
          />
        )}
      </AlignContentOrg>
    </BaseSection>
  )
}

import { get } from "lodash"
import type { JSONPatch } from "@onestore/api/types"
import { HTTP_STATUS } from "@onestore/onestore-store-common/http"
import type {
  BasketSuccessAction,
  BasketThunkAction,
} from "@gatsby-plugin-basket/store/actions"
import { BasketActions } from "@gatsby-plugin-basket/store/constants"
import api from "~/lib/api"
import type { AppDispatch, AppState } from "./reducer"

function handleBasketResponse(
  dispatch,
  promise,
  basketItemsIds: number[] = []
) {
  return promise
    .catch((e) => {
      dispatch({
        type: BasketActions.BASKET_ERROR,
        response_status_code:
          get(e, ["error", "code"]) || HTTP_STATUS.INTERNAL_SERVER_ERROR,
        message: e.message || "",
        basketItemsIds,
      })
    })
    .then((result) => {
      if (result === undefined) {
        return Promise.reject()
      }

      dispatch({
        type: BasketActions.BASKET_SUCCESS,
        result,
        basketItemsIds,
      } as BasketSuccessAction)

      return Promise.resolve(result)
    })
}

export function patchBasket(
  operations: JSONPatch[],
  basketItemsIds: number[] = []
) {
  return (
    dispatch: AppDispatch<BasketThunkAction>,
    getState: { (): AppState }
  ) => {
    dispatch({
      type: BasketActions.BASKET,
      basketItemsIds,
    })
    const state = getState()

    return handleBasketResponse(
      dispatch,
      api.basketPatch(state.basket.token, operations), // TODO ??? gdzie jest to uzywane, komu to potrzebne, czy nie trzeba dać tu source
      basketItemsIds
    )
  }
}

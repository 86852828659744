import type { ReactElement } from "react"
import React from "react"
import SavingsIconLarge from "@onestore-icons/savings-icon-large.svg"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import BorderOrg from "@onestore/hel/dist/components/organisms/BorderOrg"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import FlexItemOrg from "@onestore/hel/dist/components/organisms/FlexItemOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import SurfaceOrg from "@onestore/hel/dist/components/organisms/SurfaceOrg"
import type { Color } from "@onestore/hel/dist/components/organisms/SurfaceOrg/SurfaceOrg.types"
import ConditionalWrapper from "@onestore/hel/dist/helpers/ConditionalWrapper"

export interface Props {
  prefix?: string
  text: string
  backgroundColor: Color
  backgroundColorEmphasis?: number
}

function MessageBox({
  prefix,
  text,
  backgroundColor,
  backgroundColorEmphasis,
}: Props): ReactElement<Props> {
  return (
    <SurfaceOrg
      spacingSpace={{
        vertical: 1.5,
        horizontal: 2,
      }}
      color={backgroundColor}
      colorEmphasis={backgroundColorEmphasis}
      borderRadiusSize="large"
    >
      <FlexContainerOrg alignItems="center">
        {prefix ? (
          <>
            <SavingsIconLarge />

            <PushOrg leftSpace={1} rightSpace={3} hasNoReset>
              <TextAtm typography="xsmall2" emphasis="medium">
                {prefix}
              </TextAtm>
            </PushOrg>
          </>
        ) : null}

        <FlexItemOrg flexBasis={0} flexGrow={1} flexShrink={1}>
          <ConditionalWrapper
            condition={!!prefix}
            wrapper={(children) => (
              <BorderOrg
                border={{
                  left: {
                    style: "solid",
                    width: 1,
                    emphasis: "low",
                  },
                }}
                onColor="background"
              >
                <PushOrg leftSpace={3} hasNoReset>
                  {children}
                </PushOrg>
              </BorderOrg>
            )}
          >
            <TextAtm typography="nano1" emphasis="medium">
              {text}
            </TextAtm>
          </ConditionalWrapper>
        </FlexItemOrg>
      </FlexContainerOrg>
    </SurfaceOrg>
  )
}

export default MessageBox

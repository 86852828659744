function hasTelOrMailInLink(link: string | undefined): string | undefined {
  if (!link) return undefined

  if (link.includes("tel:", 0) || link.includes("mailto:", 0)) {
    return link
  }

  return undefined
}

export default hasTelOrMailInLink

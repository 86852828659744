/* eslint-disable no-param-reassign */
import type { Draft } from "immer"
import { produce } from "immer"
import type { ProductAlias } from "@onestore/api/types"
import type { HTTP } from "@onestore/onestore-store-common"
import { HTTP_STATUS } from "@onestore/onestore-store-common/http"
import type { ProductDefinitionActions, ProductDefinition } from "./actions"
import {
  PRODUCT_DEFINITION_GET_SUCCESS,
  PRODUCT_DEFINITION_GET_REQUEST,
} from "./actions"
import { initialState } from "./selectors"

export interface ProductDefinitionsState {
  readonly request: Record<ProductAlias, HTTP.Status>
  readonly data: Record<ProductAlias, ProductDefinition>
}

const reducer = (
  state: ProductDefinitionsState = initialState,
  action: ProductDefinitionActions
): ProductDefinitionsState =>
  produce(state, (nextState: Draft<ProductDefinitionsState>): void => {
    switch (action.type) {
      case PRODUCT_DEFINITION_GET_REQUEST:
        nextState.request[action.alias] = HTTP_STATUS.CONTINUE

        return

      case PRODUCT_DEFINITION_GET_SUCCESS:
        nextState.request[action.alias] = HTTP_STATUS.OK
        nextState.data[action.alias] = action.product
    }
  })

export default reducer

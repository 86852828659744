import type { Form } from "@formily/core"
import { md } from "~/lib/i18n"

const checkboxMaxValidator = (
  form: Form,
  name: string,
  value: string[],
  max: number
) => {
  if (value.length > max) {
    form.setFieldState(name, (formState) => {
      formState.selfErrors = [md("validator.checkboxMax", { quantity: max })]
    })
  } else {
    form.clearErrors(name)
  }
}

export default checkboxMaxValidator

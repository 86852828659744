import React from "react"
import type { ReactElement } from "react"
import { typeMatches } from "@onestore-graphql"
import AccentHeadingAtm from "@onestore/hel/dist/components/atoms/AccentHeadingAtm"
import LabelAtm from "@onestore/hel/dist/components/atoms/LabelAtm"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import ConditionalWrapper from "@onestore/hel/dist/helpers/ConditionalWrapper"
import Countdown from "@gatsby-plugin-generic-page/components/Countdown"
import type { Header as HeaderType } from "@gatsby-plugin-generic-page/fragments/header"
import type { Label } from "@gatsby-plugin-generic-page/fragments/label"
import { SquidexComponentType } from "@gatsby-plugin-generic-page/types/componentType"
import isEmpty from "~/lib/isEmpty"
import type { Caption, CaptionIcon } from "~/types/squidex"
import MarkdownCaption from "../Markdown/Caption"
import MarkdownText from "../Markdown/Text"
import MarkdownTitle from "../Markdown/Title"
import Media from "../Media"
import SelectMenu from "../SelectMenu"

function Header({
  caption,
  captionTextOverrides,
  title,
  titleTypography,
  text,
  textTypography,
  titleOverrides,
  hasAccentColor,
  align,
  selectMenuOptions,
  countdown,
}: HeaderType): ReactElement<HeaderType> {
  const isHeaderTitleH1 = !isEmpty(title) && /^#(?!#)/.test(title)
  let icon, captionText, label

  if (caption) {
    if (typeMatches(caption.__typename, SquidexComponentType.CAPTION)) {
      const { text } = caption as Caption

      captionText = text
    }

    if (typeMatches(caption.__typename, SquidexComponentType.CAPTION_ICON)) {
      const { captionIcon } = caption as CaptionIcon

      icon = captionIcon
    }

    if (typeMatches(caption.__typename, SquidexComponentType.LABEL)) {
      const { text, variant } = caption as Label

      label = (
        <PushOrg bottomSpace={1}>
          <LabelAtm variant={variant || "default"} size="small">
            {text}
          </LabelAtm>
        </PushOrg>
      )
    }
  }

  return (
    <ConditionalWrapper
      condition={!isEmpty(align)}
      wrapper={(children) => (
        <AlignContentOrg
          horizontalAlign={{
            large: align?.large.horizontalAlign || "default",
            medium: align?.medium.horizontalAlign || "default",
            small: align?.small.horizontalAlign || "default",
          }}
          horizontalAlignType="both"
        >
          {children}
        </AlignContentOrg>
      )}
    >
      {!isEmpty(selectMenuOptions) ? (
        <PushOrg
          bottomSpace={{
            large: 3.5,
            medium: 3.5,
            small: 7,
          }}
        >
          <SelectMenu options={selectMenuOptions} />
        </PushOrg>
      ) : null}

      <ConditionalWrapper
        condition={!!hasAccentColor}
        wrapper={(children) => (
          <AccentHeadingAtm>
            <div>{children}</div>
          </AccentHeadingAtm>
        )}
      >
        {!isEmpty(countdown) ? (
          <PushOrg bottomSpace={5}>
            <Countdown
              labelTypography="small3"
              digitTypography={{
                small: "small3",
                medium: "large3",
                large: "large3",
              }}
              {...countdown}
            />
          </PushOrg>
        ) : null}

        {!isEmpty(icon) ? (
          <PushOrg bottomSpace={1.5}>
            <Media media={icon} />
          </PushOrg>
        ) : null}

        {label}

        {!isEmpty(captionText) ? (
          <MarkdownCaption overrides={captionTextOverrides}>
            {captionText}
          </MarkdownCaption>
        ) : null}

        {!isEmpty(title) ? (
          <MarkdownTitle
            typography={
              titleTypography || {
                small: isHeaderTitleH1 ? "giant2" : "xhuge", // TODO delete condition after ONESTORE-578
                medium: isHeaderTitleH1 ? "giant2" : "xhuge", // TODO delete condition after ONESTORE-578
                large: isHeaderTitleH1 ? "giant2" : "xhuge", // TODO delete condition after ONESTORE-578
              }
            }
            overrides={titleOverrides}
          >
            {title}
          </MarkdownTitle>
        ) : null}

        {!isEmpty(text) ? (
          <PushOrg topSpace={2}>
            <MarkdownText typography={textTypography}>{text}</MarkdownText>
          </PushOrg>
        ) : null}
      </ConditionalWrapper>
    </ConditionalWrapper>
  )
}

export default Header

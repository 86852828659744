export default class LocalStorage {
  /**
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Feature-detecting_localStorage
   */
  static isAvailable(): boolean {
    if (typeof window === "undefined") {
      return false
    }

    try {
      const x: string = "__storage_test__"
      window.localStorage.setItem(x, x)
      window.localStorage.removeItem(x)

      return true
    } catch (e) {
      return (
        e instanceof DOMException &&
        // everything except Firefox
        (e.code === 22 ||
          // Firefox
          e.code === 1014 ||
          // test name field too, because code might not be present
          // everything except Firefox
          e.name === "QuotaExceededError" ||
          // Firefox
          e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
        // acknowledge QuotaExceededError only if there's something already stored
        window.localStorage.length !== 0
      )
    }
  }

  static get(key: string): string | null {
    if (!LocalStorage.isAvailable()) {
      return null
    }

    return window.localStorage.getItem(key)
  }

  static set(key: string, value: string | undefined): void {
    if (!LocalStorage.isAvailable()) {
      return
    }

    if (undefined === value) {
      this.remove(key)
    } else {
      window.localStorage.setItem(key, value)
    }
  }

  static getBool(key: string): boolean | null {
    if (!LocalStorage.isAvailable()) {
      return null
    }

    const value = window.localStorage.getItem(key)

    if (value == null) {
      return null
    }

    return value === "1"
  }

  static setBool(key: string, value: boolean | undefined): void {
    if (!LocalStorage.isAvailable()) {
      return
    }

    if (undefined === value) {
      this.remove(key)
    } else {
      window.localStorage.setItem(key, value ? "1" : "0")
    }
  }

  static remove(key: string): void {
    if (!LocalStorage.isAvailable()) {
      return
    }

    window.localStorage.removeItem(key)
  }

  static setArray(key: string, values: string[]): void {
    LocalStorage.set(key, values.join("|"))
  }

  static setSerialized(key: string, object: any): void {
    LocalStorage.set(key, JSON.stringify(object))
  }

  static getArray(key: string): string[] {
    const value = LocalStorage.get(key)

    return value === null ? [] : value.split("|")
  }

  static getSerialized<T = any>(key: string): T | {} {
    try {
      return JSON.parse(LocalStorage.get(key) || "{}")
    } catch (e) {
      return {}
    }
  }

  static keep(key: string, generator: { (): string }): string {
    let value = LocalStorage.get(key)

    if (!value) {
      value = generator()
      LocalStorage.set(key, value)
    }

    return value
  }

  static getKeyNames(): string[] {
    if (!LocalStorage.isAvailable()) {
      return []
    }

    return Object.keys(window.localStorage)
  }
}

import { useAppSelector } from "~/store/hooks"
import type { SaleConfigurationState } from "../../plugins/gatsby-plugin-marketplace/src/store/selectors"
import { getSaleConfiguration } from "../../plugins/gatsby-plugin-marketplace/src/store/selectors"

export default function useSaleConfigurationState(
  saleConfigurationId: string
): SaleConfigurationState {
  return useAppSelector<SaleConfigurationState>((state) =>
    getSaleConfiguration(state, saleConfigurationId)
  )
}

import type { ReactElement, ReactNode } from "react"
import React from "react"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import type { TextAtmProps } from "@onestore/hel/dist/components/atoms/TextAtm/TextAtm.props"
import useBreakpoint from "@onestore/hel/dist/hooks/useBreakpoint"

interface SectionHeaderTitleProps
  extends Pick<TextAtmProps, "typography" | "htmlTag" | "align" | "pushSpace"> {
  children: ReactNode
}

export default function SectionHeaderTitle({
  children,
  ...restProps
}: SectionHeaderTitleProps): ReactElement<SectionHeaderTitleProps> {
  const isSmallScreenOnly = useBreakpoint("small", "medium")
  const { typography, htmlTag } = restProps

  return (
    <TextAtm
      typography={
        typography ||
        (isSmallScreenOnly || htmlTag !== "h1" ? "xhuge" : "giant")
      }
      htmlTag={htmlTag || "h2"}
      {...restProps}
    >
      {children}
    </TextAtm>
  )
}

import type { ReactElement, SyntheticEvent } from "react"
import React from "react"
import _t from "@core/i18n"
import ButtonAtm from "@onestore/hel/dist/components/atoms/ButtonAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import ModalTemplateWrapper from "../ModalTemplateWrapper"

interface DomainErrorModalProps {
  isOpen: boolean
  onCloseButtonClick: (event: SyntheticEvent) => void
}

export function DomainErrorModal({
  isOpen,
  onCloseButtonClick,
}: DomainErrorModalProps): ReactElement<DomainErrorModalProps> {
  return (
    <ModalTemplateWrapper
      content={
        <>
          <TextAtm typography="great" pushSpace={3}>
            {_t("transfer.errorModalTitle")}
          </TextAtm>

          <TextAtm typography="medium2_h" pushSpace={4}>
            {_t("transfer.errorModalContent")}
          </TextAtm>

          <ButtonAtm
            title={_t("transfer.errorButton")}
            isExpanded
            onClick={onCloseButtonClick}
          >
            {_t("transfer.errorButton")}
          </ButtonAtm>
        </>
      }
      isOpen={isOpen}
      onCloseButtonClick={onCloseButtonClick}
    />
  )
}

import type { ReactElement, ReactNode } from "react"
import React from "react"
import _t from "@core/i18n"
import type { Props as ButtonAtmProps } from "@onestore/hel/dist/components/atoms/ButtonAtm"
import ButtonAtm from "@onestore/hel/dist/components/atoms/ButtonAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"

interface FormFooterProps {
  handleSubmit: { (): void }
  submitSucceeded: boolean
  submitting: boolean
  dirty: boolean
  submitErrors: string[]
  captchaField: ReactNode
  buttonTitle?: string
  buttonText?: string
  buttonSize?: ButtonAtmProps["size"]
  buttonColor?: ButtonAtmProps["color"]
}

function FormFooter({
  submitErrors,
  submitting,
  submitSucceeded,
  handleSubmit,
  dirty,
  captchaField,
  buttonTitle,
  buttonText,
  buttonSize,
  buttonColor,
}: FormFooterProps): ReactElement<FormFooterProps> {
  let buttonLabel = buttonText || _t("forms.submitButton")
  let onClickEvent = () => handleSubmit()

  if (submitErrors.length > 0 && !dirty) {
    buttonLabel = _t("forms.errorButton")
  } else if (submitting) {
    buttonLabel = _t("forms.loadingButton")
  } else if (submitSucceeded && !dirty) {
    buttonLabel = _t("forms.successButton")
    onClickEvent = () => undefined
  }

  return (
    <>
      {submitErrors.map((error) => (
        <TextAtm color="error" pushSpace={1} typography="tiny1">
          {error}
        </TextAtm>
      ))}

      <ButtonAtm
        title={buttonTitle || _t("invoices.form.submit")}
        isDisabled={submitting}
        isLoading={submitting}
        onClick={onClickEvent}
        size={buttonSize}
        color={buttonColor}
        isExpanded
      >
        {buttonLabel}
      </ButtonAtm>

      {captchaField}
    </>
  )
}

export default React.memo(FormFooter)

import { useState } from "react"
import { navigate } from "gatsby"
import type { BasketPatchItem } from "@onestore/api/types"
import {
  addItemsToBasket,
  BasketActionSource,
} from "@gatsby-plugin-basket/store/actions"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import url from "~/lib/url"
import { useAppDispatch } from "~/store/hooks"

type Hook = {
  addProductToBasket: { (): void }
  isLoading: boolean
}

function createBasketPatchItem(plans: CloudBluePlan[]): BasketPatchItem[] {
  return plans.map((plan) => ({
    plan: plan.flatData.id,
    quantity:
      plan.flatData.hasQuantity && plan.flatData.quantityResource
        ? plan.flatData.minQuantity || 1
        : 1,
  }))
}

export default function useFastCheckoutAction(plan: CloudBluePlan): Hook {
  const dispatch = useAppDispatch()
  const [isButtonLoading, setButtonLoading] = useState(false)

  const addProductToBasket = (): void => {
    setButtonLoading(true)

    dispatch(
      addItemsToBasket(
        createBasketPatchItem([plan]),
        null,
        BasketActionSource.GENERIC_PAGE,
        () => {
          setButtonLoading(false)
          navigate(url.getBasketUrl())
        }
      )
    )
  }

  return {
    addProductToBasket,
    isLoading: isButtonLoading,
  }
}

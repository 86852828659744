import Cookies from "js-cookie"

function getQueryParam(name: string, url: string | null = null): string | null {
  if (!url) {
    url = window.location.href
  }
  name = name.replace(/[\[\]]/g, "\\$&")
  const regex: RegExp = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`)
  const results: RegExpExecArray | null = regex.exec(url)

  if (!results) {
    return null
  }
  if (!results[2]) {
    return ""
  }
  return decodeURIComponent(results[2].replace(/\+/g, " "))
}

export function setAffiliateCookie(affiliateCookieName: string): void {
  const affiliateCreationId: string | null = getQueryParam("creation_id")
  const affiliateId: string | null = getQueryParam("id")

  // Sprawdzamy czy w url są id afilianta
  if (affiliateCreationId && affiliateId) {
    // Jeśli mmamy id afilianta w adresie to zapisujemy ciastko z tym id na ustalony czas, liczony od teraz
    const value: string = `pid=sm|afid=${affiliateId}|crid=${affiliateCreationId}`
    Cookies.set(affiliateCookieName, value, {
      expires: 30, // czas życia ciastka z kodem afiliacyjnym 30 dni
      secure: true,
    })
  }
}

export enum PmailCheckAction {
  PENDING = "ACTIONS.PMAIL_SEARCH.CHECK.PENDING",
  SUCCESS = "ACTIONS.PMAIL_SEARCH.CHECK.SUCCESS",
  FAILURE = "ACTIONS.PMAIL_SEARCH.CHECK.FAILURE",
}

export const PMAIL = {
  STATUS: {
    UNKNOWN: "Unknown",
    ERROR: "Error",
    AVAILABLE: "Available",
    UNAVAILABLE: "Unavailable",
    UNSUPPORTED: "Unsupported",
  },
  PARAM_NAME: {
    DOMAIN: "PersonalEmailDomain",
    NAME: "PersonalEmailName",
  },
}

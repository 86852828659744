/* eslint-disable no-param-reassign */
import type { Draft } from "immer"
import { produce } from "immer"
import { set } from "lodash"
import type { UserDataResponse } from "@onestore/api/types"
import type { BasketState } from "@onestore/onestore-store-common"
import { HTTP_STATUS } from "@onestore/onestore-store-common/http"
import type { BasketAction } from "@gatsby-plugin-basket/store/actions"
import { BasketActionSource } from "@gatsby-plugin-basket/store/actions"
import { BonusUpsellActionType } from "@gatsby-plugin-bonus/store/actions-upsell"
import type { CheckoutAction } from "@gatsby-plugin-checkout/store/actions"
import { CheckoutActions } from "@gatsby-plugin-checkout/store/constants"
import Storage, { STORAGE } from "~/lib/storage"
import LocalStorage from "~/lib/storage/LocalStorage"
import type { AccountAction } from "~/store/account/actions"
import { AccountActionTypes } from "~/store/account/actions"
import { BASKET_STATUS, BasketActions } from "./constants"

const basketInfo = LocalStorage.get(STORAGE.BASKET_INFO)

const totalValue = basketInfo
  ? parseInt(JSON.parse(basketInfo)[STORAGE.BASKET_INFO_NET_PRICE], 10)
  : 0
const itemsCount = basketInfo
  ? parseInt(JSON.parse(basketInfo)[STORAGE.BASKET_INFO_CNT], 10)
  : 0

export const initialState: BasketState = {
  domains: [],
  itemsCount,
  pmails: [],
  status: BASKET_STATUS.NONE,
  totalValue,
  vatValue: 0,
  handlingFee: 0,
  paymentMethod: null,
  paymentMethods: [],
  request_state: HTTP_STATUS.NONE,
  token: Storage.getToken(),
  buttons: {},
  items_statuses: {},
  items: [],
  savings: 0,
  promo_code: undefined,
  orderId: null,
  orderNumber: null,
  messages: [],
  needsParameters: false,
  get_params_request: HTTP_STATUS.NONE,
  terms_request: HTTP_STATUS.NONE,
  accountType: null,
  userDataReady: false,
  basketReady: false,
  promoCodeInvalid: false,
  domainUpsell: [],
  modal: {
    removeItem: {
      isOpen: false,
      itemId: undefined,
      planId: undefined,
      actionSource: BasketActionSource.EMPTY,
    },
  },
}

export default function reducer(
  state: BasketState = initialState,
  action: BasketAction | AccountAction | CheckoutAction
): BasketState {
  return produce<BasketState, BasketState>(
    state,
    (nextState: Draft<BasketState>) => {
      switch (action.type) {
        case BasketActions.REMOVE_ITEM_CONFIRM_MODAL_SHOW:
          nextState.modal.removeItem.isOpen = true
          nextState.modal.removeItem.itemId = action.itemId
          nextState.modal.removeItem.planId = action.planId
          nextState.modal.removeItem.actionSource = action.actionSource
          nextState.modal.removeItem.itemRemoval = action.itemRemoval

          return

        case BasketActions.REMOVE_ITEM_CONFIRM_MODAL_CLOSE:
          nextState.modal.removeItem.isOpen = false
          nextState.modal.removeItem.itemId = undefined
          nextState.modal.removeItem.planId = undefined
          nextState.modal.removeItem.actionSource = BasketActionSource.EMPTY
          nextState.modal.removeItem.itemRemoval = undefined

          return

        case BasketActions.BASKET_ITEM_REMOVED:
          nextState.modal.removeItem.isOpen = false
          nextState.modal.removeItem.itemId = undefined
          nextState.modal.removeItem.planId = undefined
          nextState.modal.removeItem.actionSource = BasketActionSource.EMPTY
          nextState.modal.removeItem.itemRemoval = undefined

          return
        case BasketActions.BASKET:
          if ("buttonId" in action && action.buttonId) {
            set(nextState, ["buttons", action.buttonId], HTTP_STATUS.CONTINUE)
          }

          if ("basketItemsIds" in action && action.basketItemsIds) {
            action.basketItemsIds.forEach((itemId: number) => {
              set(nextState, ["items_statuses", itemId], HTTP_STATUS.CONTINUE)
            })
          }
          nextState.request_state = HTTP_STATUS.CONTINUE
          nextState.status = BASKET_STATUS.PENDING

          return

        case BasketActions.BASKET_SUCCESS:
          if (action.buttonId) {
            nextState.buttons[action.buttonId] = HTTP_STATUS.OK
          }

          if (action.basketItemsIds) {
            action.basketItemsIds.forEach((itemId: number) => {
              set(nextState, ["items_statuses", itemId], HTTP_STATUS.OK)
            })
          }

          if (action.result.items) {
            nextState.items = action.result.items
          }

          const messages = action.result.messages ?? [
            { message: action.result.message ?? "" },
          ]
          nextState.items_statuses = {}
          nextState.domainUpsell = action.result.domain_upsell
          nextState.messages = messages
          nextState.itemsCount = action.result.items_count
          nextState.request_state = HTTP_STATUS.OK
          nextState.totalValue = action.result.total_net_price
          nextState.vatValue = action.result.total_vat_value
          nextState.handlingFee = action.result.handling_net_value
          nextState.savings = action.result.savings
          nextState.status = action.result.status
          nextState.promo_code = action.result.promo_code
          nextState.domains = action.result.domains
          nextState.pmails = action.result.pmails
          nextState.orderId = action.result.order_id
          nextState.orderNumber = action.result.order_number
          nextState.paymentMethod = action.result.payment_method
          nextState.paymentMethods = action.result.payment_methods
          nextState.accountType = action.result.account_type
          nextState.needsParameters = action.result.needs_parameters
          nextState.basketReady = true
          break
        case BasketActions.BASKET_ERROR:
          if (action.buttonId) {
            nextState.buttons[action.buttonId] = action.response_status_code
          }

          nextState.request_state = action.response_status_code

          if (action.basketItemsIds) {
            action.basketItemsIds.forEach((itemId: number) => {
              set(
                nextState,
                ["items_statuses", itemId],
                action.response_status_code
              )
            })
          }
          break

        case BasketActions.BASKET_PROMO_CODE_ERROR:
          nextState.request_state = HTTP_STATUS.BAD_REQUEST
          nextState.promoCodeInvalid = true
          break

        case BasketActions.BASKET_RESET_BUTTON:
          nextState.buttons[action.buttonId] = HTTP_STATUS.NONE
          break

        case BonusUpsellActionType.BONUS_UPSELL_DOMAINS_ADD_TO_BASKET_SUCCESS:
        case BonusUpsellActionType.BONUS_UPSELL_DOMAINS_ADD_TO_BASKET_FAILURE:
        case BonusUpsellActionType.BONUS_UPSELL_DOMAINS_ADD_TO_BASKET_REQUEST:
          nextState.buttons[action.buttonId] = action.status

          break

        case AccountActionTypes.ACTION_ACCOUNT_LOGOUT:
          nextState.status = BASKET_STATUS.ACTIVE
          break

        case CheckoutActions.USER_DATA_SUCCESS:
          const {
            result: {
              basket: {
                total_net_price,
                total_vat_value,
                items_count,
                token,
                domains,
                pmails,
                status,
                items,
              },
            },
          }: { result: UserDataResponse } = action
          Storage.setToken(token)
          nextState.request_state = HTTP_STATUS.OK
          nextState.totalValue = total_net_price
          nextState.vatValue = total_vat_value
          nextState.itemsCount = items_count
          nextState.token = token
          nextState.domains = domains
          nextState.pmails = pmails
          nextState.status = status
          nextState.userDataReady = true
          nextState.promoCodeInvalid = false

          if (Array.isArray(items)) {
            nextState.items = items
          }

          return

        case CheckoutActions.PAYMENT_SUCCESS:
          nextState.status = BASKET_STATUS.ARCHIVED
          nextState.orderId = action.result.id
          nextState.orderNumber = action.result.number

          return

        case CheckoutActions.USER_DATA_FAILURE:
          nextState.request_state = HTTP_STATUS.BAD_REQUEST

          return

        case CheckoutActions.USER_DATA_PENDING:
          nextState.request_state = HTTP_STATUS.CONTINUE
          nextState.promoCodeInvalid = false
          // eslint-disable-next-line
          return
      }
    }
  )
}

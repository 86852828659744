export function parse(version: string): string {
  const parts = version.split("-")

  return parts[0]
}

export function isNewerVersion(oldVer: string, newVer: string) {
  const oldParts = oldVer.split(".")
  const newParts = newVer.split(".")
  for (var i = 0; i < newParts.length; i++) {
    const a = ~~newParts[i] // parse int
    const b = ~~oldParts[i] // parse int
    if (a > b) return true
    if (a < b) return false
  }
  return false
}

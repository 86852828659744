import type React from "react"
import type { SyntheticEvent } from "react"
import type {
  FeedbackMessage,
  FormPathPattern,
  GeneralField,
} from "@formily/core"
import { connect, mapProps } from "@formily/react"
import { get } from "lodash"
import InputMol from "@onestore/hel/dist/components/molecules/InputMol"
import TextareaMol from "@onestore/hel/dist/components/molecules/TextareaMol"
import isEmpty from "~/lib/isEmpty"

export enum Size {
  DEFAULT = "default",
  SMALL = "small",
}

export interface InputProps {
  value?: string
  placeholder?: string
  onChange: (event: SyntheticEvent<HTMLInputElement, Event>) => void
  size?: string
}

interface InputField {
  props?: {
    name?: string | FormPathPattern
  }
  errors?: {
    messages?: FeedbackMessage
  }[]
  active?: boolean
}

interface TextAreaProps {
  value?: InputProps["value"]
  onChange: (event: SyntheticEvent<HTMLTextAreaElement, Event>) => void
  placeholder?: InputProps["placeholder"]
  rows?: number
}

interface TextAreaField {
  props?: {
    name?: FormPathPattern
  }
  errors?: {
    messages?: FeedbackMessage
  }[]
  active?: boolean
}

type ComposedInput = React.FC & {
  TextArea?: React.FC
}

const Input: ComposedInput = connect(
  InputMol,
  mapProps({}, (props: InputProps, field: InputField & GeneralField) => {
    return {
      name:
        typeof field.props?.name === "string" ? field.props.name : undefined,
      value: props.value || "",
      placeholder: props.placeholder,
      errorMessage: get(field, "errors[0].messages[0]", ""),
      hasSuccess: isEmpty(field.errors) && field.active && !!props.value,
      hasError: !isEmpty(field.errors),
      onChange: props.onChange,
      size: props.size && props.size === Size.SMALL ? Size.SMALL : Size.DEFAULT,
    }
  })
)

Input.TextArea = connect(
  TextareaMol,
  mapProps({}, (props: TextAreaProps, field: TextAreaField & GeneralField) => {
    return {
      name:
        typeof field.props?.name === "string" ? field.props.name : undefined,
      value: props.value || "",
      onChange: props.onChange,
      placeholder: props.placeholder,
      errorMessage: get(field, "errors[0].messages[0]", ""),
      hasSuccess: isEmpty(field.errors) && field.active && !!props.value,
      hasError: !isEmpty(field.errors),
      heightSpace: props.rows,
    }
  })
)

export default Input

import type { ReactElement } from "react"
import React from "react"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import type { DomainsLinksSectionFlatData } from "@gatsby-plugin-generic-page/fragments/domainsLinksSection"
import DomainItems from "./components/DomainItems"

export default function DomainsLinksSection({
  domainLinks,
  fullWidthOnLarge,
  sectionSettings,
}: DomainsLinksSectionFlatData): ReactElement<DomainsLinksSectionFlatData> {
  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        <DomainItems
          domainLinks={domainLinks}
          fullWidthOnLarge={fullWidthOnLarge}
        />
      </BaseContainer>
    </BaseSection>
  )
}

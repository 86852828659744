import type { ReactElement } from "react"
import React from "react"
import { withPrefix } from "gatsby"
import { Helmet } from "react-helmet"
import useSiteMetadataQuery from "~/hooks/useSiteMetadataQuery"
import type { Metadata as MetadataTypes } from "~/types/Metadata"

interface MetadataProps {
  metadata: MetadataTypes
  isHomepage?: boolean
  ogImageUrl?: string
  noIndex?: boolean
  children?: React.ReactNode
}

export default function Metadata({
  metadata,
  isHomepage = false,
  ogImageUrl,
  noIndex = false,
  children,
}: MetadataProps): ReactElement<MetadataProps> {
  const {
    flatData: {
      metaTitle,
      metaDescription,
      metaKeywords,
      ogDescription,
      ogTitle,
      ogImages,
      twitterTitle,
      twitterDescription,
      twitterImage,
    },
  } = metadata

  const siteMetadata = useSiteMetadataQuery()

  return (
    <Helmet>
      <title>{metaTitle}</title>
      <meta name="version" content={siteMetadata.siteVersion} />
      <meta name="build" content={siteMetadata.siteBuild} />
      <meta name="description" content={metaDescription} />
      <script
        async
        type="text/javascript"
        src={withPrefix("clever_ads.js")}
      ></script>
      {metaKeywords ? <meta name="keywords" content={metaKeywords} /> : null}
      <meta property="og:description" content={ogDescription} />
      <meta property="og:locale" content={siteMetadata.fullLocale} />
      <meta property="og:site_name" content={siteMetadata.ogSiteName} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={ogTitle} />
      <meta name="format-detection" content="telephone=no" />

      {siteMetadata.facebookDomainVerification && isHomepage ? (
        <meta
          name="facebook-domain-verification"
          content={siteMetadata.facebookDomainVerification}
        />
      ) : null}
      {siteMetadata.googleSiteVerification && isHomepage ? (
        <meta
          name="google-site-verification"
          content={siteMetadata.googleSiteVerification}
        />
      ) : null}

      {noIndex ? (
        <meta name="robots" content="noindex" />
      ) : (
        <meta name="robots" content={siteMetadata.robots} />
      )}
      <meta
        property="og:image"
        content={
          ogImageUrl ||
          (ogImages && ogImages.length > 0 ? ogImages[0].url : undefined)
        }
      />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={twitterTitle} />
      <meta name="twitter:description" content={twitterDescription} />
      <meta
        name="twitter:image"
        content={
          twitterImage && twitterImage.length > 0
            ? twitterImage[0].url
            : undefined
        }
      />
      <meta name="twitter:site" content={siteMetadata.twitterSiteName} />
      <meta name="twitter:creator" content={siteMetadata.twitterSiteName} />
      <link
        rel="manifest"
        href={withPrefix(
          `img/${siteMetadata.brand}/base/favicon/manifest.json`
        )}
      />
      <meta name="msapplication-TileColor" content={siteMetadata.tileColor} />
      <meta
        name="msapplication-TileImage"
        content={withPrefix(
          `img/${siteMetadata.brand}/base/favicon/ms-icon-144x144.png`
        )}
      />
      <meta name="theme-color" content={siteMetadata.themeColor} />

      <link
        rel="apple-touch-icon"
        sizes="57x57"
        href={withPrefix(
          `/img/${siteMetadata.brand}/base/favicon/apple-icon-57x57.png`
        )}
      />
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href={withPrefix(
          `/img/${siteMetadata.brand}/base/favicon/apple-icon-60x60.png`
        )}
      />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href={withPrefix(
          `/img/${siteMetadata.brand}/base/favicon/apple-icon-72x72.png`
        )}
      />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href={withPrefix(
          `/img/${siteMetadata.brand}/base/favicon/apple-icon-76x76.png`
        )}
      />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href={withPrefix(
          `/img/${siteMetadata.brand}/base/favicon/apple-icon-114x114.png`
        )}
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href={withPrefix(
          `/img/${siteMetadata.brand}/base/favicon/apple-icon-120x120.png`
        )}
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href={withPrefix(
          `/img/${siteMetadata.brand}/base/favicon/apple-icon-144x144.png`
        )}
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href={withPrefix(
          `/img/${siteMetadata.brand}/base/favicon/apple-icon-152x152.png`
        )}
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={withPrefix(
          `/img/${siteMetadata.brand}/base/favicon/apple-icon-180x180.png`
        )}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href={withPrefix(
          `/img/${siteMetadata.brand}/base/favicon/android-icon-192x192.png`
        )}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={withPrefix(
          `/img/${siteMetadata.brand}/base/favicon/favicon-32x32.png`
        )}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href={withPrefix(
          `/img/${siteMetadata.brand}/base/favicon/favicon-96x96.png`
        )}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={withPrefix(
          `/img/${siteMetadata.brand}/base/favicon/favicon-16x16.png`
        )}
      />
      {children}
    </Helmet>
  )
}

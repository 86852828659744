/* eslint-disable no-param-reassign */
import type { Draft } from "immer"
import { produce } from "immer"
import type { SaleConfigurationActions } from "./actions"
import { ACTIONS } from "./actions"
import type { MarketplaceState } from "./selectors"
import { initialState } from "./selectors"

export default function reducer(
  state: MarketplaceState = initialState,
  action: SaleConfigurationActions
): MarketplaceState {
  return produce(state, (nextState: Draft<MarketplaceState>): void => {
    const { payload } = action
    switch (action.type) {
      case ACTIONS.SALE_CONFIGURATION_INIT:
        nextState.configuration[payload.uuid] = payload.configuration

        return

      case ACTIONS.SALE_CONFIGURATION_VALUE_CLICK:
        nextState.configuration[payload.uuid].options[payload.name] =
          payload.value
        nextState.configuration[payload.uuid].values[payload.name] =
          payload.selectedValue
        // eslint-disable-next-line
        return
    }
  })
}

import LocalStorage from "~/lib/storage/LocalStorage"
// TODO do przerobienia aby używało https://github.com/visionmedia/debug
const styles = "color:#fff;background-color:#e20000;font-weight:bold"

export const isDebugEnabled = () =>
  LocalStorage.get("debug") === null ||
  LocalStorage.get("debug")?.indexOf("onestore") === -1
    ? false
    : true

export default function log(content: any, namespace: string = "core"): void {
  if (!isDebugEnabled()) {
    return
  }

  if (Array.isArray(content)) {
    console.log(`%c${namespace}:`, styles, ...content)
  } else {
    console.log(`%c${namespace}:`, styles, content)
  }
}

import type { ReactElement, SyntheticEvent } from "react"
import React from "react"
import _t from "@core/i18n"
import type { MarkdownToJSX } from "markdown-to-jsx"
import type { DomainCheck } from "@onestore/api/domainSearch"
import NestedLinkAtm from "@onestore/hel/dist/components/atoms/NestedLinkAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import useSiteMetadataQuery from "~/hooks/useSiteMetadataQuery"
import { md } from "~/lib/i18n"
import ModalTemplateWrapper from "../ModalTemplateWrapper"

const markdownOverrides: MarkdownToJSX.Overrides = {
  strong: {
    component: TextAtm,
    props: {
      isBold: true,
    },
  },
  a: {
    component: ({ href, children }) => (
      <NestedLinkAtm title={href} to={href || "#"}>
        {children}
      </NestedLinkAtm>
    ),
  },
}

interface DomainUnknownModalProps {
  isOpen: boolean
  onCloseButtonClick: (event: SyntheticEvent) => void
  domain: DomainCheck.FQDN
}

export function DomainUnknownModal({
  isOpen,
  onCloseButtonClick,
  domain,
}: DomainUnknownModalProps): ReactElement<DomainUnknownModalProps> {
  const { priceListPath } = useSiteMetadataQuery()

  return (
    <ModalTemplateWrapper
      content={
        <>
          <TextAtm typography="great" pushSpace={3}>
            {_t("transfer.unknownExtensionModalTitle")}
          </TextAtm>
          <TextAtm typography="medium2_h" pushSpace={4}>
            {md(
              "transfer.unknownExtensionModalContent",
              { fqdn: domain || "", priceListUrl: priceListPath },
              markdownOverrides
            )}
          </TextAtm>
        </>
      }
      isOpen={isOpen}
      onCloseButtonClick={onCloseButtonClick}
    />
  )
}

import type { ReactElement } from "react"
import React, { useContext } from "react"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import SwitchPeriodAndTooglePriceType from "@gatsby-plugin-generic-page/components/SwitchPeriodAndTooglePriceType"
import getPeriodsByPeriodVariants from "@gatsby-plugin-generic-page/helpers/getPeriodsByPeriodVariants"
import type { ParametersTableSectionFlatData } from "@gatsby-plugin-generic-page/types/sectionType"
import ContentSwitcher from "~/components/ContentSwitcher"
import ProductParameters from "~/components/ProductParameters"
import SwitchContentContext from "~/context/SwitchContentContext"
import isEmpty from "~/lib/isEmpty"
import type { PeriodName } from "~/types/squidex"

export default function ParametersTableSection({
  title,
  comparableProducts,
  tableHeaderTitle,
  hasHiddenFooter,
  hasTogglePriceType,
  text,
  isTextCentered,
  sectionSettings,
}: ParametersTableSectionFlatData): ReactElement<ParametersTableSectionFlatData> {
  const context = useContext(SwitchContentContext)

  const { periodVariants } = context || {}

  const periods = getPeriodsByPeriodVariants(periodVariants)

  const hasPeriodSwitcher = !isEmpty(periods)

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        {title ? (
          <TextAtm htmlTag="h2" typography="xhuge" pushSpace={4}>
            {title}
          </TextAtm>
        ) : null}

        {hasTogglePriceType || hasPeriodSwitcher ? (
          <SwitchPeriodAndTooglePriceType
            hasTogglePriceType={hasTogglePriceType}
          />
        ) : null}

        {context && hasPeriodSwitcher ? (
          <ContentSwitcher items={periods} selected={context.currentKey || ""}>
            {(selected) => {
              return (
                <PushOrg topSpace={2}>
                  <ProductParameters
                    hasTogglePriceType={hasTogglePriceType}
                    hasHiddenFooter={hasHiddenFooter}
                    tableHeaderTitle={tableHeaderTitle}
                    products={comparableProducts}
                    selectedPeriod={selected.period as PeriodName}
                  />
                </PushOrg>
              )
            }}
          </ContentSwitcher>
        ) : (
          <PushOrg topSpace={2}>
            <ProductParameters
              hasTogglePriceType={hasTogglePriceType}
              hasHiddenFooter={hasHiddenFooter}
              tableHeaderTitle={tableHeaderTitle}
              products={comparableProducts}
            />
          </PushOrg>
        )}

        {text ? (
          <PushOrg topSpace={4}>
            <AlignContentOrg
              horizontalAlign={isTextCentered ? "center" : "left"}
            >
              <MarkdownText>{text}</MarkdownText>
            </AlignContentOrg>
          </PushOrg>
        ) : null}
      </BaseContainer>
    </BaseSection>
  )
}

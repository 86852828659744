import Polyglot from "node-polyglot"
import { parsePeriod } from "../api"
import type { PeriodName } from "../api/types"
const onestoreLang = require("../../res/onestoreLang.json")
const { locale } = require("../../res/storeSettings.json")

const config: Polyglot.PolyglotOptions = {
  locale: locale || "pl",
  phrases: onestoreLang || {},
  onMissingKey: (key) => key, // ignorujemy błędy o braku tłumaczeń
}

const polyglot = new Polyglot(config)

const trans = (key: string, options?: Polyglot.InterpolationOptions): string =>
  polyglot.t(key, options)

const has = (key: string): boolean => polyglot.has(key)
export const hasValue = (key: string): boolean => has(key) && trans(key) !== ""

export enum PeriodTranslation {
  SHORT = "periodName.short",
  BASKET = "periodName.basket",
  BASKET_TRIAL = "periodName.basketTrial",
  BONUS = "periodName.bonus",
  LONG = "periodName.long",
}

export const _tp = (
  type: PeriodTranslation,
  periodName: PeriodName | null
): string => {
  if (periodName === null || periodName === "") {
    return ""
  }

  const { periodValue, periodType } = parsePeriod(periodName)

  return trans(`${type}.${periodType}`, {
    smart_count: periodValue,
  })
}
export default trans

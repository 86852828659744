import type { ReactElement } from "react"
import { useState } from "react"
import React from "react"
import _t from "@core/i18n"
import DeviceScreenMol from "@onestore/hel/dist/components/molecules/DeviceScreenMol"
import DevicesPreviewModalOrg from "@onestore/hel/dist/components/organisms/DevicesPreviewModalOrg"
import type { EmbeddedAssetSquidexMetadata } from "~/fragments/image"
import assetsSquidexToImageObject from "~/lib/assetsSquidexToImageObject"

export enum Devices {
  DESKTOP = "desktop",
  TABLET = "tablet",
  MOBILE = "mobile",
}

export interface Template {
  title: string
  link?: string
  staticImages?: {
    large: EmbeddedAssetSquidexMetadata[]
    medium: EmbeddedAssetSquidexMetadata[]
    small: EmbeddedAssetSquidexMetadata[]
  }
}

interface Props {
  isOpen: boolean
  template: Template
  handleClickModalClose: () => void
}

function DevicesPreviewModal({
  isOpen,
  template,
  handleClickModalClose,
}: Props): ReactElement<Props> {
  const [activeDevice, setActiveDevice] = useState(Devices.DESKTOP)

  const handleDeviceClick = (device: Devices) => setActiveDevice(device)

  const staticImagesObject = template.staticImages &&
    template.staticImages[0] && {
      large: assetsSquidexToImageObject(template.staticImages[0].large, ""),
      medium: assetsSquidexToImageObject(template.staticImages[0].medium, ""),
      small: assetsSquidexToImageObject(template.staticImages[0].small, ""),
    }

  return (
    <DevicesPreviewModalOrg
      isOpen={isOpen}
      header={{
        activeDevice: activeDevice,
        backButton: {
          title: _t("webTemplates.back"),
          onClick: handleClickModalClose,
        },
        dekstopButtonOnClick: () => handleDeviceClick(Devices.DESKTOP),
        tabletButtonOnClick: () => handleDeviceClick(Devices.TABLET),
        mobileButtonOnClick: () => handleDeviceClick(Devices.MOBILE),
      }}
      content={
        <DeviceScreenMol
          activeDevice={activeDevice}
          templateTitle={template.title}
          templateLink={template?.link}
          templateImage={staticImagesObject}
        />
      }
    />
  )
}

export default DevicesPreviewModal

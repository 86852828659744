import type {
  Action,
  AnyAction,
  Dispatch,
  ThunkDispatch,
} from "@reduxjs/toolkit"
import { combineReducers } from "@reduxjs/toolkit"
import type {
  AccountState,
  CheckoutState,
  MessageState,
  BasketState,
  OfficeState,
} from "@onestore/onestore-store-common"
import basket from "@gatsby-plugin-basket/store/reducer"
import bonus from "@gatsby-plugin-bonus/store/reducer"
import type { BonusState } from "@gatsby-plugin-bonus/types"
import pMailSearch from "@gatsby-plugin-bonus-pmail/store/reducer"
import type { PmailState } from "@gatsby-plugin-bonus-pmail/store/reducer"
import checkout from "@gatsby-plugin-checkout/store/reducer"
import type { ProductDefinitionsState } from "@gatsby-plugin-definitions/store/reducer"
import definitions from "@gatsby-plugin-definitions/store/reducer"
import type { DomainSearchState } from "@gatsby-plugin-domain-search/store/reducer"
import domainSearch from "@gatsby-plugin-domain-search/store/reducer"
import marketplace from "@gatsby-plugin-marketplace/store/reducer"
import type { MarketplaceState } from "@gatsby-plugin-marketplace/store/selectors"
import type { CoreState } from "~/store/core/reducer"
import account from "./account/reducer"
import core from "./core/reducer"
import messages from "./messages/reducer"
import office from "./office/reducer"
import type { store } from "."

export interface AppState {
  readonly account: AccountState
  readonly basket: BasketState
  readonly bonus: BonusState
  readonly checkout: CheckoutState
  readonly office: OfficeState
  readonly messages: MessageState
  readonly marketplace: MarketplaceState
  readonly definitions: ProductDefinitionsState
  readonly pMailSearch: PmailState
  readonly domainSearch: DomainSearchState
  readonly core: CoreState
}

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch<T> = Dispatch<AnyAction> &
  ThunkDispatch<RootState, any, Action<T> | AnyAction>

const rootReducer = combineReducers({
  account,
  basket,
  checkout,
  definitions,
  bonus,
  marketplace,
  domainSearch,
  pMailSearch,
  office,
  messages,
  core,
})

export default rootReducer

export enum OfficeAccountType {
  NEW = "new",
  EXISTING = "existing",
}

export enum OfficeStatus {
  ERROR = "error",
  PENDING = "pending",
  ACCEPTED = "accepted",
}

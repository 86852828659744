import type { ImageProps } from "@onestore/hel/dist/components/quarks/Picture/Picture.types"
import type { EmbeddedAssetSquidexMetadata } from "~/fragments/image"

function getImageUrl(
  image: EmbeddedAssetSquidexMetadata,
  format: "webp" | "avif" | "auto" | null = null
): string {
  if (
    format === "auto" &&
    image.type === "IMAGE" &&
    process.env.GATSBY_SQUIDEX_ASSETS_PROXY
  ) {
    return `${image.url}?version=${image.version}&auto=true`
  }

  if (
    format &&
    image.type === "IMAGE" &&
    process.env.GATSBY_SQUIDEX_ASSETS_PROXY
  ) {
    return `${image.url}?version=${image.version}&format=${format}`
  }

  return `${image.url}?version=${image.version}`
}
export default function assetsSquidexToImageObject(
  images: EmbeddedAssetSquidexMetadata[],
  title: string
): ImageProps {
  const srcImage =
    images.length > 1
      ? images.find((image) => !image.fileName.includes("@2x"))
      : undefined

  const retinaSrcImage =
    images.length > 1
      ? images.find((image) => image.fileName.includes("@2x"))
      : undefined

  const mainImage = srcImage
    ? srcImage
    : retinaSrcImage
      ? retinaSrcImage
      : images[0]

  return {
    src: getImageUrl(mainImage, "auto"),
    retinaSrc: retinaSrcImage ? getImageUrl(retinaSrcImage, "auto") : undefined,
    alt: mainImage.alt || title,
    title: mainImage.title || title,
    width: mainImage.width,
    height: mainImage.height,
  }
}

import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import type { FieldInputProps, FieldMetaState } from "react-final-form"
import InputMol from "@onestore/hel/dist/components/molecules/InputMol"
import { useFormFieldError } from "~/hooks/useFormFieldError"
import useSendFile from "./useSendFile"

interface UploadRowFieldProps {
  input: FieldInputProps<string>
  meta: FieldMetaState<string>
  placeholder: string
}

function UploadRowField({
  input,
  meta,
  placeholder,
}: UploadRowFieldProps): ReactElement<UploadRowFieldProps> {
  const { hasError, errorPlaceholder } = useFormFieldError(input, meta)
  const {
    inputRef,
    uploadedFilesNames,
    handleFileEvent,
    resetFiles,
    setUploadedFilesNames,
  } = useSendFile(input.onChange, input.name)

  return (
    <InputMol
      {...input}
      value={undefined}
      hasError={hasError}
      type="file"
      ref={inputRef}
      placeholder={errorPlaceholder || placeholder}
      isPlaceholderVisibleWhenHasValue={false}
      onChange={handleFileEvent}
      uploadedFilesNames={uploadedFilesNames}
      ctaLink={{
        text: _t("form.upload.remove"),
        onClick: () => {
          setUploadedFilesNames("")
          resetFiles()
        },
      }}
    />
  )
}

export default React.memo(UploadRowField)

import type { ReactElement } from "react"
import React from "react"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import type { TextAtmProps } from "@onestore/hel/dist/components/atoms/TextAtm/TextAtm.props"
import isEmpty from "~/lib/isEmpty"

interface Props {
  children: ReactElement[]
  typography: TextAtmProps["typography"]
  pushSpace: TextAtmProps["pushSpace"]
  emphasis?: TextAtmProps["emphasis"]
}
function Paragraph({
  children,
  typography,
  emphasis,
  pushSpace,
}: Props): ReactElement {
  const textChild = children.find(
    (child) =>
      typeof child === "string" ||
      (typeof child === "object" &&
        !isEmpty(child.props) &&
        child.props.hasOwnProperty("typography"))
  )

  if (isEmpty(textChild)) {
    return <>{children}</>
  }

  return (
    <TextAtm typography={typography} pushSpace={pushSpace} emphasis={emphasis}>
      {children}
    </TextAtm>
  )
}

export default Paragraph

import type { ReactElement } from "react"
import React, { Children } from "react"
import { typeMatches } from "@onestore-graphql"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import FlexItemOrg from "@onestore/hel/dist/components/organisms/FlexItemOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import WrapOrg from "@onestore/hel/dist/components/organisms/WrapOrg"
import ConditionalWrapper from "@onestore/hel/dist/helpers/ConditionalWrapper"
import type Theme from "@onestore/hel/dist/typings/theme"
import space from "@onestore/hel/dist/utils/space"
import Button from "@gatsby-plugin-generic-page/components/Button"
import CtaLink from "@gatsby-plugin-generic-page/components/CtaLink"
import ImageButton from "@gatsby-plugin-generic-page/components/ImageButton/ImageButton"
import type { BundleButtonWithPrice as BundleButtonWithPriceType } from "@gatsby-plugin-generic-page/fragments/bundleButtonWithPrice"
import type { CtaElement as CtaElementType } from "@gatsby-plugin-generic-page/fragments/ctaElement"
import type { CtaSectionsModal as CtaSectionsModalType } from "@gatsby-plugin-generic-page/fragments/ctaSectionsModal"
import type { DomainButtonWithPrice as DomainButtonWithPriceType } from "@gatsby-plugin-generic-page/fragments/domainButtonWithPrice"
import type { PulseButtonType } from "@gatsby-plugin-generic-page/fragments/pulseButton"
import { getCtaButtonFlatData } from "@gatsby-plugin-generic-page/helpers/getCtaElementFlatData"
import { SquidexComponentType } from "@gatsby-plugin-generic-page/types/componentType"
import { SquidexReferenceType } from "@gatsby-plugin-generic-page/types/referenceType"
import isEmpty from "~/lib/isEmpty"
import CtaBundleButtonWithPrice from "../CtaBundleButtonWithPrice"
import CtaSectionsModal from "../CtaSectionsModal"
import DomainButtonWithPrice from "../DomainButtonWithPrice"
import PriceWithButtonWrapper from "../PriceWithButtonWrapper"
import PulseButton from "../PulseButton"

interface CtaElementProps {
  ctaElement:
    | CtaElementType[]
    | BundleButtonWithPriceType[]
    | DomainButtonWithPriceType[]
    | CtaSectionsModalType[]
    | PulseButtonType[]
  typography?: Theme.Typography
  expandFromMedium?: boolean
}

export default function CtaElement({
  ctaElement,
  typography,
  expandFromMedium,
}: CtaElementProps): ReactElement<CtaElementProps> | null {
  if (isEmpty(ctaElement)) {
    return null
  }

  let expandedButtonsOnMobile = 0
  let hasPriceWithButton = 0

  const elements = ctaElement.map((element) => {
    const { ctaType, hasSurface } = element.flatData
    const { __typename } = element

    if (
      typeMatches(__typename, SquidexReferenceType.BUNDLE_BUTTON_WITH_PRICE)
    ) {
      hasPriceWithButton++

      return (
        <PriceWithButtonWrapper
          key={`price-with-button-wrapper-${element.id}`}
          hasSurface={hasSurface}
        >
          <CtaBundleButtonWithPrice
            {...element}
            key={`bundle-button-${element.id}`}
            hasTextColor
            pushSpace={{
              rightSpace: 3,
              leftSpace: { large: 0, medium: 0, small: 1 },
            }}
          />
        </PriceWithButtonWrapper>
      )
    }

    if (
      typeMatches(__typename, SquidexReferenceType.DOMAIN_BUTTON_WITH_PRICE)
    ) {
      hasPriceWithButton++

      return (
        <PriceWithButtonWrapper
          key={`price-with-button-wrapper-${element.id}`}
          hasSurface={hasSurface}
        >
          <DomainButtonWithPrice
            {...element.flatData}
            key={`domain-button-${element.id}`}
            isSmall
            hasTextColor
          />
        </PriceWithButtonWrapper>
      )
    }

    if (typeMatches(__typename, SquidexReferenceType.CTA_SECTIONS_MODAL)) {
      return (
        <CtaSectionsModal key={`modal-button-${element.id}`} {...element} />
      )
    }

    if (
      typeMatches(ctaType?.__typename, SquidexComponentType.CTA_IMAGE_BUTTON)
    ) {
      return (
        <ImageButton key={`cta-image-button-${element.id}`} button={element} />
      )
    }

    if (typeMatches(ctaType?.__typename, SquidexComponentType.CTA_LINK)) {
      return (
        <CtaLink
          key={`cta-link-${element.id}`}
          link={element}
          typography={typography}
        />
      )
    }

    if (typeMatches(ctaType?.__typename, SquidexComponentType.CTA_BUTTON)) {
      const { isExpandedOnMobile, showPrice } = getCtaButtonFlatData(element)

      if (isExpandedOnMobile) {
        expandedButtonsOnMobile++
      }

      if (!isEmpty(showPrice?.plan)) {
        hasPriceWithButton++
      }

      return (
        <Button
          key={`cta-button-${element.id}`}
          button={element}
          isExpandedOnMobile={ctaElement.length > 1 && isEmpty(showPrice?.plan)}
        />
      )
    }

    if (typeMatches(__typename, SquidexReferenceType.PULSE_BUTTON)) {
      return <PulseButton {...(element as PulseButtonType).flatData} />
    }

    return null
  })

  const elementsLength = elements.length

  if (elementsLength) {
    const gapSpace = 1.5

    return (
      <ConditionalWrapper
        condition={elementsLength > 1}
        wrapper={(children) => (
          <>
            <BreakpointVisibilityOrg from="small" to="medium">
              {hasPriceWithButton ? (
                <AlignContentOrg
                  horizontalAlign="center"
                  horizontalAlignType="both"
                >
                  {Children.map(elements, (child, index) => (
                    <PushOrg
                      key={`flex-element-${index}`}
                      topSpace={2}
                      hasForcedFullWidth
                    >
                      {child}
                    </PushOrg>
                  ))}
                </AlignContentOrg>
              ) : (
                <FlexContainerOrg
                  direction={expandedButtonsOnMobile ? "column" : "row"}
                  alignItems={!expandedButtonsOnMobile ? "center" : undefined}
                  gapSpace={gapSpace}
                  wrap="wrap"
                >
                  {Children.map(children, (child, index) => (
                    <FlexItemOrg
                      flexBasis={`calc(50% - ${space(gapSpace)})`}
                      flexGrow={1}
                      minWidth="fit-content"
                      key={`flex-element-${index}`}
                    >
                      {child}
                    </FlexItemOrg>
                  ))}
                </FlexContainerOrg>
              )}
            </BreakpointVisibilityOrg>

            <BreakpointVisibilityOrg from="medium">
              {expandFromMedium ? (
                <FlexContainerOrg
                  direction={"row"}
                  gapSpace={gapSpace}
                  wrap="wrap"
                >
                  {Children.map(children, (child, index) => (
                    <FlexItemOrg
                      flexBasis={`calc(50% - ${space(gapSpace)})`}
                      flexGrow={1}
                      minWidth="fit-content"
                      key={`flex-element-${index}`}
                    >
                      {child}
                    </FlexItemOrg>
                  ))}
                </FlexContainerOrg>
              ) : (
                <WrapOrg alignCenter="vertical" gutterSpace={4}>
                  {children}
                </WrapOrg>
              )}
            </BreakpointVisibilityOrg>
          </>
        )}
      >
        {elements}
      </ConditionalWrapper>
    )
  }

  return null
}

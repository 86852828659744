import type { OrderResponse, PlanId } from "@onestore/api/types"
import { getFlatBasketItems } from "@gatsby-plugin-basket/store/selectors"
import { getCeneoOpinionsSettings } from "~/lib/config"
import log from "~/lib/log"
import { STORAGE } from "~/lib/storage"
import SessionStorage from "~/lib/storage/SessionStorage"
import type { AppState } from "~/store/reducer"

export const getCeneoItems = (
  state: AppState,
  ceneoFeedPlansIds: number[]
): PlanId[] => {
  return getFlatBasketItems(state)
    .filter((plan) => ceneoFeedPlansIds.includes(plan.plan_id))
    .map((plan) => plan.plan_id)
}

export const containsCeneoItems = (
  state: AppState,
  ceneoFeedPlansIds: number[]
): boolean => getCeneoItems(state, ceneoFeedPlansIds).length !== 0

export function handleCeneoOpinionsTracking(
  state: AppState,
  order: OrderResponse,
  ceneoFeedPlansIds: number[]
): void {
  const { accountGuid } = getCeneoOpinionsSettings()

  if (
    !accountGuid ||
    ceneoFeedPlansIds.length === 0 ||
    !containsCeneoItems(state, ceneoFeedPlansIds)
  ) {
    log("not active", "ceneo")

    return
  }

  const user = state.checkout.user

  if (!user?.email) {
    return
  }

  log(
    {
      email: user.email,
      orderNumber: order.number,
      products: getCeneoItems(state, ceneoFeedPlansIds),
    },
    "ceneo"
  )

  SessionStorage.setSerialized(STORAGE.OPINIONS, {
    email: user.email,
    orderNumber: order.number,
    products: getCeneoItems(state, ceneoFeedPlansIds),
  })
}

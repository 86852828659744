import type { ReactElement } from "react"
import React from "react"
import ArrowDoubleTop from "@onestore-icons/arrow-double-top.svg"
import IconLinkAtm from "@onestore/hel/dist/components/atoms/IconLinkAtm"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import ParallaxOrg from "@onestore/hel/dist/components/organisms/ParallaxOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import SurfaceOrg from "@onestore/hel/dist/components/organisms/SurfaceOrg"
import CategoryCard from "@gatsby-plugin-generic-page/components/CategoryCard"
import Header from "@gatsby-plugin-generic-page/components/Header"
import Media from "@gatsby-plugin-generic-page/components/Media"
import { CustomTextColors } from "@gatsby-plugin-generic-page/fragments/customColor"
import type { ParallaxSectionFlatData } from "@gatsby-plugin-generic-page/fragments/parallaxSection"
import getBaseMarkdownOverrides from "@gatsby-plugin-generic-page/helpers/getBaseMarkdownOverrides"
import getLinkProps from "@gatsby-plugin-generic-page/helpers/getLinkProps"
import isEmpty from "~/lib/isEmpty"

const captionMarkdownOverrides = getBaseMarkdownOverrides({
  typography: { small: "xsmall2", medium: "medium2_h", large: "medium2_h" },
  boldTypography: "medium2_h",
  pushSpace: 3,
})

export default function ParallaxSection({
  header,
  backgroundColor,
  textColor,
  sectionId,
  productsCategories,
  bottomButton,
}: ParallaxSectionFlatData): ReactElement<ParallaxSectionFlatData> {
  const cards = productsCategories.map((productCategory, index) => (
    <FlexContainerOrg
      key={`${productCategory.title}-${index}`}
      justifyContent="center"
    >
      <CategoryCard
        card={productCategory}
        sectionBackgroundColor={backgroundColor.color}
      />
    </FlexContainerOrg>
  ))

  return (
    <SurfaceOrg
      id={sectionId}
      color={CustomTextColors[textColor]}
      customColor={backgroundColor.color}
      spacingSpace={{
        vertical: 10,
        horizontal: 2,
      }}
      borderRadiusSize="none"
    >
      {!isEmpty(header) ? (
        <PushOrg bottomSpace={6}>
          <AlignContentOrg horizontalAlign="center">
            <Header
              {...header}
              titleTypography="giant2"
              captionTextOverrides={captionMarkdownOverrides}
            />
          </AlignContentOrg>
        </PushOrg>
      ) : null}

      <ParallaxOrg isAnimationActive scrollContainerRef="#parallax-container">
        {cards}
      </ParallaxOrg>

      {!isEmpty(bottomButton) ? (
        <PushOrg topSpace={5}>
          <AlignContentOrg horizontalAlign="center">
            <IconLinkAtm
              icon={isEmpty(bottomButton.icon) ? ArrowDoubleTop : undefined}
              image={
                !isEmpty(bottomButton.icon) ? (
                  <Media media={bottomButton.icon} />
                ) : undefined
              }
              iconPosition="left"
              iconAlign="center"
              sizeSpace={2}
              typography="buttonSmall"
              color="white"
              iconHoverColor="disabled"
              textHoverColor="disabled"
              {...getLinkProps(bottomButton.link)}
            />
          </AlignContentOrg>
        </PushOrg>
      ) : null}
    </SurfaceOrg>
  )
}

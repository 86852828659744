import { useLocation } from "@reach/router"
import { graphql, useStaticQuery } from "gatsby"
import type { NotificationBar } from "./useNotificationBarQuery"
import useNotificationBarQuery from "./useNotificationBarQuery"

interface Query {
  GraphCmsSquidex: {
    genericPages: {
      id: string
      flatData: {
        url: string
        hideNotificationBar?: boolean
      }
    }[]
  }
}

function useNotificationBars(
  location: "productPage" | "checkout"
): NotificationBar | undefined {
  const {
    GraphCmsSquidex: { genericPages },
  } = useStaticQuery<Query>(graphql`
    {
      GraphCmsSquidex {
        genericPages: queryGenericPageContents {
          id
          flatData {
            url
            hideNotificationBar
          }
        }
      }
    }
  `)

  const notificationBars = useNotificationBarQuery()
  const { pathname } = useLocation()

  const isVisibleInLocation = (bar: NotificationBar): boolean => {
    const { displayOnCheckout, displayOnProductPages } = bar.flatData

    const locations = {
      checkout: !!displayOnCheckout,
      productPage: !!displayOnProductPages,
    }

    return locations[location]
  }

  const isNotExcludedPage = (): boolean => {
    const excludedPage = genericPages.find(
      (page) => page.flatData.url === pathname
    )

    return excludedPage ? !excludedPage.flatData.hideNotificationBar : true
  }

  const now = new Date().toISOString()

  const activeBars = notificationBars
    .filter((bar) =>
      bar.flatData.period.some((date) => {
        if (date.to) {
          return date.from <= now && date.to > now
        }

        return date.from <= now && !date.to
      })
    )
    .filter(isVisibleInLocation)
    .filter(isNotExcludedPage)
    .sort(
      (prevBar, nextBar) =>
        prevBar.flatData.priority - nextBar.flatData.priority
    )

  return activeBars.shift()
}

export default useNotificationBars

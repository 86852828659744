import type { ReactElement } from "react"
import React from "react"
import { typeMatches } from "@onestore-graphql"
import VideoAtm from "@onestore/hel/dist/components/atoms/VideoAtm"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import type Theme from "@onestore/hel/dist/typings/theme"
import Countdown from "@gatsby-plugin-generic-page/components/Countdown"
import CtaElement from "@gatsby-plugin-generic-page/components/CtaElement"
import type { Counter as CounterType } from "@gatsby-plugin-generic-page/fragments/counter"
import type { CtaElement as CtaElementType } from "@gatsby-plugin-generic-page/fragments/ctaElement"
import type { Video } from "@gatsby-plugin-generic-page/fragments/video"
import assetsSquidexToImageObject from "~/lib/assetsSquidexToImageObject"
import assetsSquidexToVideoObject from "~/lib/assetsSquidexToVideoObject"
import isEmpty from "~/lib/isEmpty"
import type { SquidexMarkdownEmbeddedContent } from "~/types/squidex"
import { ContentType } from "./contentType"

export interface EmbeddedContentProps extends SquidexMarkdownEmbeddedContent {
  referenceId: string
  typography?: Theme.Typography
}

function EmbeddedContent({
  contents,
  referenceId,
  typography,
}: EmbeddedContentProps): ReactElement<EmbeddedContentProps> | null {
  const reference = contents?.find((content) => content.id === referenceId)

  if (reference && typeMatches(reference.__typename, ContentType.VIDEO)) {
    const { flatData } = reference as Video

    if (isEmpty(flatData.video)) {
      return null
    }

    const videoProps = {
      ...flatData,
      video: assetsSquidexToVideoObject(flatData.video),
      fallbackImg: assetsSquidexToImageObject(flatData.fallbackImage, ""),
      poster: flatData.poster ? flatData.poster[0].url : undefined,
    }

    return (
      <PushOrg bottomSpace={4} topSpace={4} hasNoReset>
        <VideoAtm {...videoProps} disablePictureInPicture />
      </PushOrg>
    )
  }

  if (reference && typeMatches(reference.__typename, ContentType.CTA_ELEMENT)) {
    return (
      <CtaElement
        typography={typography}
        ctaElement={[reference as CtaElementType]}
      />
    )
  }

  if (reference && typeMatches(reference.__typename, ContentType.COUNTER)) {
    const { flatData } = reference as CounterType

    return <Countdown isSmall {...flatData} />
  }

  return null
}

export default EmbeddedContent

import React from "react"
import { createContext, useState } from "react"

interface HeaderContextProps {
  headerHeight: number
  setHeaderHeight: { (height: number): void }
  headerInitHeight: number
  setHeaderInitHeight: { (height: number): void }
}

export const HeaderContext = createContext<HeaderContextProps>({
  headerHeight: 0,
  headerInitHeight: 0,
  setHeaderHeight: () => {},
  setHeaderInitHeight: () => {},
})

export function HeaderContextProvider({ children }) {
  const [headerHeight, setHeaderHeight] = useState(0)
  const [headerInitHeight, setHeaderInitHeight] = useState(0)

  return (
    <HeaderContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        headerInitHeight,
        setHeaderInitHeight,
      }}
    >
      {children}
    </HeaderContext.Provider>
  )
}

export default HeaderContext

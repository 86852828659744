import { useStaticQuery, graphql } from "gatsby"

export interface DataCenterLocationProps {
  id: string
  label: string
  isDefault: boolean
}

interface Query {
  GraphCmsSquidex: {
    findIonosCloudConfigurationSingleton: {
      flatData: {
        dataCenterLocation: DataCenterLocationProps[]
      }
    }
  }
}

export default function useIonosCloudConfigurationQuery() {
  const { GraphCmsSquidex } = useStaticQuery<Query>(graphql`
    {
      GraphCmsSquidex {
        findIonosCloudConfigurationSingleton {
          flatData {
            dataCenterLocation {
              id
              label
              isDefault
            }
          }
        }
      }
    }
  `)

  return GraphCmsSquidex.findIonosCloudConfigurationSingleton.flatData
    .dataCenterLocation
}

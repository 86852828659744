import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import { get } from "lodash"
import ContainerOrg from "@onestore/hel/dist/components/organisms/ContainerOrg"
import SectionOrg from "@onestore/hel/dist/components/organisms/SectionOrg"
import useBreakpoint from "@onestore/hel/dist/hooks/useBreakpoint"
import type { MarketplaceCategoryProductCardSectionFlatData } from "@gatsby-plugin-generic-page/fragments/marketplaceCategoryProductCardSection"
import useLinkProperties from "~/hooks/useLinkProperties"
import useMarketplaceCategoryBySlug from "~/hooks/useMarketplaceCategoryBySlug"
import useSortOrderAndSliceCategoryProducts from "~/hooks/useSortOrderAndSliceCategoryProducts"
import isEmpty from "~/lib/isEmpty"
import AdvancedProductCardsTemplate from "~/templates/AdvancedProductCardsTemplate"

export default function CategoryAdvancedProductCardsSection({
  linkText,
  category,
}: MarketplaceCategoryProductCardSectionFlatData): ReactElement<MarketplaceCategoryProductCardSectionFlatData> | null {
  const categoryData = useMarketplaceCategoryBySlug(category[0])

  const products = useSortOrderAndSliceCategoryProducts(categoryData, 4).filter(
    (item) => item !== undefined || null
  )
  const link = useLinkProperties(
    get(categoryData, "flatData.pages[0].flatData.url", "/")
  )
  const breakpoint = useBreakpoint("small", "large")

  if (isEmpty(products) || isEmpty(categoryData)) {
    return null
  }

  return (
    <SectionOrg
      key={categoryData.id}
      spacing="small"
      color="light"
      hasOverflowHidden={breakpoint}
    >
      <ContainerOrg>
        <AdvancedProductCardsTemplate
          heading={{
            title: categoryData.flatData.title,
          }}
          link={{
            text: linkText ? linkText : _t("showAll"),
            href: link.href,
            title: categoryData.flatData.name,
            onClick: link.onClick,
          }}
          products={categoryData.products}
        />
      </ContainerOrg>
    </SectionOrg>
  )
}

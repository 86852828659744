import type { ReactNode } from "react"
import React from "react"
import type { MarkdownToJSX } from "markdown-to-jsx"
import Markdown from "markdown-to-jsx"
import type Polyglot from "node-polyglot"
import trans, { hasValue } from "../../lib/core/i18n"

export function md(
  key: string,
  options?: Polyglot.InterpolationOptions,
  overrides?: MarkdownToJSX.Overrides,
  block: boolean = false
): ReactNode {
  return (
    <Markdown options={{ forceInline: !block, overrides: overrides }}>
      {trans(key, options)}
    </Markdown>
  )
}

export const _tu = (key: string): string | undefined =>
  hasValue(key) ? trans(key) : undefined

/* eslint-disable no-param-reassign */
import type { Draft } from "immer"
import { produce } from "immer"
import { uniqBy } from "lodash"
import { HTTP_STATUS } from "@onestore/onestore-store-common/http"
import { convertPeriodToBonusPeriods } from "@gatsby-plugin-bonus/lib/bonus-definitions"
import type { BonusDomainSearchBoxActions } from "@gatsby-plugin-bonus/store/actions-domain-search-box"
import { BonusDomainSearchActionType } from "@gatsby-plugin-bonus/store/actions-domain-search-box"
import type { BonusState } from "@gatsby-plugin-bonus/types"
import { initialState } from "./selectors"

export default function reducer(
  state: BonusState = initialState,
  action: BonusDomainSearchBoxActions
) {
  return produce(state, (nextState: Draft<BonusState>) => {
    switch (action.type) {
      case BonusDomainSearchActionType.BONUS_DOMAIN_SEARCH_REQUEST:
        if (!nextState.domainsSearch) {
          return
        }
        nextState.domainsSearch.status = HTTP_STATUS.CONTINUE
        nextState.domainsSearch.results = uniqBy([...action.domains], "fqdn")
        nextState.domainsSearch.bundleDomains = null

        return

      case BonusDomainSearchActionType.BONUS_DOMAIN_SEARCH_SUCCESS:
        if (!nextState.domainsSearch) {
          return
        }
        nextState.domainsSearch.status = HTTP_STATUS.OK
        nextState.domainsSearch.results = uniqBy([...action.domains], "fqdn")
        nextState.domainsSearch.nextStep = action.nextStep
        nextState.domainsSearch.bundleDomains = action.bundleDomains

        if (nextState.domain && state.domain) {
          const domain = action.domains.find(
            (domain) => domain.extension === state.domain?.alias
          )

          if (domain && domain.period) {
            nextState.domain.planId = domain.plan_id
            nextState.domain.periods = convertPeriodToBonusPeriods(
              [domain.period],
              1
            )

            nextState.domain.defaultPeriodName = domain.period.period_name
            nextState.domain.chosenPeriodId = domain.period.id
            nextState.domain.previousPeriodId = domain.period.id
          }
        }

        return

      case BonusDomainSearchActionType.BONUS_DOMAIN_SEARCH_FAILURE:
        if (!nextState.domainsSearch) {
          return
        }

        if (!nextState.domainsSearch) {
          return
        }
        nextState.domainsSearch.status = HTTP_STATUS.INTERNAL_SERVER_ERROR

        return
      case BonusDomainSearchActionType.BONUS_DOMAIN_SEARCH_HIDE:
        if (!nextState.domainsSearch) {
          return
        }
        nextState.domainsSearch.hideSearch = action.hideSearch
        break
      default:
        break
    }
  })
}

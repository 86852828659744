import type { ReactElement } from "react"
import React from "react"
import FloatingButtonMol from "@onestore/hel/dist/components/molecules/FloatingButtonMol"
import type { FloatingButtonMolCommonProps } from "@gatsby-plugin-generic-page/components/FloatingCtaElement"
import type { IconPositionKeys } from "@gatsby-plugin-generic-page/fragments/floatingCtaElementSection"
import { IconPosition } from "@gatsby-plugin-generic-page/fragments/floatingCtaElementSection"
import useAnchorProperties from "~/hooks/useAnchorProperties"

export type FloatingButtonWithAnchorProps = FloatingButtonMolCommonProps & {
  anchorId: string
  iconPosition?: IconPositionKeys
}

function FloatingButtonWithAnchor({
  title,
  text,
  anchorId,
  isVisible,
  icon,
  iconPosition,
}: FloatingButtonWithAnchorProps): ReactElement<FloatingButtonWithAnchorProps> {
  const { href, onClick } = useAnchorProperties(anchorId)

  return (
    <FloatingButtonMol
      icon={icon}
      iconPosition={iconPosition ? IconPosition[iconPosition] : undefined}
      title={title}
      text={text}
      href={href}
      onClick={onClick}
      isVisible={isVisible}
    />
  )
}

export default FloatingButtonWithAnchor

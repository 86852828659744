import { graphql } from "gatsby"

export interface SquidexTableWithContentRow {
  label: string
  values: {
    type: string
    text?: string
    isAvailable?: boolean
  }[]
}

export enum SquidexTableWithContentCellType {
  TEXT = "tekst",
  DEFAULT_ICONS = "znacznik wyboru",
}

export const query = graphql`
  fragment TableWithContentRow on GraphCmsSquidex_TableWithContentRowComponent {
    label
    values {
      type
      text
      isAvailable
    }
  }
`

export const FRAGMENT_TABLE_WITH_CONTENT_ROW = `
  fragment TableWithContentRow on TableWithContentRowComponent {
    label
    values {
      type
      text
      isAvailable
    }
  }
`

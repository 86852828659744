import type { ReactElement, ReactNode } from "react"
import React from "react"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import {
  priceAffixTypography,
  priceTypography,
} from "@onestore/hel/dist/components/molecules/PackageBoxSlimMol/PackageBoxSlimMol"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"

interface ProductBoxPriceProps {
  prefix?: string
  value: string
  suffix: string
  tooltip?: ReactNode
  hasPromotion?: boolean
  additionalText?: ReactNode
  hasDarkMode?: boolean
}

function ProductBoxPrice({
  prefix,
  value,
  suffix,
  tooltip,
  hasPromotion,
  additionalText,
  hasDarkMode,
}: ProductBoxPriceProps): ReactElement<ProductBoxPriceProps> {
  return (
    <PushOrg bottomSpace={2}>
      <FlexContainerOrg alignItems="baseline" wrap="wrap">
        {prefix ? (
          <TextAtm
            data-testid="product-box-price-prefix"
            typography={{
              small: priceAffixTypography.small,
              medium: priceAffixTypography.default,
              large: priceAffixTypography.default,
            }}
          >
            {`${prefix} `}
          </TextAtm>
        ) : null}

        <TextAtm
          color={hasPromotion && !hasDarkMode ? "primary" : undefined}
          data-testid="product-box-price-price"
          typography={{
            small: priceTypography.small,
            medium: priceTypography.default,
            large: priceTypography.default,
          }}
        >
          {value}
        </TextAtm>
      </FlexContainerOrg>

      <FlexContainerOrg alignItems="center" wrap="wrap">
        <TextAtm
          emphasis="medium"
          data-testid="product-box-price-suffix"
          pushSpace={{ right: 0.5 }}
          typography={{
            small: priceAffixTypography.small,
            medium: priceAffixTypography.default,
            large: priceAffixTypography.default,
          }}
        >
          {` ${suffix}`}
        </TextAtm>

        {tooltip}
      </FlexContainerOrg>

      {additionalText}
    </PushOrg>
  )
}

export default ProductBoxPrice

import { useCallback, useState, useEffect } from "react"
import isServer from "~/lib/isServer"

export function useConnectionCheck(imgSrc: string): boolean {
  const [websiteConnection, setWebsiteConnection] = useState(false)

  const checkInternetConnection = useCallback(() => {
    if (isServer()) {
      setWebsiteConnection(false)

      return
    }

    const TestImage = new Image()
    TestImage.onload = () => {
      setWebsiteConnection(true)
    }
    TestImage.onerror = () => {
      setWebsiteConnection(false)
    }
    TestImage.src = `${imgSrc}?${escape(Date())}`
  }, [])

  useEffect(() => {
    checkInternetConnection()
  }, [])

  return websiteConnection
}

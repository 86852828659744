import type { ReactElement } from "react"
import React from "react"
import { typeMatches } from "@onestore-graphql"
import TileMol from "@onestore/hel/dist/components/molecules/TileMol"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import GridOrg from "@onestore/hel/dist/components/organisms/GridOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import Header from "@gatsby-plugin-generic-page/components/Header"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import type { RecommendedProductSectionFlatData } from "@gatsby-plugin-generic-page/fragments/recommendedProductsSection"
import getLinkProps from "@gatsby-plugin-generic-page/helpers/getLinkProps"
import { SquidexComponentType } from "@gatsby-plugin-generic-page/types/componentType"
import assetsSquidexToImageObject from "~/lib/assetsSquidexToImageObject"
import isEmpty from "~/lib/isEmpty"
import type { ButtonElement } from "../fragments/buttonElement"

export default function RecommendedProductSection({
  header,
  cards,
  footer,
  sectionSettings,
}: RecommendedProductSectionFlatData): ReactElement<RecommendedProductSectionFlatData> {
  const cardsGridLargeColumns = cards.filter(
    (card) => card && !isEmpty(card.images)
  ).length
    ? 2
    : 3

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        {header ? (
          <PushOrg bottomSpace={{ small: 4, medium: 10, large: 10 }}>
            <AlignContentOrg
              horizontalAlign={{
                small: "left",
                medium: "center",
                large: "center",
              }}
            >
              <Header {...header} />
            </AlignContentOrg>
          </PushOrg>
        ) : null}

        <GridOrg
          columns={{ small: 1, medium: 2, large: cardsGridLargeColumns }}
          gutterSpace={{
            small: 4,
            medium: 4,
            large: 10,
          }}
        >
          {cards.map((card, index) => {
            let linkType, linkObject, button

            if (
              typeMatches(card.linkAction.__typename, SquidexComponentType.LINK)
            ) {
              linkType = SquidexComponentType.LINK
              linkObject = card.linkAction
            }

            if (
              typeMatches(
                card.linkAction.__typename,
                SquidexComponentType.BUTTON
              )
            ) {
              const { link, color, variant, isWider } =
                card.linkAction as ButtonElement

              linkType = SquidexComponentType.BUTTON
              linkObject = link

              button = {
                color,
                variant,
                isWider,
              }
            }

            const { text, title, to, href, openInNewTab, onClick } =
              linkObject && !isEmpty(linkObject) && getLinkProps(linkObject)

            return (
              <TileMol
                key={`${card.title}-${index}`}
                image={
                  card.images
                    ? assetsSquidexToImageObject(card.images, card.title)
                    : undefined
                }
                caption={card.caption}
                title={card.title}
                titleTypography={card.titleTypography?.typography}
                text={
                  card.text ? (
                    <MarkdownText
                      typography={{ bold: "small4", default: "small1" }}
                    >
                      {card.text}
                    </MarkdownText>
                  ) : null
                }
                footerText={text}
                linkType={linkType}
                to={to}
                href={href}
                hrefTitle={title}
                openInNewTab={openInNewTab}
                button={button}
                hasArrow={card.hasArrow}
                backgroundColor={card.backgroundColor?.background}
                isReversed={card.isReversed}
                onClick={onClick}
              />
            )
          })}
        </GridOrg>

        {footer ? (
          <PushOrg topSpace={6}>
            <AlignContentOrg horizontalAlign="center">
              <MarkdownText>{footer}</MarkdownText>
            </AlignContentOrg>
          </PushOrg>
        ) : null}
      </BaseContainer>
    </BaseSection>
  )
}

import React from "react"
import find from "lodash/find"
import get from "lodash/get"
import Checkout from "@gatsby-plugin-checkout/layouts/Checkout"
import ExternalScripts from "~/components/ExternalScripts"
import Base from "~/layouts/Base"
import { store } from "~/store"
import { typeMatches } from "../../lib/onestore-graphql"
import { SectionType } from "../../plugins/gatsby-plugin-generic-page/src/types/sectionType"
import isEmpty from "../lib/isEmpty"

export function createWrapperWithStore(wrapper) {
  return wrapper(store)
}

export function wrapWithLayout(element, props, ignoredPaths = []) {
  if (find(ignoredPaths, (item) => item === props.location.pathname)) {
    return addExternalScripts(element, props)
  }

  const sections = get(props, "data.GraphCmsSquidex.page.flatData.sections", [])
  const hasParallaxSection = !isEmpty(sections)
    ? sections.some((section) =>
        typeMatches(section.__typename, SectionType.PARALLAX_SECTION)
      )
    : false

  const hasFullScreenContainerSection = !isEmpty(sections)
    ? sections.some((section) =>
        typeMatches(
          section.__typename,
          SectionType.FULL_SCREEN_CONTAINER_SECTION
        )
      )
    : false

  return (
    <Base
      hasParallaxWrapper={
        hasParallaxSection ? !hasFullScreenContainerSection : false
      }
      {...props}
    >
      {addExternalScripts(element, props)}
    </Base>
  )
}

export function addExternalScripts(element, props) {
  const page = get(
    props,
    "data.GraphCmsSquidex.pages.0",
    get(
      props,
      "data.GraphCmsSquidex.productPages.0",
      get(props, "data.GraphCmsSquidex.websites.flatData.pages.0")
    )
  )

  if (!page) {
    return element
  }

  return (
    <>
      <ExternalScripts page={page} />
      {element}
    </>
  )
}

export function wrapWithCheckoutLayout(element, props) {
  // Na podstronach niepowodzenie/dziekujemy CheckoutContext jest zbędny bo działamy na oldToken
  return (
    <Checkout
      skipBasketContext={props.pageContext.skipBasketContext || false}
      {...props}
    >
      {element}
    </Checkout>
  )
}

import type { ReactElement, SyntheticEvent } from "react"
import React from "react"
import _t from "@core/i18n"
import TableMol from "@onestore/hel/dist/components/molecules/TableMol"
import type { BonusUrl } from "~/lib/url"
import url from "~/lib/url"

interface ButtonProps {
  bonus?: BonusUrl
  name: string
  onClick?: { (event: SyntheticEvent): void }
  text?: string
  isDisabled?: boolean
}

export function Button({
  bonus,
  name,
  onClick,
  text,
  isDisabled,
}: ButtonProps): ReactElement {
  const continueUrl = bonus ? url.generateBonusUrl(bonus) : undefined

  return (
    <TableMol.FooterButton
      href={continueUrl}
      onClick={onClick}
      title={name}
      rel="nofollow"
      text={text ?? _t("marketplace.chooseButton")}
      isDisabled={isDisabled}
    />
  )
}

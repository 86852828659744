import type { ReactElement } from "react"
import React, { useState } from "react"
import _t from "@core/i18n"
import CaretDown from "@onestore-icons/caret-down.svg"
import CaretUp from "@onestore-icons/caret-up.svg"
import IconLinkAtm from "@onestore/hel/dist/components/atoms/IconLinkAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import UnderlineLinkAtm from "@onestore/hel/dist/components/atoms/UnderlineLinkAtm"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import DomainSearchResultBoxOrg from "@onestore/hel/dist/components/organisms/DomainSearchResultBoxOrg"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import SpaceBetweenOrg from "@onestore/hel/dist/components/organisms/SpaceBetweenOrg"
import WidthOrg from "@onestore/hel/dist/components/organisms/WidthOrg"
import WrapOrg from "@onestore/hel/dist/components/organisms/WrapOrg"
import Picture from "@onestore/hel/dist/components/quarks/Picture"
import ConditionalWrapper from "@onestore/hel/dist/helpers/ConditionalWrapper"
import useBreakpoint from "@onestore/hel/dist/hooks/useBreakpoint"
import type { DomainSearchResultProduct } from "@gatsby-plugin-domain-search/hooks/useDomainSearchProducts"
import { useDomainSearchProducts } from "@gatsby-plugin-domain-search/hooks/useDomainSearchProducts"
import { COMPONENT_ID } from "~/types/component"

interface ProductProps {
  product: DomainSearchResultProduct
}

function ProductPrice({ product }: ProductProps) {
  const isMediumUp = useBreakpoint("medium")

  return (
    <SpaceBetweenOrg align="center">
      <PushOrg rightSpace={4}>
        <TextAtm typography="medium3_h" pushSpace={0.5}>
          {product.name}
        </TextAtm>

        {isMediumUp ? (
          <TextAtm typography="xsmall1">{product.description}</TextAtm>
        ) : null}
      </PushOrg>

      {isMediumUp ? (
        <WidthOrg widthSpace={22.5}>
          {product.price ? (
            <>
              <TextAtm typography="medium4_s">
                <TextAtm typography="xsmall1">{product.price.prefix}</TextAtm>{" "}
                {product.price.integer}
                {product.price.fraction} {product.price.currency}
              </TextAtm>
              <TextAtm typography="xsmall1">{product.price.suffix}</TextAtm>
            </>
          ) : null}
        </WidthOrg>
      ) : null}
    </SpaceBetweenOrg>
  )
}

function ProductAside({ product }: ProductProps) {
  return (
    <WrapOrg gutterSpace={2} alignCenter="vertical">
      <UnderlineLinkAtm
        to={product.url}
        typography="xsmall3"
        title={_t("viewMore")}
      >
        {_t("viewMore")}
      </UnderlineLinkAtm>
    </WrapOrg>
  )
}

function ProductImage({ product }: ProductProps) {
  return (
    <Picture
      srcData={{
        src: product.logo,
        width: 48,
        height: 48,
        alt: product.name,
      }}
    />
  )
}

function MainProductPrice({ product }: ProductProps): ReactElement {
  const isMediumUp = useBreakpoint("medium")

  return (
    <ConditionalWrapper
      condition={!isMediumUp}
      wrapper={(children) => (
        <FlexContainerOrg justifyContent="space-between" alignItems="center">
          {children}
        </FlexContainerOrg>
      )}
    >
      <ConditionalWrapper
        condition={!isMediumUp}
        wrapper={(children) => <div>{children}</div>}
      >
        {!isMediumUp && <ProductImage product={product} />}

        <SpaceBetweenOrg align="center">
          <PushOrg rightSpace={4}>
            <TextAtm typography="medium3_h" pushSpace={0.5}>
              {product.name}
            </TextAtm>

            {isMediumUp ? (
              <TextAtm typography="xsmall1">{product.description}</TextAtm>
            ) : null}
          </PushOrg>

          {isMediumUp ? (
            <WidthOrg widthSpace={22.5}>
              {product.oldPrice ? (
                <TextAtm
                  typography="tiny1"
                  emphasis="medium"
                  hasLineThrough
                  pushSpace={0.5}
                >
                  {product.oldPrice}
                </TextAtm>
              ) : null}

              {product.price ? (
                <>
                  <TextAtm typography="medium4_s">
                    <TextAtm typography="xsmall1">
                      {product.price.prefix}
                    </TextAtm>{" "}
                    {product.price.integer}
                    {product.price.fraction} {product.price.currency}
                  </TextAtm>
                  <TextAtm typography="xsmall1">{product.price.suffix}</TextAtm>
                </>
              ) : null}
            </WidthOrg>
          ) : null}
        </SpaceBetweenOrg>
      </ConditionalWrapper>

      {!isMediumUp && (
        <UnderlineLinkAtm
          to={product.url}
          typography="xsmall3"
          title={_t("viewMore")}
        >
          {_t("viewMore")}
        </UnderlineLinkAtm>
      )}
    </ConditionalWrapper>
  )
}

export default function ProductResultBox(): ReactElement | null {
  const isMediumUp = useBreakpoint("medium")

  const [showMore, setShowMore] = useState(false)

  const { mainProduct, moreProducts, count } = useDomainSearchProducts()

  if (count === 0 || !mainProduct) {
    return null
  }

  return (
    <PushOrg topSpace={5}>
      <div id={COMPONENT_ID.DOMAIN_SEARCH_PRODUCT_RESULTS}>
        <PushOrg bottomSpace={2}>
          <TextAtm typography="medium3_s">Czy chodziło Ci o:</TextAtm>
        </PushOrg>

        <DomainSearchResultBoxOrg
          image={<ProductImage product={mainProduct} />}
          main={<MainProductPrice product={mainProduct} />}
          aside={isMediumUp ? <ProductAside product={mainProduct} /> : null}
          subItems={
            showMore
              ? moreProducts.map((product) => ({
                  key: product.id,
                  label: <ProductImage product={product} />,
                  main: <ProductPrice product={product} />,
                  aside: <ProductAside product={product} />,
                }))
              : []
          }
          footer={
            moreProducts.length > 1 ? (
              <PushOrg topSpace={2}>
                <AlignContentOrg horizontalAlign="center">
                  <IconLinkAtm
                    icon={showMore ? CaretUp : CaretDown}
                    iconPosition="left"
                    iconAlign="center"
                    sizeSpace={2}
                    title={
                      showMore
                        ? "Ukryj pozostałe produkty"
                        : "Pokaż więcej produktów"
                    }
                    text={
                      showMore
                        ? "Ukryj pozostałe produkty"
                        : "Pokaż więcej produktów"
                    }
                    typography="buttonSmall"
                    onClick={() => {
                      setShowMore(!showMore)
                    }}
                    href="#"
                  />
                </AlignContentOrg>
              </PushOrg>
            ) : undefined
          }
        />
      </div>
    </PushOrg>
  )
}

import React from "react"
import type { ReactElement, SyntheticEvent } from "react"
import _t from "@core/i18n"
import Tick from "@onestore-icons/tick.svg"
import type { ButtonAtmProps } from "@onestore/hel/dist/components/atoms/ButtonAtm"
import ButtonAtm from "@onestore/hel/dist/components/atoms/ButtonAtm"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import ConditionalWrapper from "@onestore/hel/dist/helpers/ConditionalWrapper"
import useBasketActions from "@gatsby-plugin-basket/hooks/useBasketActions"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import ProductPrice from "../ProductPrice"

export interface BasketButtonProps extends ButtonAtmProps {
  plan: CloudBluePlan[]
  hasSurface?: boolean
  hidePrice?: boolean
}

function BasketButton({
  onClick,
  href,
  title,
  text,
  color,
  variant,
  size,
  hidePrice,
  hasSurface,
  plan,
  rel,
  ...restProps
}: BasketButtonProps): ReactElement<BasketButtonProps> {
  const { addProductToBasket, isLoading, isDone, goToBasket } =
    useBasketActions(plan[0])

  const handleOnClick = (event: SyntheticEvent) => {
    event.preventDefault()

    if (onClick) {
      onClick(event)
    }

    addProductToBasket()
  }

  const priceElement = (
    <ProductPrice
      hasTextColor
      plan={plan}
      priceTypography={{
        large: "big3",
        medium: "big3",
        small: "small3",
      }}
      currencyTypography={{
        large: "nano1",
        medium: "nano1",
        small: "xsmall2",
      }}
      suffixTypography={{
        large: "nano2",
        medium: "nano2",
        small: "nano1",
      }}
    />
  )

  return (
    <ConditionalWrapper
      condition={!hidePrice}
      wrapper={(children) => (
        <FlexContainerOrg
          alignItems="center"
          display="flex"
          justifyContent="space-between"
        >
          <PushOrg
            rightSpace={{ small: 1, medium: 1, large: 2 }}
            leftSpace={{ large: 0, medium: 0, small: 1 }}
            hasNoReset
          >
            {priceElement}
          </PushOrg>

          {children}
        </FlexContainerOrg>
      )}
    >
      <ButtonAtm
        onClick={isDone ? goToBasket : handleOnClick}
        isLoading={isLoading}
        title={isDone ? _t("button.genericPageSuccess") : title}
        color={color}
        icon={isDone ? Tick : undefined}
        variant={variant}
        size={size}
        rel={rel}
        {...restProps}
      >
        {isDone ? _t("preBasket.upsell.added") : text}
      </ButtonAtm>
    </ConditionalWrapper>
  )
}

export default BasketButton

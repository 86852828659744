import React from "react"
import Tick from "@onestore-icons/tick.svg"
import IconAtm from "@onestore/hel/dist/components/atoms/IconAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import FlexItemOrg from "@onestore/hel/dist/components/organisms/FlexItemOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import WrapOrg from "@onestore/hel/dist/components/organisms/WrapOrg"

export default function ListItem({ children }) {
  return (
    <WrapOrg gutterSpace={1}>
      <FlexContainerOrg alignItems="center">
        <FlexItemOrg flexShrink={0}>
          <IconAtm icon={Tick} sizeSpace={2} color="success" />
        </FlexItemOrg>

        <PushOrg leftSpace={1}>
          <TextAtm typography="small1">{children}</TextAtm>
        </PushOrg>
      </FlexContainerOrg>
    </WrapOrg>
  )
}

import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import { PeriodInfo } from "@core/period-info"
import type { DomainCheck } from "@onestore/api/domainSearch"
import LabelAtm from "@onestore/hel/dist/components/atoms/LabelAtm"
import DomainNameMol from "@onestore/hel/dist/components/molecules/DomainNameMol"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import useBreakpoint from "@onestore/hel/dist/hooks/useBreakpoint"
import type Theme from "@onestore/hel/dist/typings/theme"
import type { DomainPromoLabel } from "@gatsby-plugin-domain-search/types"
import LimitTooltipLabel from "~/components/LimitTooltipLabel"
import { useCloudblueDomainInfo } from "~/hooks/useCloudblueDomainInfo"

interface DomainNameProps {
  domain: DomainCheck.Result
  label?: DomainPromoLabel | null
  extensionColor?: Theme.AccentColor
}

export function DomainName({
  domain,
  label,
  extensionColor = "success",
}: DomainNameProps): ReactElement {
  const info = domain.period ? new PeriodInfo(domain.period) : null
  const isSmallOnly = useBreakpoint("small", "medium")
  const hasPromotion = info?.hasPromotion() ?? false
  const hasLimitTooltip = info?.hasLimit() ?? false
  const { limitTooltip } = useCloudblueDomainInfo(domain.plan_id)

  return (
    <>
      {label || hasPromotion || hasLimitTooltip ? (
        <PushOrg bottomSpace={1}>
          <FlexContainerOrg gapSpace={1} wrap="wrap">
            {label || hasPromotion ? (
              <LabelAtm size="small" variant="notifyLight" emphasis="high">
                {hasPromotion
                  ? _t("marketplace.discountTitle")
                  : label?.shortLabel}
              </LabelAtm>
            ) : null}

            {hasLimitTooltip && limitTooltip ? (
              <LimitTooltipLabel tooltipText={limitTooltip} />
            ) : null}
          </FlexContainerOrg>
        </PushOrg>
      ) : null}

      <PushOrg bottomSpace={0.5}>
        <DomainNameMol
          typography="large2"
          align={isSmallOnly ? "left" : undefined}
          name={domain.name}
          extension={domain.extension}
          extensionColor={extensionColor}
          isExtensionBolded
        />
      </PushOrg>
    </>
  )
}

/*
 * Parsuje parametry  urla ze specyfikacją przedkoszyka.
 *
 * @param {string} parameters Ciąg znaków zawierający listę parametrów

 * @returns {object} Sprawsowane dane produktu w postaci aliasu i opcjonalnego szablonu.
 */
import { ALIAS_REGEX } from "./regex"
import { AliasParameters, AliasProductData } from "./index"

export function parseParameters(parameters: string): AliasParameters {
  const result = {}
  parameters.split(";").forEach((param) => {
    const params = param.split(":")
    if (params.length === 2) {
      const [property, value] = params
      result[property] = value
    }
  })

  return result
}

/**
 * Parsuje dany element urla ze specyfikacją przedkoszyka.
 *
 * @param {object} data Element urla ze specyfikacją produktu przedkoszyka

 * @returns {object} Sprawsowane dane produktu w postaci aliasu i opcjonalnego szablonu.
 */
export function parseProductData(data: string): AliasProductData {
  const [, alias, parameters, template] = ALIAS_REGEX.exec(data) as string[]

  const result: AliasProductData = {
    alias,
  }

  if (template) {
    result.template = template
  }

  if (parameters) {
    result.parameters = parseParameters(parameters)
  }

  return result
}

import type { ReactElement } from "react"
import React from "react"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import SurfaceOrg from "@onestore/hel/dist/components/organisms/SurfaceOrg"
import ConditionalWrapper from "@onestore/hel/dist/helpers/ConditionalWrapper"

interface PriceWithButtonWrapperProps {
  children: ReactElement
  hasSurface: boolean
}

export default function PriceWithButtonWrapper({
  children,
  hasSurface,
}: PriceWithButtonWrapperProps): ReactElement<PriceWithButtonWrapperProps> {
  return (
    <ConditionalWrapper
      condition={!!hasSurface}
      wrapper={(child) => (
        <>
          <BreakpointVisibilityOrg from="large">
            <SurfaceOrg
              display="inline-block"
              spacingSpace={2}
              shadow="small"
              color="backgroundLight"
            >
              {child}
            </SurfaceOrg>
          </BreakpointVisibilityOrg>

          <BreakpointVisibilityOrg from="small" to="large">
            <SurfaceOrg
              display="block"
              spacingSpace={1}
              shadow="small"
              color="backgroundLight"
            >
              {child}
            </SurfaceOrg>
          </BreakpointVisibilityOrg>
        </>
      )}
    >
      {children}
    </ConditionalWrapper>
  )
}

import _t from "@core/i18n"
import { formatDiscountPercent } from "@core/pricing"
import { getCurrencySign } from "@core/pricing"
import { PriceType } from "@core/types"
import type { StaticPrice } from "~/fragments/staticPrice"
import { parsePeriod } from "../../../../lib/api"

export interface StaticPrices {
  price: {
    prefix?: string
    value: string
    suffix: string
  }
  renewPrice?: {
    value: string
    suffix: string
  }
}

export interface StaticRegularPrices {
  price: string
  percent: string
}

export function getStaticPrices(
  staticPrices: StaticPrice[],
  priceType,
  hideCurrency?: boolean
): Record<string, StaticPrices> {
  const hasNettoPriceType = priceType === PriceType.NETTO

  const pricesSecondary: Record<string, StaticPrices> = {}

  for (const price in staticPrices) {
    const {
      period,
      prefix,
      registerNetto,
      registerGross,
      renewalGross,
      renewalNetto,
    } = staticPrices[price].flatData

    const periodInfo = parsePeriod(period)

    const periodName =
      periodInfo.periodValue === 0
        ? _t("periodName.oneTimeFee")
        : _t(`periodName.searchDomainPrice.${periodInfo.periodType}`, {
            smart_count: periodInfo.periodValue,
          })

    pricesSecondary[period] = {
      price: {
        prefix,
        value: `${
          hasNettoPriceType
            ? convertPrice(registerNetto)
            : convertPrice(registerGross)
        } ${hideCurrency ? "" : getCurrencySign()}`,
        suffix: `${_t(`prices.${priceType}`)} ${periodName}`,
      },
      renewPrice:
        renewalNetto && renewalGross
          ? {
              value: `${
                hasNettoPriceType
                  ? convertPrice(renewalNetto)
                  : convertPrice(renewalGross)
              } ${hideCurrency ? "" : getCurrencySign()}`,
              suffix: `${_t(`prices.${priceType}`)} ${periodName}`,
            }
          : undefined,
    }
  }

  return pricesSecondary
}

export function getSecondaryPrices(staticPrices: StaticPrice[], priceType) {
  const pricesSecondary = {}

  for (const price in staticPrices) {
    const { period, promoGross, promoNetto } = staticPrices[price].flatData

    if (!promoGross || !promoNetto) {
      return
    }

    pricesSecondary[period] =
      priceType === PriceType.NETTO ? promoNetto : promoGross
  }

  return pricesSecondary
}

export function getStaticRegularPrice(
  staticPrices: StaticPrice[],
  priceType,
  hideCurrency?: boolean
): Record<string, StaticRegularPrices> | undefined {
  const hasNettoPriceType = priceType === PriceType.NETTO

  const regularPrice = {}

  for (const price in staticPrices) {
    const { period, promoGross, promoNetto, regularPricePercent } =
      staticPrices[price].flatData

    if (!promoGross || !promoNetto) {
      return
    }

    regularPrice[period] = {
      price: `${
        hasNettoPriceType ? convertPrice(promoNetto) : convertPrice(promoGross)
      } ${hideCurrency ? "" : getCurrencySign()}`,
      percent: formatDiscountPercent(regularPricePercent || 0),
    }
  }

  return regularPrice
}

export function getStaticLowestPrice(
  staticPrices: StaticPrice[],
  priceType,
  hideCurrency?: boolean
): Record<string, StaticRegularPrices> | undefined {
  const hasNettoPriceType = priceType === PriceType.NETTO

  const lowestPrice = {}

  for (const price in staticPrices) {
    const { period, lowestPriceGross, lowestPriceNetto, lowestPricePercent } =
      staticPrices[price].flatData

    if (!lowestPriceGross || !lowestPriceNetto) {
      return
    }

    lowestPrice[period] = {
      price: `${
        hasNettoPriceType
          ? convertPrice(lowestPriceNetto)
          : convertPrice(lowestPriceGross)
      } ${hideCurrency ? "" : getCurrencySign()}`,
      percent: formatDiscountPercent(lowestPricePercent || 0),
    }
  }

  return lowestPrice
}

export function convertPrice(price: number): string {
  return price.toFixed(2).replace(".", ",")
}

import type { ReactNode } from "react"
import React from "react"
import { connect, mapProps } from "@formily/react"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"

type CardProps = {
  title?: string
  extra?: string
  children: ReactNode
}

const CardLayout = ({ title, extra, children }: CardProps) => {
  return (
    <PushOrg bottomSpace={6}>
      {title ? (
        <TextAtm typography="large3" pushSpace={extra ? 1 : 4}>
          {title}
        </TextAtm>
      ) : null}

      {extra ? (
        <TextAtm typography="small2" pushSpace={4}>
          {extra}
        </TextAtm>
      ) : null}

      {children}
    </PushOrg>
  )
}

const Card = connect(
  CardLayout,
  mapProps({}, (props: CardProps) => {
    return {
      title: props.title,
      extra: props.extra,
      children: props.children,
    }
  })
)

export default Card

import type { ReactElement, ReactNode } from "react"
import React from "react"
import type { NestedLinkAtmProps } from "@onestore/hel/dist/components/atoms/NestedLinkAtm"
import NestedLinkAtm from "@onestore/hel/dist/components/atoms/NestedLinkAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import type Theme from "@onestore/hel/dist/typings/theme"
import hasTelOrMailInLink from "@gatsby-plugin-generic-page/helpers/hasTelOrMailInLink"

export interface NestedLinkProps extends NestedLinkAtmProps {
  typography: Theme.Typography
  children: ReactNode
}

function NestedLink({
  typography,
  to,
  href,
  ...restProps
}: NestedLinkProps): ReactElement<NestedLinkProps> {
  const hrefWithMailOrTel = hasTelOrMailInLink(to)

  return (
    <TextAtm typography={typography}>
      <NestedLinkAtm
        to={hrefWithMailOrTel ? undefined : to}
        href={hrefWithMailOrTel || href}
        {...restProps}
      />
    </TextAtm>
  )
}

export default NestedLink

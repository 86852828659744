import { useMemo, useState } from "react"

export interface UseTextToBeCopied {
  isButtonClicked: boolean
  textToCopy: string
  onButtonCLick: () => void
}

function useTextToBeCopied(
  textsToBeCopied: {
    text: string
  }[]
): UseTextToBeCopied {
  const [isButtonClicked, setIsButtonClicked] = useState(false)
  const handleButtonClick = () => setIsButtonClicked(!isButtonClicked)

  const copyStringToClipboard = (text: string) =>
    navigator.clipboard.writeText(text)

  const textToCopy = useMemo(
    () =>
      textsToBeCopied[Math.floor(Math.random() * textsToBeCopied.length)].text,
    [textsToBeCopied]
  )

  const onButtonCLick = () => {
    !isButtonClicked && copyStringToClipboard(textToCopy)
    handleButtonClick()
  }

  return { isButtonClicked, textToCopy, onButtonCLick }
}

export default useTextToBeCopied

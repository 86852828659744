import { areRequestsPending, areRequestsFailed } from "./http"
import { HTTP } from "."

export function scrollToTop(): void {
  window.scroll(0, 0)
}

/**
 * Funkcja dodająca potwierdzenie podczas kliknięcia odśwież lub zamknij.
 *
 * @function
 * @param {string} message Opcjonalna wiadomość.
 */
export function addCloseConfirmation(message: string): void {
  // eslint-disable-next-line
  window.onbeforeunload = () => confirm(message)
}

/**
 * Funkcja usuwająca potwierdzenie odświeżenia/zamknięcia strony.
 *
 * @function
 */
export function removeCloseConfirmation(): void {
  window.onbeforeunload = null
}

/**
 * Funkcja zwracająca status komponentu na podstawie stanów przekazanych żądań HTTP.
 *
 * @function
 * @param {array} requests Tablica zawierająca statusy żądań.
 * @param {array} acceptableStatuses Tablica zawierająca zwracane statusy odpowiedzi.
 * @returns {string} status komponentu.
 */
export function getComponentStatusForRequests(
  requests: HTTP.Status[],
  acceptableStatuses: string[] = ["error", "loading"]
): "" | "error" | "loading" {
  if (
    -1 !== acceptableStatuses.indexOf("loading") &&
    areRequestsPending(requests)
  ) {
    return "loading"
  }

  if (
    -1 !== acceptableStatuses.indexOf("error") &&
    areRequestsFailed(requests)
  ) {
    return "error"
  }

  return ""
}

import { useCallback, useState } from "react"
import {
  addItemsToBasket,
  BasketActionSource,
} from "@gatsby-plugin-basket/store/actions"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import { getSelectedPeriod } from "~/lib/plan"
import { useAppDispatch } from "~/store/hooks"
import type { PeriodName } from "~/types/squidex"

interface ProductAddToBasketHook {
  isLoading: boolean
  addProduct: {
    (plan: CloudBluePlan["flatData"], onSuccess: { (): void }): void
  }
}
export default function useProductAddToBasket(
  id: string,
  selectedPeriod?: PeriodName
): ProductAddToBasketHook {
  const [isLoading, setLoading] = useState(false)
  const dispatch = useAppDispatch()

  const addProduct = useCallback(
    (plan: CloudBluePlan["flatData"], onSuccess: { (): void }) => {
      const periodDefinitions = getSelectedPeriod(plan, selectedPeriod)

      setLoading(true)
      dispatch(
        addItemsToBasket(
          [
            {
              plan: plan.id,
              planPeriod: periodDefinitions.id,
              quantity: 1,
            },
          ],
          `async-button-${id}`,
          BasketActionSource.EMPTY,
          () => {
            onSuccess()
            setLoading(false)
          }
        )
      )
    },
    [id]
  )

  return {
    isLoading,
    addProduct,
  }
}

import type { ReactElement } from "react"
import React from "react"
import type { FieldInputProps, FieldMetaState } from "react-final-form"
import InputMol from "@onestore/hel/dist/components/molecules/InputMol"
import type { Type } from "@onestore/hel/dist/components/molecules/InputMol/InputMol.types"
import { useFormFieldError } from "~/hooks/useFormFieldError"

interface TextRowFieldProps {
  input: FieldInputProps<string>
  meta: FieldMetaState<string>
  placeholder: string
  type?: Type
}

function TextRowField({
  input,
  meta,
  placeholder,
  type = "text",
}: TextRowFieldProps): ReactElement<TextRowFieldProps> {
  const { hasError, errorMessage, errorPlaceholder } = useFormFieldError(
    input,
    meta
  )

  return (
    <InputMol
      {...input}
      hasError={hasError}
      type={type}
      errorMessage={errorMessage}
      placeholder={errorPlaceholder || placeholder}
    />
  )
}

export default React.memo(TextRowField)

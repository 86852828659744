import React from "react"
import type { ReactElement } from "react"
import _t from "@core/i18n"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import type { Props as CountdownMolProps } from "@onestore/hel/dist/components/molecules/CountdownMol"
import CountdownMol from "@onestore/hel/dist/components/molecules/CountdownMol"
import SurfaceOrg from "@onestore/hel/dist/components/organisms/SurfaceOrg"
import ConditionalWrapper from "@onestore/hel/dist/helpers/ConditionalWrapper"
import type { CountdownType } from "@gatsby-plugin-generic-page/fragments/countdown"
import getBackground from "@gatsby-plugin-generic-page/helpers/getBackground"
import isEmpty from "~/lib/isEmpty"
import useCountdown from "../../hooks/useCountdown"

type Props = Omit<CountdownType, "__typename"> & {
  labelTypography?: CountdownMolProps["labelTypography"]
  digitTypography?: CountdownMolProps["digitTypography"]
}

function Countdown({
  from,
  to,
  textBefore,
  text,
  textAfter,
  labelTypography,
  digitTypography,
  isDark,
  isSmall,
  surfaceBackground,
  hasTransparentSurface,
  hasBorder,
}: Props): ReactElement<Props> {
  const { days, hours, minutes, seconds } = useCountdown(to)

  const currentTime = new Date()
  const startTime = new Date(from)
  const endTime = new Date(to)

  const isBeforeStart = startTime > currentTime
  const hasStarted = currentTime > startTime && currentTime < endTime
  const hasFinished = currentTime > endTime

  const { backgroundThemeColor, customColorFromPicker } =
    getBackground(surfaceBackground)

  const countdownJsx = (
    <ConditionalWrapper
      condition={!isEmpty(surfaceBackground) || !isEmpty(hasBorder)}
      wrapper={(children) => (
        <SurfaceOrg
          spacingSpace={{ vertical: 2, horizontal: 3 }}
          color={backgroundThemeColor}
          customColor={customColorFromPicker}
          colorEmphasis={hasTransparentSurface ? 0.1 : undefined}
          backgroundBlurUnit={hasTransparentSurface ? 20 : undefined}
          hasBorder={hasBorder}
          borderWidth={1}
          borderRadiusSize="xlarge"
          borderEmphasis="extraLow"
        >
          {children}
        </SurfaceOrg>
      )}
    >
      {text ? (
        <TextAtm typography="medium3_h" pushSpace={1}>
          {text}
        </TextAtm>
      ) : null}

      <CountdownMol
        values={[
          {
            value: hasFinished ? "0" : days,
            label: `${_t("time.days")}`,
          },
          {
            value: hasFinished ? "0" : hours,
            label: `${_t("time.hours")}`,
          },
          {
            value: hasFinished ? "0" : minutes,
            label: `${_t("time.minutes")}`,
          },
          {
            value: hasFinished ? "0" : seconds,
            label: `${_t("time.seconds")}`,
          },
        ]}
        labelTypography={labelTypography}
        digitTypography={digitTypography}
        digitBackground={!!isDark ? "customDark" : "customLight"}
        digitColor={!!isDark ? "white" : "dark"}
        isSmall={isSmall}
      />
    </ConditionalWrapper>
  )

  if (isBeforeStart) {
    return <TextAtm typography="medium3_h">{textBefore}</TextAtm>
  } else if (hasStarted) {
    return countdownJsx
  } else {
    return <TextAtm typography="medium3_h">{textAfter}</TextAtm>
  }
}

export default Countdown

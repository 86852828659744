import type { ReactElement } from "react"
import React from "react"
import type { MarkdownToJSX } from "markdown-to-jsx"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import { backgrounds } from "@onestore/hel/dist/components/organisms/SectionOrg/SectionOrg.settings"
import SurfaceOrg from "@onestore/hel/dist/components/organisms/SurfaceOrg"
import ConditionalWrapper from "@onestore/hel/dist/helpers/ConditionalWrapper"
import getBaseMarkdownOverrides from "@gatsby-plugin-generic-page/helpers/getBaseMarkdownOverrides"
import isEmpty from "~/lib/isEmpty"
import type { Paragraph as ParagraphType } from "~/types/squidex"
import MarkdownCaption from "../Markdown/Caption"
import MarkdownText from "../Markdown/Text"
import MarkdownTitle from "../Markdown/Title"

interface Props extends ParagraphType {
  markdownOverrides?: MarkdownToJSX.Overrides
}

function Paragraph({
  caption,
  title,
  text,
  backgroundColor,
  markdownOverrides,
}: Props): ReactElement<Props> {
  return (
    <ConditionalWrapper
      condition={!isEmpty(backgroundColor)}
      wrapper={(children) => (
        <SurfaceOrg
          color={backgroundColor ? backgrounds[backgroundColor] : undefined}
          spacingSpace={3}
        >
          {children}
        </SurfaceOrg>
      )}
    >
      {caption ? <MarkdownCaption>{caption}</MarkdownCaption> : null}

      {title ? (
        <PushOrg bottomSpace={1}>
          <MarkdownTitle
            overrides={
              markdownOverrides ||
              getBaseMarkdownOverrides({
                typography: "big3",
                pushSpace: 1,
              })
            }
          >
            {title}
          </MarkdownTitle>
        </PushOrg>
      ) : null}

      <MarkdownText>{text}</MarkdownText>
    </ConditionalWrapper>
  )
}

export default Paragraph

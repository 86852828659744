import { globalHistory } from "@reach/router"
import type {
  BasketResponse,
  BasketLastItem,
  BasketResponseResource,
} from "@onestore/api/basket"
import type { BasketStateItem } from "@onestore/onestore-store-common"
import type { BasketViewItem } from "@gatsby-plugin-basket/types"
import type { CardItem } from "~/lib/dataLayer"

export function getLastItemsFromBasket(
  basket: BasketResponse,
  withResources: boolean = false
): BasketLastItem[] {
  const lastItems: BasketLastItem[] = []
  basket.items.forEach((item) => {
    if (item.last_patch_operation_flag) {
      lastItems.push(item)
    }
    item.children.forEach((child) => {
      if (child.last_patch_operation_flag) {
        lastItems.push(child)
      }

      if (withResources) {
        child.resources.forEach((subChild: BasketResponseResource) => {
          if (subChild.last_patch_operation_flag) {
            lastItems.push(subChild)
          }
        })
      }
    })

    if (withResources) {
      item.resources.forEach((child) => {
        if (child.last_patch_operation_flag) {
          lastItems.push(child)
        }
      })
    }
  })

  return lastItems
}

export function getBasketItemValues(
  itemId: number,
  basketItems: BasketStateItem[]
) {
  const items = {}

  basketItems.forEach((item) => {
    items[item.id] = {
      item_id: item.sku,
      name: item.name,
      price: item.price,
      quantity: item.quantity,
      affiliation: globalHistory.location.hostname
        ? globalHistory.location.hostname
        : undefined,
      item_category: item.definition_type,
      item_variant: item.period_name,
    }
    item.children.forEach((subItem) => {
      items[subItem.id] = {
        item_id: subItem.sku,
        name: subItem.name,
        price: subItem.price,
        quantity: subItem.quantity,
      }
    })
  })
  items[itemId].index = getBasketItemIndex(basketItems, itemId)

  return items[itemId] || ""
}

export function getBasketItemIndex(
  items: BasketStateItem[] | BasketViewItem[] | CardItem[],
  itemId: number
): number {
  const result = items.findIndex((item) => {
    return item.id === itemId
  })

  return result
}

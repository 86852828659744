import { createSelector } from "reselect"
import type { ProductAlias } from "@onestore/api/types"
import { getProductAlias } from "@gatsby-plugin-bonus/store/selectors"
import type { BonusResourceCategory } from "@gatsby-plugin-bonus/types"
import { resourceCategoryNormalizer } from "@gatsby-plugin-definitions/lib/normalizers"
import type { AppState } from "~/store/reducer"
import type { ProductDefinition } from "./actions"
import type { ProductDefinitionsState } from "./reducer"

export const initialState: ProductDefinitionsState = {
  request: {},
  data: {},
}

const getDefinitionsFrom = (state: AppState): ProductDefinitionsState =>
  state.definitions

export const getAdvancedProductDefinitions = createSelector(
  getDefinitionsFrom,
  (definitions) => definitions.data || {}
)

export const getAdvancedProductDefinitionsRequests = createSelector(
  getDefinitionsFrom,
  (definitions) => definitions.request || {}
)

export const getDefinitionResourceCategory = createSelector(
  getAdvancedProductDefinitions,
  getProductAlias,
  (
    definitions: Record<ProductAlias, ProductDefinition>,
    alias: ProductAlias
  ): BonusResourceCategory[] | null => {
    if (!alias || !definitions[alias]) {
      return null
    }

    if (definitions[alias].resource_categories) {
      return definitions[alias].resource_categories.map(
        resourceCategoryNormalizer
      )
    }

    return null
  }
)

export const createDefinitionResourceCategory = (alias) =>
  createSelector(getAdvancedProductDefinitions, (definitions) => {
    if (!alias || !definitions[alias]) {
      return null
    }

    if (definitions[alias].resource_categories) {
      return definitions[alias].resource_categories.map(
        resourceCategoryNormalizer
      )
    }

    return null
  })

import type { EmbeddedAssetSquidexMetadata } from "~/fragments/image"

export default function assetsSquidexToVideoObject(
  assets: EmbeddedAssetSquidexMetadata[]
): {
  src: string
  retinaSrc?: string
  width?: number
  height?: number
  fileType?: string
  webmSrc?: string
  movSrc?: string
} {
  const retinaAsset = assets.find((asset) => asset.fileName.includes("@2x"))
  const webmAsset = assets.find((asset) => asset.fileType === "webm")
  const movSrc = assets.find((asset) => asset.fileType === "mov")
  const asset = assets.find((asset) => !asset.fileName.includes("@2x"))

  return {
    src: assets.length > 1 && asset ? asset.url : assets[0].url,
    retinaSrc: assets.length > 1 && retinaAsset ? retinaAsset.url : undefined,
    webmSrc: assets.length > 1 && webmAsset ? webmAsset.url : undefined,
    movSrc: assets.length > 1 && movSrc ? movSrc.url : undefined,
    width: assets.length > 1 && asset ? asset.videoWidth : assets[0].videoWidth,
    height:
      assets.length > 1 && asset ? asset.videoHeight : assets[0].videoHeight,
    fileType: assets[0].fileType,
  }
}

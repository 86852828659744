import { doRedirect } from "@onestore/onestore-store-common/http"
import type { OldOrderInfo } from "@gatsby-plugin-checkout/hooks/useOldOrderInfo"
import { STORAGE } from "~/lib/storage"
import LocalStorage from "~/lib/storage/LocalStorage"

export function checkTokens() {
  const currentToken = LocalStorage.get(STORAGE.TOKEN_NAME)
  const oldOrderInfo = LocalStorage.getSerialized<OldOrderInfo>(
    STORAGE.OLD_ORDER
  )
  const oldToken = "token" in oldOrderInfo ? oldOrderInfo.token : null

  if (currentToken && 36 === currentToken.length && oldToken === currentToken) {
    LocalStorage.remove(STORAGE.TOKEN_NAME)
    LocalStorage.remove(STORAGE.OLD_ORDER)
    // Przekierowanie w ten sposób aby strona się przeładowała
    doRedirect("GET", "/")
  }
}

import type { KeyboardEvent, SyntheticEvent } from "react"
import { useState } from "react"
import _t from "@core/i18n"
import { navigate } from "gatsby"
import { get } from "lodash"
import { uniqueId } from "lodash"
import type { BasketLastItem } from "@onestore/api/basket"
import type { DomainCheck } from "@onestore/api/domainSearch"
import type { PlanId } from "@onestore/api/types"
import {
  BasketActionSource,
  updateItemsInBasket,
} from "@gatsby-plugin-basket/store/actions"
import { addTransferToBasket } from "@gatsby-plugin-domain-search/store/actions"
import { getBrand } from "~/lib/config"
import isNask from "~/lib/isNask"
import { getPeriodByName } from "~/lib/plan"
import url from "~/lib/url"
import { useAppDispatch } from "~/store/hooks"

type Hook = [
  string,
  boolean,
  boolean,
  boolean,
  string,
  { (event: SyntheticEvent): void },
  { (event: SyntheticEvent): void },
  { (event: SyntheticEvent): void },
  { (): void },
]

const validateAuthInfo = (authInfo: string) => {
  if (authInfo.length < 8 || authInfo.length > 63) {
    return {
      valid: false,
      errors: _t("domainSearch.errors.authInfo"),
    }
  }

  return {
    valid: true,
    errors: "",
  }
}

export function useAuthinfoState(
  fqdn: DomainCheck.FQDN | null,
  extension: DomainCheck.Extension | null,
  id: PlanId | null
): Hook {
  const [buttonId] = useState(uniqueId("TransferBtn"))

  const [value, setValue] = useState<string>("")
  const [valid, setValid] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const dispatch = useAppDispatch()

  const navigateToBasket = () => {
    navigate(url.getBasketUrl())
    setIsLoading(false)
    setIsRedirecting(true)
  }

  const onChange = (event: SyntheticEvent<HTMLInputElement>): void => {
    setValue(event.currentTarget.value)
    const validation = validateAuthInfo(event.currentTarget.value)
    setValid(validation.valid)
    setErrorMessage(validation.errors)
  }

  const onKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      onSubmit(event)
    }
  }

  const onSubmit = async (event: SyntheticEvent) => {
    if (value.length === 0 || !valid || extension === null || fqdn === null) {
      return
    }

    setIsLoading(true)

    try {
      const lastItems = await dispatch(
        addTransferToBasket(
          {
            fqdn,
            plan: id,
          },
          value,
          buttonId,
          navigateToBasket
        )
      )

      // Zmiana okresu powinna działać przez przekazanie planPeriod do addTransferToBasket, na ten moment hack, do wyprowadzenia API ONESTORE-6533, tylko dla home.pl
      if (getBrand() === "home" && isNask(extension) && lastItems) {
        const domainItem: BasketLastItem = lastItems[0]

        const period =
          "periods" in domainItem
            ? getPeriodByName(domainItem.periods, "1y")
            : undefined

        if (period) {
          await dispatch(
            updateItemsInBasket(
              [
                {
                  id: domainItem.id,
                  planPeriod: period.id, // Zmiana domyślnego planu dla domeny.pl na 1y
                },
              ],
              BasketActionSource.TRANSFER
            )
          )
        }
      }
    } catch (error) {
      if (error.form) {
        const errorMessage =
          get(error, "form.children.parameters.children.authinfo.errors[0]") ||
          get(error, "form.errors[0]")

        setIsLoading(false)
        setIsRedirecting(false)
        setValid(false)
        setErrorMessage(errorMessage)
      }
    }
  }

  const onClose = () => {
    setIsLoading(false)
    setIsRedirecting(false)
    setValid(false)
    setErrorMessage("")
    setValue("")
  }

  return [
    value,
    valid,
    isLoading,
    isRedirecting,
    errorMessage,
    onChange,
    onSubmit,
    onKeyPress,
    onClose,
  ]
}

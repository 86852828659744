import { BasketActionSource } from "@gatsby-plugin-basket/store/actions"
import { addDomainsToBasket } from "@gatsby-plugin-domain-search/store/actions"
import type { DomainSearch } from "@gatsby-plugin-domain-search/types"
import { useAppDispatch } from "~/store/hooks"
import useDomainHookApiFlag from "./useDomainHookApiFlag"

interface Hook {
  addDomainsToBasket: {
    (
      domains: DomainSearch.DomainFqdnWithPlan[],
      childDomains: DomainSearch.DomainFqdnWithPlan[],
      buttonId: string,
      ignoreHook: boolean
    ): void
  }
}

export default function useDomainActionsDispatch(): Hook {
  const dispatch = useAppDispatch()
  const disableApiHook = useDomainHookApiFlag()

  const addToBasket = (
    domains: DomainSearch.DomainFqdnWithPlan[],
    childDomains: DomainSearch.DomainFqdnWithPlan[],
    buttonId: string,
    ignoreHack: boolean
  ): void => {
    dispatch(
      addDomainsToBasket(
        domains,
        childDomains,
        buttonId,
        () => {},
        BasketActionSource.DOMAINS,
        disableApiHook || ignoreHack,
        {}
      )
    )
  }

  return {
    addDomainsToBasket: addToBasket,
  }
}

import { BarType } from "./types"

const stylesByType = {
  [BarType.INFO]: { color: "notifyLight", spacingSpace: { x: 0, y: 1 } },
  [BarType.UNAVAILABLE]: { color: "errorLight", spacingSpace: { x: 0, y: 1 } },
  [BarType.PROMOTION]: {
    color: "backgroundDark",
    spacingSpace: { x: 0, y: 2 },
  },
}

export default stylesByType

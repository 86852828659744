import type { ReactElement } from "react"
import React, { memo } from "react"
import { typeMatches } from "@onestore-graphql"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import PageFooterTemplate from "@onestore/hel/dist/components/templates/PageFooterTemplate"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import type { FooterText } from "@gatsby-plugin-generic-page/fragments/footerText"
import { SquidexComponentType } from "@gatsby-plugin-generic-page/types/componentType"
import type { ModalWithButtonsData } from "~/fragments/modalWithButtons"
import useFooterData from "~/hooks/useFooterData"
import { brand } from "~/lib/config"
import isEmpty from "~/lib/isEmpty"
import AdditionalFooter from "./AdditionalFooter"

interface FooterProps {
  hideMobileFooter?: boolean
}

function FooterTemplate({
  hideMobileFooter = false,
}: FooterProps): ReactElement {
  const hasToggleableCategoriesOnSmall = brand === "home"

  const { logo, socials, partners, categories, additionalFooterData } =
    useFooterData()

  let footerAdditionalData

  if (!isEmpty(additionalFooterData)) {
    if (
      typeMatches(
        additionalFooterData.__typename,
        SquidexComponentType.FOOTER_TEXT
      )
    ) {
      const data = additionalFooterData as FooterText

      footerAdditionalData = (
        <MarkdownText
          pushSpace={0}
          typography={{ bold: "nano1", default: "nano2" }}
        >
          {data.text}
        </MarkdownText>
      )
    }

    if (
      typeMatches(
        additionalFooterData.__typename,
        SquidexComponentType.MODAL_WITH_BUTTONS_COMPONENT
      )
    ) {
      const data = additionalFooterData as ModalWithButtonsData

      footerAdditionalData = <AdditionalFooter additionalFooterData={data} />
    }
  }

  const footer = (
    <PageFooterTemplate
      logo={logo}
      socials={socials}
      categories={categories}
      partners={partners}
      footer={footerAdditionalData}
      hasToggleableCategoriesOnSmall={hasToggleableCategoriesOnSmall}
    />
  )

  return (
    <>
      <BreakpointVisibilityOrg from="small" to="medium">
        {hideMobileFooter ? null : footer}
      </BreakpointVisibilityOrg>

      <BreakpointVisibilityOrg from="medium">
        {hideMobileFooter ? null : footer}
      </BreakpointVisibilityOrg>
    </>
  )
}

export default memo(FooterTemplate)

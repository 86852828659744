import domainsTransferDefinitions from "_/res/domainsTransferDefinitions.json"
import { keyBy } from "lodash"
import type { DomainCheck } from "@onestore/api/domainSearch"
import type { DomainTransferDefinition } from "@onestore/api/types"
import type { PriceValue } from "../../lib/api/basket"

const transferPrices: Record<string, DomainTransferDefinition> = keyBy(
  domainsTransferDefinitions,
  (domain) => domain.extension
)

export interface TransferPrice {
  id: number
  price_transfer: PriceValue | null
  authinfo_required: boolean
}

export function getTransferPricing(
  extension: DomainCheck.Extension
): TransferPrice {
  return {
    id: transferPrices[extension].id,
    price_transfer: transferPrices[extension].price_transfer,
    authinfo_required: transferPrices[extension].authinfo_required ?? false,
  }
}

export enum DOMAIN_SEARCH_ACTIONS {
  CHECK_PENDING = "ACTIONS.DOMAIN_SEARCH.CHECK.PENDING",
  CHECK_SUCCESS = "ACTIONS.DOMAIN_SEARCH.CHECK.SUCCESS",
  CHECK_FAILURE = "ACTIONS.DOMAIN_SEARCH.CHECK.FAILURE",
  POOL_PENDING = "ACTIONS.DOMAIN_SEARCH.POOL.PENDING",
  POOL_SUCCESS = "ACTIONS.DOMAIN_SEARCH.POOL.SUCCESS",
  POOL_FAILURE = "ACTIONS.DOMAIN_SEARCH.POOL.FAILURE",
  SUGGESTED_PENDING = "ACTIONS.DOMAIN_SEARCH.SUGGESTED.PENDING",
  SUGGESTED_SUCCESS = "ACTIONS.DOMAIN_SEARCH.SUGGESTED.SUCCESS",
  SUGGESTED_FAILURE = "ACTIONS.DOMAIN_SEARCH.SUGGESTED.FAILURE",
  LAZY_ENQUEUE = "ACTIONS.DOMAIN_SEARCH.LAZY.ENQUEUE",
  LAZY_DEQUEUE = "ACTIONS.DOMAIN_SEARCH.LAZY.DEQUEUE",
  LAZY_PENDING = "ACTIONS.DOMAIN_SEARCH.LAZY.PENDING",
  LAZY_SUCCESS = "ACTIONS.DOMAIN_SEARCH.LAZY.SUCCESS",
  LAZY_FAILURE = "ACTIONS.DOMAIN_SEARCH.LAZY.FAILURE",
  CHECK_RESULT_APPLY = "ACTIONS.DOMAIN_SEARCH.CHECK_RESULT.APPLY",
  VALIDATION_SUCCESS = "ACTIONS.DOMAIN_SEARCH.VALIDATION.SUCCESS",
  VALIDATION_CLEAR = "ACTIONS.DOMAIN_SEARCH.VALIDATION.CLEAR",
  VALIDATION_FAILURE = "ACTIONS.DOMAIN_SEARCH.VALIDATION.FAILURE",
  TOGGLE_ADVANCED_MODE = "TOGGLE_ADVANCED_MODE",
}

export enum DOMAIN_SEARCH_VIEW {
  PAGE_TYPE_POOL = "VIEW.DOMAIN_SEARCH.PAGE_TYPE.POOL",
  PAGE_TYPE_FULL = "VIEW.DOMAIN_SEARCH.PAGE_TYPE.FULL",
}

export enum DOMAIN_STATUS {
  UNKNOWN = "Unknown",
  ERROR = "Error",
  AVAILABLE = "Available",
  INVALID = "Invalid",
  UNAVAILABLE = "Unavailable",
  EXTERNAL = "External",
}

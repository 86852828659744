import type { ReactElement, SyntheticEvent } from "react"
import React from "react"
import type { ButtonAtmProps } from "@onestore/hel/dist/components/atoms/ButtonAtm"
import ButtonAtm from "@onestore/hel/dist/components/atoms/ButtonAtm"
import useFastCheckoutAction from "@gatsby-plugin-basket/hooks/useFastCheckoutAction"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"

type FastCheckoutButtonProps = ButtonAtmProps & {
  plan: CloudBluePlan[]
}

function FastCheckoutButton({
  onClick,
  href,
  title,
  text,
  color,
  variant,
  size,
  plan,
  rel,
  ...restProps
}: FastCheckoutButtonProps): ReactElement<FastCheckoutButtonProps> {
  const { addProductToBasket, isLoading } = useFastCheckoutAction(plan[0])

  const handleOnClick = (event: SyntheticEvent) => {
    event.preventDefault()

    if (onClick) {
      onClick(event)
    }

    addProductToBasket()
  }

  return (
    <ButtonAtm
      onClick={handleOnClick}
      isLoading={isLoading}
      title={title}
      color={color}
      variant={variant}
      size={size}
      rel={rel}
      {...restProps}
    >
      {text}
    </ButtonAtm>
  )
}

export default FastCheckoutButton

import type { ReactElement } from "react"
import React from "react"
import { typeMatches } from "@onestore-graphql"
import type Theme from "@onestore/hel/dist/typings/theme"
import FormilyFormLink from "@gatsby-plugin-generic-page/components/FormilyFormLink"
import type { Formily } from "@gatsby-plugin-generic-page/fragments/formily"
import type { SquidexLinkType } from "@gatsby-plugin-generic-page/types/ctaElementType"
import { SquidexFormType } from "@gatsby-plugin-generic-page/types/formType"
import CerbFormLink from "~/components/FormLink"
import type { Form } from "~/fragments/form"
import type { Forms } from "~/types/squidex"

interface Props {
  text: string
  title: string
  form: Forms[]
  typography?: Theme.Typography
  linkType?: SquidexLinkType
}

export default function FormLink({
  title,
  text,
  form,
  typography,
  linkType,
}: Props): ReactElement<Props> | null {
  const { __typename, flatData } = form[0]

  if (typeMatches(__typename, SquidexFormType.FORM)) {
    const { title: headingTitle, button, id } = flatData as Form["flatData"]

    return (
      <CerbFormLink
        id={id}
        title={title}
        text={text}
        typography={typography}
        headingText={headingTitle}
        formButtonColor={button[0].buttonColor.color}
        formButtonText={button[0].text}
        formButtonTitle={button[0].title}
        linkType={linkType}
      />
    )
  }

  if (typeMatches(__typename, SquidexFormType.FORMILY_FORM)) {
    return (
      <FormilyFormLink
        title={title}
        text={text}
        formilyForm={form[0] as Formily}
        linkType={linkType}
      />
    )
  }

  return null
}

/* eslint-disable no-param-reassign */
import { produce } from "immer"
import type { Draft } from "immer"
import { convertPeriodToBonusPeriods } from "@gatsby-plugin-bonus/lib/bonus-definitions"
import type { BonusBundleActions } from "@gatsby-plugin-bonus/store/actions-bundle"
import type { BonusProduct, BonusState } from "@gatsby-plugin-bonus/types"
import { resourceCategoryNormalizer } from "@gatsby-plugin-definitions/lib/normalizers"
import type {
  ProductDefinitionActions,
  ProductDefinitionGetSuccessAction,
} from "@gatsby-plugin-definitions/store/actions"
import { PRODUCT_DEFINITION_GET_SUCCESS } from "@gatsby-plugin-definitions/store/actions"
import { initialState } from "./selectors"
import {
  defaultCheckedResources,
  getBonusItemTotalPrices,
  getInitialChosenPeriod,
  getProductParameters,
  getResourceLabels,
} from "./utils"

function handleDefinitionGet(
  action: ProductDefinitionGetSuccessAction,
  currentProduct: BonusProduct | undefined,
  draftProduct: Draft<BonusProduct>
): void {
  const { periods } = action.product

  const resourceCategories = action.product.resource_categories.map(
    resourceCategoryNormalizer
  )

  draftProduct.definitionLoaded = true
  draftProduct.resourceLabels = getResourceLabels(
    action.product.resources || {}
  )
  draftProduct.resourceCategories = resourceCategories
  draftProduct.periods = convertPeriodToBonusPeriods(
    periods,
    action.product.min_quantity ?? 1
  )

  draftProduct.chosenPeriodId = getInitialChosenPeriod(
    draftProduct.periods,
    currentProduct?.chosenPeriodId,
    draftProduct.defaultPeriodName
  )
  draftProduct.resources = defaultCheckedResources(
    resourceCategories,
    draftProduct.chosenPeriodId,
    draftProduct.periods,
    currentProduct?.resources
  )
  draftProduct.parameters = getProductParameters(action.product.parameters)
  draftProduct.totalPrices = getBonusItemTotalPrices(
    action.product,
    draftProduct
  )
}

export default function reducer(
  state: BonusState = initialState,
  action: BonusBundleActions | ProductDefinitionActions
) {
  return produce(state, (nextState: Draft<BonusState>) => {
    switch (action.type) {
      case PRODUCT_DEFINITION_GET_SUCCESS: {
        const { alias } = action.product

        if (nextState.product && alias === nextState.product.alias) {
          handleDefinitionGet(action, state.product, nextState.product)
        } else if (nextState.upsell && nextState.upsell[alias]) {
          handleDefinitionGet(
            action,
            state.upsell[alias],
            nextState.upsell[alias]
          )
        }

        return
      }
      default:
        break
    }
  })
}

import type { ReactElement, SyntheticEvent } from "react"
import React from "react"
import _t from "@core/i18n"
import { navigate } from "gatsby"
import type { MarkdownToJSX } from "markdown-to-jsx"
import type { DomainCheck } from "@onestore/api/domainSearch"
import ButtonAtm from "@onestore/hel/dist/components/atoms/ButtonAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import { buildDomainHash } from "@gatsby-plugin-domain-search/lib/domains"
import { md } from "~/lib/i18n"
import url from "~/lib/url"
import ModalTemplateWrapper from "../ModalTemplateWrapper"

const markdownOverrides: MarkdownToJSX.Overrides = {
  strong: {
    component: TextAtm,
    props: {
      isBold: true,
    },
  },
}

interface DomainIsAvailableModalProps {
  isOpen: boolean
  onCloseButtonClick: (event: SyntheticEvent) => void
  domain: DomainCheck.FQDN
}

export function DomainIsAvailableModal({
  isOpen,
  onCloseButtonClick,
  domain,
}: DomainIsAvailableModalProps): ReactElement<DomainIsAvailableModalProps> {
  const hash = buildDomainHash({
    d: domain,
  })

  const handleButtonClick = (event) => {
    event.preventDefault()

    navigate(url.getDomainSearchUrl() || "", {
      state: {
        hash,
      },
      replace: false,
    })
  }

  return (
    <ModalTemplateWrapper
      content={
        <>
          <TextAtm typography="great" pushSpace={3}>
            {_t("transfer.domainAvailableModalTitle")}
          </TextAtm>
          <TextAtm typography="medium2_h" pushSpace={4}>
            {md(
              "transfer.domainAvailableModalContent",
              { fqdn: domain || "" },
              markdownOverrides
            )}
          </TextAtm>

          <ButtonAtm
            title={_t("transfer.domainAvailableButton")}
            isExpanded
            color="success"
            onClick={handleButtonClick}
          >
            {_t("transfer.domainAvailableButton")}
          </ButtonAtm>
        </>
      }
      isOpen={isOpen}
      onCloseButtonClick={onCloseButtonClick}
    />
  )
}

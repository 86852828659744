import React from "react"
import { useParentForm, observer } from "@formily/react"
import type { AxiosError } from "axios"
import type { ButtonAtmProps } from "@onestore/hel/dist/components/atoms/ButtonAtm"
import ButtonAtm from "@onestore/hel/dist/components/atoms/ButtonAtm"

interface FormSubmitProps extends ButtonAtmProps {
  onSubmit: (values: any) => any //TODO
  onSubmitSuccess?: (payload: any) => void
  onSubmitFailed?: (data: AxiosError) => void
}

export const FormSubmit: React.FC<React.PropsWithChildren<FormSubmitProps>> =
  observer(
    ({
      onSubmit,
      onSubmitFailed,
      onSubmitSuccess,
      ...props
    }: FormSubmitProps) => {
      const form = useParentForm()

      return (
        <ButtonAtm
          {...props}
          type="submit"
          onClick={() => {
            if (onSubmit) {
              form.submit(onSubmit).then(onSubmitSuccess).catch(onSubmitFailed)
            }
          }}
        >
          {props.text}
        </ButtonAtm>
      )
    },
    {
      forwardRef: true,
    }
  )

export default FormSubmit

import { useCallback, useEffect, useState } from "react"
import { useIsomorphicLayoutEffect } from "./useIsomorphicLayoutEffect"

function useIsFloatingElementVisible(
  sectionIdFrom?: string,
  sectionIdTo?: string
): boolean {
  const [isVisible, setIsVisible] = useState(false)
  const [elementFrom, setElementFrom] = useState<HTMLElement | null>(null)
  const [elementTo, setElementTo] = useState<HTMLElement | null>(null)

  const isScrolledIntoView = (element, from) => {
    if (!element) {
      return false
    }

    const rect = element.getBoundingClientRect()
    const elemTop = rect.top
    const elemBottom = rect.bottom

    let isVisible

    if (from === "top") {
      isVisible = elemTop <= 0 && elemTop <= window.innerHeight
    }

    if (from === "bottom") {
      isVisible =
        (elemTop >= 0 || elemBottom >= 0) && elemBottom >= window.innerHeight
    }

    return isVisible
  }

  const handleScroll = useCallback(() => {
    const isSectionFromVisible = sectionIdFrom
      ? isScrolledIntoView(elementFrom, "top")
      : true

    const isSectionToVisible = sectionIdTo
      ? isScrolledIntoView(elementTo, "bottom")
      : true

    setIsVisible(isSectionFromVisible && isSectionToVisible)
  }, [elementFrom, elementTo, sectionIdFrom, sectionIdTo])

  useEffect(() => {
    if (sectionIdFrom) {
      const sectionFrom = document.getElementById(sectionIdFrom)

      setElementFrom(sectionFrom)
    }

    if (sectionIdTo) {
      const sectionTo = document.getElementById(sectionIdTo)

      setElementTo(sectionTo)
    }
  }, [sectionIdTo, sectionIdFrom])

  useIsomorphicLayoutEffect()(() => {
    if (sectionIdFrom || sectionIdTo) {
      handleScroll()
    }
  }, [sectionIdFrom, sectionIdTo, handleScroll])

  useIsomorphicLayoutEffect()(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [handleScroll])

  return isVisible
}

export default useIsFloatingElementVisible

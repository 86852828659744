import type { ReactElement } from "react"
import React, { useCallback } from "react"
import _t from "@core/i18n"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import { createDomainSearchrModalHideAction } from "@gatsby-plugin-domain-search/store/actions"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import type { AppState } from "~/store/reducer"
import DomainInputField from "../DomainInputField"
import ModalTemplateWrapper from "../ModalTemplateWrapper"

interface SearchModal {
  visible: boolean
  extension?: string
  title: string
}

export default function DomainSearchModal(): ReactElement {
  const dispatch = useAppDispatch()
  const searchModal = useAppSelector<SearchModal>(
    (state: AppState) => state.domainSearch.searchModal
  )

  const handleClose = useCallback((): void => {
    dispatch(createDomainSearchrModalHideAction())
  }, [dispatch])

  return (
    <ModalTemplateWrapper
      isOpen={searchModal.visible}
      onCloseButtonClick={handleClose}
      content={
        <>
          <TextAtm align="center" pushSpace={2} typography="huge">
            {searchModal.title}
          </TextAtm>

          <DomainInputField
            extension={searchModal.extension}
            submitText={_t("domainSearch.submit")}
            placeholderText={_t("domainSearch.inputPlaceholder")}
            afterSubmit={() => {
              handleClose()
            }}
          />
        </>
      }
    />
  )
}

import { SyntheticEvent } from "react"

export function scrollToAnchor(anchor: string): void {
  const element = document.getElementById(anchor.replace("#", ""))

  if (!element) {
    return
  }

  element.scrollIntoView({
    behavior: "smooth",
  })
}

export interface AnchorProperties {
  href: string
  onClick: { (event: SyntheticEvent) }
}

export function getAnchorProperties(anchor: string): AnchorProperties {
  const href: string = `#${anchor}`
  const onClick = (event: SyntheticEvent): void => {
    event.preventDefault()

    scrollToAnchor(anchor)
  }

  return {
    href,
    onClick,
  }
}

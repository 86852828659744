import { useMemo, useRef } from "react"
import Fuse from "fuse.js"
import type { IFuseOptions } from "fuse.js"
import type { ProductCardItem } from "@gatsby-plugin-marketplace/fragments/marketplaceProductCard"
import useAllMarketplaceProductsQuery from "~/hooks/useAllMarketplaceProductsQuery"

const fuseOptions: IFuseOptions<ProductCardItem> = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 100,
  minMatchCharLength: 2,
  ignoreLocation: true,
  keys: [
    "flatData.name",
    "flatData.cardDescription",
    "flatData.tags",
    "flatData.searchKeywords",
  ],
}

export function createFuse(
  products: ProductCardItem[],
  options?: IFuseOptions<ProductCardItem>
) {
  return new Fuse<ProductCardItem>(products, options ?? fuseOptions)
}

export default function useFilteredProducts(
  query?: string,
  options?: IFuseOptions<ProductCardItem>
): ProductCardItem[] {
  const allProducts = useAllMarketplaceProductsQuery()

  const fuse = useRef(createFuse(allProducts, options))

  return useMemo(() => {
    if (!query) {
      return []
    }

    return (
      fuse.current.search<ProductCardItem>(query).map((item) => item.item) || []
    )
  }, [query])
}

import type { ReactElement } from "react"
import { useContext } from "react"
import React from "react"
import _t from "@core/i18n"
import { PeriodInfo } from "@core/period-info"
import type { DomainCheck } from "@onestore/api/domainSearch"
import DomainPriceTypeContext from "@gatsby-plugin-domain-search/context/DomainPriceTypeContext"
import { useCloudblueDomainInfo } from "~/hooks/useCloudblueDomainInfo"
import SearchResultPrice from "../ResultsOrgSingle/SearchResultPrice"

interface PriceProps {
  domain: DomainCheck.Result
}

export function Price({ domain }: PriceProps): ReactElement | null {
  const info = domain.period ? new PeriodInfo(domain.period) : null
  const { currentPriceType } = useContext(DomainPriceTypeContext) || {}
  const { tooltip } = useCloudblueDomainInfo(domain.plan_id)

  const periodText = !info
    ? ""
    : ` ${_t(`periodName.searchDomainPrice.3`, {
        smart_count: info.getPeriodLength(),
      })}`

  return (
    <SearchResultPrice
      periodInfo={info}
      priceType={currentPriceType}
      periodText={periodText}
      tooltip={tooltip}
      isMainResult
    />
  )
}

import type { ReactElement } from "react"

interface HideWhenNotInRangeProps {
  from: string
  children: ReactElement
  to?: string | null
}

function HideWhenNotInRange({
  to,
  from,
  children,
}: HideWhenNotInRangeProps): ReactElement<HideWhenNotInRangeProps> | null {
  const currentDate = Date.now()

  if (
    (to && Date.parse(from) >= currentDate && currentDate >= Date.parse(to)) ||
    (!to && Date.parse(from) >= currentDate)
  ) {
    return null
  }

  return children
}

export default HideWhenNotInRange

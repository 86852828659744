import { PeriodInfo } from "@core/period-info"
import type { CloudBluePeriod } from "@gatsby-plugin-definitions/fragments/CloudBluePeriod"
import type { ProductBoxBundle } from "~/fragments/bundle"
import type { SaleConfiguration } from "~/fragments/saleConfigurations"
import { safeFind } from "~/lib/collection"
import isEmpty from "~/lib/isEmpty"
import type { PeriodName } from "../../../../lib/api/types"

interface UseProductPeriodInfo {
  periodInfo: PeriodInfo | null
  cloudBluePeriod: CloudBluePeriod | null
  bundlePeriod: CloudBluePeriod | null
  planID: number | null
}

const useProductPeriodInfo = (
  isChecked: boolean,
  saleConfiguration?: SaleConfiguration[] | null,
  bundlePlan?: ProductBoxBundle[] | null,
  period?: PeriodName
): UseProductPeriodInfo => {
  const cbPeriods = !isEmpty(saleConfiguration)
    ? saleConfiguration[0].flatData.defaultCloudBluePlan[0].flatData.periods
    : null
  const planID = !isEmpty(saleConfiguration)
    ? saleConfiguration[0].flatData.defaultCloudBluePlan[0].flatData.id
    : null
  const cloudBluePeriod = cbPeriods
    ? cbPeriods.find((item) => item.period_name === period) ||
      safeFind(cbPeriods, (item) => item.default ?? false)
    : null

  const bundlePlanData = !isEmpty(bundlePlan)
    ? bundlePlan[0].flatData.plan
    : null
  const bundlePeriods = !isEmpty(bundlePlanData)
    ? bundlePlanData[0].flatData.periods
    : null

  const bundlePeriod =
    !isEmpty(bundlePeriods) && isChecked
      ? bundlePeriods.find((item) => item.period_name === period) ||
        safeFind(bundlePeriods, (item) => item.default ?? false)
      : null

  const periodInfo = cloudBluePeriod
    ? new PeriodInfo(cloudBluePeriod, bundlePeriod)
    : null

  return { periodInfo, cloudBluePeriod, bundlePeriod, planID }
}

export default useProductPeriodInfo

import { get, isNumber } from "lodash"
import type { BasketLastItem } from "@onestore/api/basket"
import type { BasketStateItem } from "@onestore/onestore-store-common"
import type { BasketActionSource } from "@gatsby-plugin-basket/store/actions"
import type { BasketViewItem } from "@gatsby-plugin-basket/types"
import isEmpty from "./isEmpty"
import SessionStorage from "./storage/SessionStorage"

const SOURCE_KEY = "source"

export type SourceObject = {
  planId: number
  source: BasketActionSource | string
}

export function isItemEnabled(item: BasketStateItem | BasketViewItem): boolean {
  return !isNumber(
    (item as BasketStateItem).disabled_reason ||
      (item as BasketViewItem).disabledReason
  )
}

export function setAddCartSource(
  planId: SourceObject["planId"],
  source: SourceObject["source"]
): void {
  SessionStorage.set(
    SOURCE_KEY,
    JSON.stringify({
      planId,
      source,
    })
  )
}

export function getAddCartSource(
  products: BasketLastItem[]
): BasketActionSource | string | null {
  const source = SessionStorage.get(SOURCE_KEY)

  if (isEmpty(source)) {
    return null
  }

  const sourceObject: SourceObject = JSON.parse(source)

  const searchedProducts = products.find((product) => {
    const productPlanId = get(product, "plan_id")

    return productPlanId === sourceObject.planId
  })

  SessionStorage.remove(SOURCE_KEY)

  return !isEmpty(searchedProducts) ? sourceObject.source : null
}

import { useEffect, useState } from "react"
import * as Sentry from "@sentry/browser"
import axios from "axios"
import type { PlanId } from "@onestore/api/types"
import { useSessionStorage } from "./useSessionStorage"

export interface CachedAjaxRequestHook<T> {
  isLoading: boolean
  result: T
  fetchData: { (): void }
}

export default function useCachedAjaxRequest<T>(
  url: string,
  cacheKey: string,
  autoFetch = false,
  plan?: PlanId
): CachedAjaxRequestHook<T | null> {
  const [result, setResult] = useSessionStorage<T>(`_cr-${cacheKey}`)
  const [isLoading, setLoading] = useState(false)

  const makeRequest = async () => {
    setLoading(true)

    try {
      const result = await axios(url)

      setResult(result.data)
    } catch (error) {
      if (plan) {
        Sentry.captureMessage(`Plan "${plan}" is not active`, "error")
      }
    }

    setLoading(false)
  }

  const fetchData = () => {
    if (result === null) {
      makeRequest()
    }
  }

  useEffect(() => {
    if (autoFetch) {
      fetchData()
    }
  }, [])

  return {
    isLoading,
    result,
    fetchData,
  }
}

import { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import type { ProductCardItem } from "@gatsby-plugin-marketplace/fragments/marketplaceProductCard"

interface Query {
  GraphCmsSquidex: {
    queryMarketplaceProductContents: ProductCardItem[]
  }
}

export default function useAllMarketplaceProductsQuery(): ProductCardItem[] {
  const {
    GraphCmsSquidex: { queryMarketplaceProductContents },
  } = useStaticQuery<Query>(graphql`
    {
      GraphCmsSquidex {
        queryMarketplaceProductContents(
          top: 1000
          orderby: "data/order/iv desc"
        ) {
          ...MarketplaceProductCard
        }
      }
    }
  `)

  return useMemo(
    () =>
      queryMarketplaceProductContents.filter(
        (product) => product.flatData.pages && product.flatData.pages.length > 0
      ),
    [queryMarketplaceProductContents]
  )
}

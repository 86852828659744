import type { ReactNode } from "react"
import React, { useContext, useEffect, useState } from "react"
import _t from "@core/i18n"
import { getSelectedPeriodInfo, PeriodInfo } from "@core/period-info"
import { formatPriceObject, hasNoTaxRate } from "@core/pricing"
import type { PriceWithOptionalTaxRate } from "@core/types"
import { PriceFormat } from "@core/types"
import { PriceType } from "@core/types"
import { first, get, last } from "lodash"
import type { BasketPatchItem, PeriodName, Price } from "@onestore/api/types"
import type { ButtonAtmProps } from "@onestore/hel/dist/components/atoms/ButtonAtm"
import type { LabelAtmVariant } from "@onestore/hel/dist/components/atoms/LabelAtm"
import LabelAtm from "@onestore/hel/dist/components/atoms/LabelAtm"
import NumberInputMol from "@onestore/hel/dist/components/molecules/NumberInputMol"
import type { PackageBoxSlimMolProps } from "@onestore/hel/dist/components/molecules/PackageBoxSlimMol"
import {
  ParameterItem,
  ParametersGroup,
} from "@onestore/hel/dist/components/molecules/PackageBoxSlimMol/PackageBoxSlimMol"
import TooltipMol from "@onestore/hel/dist/components/molecules/TooltipMol"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import type Theme from "@onestore/hel/dist/typings/theme"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import MarkdownTile from "@gatsby-plugin-generic-page/components/Markdown/Tile"
import ProductBoxFooter from "@gatsby-plugin-generic-page/components/ProductBoxSlim/ProductBoxFooter"
import type { BackgroundImages } from "@gatsby-plugin-generic-page/fragments/backgroundImages"
import getBackground from "@gatsby-plugin-generic-page/helpers/getBackground"
import { getDomainsPoolId } from "@gatsby-plugin-generic-page/helpers/getDomainsPoolId"
import useProductPeriodInfo from "@gatsby-plugin-generic-page/hooks/useProductPeriodInfo"
import LimitTooltipLabel from "~/components/LimitTooltipLabel"
import LowestPriceInfo from "~/components/LowestPriceInfo"
import ProductBoxPrice from "~/components/ProductBoxPrice"
import { PriceTypeContext } from "~/context/PriceTypeContext"
import type { ProductBoxBundle } from "~/fragments/bundle"
import type { SaleConfiguration } from "~/fragments/saleConfigurations"
import useNumberInputMolState from "~/hooks/useNumberInputMolState"
import { productBoxClickEvent } from "~/lib/ga4"
import isEmpty from "~/lib/isEmpty"
import onestoreVars from "~/lib/onestoreVars"
import {
  getBundledProduct,
  getMainProduct,
  getSelectedPeriod,
} from "~/lib/plan"
import type { PlanSettings } from "~/lib/url"
import url from "~/lib/url"
import useCloudbluePlanTooltip from "./useCloudbluePlanTooltip"

export type ProductBoxSlimContent = {
  parameters?: ReactNode
  footer?: {
    value?: string
    label: string
    isBundle?: boolean
    tooltip?: ReactNode
  }
}

interface Box extends PackageBoxSlimMolProps {
  selectedPlan: CloudBluePlan["flatData"]
  outerFooter?: ReactNode
  onChangePeriodModalClick?: () => void
}

export type ProductBoxSlimHookProps = {
  id: string
  bundleTogglePosition?: "on" | "off"
  promoText?: string
  description: ReactNode
  subtitle?: string
  content: ProductBoxSlimContent
  outerFooter?: ReactNode
  plan: CloudBluePlan["flatData"] | Record<number, CloudBluePlan["flatData"]>
  period?: PeriodName
  code?: string
  title?: string
  discountLabel?: string
  discountLabelVariant?: LabelAtmVariant
  upsell?: (string | PlanSettings)[]
  bundle?: CloudBluePlan["flatData"][]
  upgrade?: string
  bundledTitle?: string
  hideCents?: boolean
  priceType?: PriceType
  buttonColor?: PackageBoxSlimMolProps["button"]["color"]
  buttonText?: string
  hideRenewalPrices?: boolean
  additionalText?: string
  showMonthlyPrices?: boolean
  hasChangedDefaultPlan?: boolean
  planInputLabel?: string
  saleConfiguration?: SaleConfiguration[]
  bundlePlan?: ProductBoxBundle[] | null
  hasDarkMode?: boolean
  changePeriod?: PeriodName
  backgroundImage?: BackgroundImages
  customTextColor?: Theme.CustomColor
  isTrialBox?: boolean
}

const calculateBundlePrice = (
  bundlePrice: PriceWithOptionalTaxRate,
  productPrice: PriceWithOptionalTaxRate
): PriceWithOptionalTaxRate =>
  bundlePrice &&
  productPrice && {
    ...bundlePrice,
    netto: bundlePrice.netto - productPrice.netto,
    gross: bundlePrice.gross - productPrice.gross,
  }

export default function useProductBoxSlim({
  id,
  bundleTogglePosition = "off",
  promoText,
  description,
  plan,
  period,
  content,
  code,
  subtitle,
  title = "",
  discountLabel,
  discountLabelVariant,
  upsell = [],
  bundle = [],
  upgrade,
  bundledTitle,
  hideCents = false,
  priceType: initialPriceType,
  buttonColor,
  buttonText,
  outerFooter,
  hideRenewalPrices: initialHideRenewalPrices,
  additionalText,
  showMonthlyPrices,
  hasChangedDefaultPlan,
  planInputLabel,
  saleConfiguration,
  bundlePlan,
  hasDarkMode,
  changePeriod,
  isTrialBox,
  backgroundImage,
}: ProductBoxSlimHookProps): Box {
  const context = useContext(PriceTypeContext)
  const priceType = context?.currentPriceType || initialPriceType
  const { hideRenewalPrices } = onestoreVars.flags
  const plans: Record<number, CloudBluePlan["flatData"]> =
    "alias" in plan ? { 0: plan } : plan
  const [checked, setChecked] = useState("on" === bundleTogglePosition)
  const quantities = Object.keys(plans).map((int) => parseInt(int, 10))

  const { props: numberInputProps, currentValue: selectedQuantity } =
    useNumberInputMolState(
      first(quantities) || 0,
      last(quantities) || 0,
      quantities[0] || 0
    )

  useEffect(() => {
    setChecked("on" === bundleTogglePosition)
  }, [bundleTogglePosition])

  const handleSetIsChecked = () => {
    setChecked((v) => !v)
  }

  const bundleProduct = getBundledProduct(bundle)
  const selectedBundlePlan = checked && bundle.length > 1 ? bundleProduct : null

  const selectedPlan: CloudBluePlan["flatData"] = checked
    ? getMainProduct(plans[selectedQuantity], bundle)
    : plans[selectedQuantity]

  let bundlePrice: Price | null = null
  let productPrice: Price | null = null

  if (bundle && bundleProduct) {
    const periodInfo = getSelectedPeriodInfo(bundleProduct.periods, undefined)
    const productPeriodInfo = getSelectedPeriodInfo(
      selectedPlan.periods,
      undefined
    )

    bundlePrice = periodInfo ? periodInfo.getRegisterPriceValue() : null
    productPrice = productPeriodInfo
      ? productPeriodInfo.getRegisterPriceValue()
      : null

    if (bundleProduct.id && periodInfo) {
      const bundleSpecialPrice = periodInfo.getPromoPricePorPlanPrice(
        selectedPlan.id
      )

      if (bundleSpecialPrice) {
        bundlePrice = bundleSpecialPrice
      }

      if (!!showMonthlyPrices) {
        bundlePrice = periodInfo ? periodInfo.getMonthlyPriceValue() : null
      }
    }
  }

  const [mainProductPrice, setMainProductPrice] =
    useState<PriceWithOptionalTaxRate | null>(productPrice)

  useEffect(() => {
    setMainProductPrice(productPrice)
  }, [])

  let bundledProduct
  let bundlePriceFormat

  if (
    hasChangedDefaultPlan &&
    bundlePrice &&
    productPrice &&
    mainProductPrice
  ) {
    const calculatedBundlePrice = calculateBundlePrice(
      checked ? productPrice : bundlePrice,
      mainProductPrice
    )

    bundlePriceFormat =
      calculatedBundlePrice &&
      formatPriceObject(calculatedBundlePrice, priceType)
  } else if (bundlePrice) {
    bundlePriceFormat = formatPriceObject(bundlePrice, priceType)
  }

  if (checked && selectedBundlePlan) {
    bundledProduct = selectedBundlePlan
  }

  const { periodInfo, cloudBluePeriod, bundlePeriod, planID } =
    useProductPeriodInfo(checked, saleConfiguration, bundlePlan, period)

  const { limitTooltip } = useCloudbluePlanTooltip(planID)

  const isOneTimeFee = periodInfo ? periodInfo.isOneTimeFee() : null
  const isMultiPlan = !("alias" in plan)

  const hasNettoPriceType = hasNoTaxRate() || priceType === PriceType.NETTO

  const priceFormat = periodInfo
    ? periodInfo.getFormatted(
        hasNettoPriceType ? PriceFormat.NETTO : PriceFormat.GROSS
      )
    : undefined

  // title text
  let headerTitle = isEmpty(title) ? selectedPlan.name : title

  if (bundledProduct) {
    headerTitle = `${headerTitle} + ${bundledTitle || bundledProduct.name}`
  }

  //price
  function getPricingData(
    product,
    bundledProduct,
    periodName: PeriodName | undefined
  ) {
    const selectedPeriod = getSelectedPeriod(product, periodName)

    let bundledPeriod = bundledProduct
      ? getSelectedPeriod(bundledProduct, periodName)
      : undefined

    // Hack na promocje nadrzędną dla hosting + ssl (ONESTORE-4684)
    if (bundledProduct && bundledPeriod) {
      const bundledPrice =
        bundledPeriod.parent_promotions.find(
          (item) => item.parent_plan_id === product.id
        ) ?? null

      if (bundledPrice !== null) {
        bundledPeriod = {
          ...bundledPeriod,
          price_register: bundledPrice.price_register,
        }
      }
    }

    return new PeriodInfo(selectedPeriod, bundledPeriod)
  }

  const [changedPrice, setPrice] = useState(
    getPricingData(selectedPlan, bundledProduct, period)
  )

  const productId = selectedPlan.id
  const bundledProductId = bundledProduct?.id

  useEffect(() => {
    setPrice(getPricingData(selectedPlan, bundledProduct, period))
  }, [productId, bundledProductId, period])

  const item: BasketPatchItem = {
    plan: productId,
    quantity: 1,
  }

  if (bundledProductId) {
    item.children = [
      {
        plan: bundledProductId,
        quantity: 1,
      },
    ]
  }

  const periodText = periodInfo ? periodInfo.periodText() : null

  const periodInfoValues = periodInfo
    ? periodInfo.usePrices(priceType)
    : {
        priceValue: "",
        lowestPrice: "",
        lowestPricePercent: "",
        renewPrice: "",
      }

  const changedPeriodInfoValues = changedPrice
    ? changedPrice.usePrices(priceType)
    : {
        priceValue: "",
        lowestPrice: "",
        lowestPricePercent: "",
        renewPrice: "",
      }

  const { priceValue, lowestPrice, lowestPricePercent, renewPrice } =
    !isMultiPlan && !hasChangedDefaultPlan
      ? periodInfoValues
      : changedPeriodInfoValues

  const updatedPeriodInfo =
    !isMultiPlan && !hasChangedDefaultPlan ? periodInfo : changedPrice

  const hasPromotion = updatedPeriodInfo?.hasPromotion() ?? false

  const hasLimitTooltip = updatedPeriodInfo?.hasLimit() ?? false

  const priceSuffix = `${priceFormat} ${periodText}`

  const promotionPriceTooltip = !isEmpty(saleConfiguration)
    ? get(saleConfiguration[0], "flatData.defaultCloudBluePlan[0].flatData")
        .promotionPriceTooltip
    : null

  const tooltip = !isEmpty(promotionPriceTooltip)
    ? promotionPriceTooltip[0].flatData.text
    : null

  const calculatedPriceValue = hideCents
    ? priceValue.replace(/,\d{2}\s([a-zA-Z]+)/, " $1")
    : priceValue

  const calculatedLowestPrice = hideCents
    ? lowestPrice.replace(/,\d{2}\s([a-zA-Z]+)/, " $1")
    : lowestPrice

  const price =
    !isTrialBox && periodInfo ? (
      <ProductBoxPrice
        value={calculatedPriceValue}
        suffix={priceSuffix}
        tooltip={
          hasPromotion && tooltip ? (
            <TooltipMol iconSize="large">
              <MarkdownTile>{tooltip}</MarkdownTile>
            </TooltipMol>
          ) : null
        }
        additionalText={
          hasPromotion && !isEmpty(calculatedLowestPrice) ? (
            <PushOrg topSpace={2}>
              <LowestPriceInfo
                value={calculatedLowestPrice}
                percent={lowestPricePercent}
              />
            </PushOrg>
          ) : null
        }
        hasPromotion={hasPromotion}
        hasDarkMode={hasDarkMode}
      />
    ) : null

  ///

  const href = url.generateBonusUrl({
    p: selectedPlan.alias,
    b: selectedBundlePlan?.alias || undefined,
    ax: upsell,
    u: upgrade,
    code: code,
    period: period,
    domainSearch: !isEmpty(saleConfiguration)
      ? getDomainsPoolId(saleConfiguration[0])
      : undefined,
  })

  let hrefChangePeriod: string

  if (!isEmpty(changePeriod)) {
    hrefChangePeriod = url.generateBonusUrl({
      p: selectedPlan.alias,
      b: selectedBundlePlan?.alias || undefined,
      ax: upsell,
      u: upgrade,
      code: code,
      period: changePeriod,
      domainSearch: !isEmpty(saleConfiguration)
        ? getDomainsPoolId(saleConfiguration[0])
        : undefined,
    })
  }

  /// renew price
  const productName = saleConfiguration
    ? saleConfiguration[0].flatData.name
    : null
  const bundledProductName = bundlePlan ? bundlePlan[0].flatData.name : null

  const productText = `${_t(
    `periodName.renewPrice.${periodInfo?.getPeriodType()}`,
    {
      smart_count: periodInfo?.getPeriodLength(),
    }
  )}`

  const formatedRenewPrice = hideCents
    ? renewPrice.replace(/,\d{2}\s([a-zA-Z]+)/, " $1")
    : renewPrice

  let priceRenewal: string | string[][] = `${_t(
    "prices.renewal"
  )}: ${formatedRenewPrice} ${priceFormat} ${productText}`

  if (checked && bundlePeriod?.period_name !== cloudBluePeriod?.period_name) {
    const productPeriodInfo = cloudBluePeriod
      ? new PeriodInfo(cloudBluePeriod)
      : null

    const productPeriodText = `${_t(
      `periodName.renewPrice.${productPeriodInfo?.getPeriodType()}`,
      {
        smart_count: productPeriodInfo?.getPeriodLength(),
      }
    )}`

    const bundlePeriodInfo = bundlePeriod ? new PeriodInfo(bundlePeriod) : null

    const bundleProductPeriodText = `${_t(
      `periodName.renewPrice.${bundlePeriodInfo?.getPeriodType()}`,
      {
        smart_count: bundlePeriodInfo?.getPeriodLength(),
      }
    )}`

    priceRenewal = []

    if (productPeriodInfo?.hasRenewalPrice()) {
      priceRenewal.push([
        `${_t("prices.renewal")} ${productName}: ${
          productPeriodInfo?.getFormatted(
            priceType === PriceType.NETTO
              ? PriceFormat.RENEW_NETTO_NO_PERIOD
              : PriceFormat.RENEW_GROSS_NO_PERIOD
          ) ?? ""
        } ${priceFormat} ${productPeriodText}`,
      ])
    }

    if (bundlePeriodInfo?.hasRenewalPrice()) {
      priceRenewal.push([
        `${_t("prices.renewal")} ${bundledProductName}: ${
          bundlePeriodInfo?.getFormatted(
            priceType === PriceType.NETTO
              ? PriceFormat.RENEW_NETTO_NO_PERIOD
              : PriceFormat.RENEW_GROSS_NO_PERIOD
          ) ?? ""
        } ${priceFormat} ${bundleProductPeriodText}`,
      ])
    }
  }

  if (isOneTimeFee) {
    priceRenewal = _t("bonus.oneTimeFee")
  }

  const renewalPayment =
    !isTrialBox && (!initialHideRenewalPrices || !hideRenewalPrices)
      ? additionalText || !isEmpty(renewPrice)
        ? priceRenewal
        : undefined
      : undefined
  ///

  const label =
    hasPromotion || !isEmpty(discountLabel) || hasLimitTooltip ? (
      <FlexContainerOrg gapSpace={1} wrap="wrap">
        {hasPromotion ? (
          <LabelAtm size="small" variant={"notifyLight"} emphasis="high">
            {_t("marketplace.discountTitle")}
          </LabelAtm>
        ) : !isEmpty(discountLabel) ? (
          <LabelAtm
            size="small"
            variant={discountLabelVariant ?? "notifyLight"}
            emphasis="high"
            wrapLabel
          >
            {discountLabel}
          </LabelAtm>
        ) : null}

        {hasLimitTooltip && limitTooltip ? (
          <LimitTooltipLabel tooltipText={limitTooltip} />
        ) : null}
      </FlexContainerOrg>
    ) : undefined

  const button: Omit<ButtonAtmProps, "size" | "isWider" | "isExpanded"> = {
    text: buttonText || _t("marketplace.chooseButton"),
    title: buttonText || _t("marketplace.chooseButton"),
    rel: "nofollow",
    color: buttonColor,
    onClick: () => {
      document.location.replace(href)

      productBoxClickEvent({
        productbox_type: "button",
        productbox_option: headerTitle,
        productbox_checked_item: planID || undefined,
        productboxbox_time: periodInfo?.period.period_name,
        productboxbox_package: href,
      })
    },
  }

  const parameters = (
    <>
      {content.parameters}

      {planInputLabel && !!numberInputProps && !!selectedQuantity ? (
        <ParametersGroup key={`parameter-group-${planInputLabel}`}>
          <ParameterItem
            label={planInputLabel}
            cta={
              <AlignContentOrg horizontalAlign="center">
                <NumberInputMol {...numberInputProps} />
              </AlignContentOrg>
            }
          />
        </ParametersGroup>
      ) : null}
    </>
  )

  const footer = content?.footer ? (
    <ProductBoxFooter
      {...content.footer}
      bundlePriceFormat={bundlePriceFormat}
      setIsChecked={handleSetIsChecked}
      isChecked={checked}
    />
  ) : undefined

  const onChangePeriodModalClick = () => {
    document.location.replace(hrefChangePeriod)

    productBoxClickEvent({
      productbox_type: "button",
      productbox_option: headerTitle,
      productbox_checked_item: planID || undefined,
      productboxbox_time: changePeriod,
      productboxbox_package: hrefChangePeriod,
    })
  }

  const { customOnColorText, imageBackground } = getBackground(backgroundImage)

  return {
    id,
    selectedPlan,
    title: headerTitle,
    description,
    promoText,
    subtitle,
    label,
    price,
    button,
    renewalPayment,
    parameters,
    footer,
    outerFooter,
    hasDarkMode,
    customTextColor:
      isTrialBox && customOnColorText ? customOnColorText : undefined,
    backgroundImage:
      isTrialBox && imageBackground ? imageBackground : undefined,
    onChangePeriodModalClick,
    isTrialBox,
  }
}

import type { ReactElement } from "react"
import React from "react"
import { typeMatches } from "@onestore-graphql"
import type Theme from "@onestore/hel/dist/typings/theme"
import ArrowLink from "@gatsby-plugin-generic-page/components/ArrowLink"
import Link from "@gatsby-plugin-generic-page/components/Link"
import UnderlineLink from "@gatsby-plugin-generic-page/components/UnderlineLink"
import type { CtaElement } from "@gatsby-plugin-generic-page/fragments/ctaElement"
import type { CtaLink as CtaLinkType } from "@gatsby-plugin-generic-page/fragments/ctaLink"
import { SquidexLinkType } from "@gatsby-plugin-generic-page/types/ctaElementType"
import isEmpty from "~/lib/isEmpty"

interface Props {
  link: CtaElement
  typography?: Theme.Typography
}

function CtaLink({ link, typography }: Props): ReactElement<Props> | null {
  const { ctaType } = link.flatData
  const { linkType } = ctaType as CtaLinkType

  if (isEmpty(linkType)) {
    return null
  }

  if (typeMatches(linkType, SquidexLinkType.UNDERLINE)) {
    return <UnderlineLink typography={typography} key={link.id} link={link} />
  }

  if (linkType === SquidexLinkType.ARROW) {
    return <ArrowLink typography={typography} key={link.id} link={link} />
  }

  return <Link key={link.id} typography={typography} link={link} />
}

export default CtaLink

import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import { navigate } from "gatsby"
import TableMol from "@onestore/hel/dist/components/molecules/TableMol"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import useProductAddToBasket from "~/hooks/useProductAddToBasket"
import url from "~/lib/url"
import type { PeriodName } from "~/types/squidex"

interface AddBasketButtonProps {
  product: CloudBluePlan["flatData"]
  name: string
  saleConfigurationId: string
  isDisabled?: boolean
  selectedPeriod?: PeriodName
}

export function AddToBasketButton({
  product,
  name,
  saleConfigurationId,
  isDisabled,
  selectedPeriod,
}: AddBasketButtonProps): ReactElement<AddBasketButtonProps> {
  const onSuccess = () => {
    navigate(url.getBasketUrl())
  }
  const { isLoading, addProduct } = useProductAddToBasket(
    saleConfigurationId,
    selectedPeriod
  )

  return (
    <TableMol.FooterButton
      isLoading={isLoading}
      onClick={() => {
        addProduct(product, onSuccess)
      }}
      title={name}
      rel="nofollow"
      text={_t("marketplace.chooseButton")}
      to={undefined}
      href={undefined}
      isDisabled={isDisabled}
    />
  )
}

import isServer from "~/lib/isServer"

/**
 * @returns {string} Zwraca ciąg parametrów z aktualnego adresu.
 */
export default function getQuery(): string {
  if (isServer()) {
    return ""
  }

  return window.location.search
}

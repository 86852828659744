import type { ReactElement } from "react"
import React, { createContext, useEffect } from "react"
import type { Basket } from "@onestore/api/basket"
import { loadUserDataForToken } from "@gatsby-plugin-basket/store/actions"
import { getBasketToken } from "@gatsby-plugin-basket/store/selectors"
import { useAppSelector, useAppDispatch } from "~/store/hooks"
import { getUserDataReady } from "~/store/selectors"

const UserdataContext = createContext(false)

export default function ({ children }): ReactElement {
  const dispatch = useAppDispatch()
  const token = useAppSelector<Basket.Token>(getBasketToken)
  const isReady = useAppSelector<boolean>(getUserDataReady)

  useEffect(() => {
    dispatch(loadUserDataForToken(token))
  }, [])

  return (
    <UserdataContext.Provider value={isReady}>
      {children}
    </UserdataContext.Provider>
  )
}

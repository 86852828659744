import type { ReactElement } from "react"
import React from "react"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import NumberInputMol from "@onestore/hel/dist/components/molecules/NumberInputMol"

interface Props {
  label: string
  value: number
  minValue: number
  maxValue: number
  isDisable?: boolean
  handleIncrementButton: () => void
  handleDecrementButton: () => void
}

export default function CounterConfigElement({
  label,
  value,
  minValue,
  maxValue,
  isDisable,
  handleIncrementButton,
  handleDecrementButton,
}: Props): ReactElement<Props> {
  const onIncrementButtonClick = () => {
    if (value < maxValue && !isDisable) {
      handleIncrementButton()
    }
  }

  const onDecrementButtonClick = () => {
    if (value > minValue && !isDisable) {
      handleDecrementButton()
    }
  }

  return (
    <div>
      <TextAtm typography="small3">{label}</TextAtm>

      <NumberInputMol
        onIncrementButtonClick={onIncrementButtonClick}
        onDecrementButtonClick={onDecrementButtonClick}
        isDecrementButtonDisabled={isDisable}
        isIncrementButtonDisabled={isDisable}
        value={value.toString()}
        backgroundColor={"rgba(0, 0, 0, 0.1)"}
        onChange={() => null}
      />
    </div>
  )
}

import React from "react"
import { get } from "lodash"
import Invoices from "../layouts/Invoices"

export default ({ element, props }, options) => {
  if (get(props, "pageContext.wrapperLayout") !== "Invoices") {
    return element
  }

  // Na podstronach niepowodzenie/dziekujemy CheckoutContext jest zbędny bo działamy na oldToken
  return <Invoices {...props}>{element}</Invoices>
}

import type { ReactElement, ReactNode } from "react"
import React from "react"
import _t from "@core/i18n"
import type { GenericSection } from "@onestore-graphql/Sections"
import ButtonAtm from "@onestore/hel/dist/components/atoms/ButtonAtm"
import SquidexToolbar from "../SquidexToolbar"

interface SquidexToolbarSectionWrapperProps {
  children: ReactNode
  urlToSection: GenericSection["url"]
}

function SquidexToolbarSectionWrapper({
  urlToSection,
  children,
}: SquidexToolbarSectionWrapperProps): ReactElement<SquidexToolbarSectionWrapperProps> {
  if (process.env.GATSBY_SQUIDEX_TOOLBAR === "true") {
    return (
      <SquidexToolbar
        button={
          <ButtonAtm
            isWider
            href={urlToSection}
            title={urlToSection}
            color="notify"
            openInNewTab
          >
            {_t("button.edit")}
          </ButtonAtm>
        }
      >
        {children}
      </SquidexToolbar>
    )
  }

  return <>{children}</>
}

export default SquidexToolbarSectionWrapper

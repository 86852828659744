import type { PeriodInfo } from "@core/period-info"
import { PriceFormat } from "@core/types"

function getPriceFromPlan(periodInfo: PeriodInfo, isPriceTypeNetto: boolean) {
  const suffix = periodInfo.getFormatted(
    isPriceTypeNetto
      ? PriceFormat.NETTO_PRICE_TYPE_WITH_PERIOD_NAME
      : PriceFormat.GROSS_PRICE_TYPE_WITH_PERIOD_NAME
  )

  const value = periodInfo.getFormatted(
    isPriceTypeNetto
      ? PriceFormat.NO_PROMO_NETTO_NO_CURRENCY_NO_PERIOD
      : PriceFormat.REGISTER_GROSS_NO_CURRENCY_NO_PERIOD
  )

  const integer = periodInfo.getFormatted(
    isPriceTypeNetto
      ? PriceFormat.NO_PROMO_NETTO_DECIMALS
      : PriceFormat.REGISTER_GROSS_DECIMALS
  )

  const fraction = periodInfo.getFormatted(
    isPriceTypeNetto
      ? PriceFormat.REGISTER_NETTO_CENTS
      : PriceFormat.REGISTER_GROSS_CENTS
  )

  const noPromoPrice = periodInfo.getFormatted(
    isPriceTypeNetto
      ? PriceFormat.RENEW_NETTO_NO_PERIOD
      : PriceFormat.RENEW_GROSS_NO_PERIOD
  )

  return { suffix, value, integer, fraction, noPromoPrice }
}

export default getPriceFromPlan

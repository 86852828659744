import type { ProductAlias } from "@onestore/api/types"
import type { BonusResourceCategory } from "@gatsby-plugin-bonus/types"

interface ProductDefinitionResource {
  id: number
  setup_fee_changed_per_unit: boolean
  alias: null | ProductAlias
  name: string
  description: string
  measure_unit: string
}

export interface ProductDefinitionResourceCategory {
  id: number
  name: string
  resources?: ProductDefinitionResource[]
  description: string
  parent?: number | null
  default_resource_id: null | number
  default_resource_category: null
  optional: boolean
  display_type: number
  sort_order: number
}

export interface NormalizedBonusResource {
  id: number
  alias: ProductAlias | null
  description: string
  name: string
  measureUnit: string
}

export function resourceNormalizer({
  id,
  alias,
  description,
  name,
  measure_unit,
}: ProductDefinitionResource): NormalizedBonusResource {
  return {
    id,
    alias,
    description,
    name,
    measureUnit: measure_unit,
  }
}

export function resourceCategoryNormalizer({
  id,
  name,
  sort_order,
  resources,
  parent,
  optional,
  display_type,
  description,
  default_resource_id,
}: ProductDefinitionResourceCategory): BonusResourceCategory {
  return {
    id,
    name,
    resources: (resources || []).map(resourceNormalizer),
    parent,
    description,
    noneOption: optional && display_type === 1,
    displayType: display_type,
    sortOrder: sort_order,
    defaultResourceId: default_resource_id,
  }
}

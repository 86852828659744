import type { ContainerSettings } from "@gatsby-plugin-generic-page/fragments/containerSettings"
import { MarginType } from "@gatsby-plugin-generic-page/fragments/containerSettings"

const getHasZindex = (containerSettings?: ContainerSettings) => {
  if (!containerSettings) {
    return false
  }

  const { negativeMarginTop, negativeMarginBottom } = containerSettings

  if (
    negativeMarginTop !== MarginType.STICKY ||
    negativeMarginBottom !== MarginType.STICKY
  ) {
    return true
  }

  return false
}

export default getHasZindex

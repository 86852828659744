import { typeMatches } from "@onestore-graphql"
import type { BorderRadiusSize } from "@onestore/hel/dist/components/organisms/SurfaceOrg/SurfaceOrg.types"
import type { BorderRadius } from "@gatsby-plugin-generic-page/fragments/borderRadius"
import type { BorderRadiusBreakpoints } from "@gatsby-plugin-generic-page/fragments/borderRadiusBreakpoints"
import { SquidexComponentType } from "@gatsby-plugin-generic-page/types/componentType"

function getBorderRadiusSize(
  borderRadius: BorderRadius | BorderRadiusBreakpoints
): BorderRadiusSize {
  if (
    typeof borderRadius === "object" &&
    typeMatches(
      borderRadius.__typename,
      SquidexComponentType.BORDER_RADIUS_BREAKPOINTS
    )
  ) {
    const { small, medium, large, huge } =
      borderRadius as BorderRadiusBreakpoints

    return {
      small: small.size,
      medium: medium.size,
      large: large.size,
      huge: huge.size,
    }
  } else if (
    typeMatches(borderRadius.__typename, SquidexComponentType.BORDER_RADIUS)
  ) {
    const { size } = borderRadius as BorderRadius

    return size
  }

  return "none"
}

export default getBorderRadiusSize

import React from "react"
import { createContext, useState } from "react"
import type { PriceType } from "@core/types"
import useSiteMetadataQuery from "~/hooks/useSiteMetadataQuery"
import { productBoxClickEvent } from "~/lib/ga4"

interface DomainPriceTypeProps {
  currentPriceType: PriceType
  setPriceType: { (priceType: PriceType): void }
}

export const DomainPriceTypeContext =
  createContext<DomainPriceTypeProps | null>(null)

export default DomainPriceTypeContext

export function DomainPriceTypeContextProvider({ children }) {
  const { defaultPriceType } = useSiteMetadataQuery()
  const [currentPriceType, setCurrentPriceType] = useState(defaultPriceType)

  const setPriceType = (priceType) => {
    productBoxClickEvent({
      productbox_type: "toggle",
      productbox_option: priceType,
    })
    setCurrentPriceType(priceType)
  }

  return (
    <DomainPriceTypeContext.Provider
      value={{
        currentPriceType,
        setPriceType,
      }}
    >
      {children}
    </DomainPriceTypeContext.Provider>
  )
}

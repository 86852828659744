import type { ReactNode } from "react"
import React from "react"
import { connect, mapProps } from "@formily/react"
import GridOrg from "@onestore/hel/dist/components/organisms/GridOrg"

interface FormGridProps {
  maxColumns?: number
  children: ReactNode
}

interface GridColumnProps {
  children: ReactNode
}

type ComposedFormGrid = React.FC & {
  GridColumn?: React.FC<GridColumnProps>
}

export const FormGridLayout = ({ maxColumns, children }: FormGridProps) => {
  return (
    <GridOrg
      columns={{
        large: maxColumns || 1,
        medium: 1,
        small: 1,
      }}
      gutterSpace={2}
    >
      {children}
    </GridOrg>
  )
}

const FormGrid: ComposedFormGrid = connect(
  FormGridLayout,
  mapProps({}, (props: FormGridProps) => {
    return {
      maxColumns: props.maxColumns,
      children: props.children,
    }
  })
)

FormGrid.GridColumn = ({ children }: GridColumnProps) => <>{children}</>

export default FormGrid

import type { ReactElement } from "react"
import React from "react"
import ArrowLinkAtm from "@onestore/hel/dist/components/atoms/ArrowLinkAtm"
import LinkAtm from "@onestore/hel/dist/components/atoms/LinkAtm"
import UnderlineLinkAtm from "@onestore/hel/dist/components/atoms/UnderlineLinkAtm"
import type Theme from "@onestore/hel/dist/typings/theme"
import FormilyFormButton from "@gatsby-plugin-generic-page/components/FormilyFormButton"
import type { Formily as FormilyType } from "@gatsby-plugin-generic-page/fragments/formily"
import { SquidexLinkType } from "@gatsby-plugin-generic-page/types/ctaElementType"

export type FormilyFormLinkProps = {
  title: string
  text: string
  typography?: Theme.Typography
  linkType?: SquidexLinkType
  formilyForm: FormilyType
}

export default function FormilyFormLink({
  title,
  text,
  typography = "small2",
  linkType,
  formilyForm,
}: FormilyFormLinkProps): ReactElement<FormilyFormLinkProps> {
  return (
    <>
      {linkType === SquidexLinkType.DEFAULT && (
        <FormilyFormButton
          button={(onClick) => (
            <LinkAtm
              data-test-id="form-button"
              title={title}
              typography={typography}
              onClick={onClick}
            >
              {text}
            </LinkAtm>
          )}
          formilyForm={formilyForm}
        />
      )}

      {linkType === SquidexLinkType.UNDERLINE && (
        <FormilyFormButton
          button={(onClick) => (
            <UnderlineLinkAtm
              data-test-id="form-button"
              title={title}
              typography={typography}
              onClick={onClick}
            >
              {text}
            </UnderlineLinkAtm>
          )}
          formilyForm={formilyForm}
        />
      )}

      {linkType === SquidexLinkType.ARROW && (
        <FormilyFormButton
          button={(onClick) => (
            <ArrowLinkAtm
              data-test-id="form-button"
              title={title}
              typography={typography}
              onClick={onClick}
            >
              {text}
            </ArrowLinkAtm>
          )}
          formilyForm={formilyForm}
        />
      )}
    </>
  )
}

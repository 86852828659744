import type { ReactElement } from "react"
import React from "react"
import CardWithInfoMol from "@onestore/hel/dist/components/molecules/CardWithInfoMol"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import type { CardWithInfoFlatData } from "@gatsby-plugin-generic-page/fragments/cardWithInfo"
import getResponsiveBackgroundImage from "@gatsby-plugin-generic-page/helpers/getResponsiveBackgroundImage"
import isEmpty from "~/lib/isEmpty"
import CtaElement from "../CtaElement"

export default function CardWithInfo({
  title,
  description,
  ctaElement,
  responsiveImages,
  backgroundPosition,
}: CardWithInfoFlatData): ReactElement<CardWithInfoFlatData> {
  const contentElement = (
    <>
      {!isEmpty(title) ? (
        <PushOrg bottomSpace={1}>
          <MarkdownText
            typography={{ bold: "big3", default: "big3" }}
            hasOverridedSmallTypography
          >
            {title}
          </MarkdownText>
        </PushOrg>
      ) : null}

      <MarkdownText
        typography={{ bold: "medium3_s", default: "medium1_s" }}
        hasOverridedSmallTypography
      >
        {description}
      </MarkdownText>

      {!isEmpty(ctaElement) ? (
        <PushOrg topSpace={2}>
          <CtaElement ctaElement={ctaElement} />
        </PushOrg>
      ) : null}
    </>
  )

  const backgroundImage = getResponsiveBackgroundImage(
    responsiveImages,
    backgroundPosition
  )

  return (
    <CardWithInfoMol
      backgroundImage={backgroundImage}
      content={contentElement}
      cardHeightSpace={57.5}
    />
  )
}

import type { ReactElement } from "react"
import React from "react"
import type { InfoBar as InfoBarProps } from "@onestore-graphql/Model"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import InfoBarMol from "@onestore/hel/dist/components/molecules/InfoBarMol"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import FlexItemOrg from "@onestore/hel/dist/components/organisms/FlexItemOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import Picture from "@onestore/hel/dist/components/quarks/Picture"
import ConditionalWrapper from "@onestore/hel/dist/helpers/ConditionalWrapper"
import BaseMarkdown from "@gatsby-plugin-generic-page/components/Markdown/Base"
import { infoBar } from "@gatsby-plugin-generic-page/markdown/infoBar"
import Display from "~/components/Display"
import assetsSquidexToImageObject from "~/lib/assetsSquidexToImageObject"
import getPeriodUntillNow from "~/lib/getPeriodUntilNow"

export default function InfoBar({
  flatData: { text, barColor, icon, period },
}: InfoBarProps): ReactElement<InfoBarProps> {
  const display = period && getPeriodUntillNow(period)

  return (
    <ConditionalWrapper
      condition={!!display}
      wrapper={(contentJsx: ReactElement) => (
        <Display from={display!.from} to={display!.to}>
          {contentJsx}
        </Display>
      )}
    >
      <InfoBarMol backgroundColor={barColor.color} hasBorderRadius>
        <FlexContainerOrg justifyContent="center" alignItems="center">
          {icon ? (
            <FlexItemOrg flexShrink={0}>
              <PushOrg rightSpace={1} hasNoReset>
                <Picture srcData={assetsSquidexToImageObject(icon, "")} />
              </PushOrg>
            </FlexItemOrg>
          ) : null}

          <BaseMarkdown
            options={{
              wrapper: ({ children }) => (
                <TextAtm typography="small2">{children}</TextAtm>
              ),
              forceWrapper: true,
              overrides: infoBar,
            }}
          >
            {text}
          </BaseMarkdown>
        </FlexContainerOrg>
      </InfoBarMol>
    </ConditionalWrapper>
  )
}

import type { MarkdownToJSX } from "markdown-to-jsx"
import type { NestedLinkAtmProps } from "@onestore/hel/dist/components/atoms/NestedLinkAtm"
import NestedLinkAtm from "@onestore/hel/dist/components/atoms/NestedLinkAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import type { TextAtmProps } from "@onestore/hel/dist/components/atoms/TextAtm/TextAtm.props"
import ListItem from "@gatsby-plugin-generic-page/markdown/components/ListItem"

const titleTypography = "great"
const titlePushSpace = 1.5

const modalTextOverrides: MarkdownToJSX.Overrides = {
  strong: {
    component: TextAtm,
    props: {
      typography: "medium2_h",
    } as TextAtmProps,
  },
  h1: {
    component: TextAtm,
    props: {
      htmlTag: "h1",
      pushSpace: titlePushSpace,
      typography: titleTypography,
    } as TextAtmProps,
  },
  h2: {
    component: TextAtm,
    props: {
      htmlTag: "h2",
      pushSpace: titlePushSpace,
      typography: titleTypography,
    } as TextAtmProps,
  },
  h3: {
    component: TextAtm,
    props: {
      htmlTag: "h3",
      pushSpace: titlePushSpace,
      typography: titleTypography,
    } as TextAtmProps,
  },
  p: {
    component: TextAtm,
    props: {
      htmlTag: "p",
      pushSpace: 1,
      typography: "medium1_h",
    } as TextAtmProps,
  },
  a: {
    component: NestedLinkAtm,
    props: {
      openInNewTab: true,
    } as NestedLinkAtmProps,
  },
  li: {
    component: ListItem,
  },
}

export default modalTextOverrides

export enum SquidexComponentType {
  ADD_TO_BASKET = "CtaCloudbluePlanComponent",
  ANCHOR_ID = "AnchorIdComponent",
  BACKGROUND_COLOR = "BackgroundColorComponent",
  BORDER_RADIUS = "BorderRadiusComponent",
  BORDER_RADIUS_BREAKPOINTS = "BorderRadiusBreakpointsComponent",
  BUTTON = "ButtonElementComponent",
  CAPTION = "CaptionComponent",
  CAPTION_ICON = "CaptionIconComponent",
  COUNTDOWN = "CountdownComponent",
  CTA_BUTTON = "CtaButtonComponent",
  CTA_IMAGE_BUTTON = "CtaImageButtonComponent",
  CTA_LINK = "CtaLinkComponent",
  CTA_LOTTIE_BUTTON = "CtaLottieButtonComponent",
  OPEN_FORM = "CtaFormComponent",
  OPEN_MODAL = "CtaModalComponent",
  CUSTOM_COLOR = "CustomColorComponent",
  EXTERNAL_LINK = "ExternalLinkComponent",
  FOOTER_TEXT = "FooterTextComponent",
  SCROLL_TO_SECTION = "AnchorIdComponent",
  GRADIENT = "GradientComponent",
  LINK = "LinkElementComponent",
  INTERNAL_LINK = "InternalLinkComponent",
  LABEL = "LabelComponent",
  LOGO_IMAGE = "LogoImageComponent",
  LOGO_TEXT = "LogoTextComponent",
  MODAL_WITH_BUTTONS_COMPONENT = "ModalWithButtonsComponent",
  PRICE = "PriceComponent",
  SPACING = "SpacingComponent",
  SPACING_BREAKPOINTS = "SpacingBreakpointsComponent",
  THEME_BACKGROUND_COLOR = "ThemeBackgroundColorComponent",
  THEME_ACCENT_COLOR = "ThemeAccentColorComponent",
  TOGGLE = "ToggleComponent",
  PERIODS_TABLE_SELECT = "PeriodsTableSelectComponent",
  DATA_CENTER_ELEMENT = "DataCenterElementComponent",
  RESOURCE_CATEGORY_ELEMENT = "CloudblueResourceCategoryElementComponent",
  BACKGROUND_IMAGES = "BackgroundImagesComponent",
  FAST_CHECKOUT = "CtaFastCheckoutComponent",
}

import type { ReactElement } from "react"
import React from "react"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import ProductPriceMol from "@onestore/hel/dist/components/molecules/ProductPriceMol"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import type { CustomColorBackground } from "@onestore/hel/dist/utils/getCustomColor"
import type { TileProductPriceType } from "@gatsby-plugin-generic-page/fragments/tileProductPrice"
import getPriceBySquidexType from "@gatsby-plugin-generic-page/helpers/getPriceBySquidexType"
import useSiteMetadataQuery from "~/hooks/useSiteMetadataQuery"
import CtaElement from "../CtaElement"

interface Props extends TileProductPriceType {
  isActive?: boolean
  customColor?: CustomColorBackground
}

export default function TileProductPrice({
  title,
  price,
  text,
  ctaElements,
  isActive,
  customColor,
}: Props): ReactElement<Props> {
  const { defaultPriceType } = useSiteMetadataQuery()
  const priceValue = price && getPriceBySquidexType(price, defaultPriceType)

  const priceContent = (
    <TextAtm typography="xsmall1" emphasis="medium">
      {price ? (
        <>
          <TextAtm typography="xsmall2">
            {priceValue?.price.value} {priceValue?.price.currency}
          </TextAtm>{" "}
          {priceValue?.price.suffix}
        </>
      ) : (
        <TextAtm typography="xsmall2">{text}</TextAtm>
      )}
    </TextAtm>
  )

  const footer = (
    <PushOrg topSpace={6}>
      <AlignContentOrg horizontalAlign="center">
        {ctaElements.map((element, index) => (
          <PushOrg
            key={`${element.flatData.title}-${index}`}
            bottomSpace={2}
            hasForcedFullWidth
          >
            <CtaElement
              ctaElement={[
                {
                  ...element,
                  flatData: {
                    ...element.flatData,
                    ctaType: {
                      ...element.flatData.ctaType,
                      isExpanded: true,
                    },
                  },
                },
              ]}
            />
          </PushOrg>
        ))}
      </AlignContentOrg>
    </PushOrg>
  )

  return (
    <ProductPriceMol
      title={title}
      price={priceContent}
      footer={footer}
      isActive={!!isActive}
      customColor={customColor}
    />
  )
}

import type { ReactElement } from "react"
import React from "react"
import ItemsSliderContainerOrg from "@onestore/hel/dist/components/organisms/ItemsSliderContainerOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import CardWithInfo from "@gatsby-plugin-generic-page/components/CardWithInfo"
import type { CardsWithInfoSectionFlatData } from "@gatsby-plugin-generic-page/fragments/cardsWithInfoSection"

export default function CardsWithInfoSection({
  cards,
  sectionSettings,
}: CardsWithInfoSectionFlatData): ReactElement<CardsWithInfoSectionFlatData> {
  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        <ItemsSliderContainerOrg
          items={cards.map((card) => (
            <CardWithInfo key={card.description} {...card} />
          ))}
          itemMinWidthSpace={34.5}
          maxItemsToShow={4}
          gapSpace={3}
        />
      </BaseContainer>
    </BaseSection>
  )
}

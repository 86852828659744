import type { ReactElement } from "react"
import React from "react"
import type { VideoAtmProps } from "@onestore/hel/dist/components/atoms/VideoAtm"
import VideoAtm from "@onestore/hel/dist/components/atoms/VideoAtm"
import Picture from "@onestore/hel/dist/components/quarks/Picture"
import type { ImageProps } from "@onestore/hel/dist/components/quarks/Picture/Picture.types"
import type { EmbeddedAssetSquidexMetadata } from "~/fragments/image"
import assetsSquidexToImageObject from "~/lib/assetsSquidexToImageObject"
import assetsSquidexToVideoObject from "~/lib/assetsSquidexToVideoObject"
import type { ResponsiveImages } from "~/types/squidex"
import ResponsiveMedia from "../ResponsiveMedia"

interface MediaProps {
  media: ResponsiveImages | EmbeddedAssetSquidexMetadata[]
}

enum FileType {
  MP4 = "mp4",
}

function Media({
  media,
}: MediaProps): ReactElement<ImageProps | VideoAtmProps> {
  if (!Array.isArray(media)) {
    return <ResponsiveMedia media={media} />
  } else {
    const videoAssets = media.filter((asset) => asset.fileType === FileType.MP4)

    if (videoAssets.length > 0) {
      return (
        <VideoAtm
          video={assetsSquidexToVideoObject(videoAssets)}
          isLooped
          isAutoPlayed
          isMuted
          disablePictureInPicture
        />
      )
    }

    return <Picture srcData={assetsSquidexToImageObject(media, "")} />
  }
}

export default Media

import _t from "@core/i18n"
import type {
  CellValueObject,
  List,
  TableGroup,
  TableItems,
  TableValue,
  Tooltip,
} from "@onestore-graphql/Parameters"
import {
  flattenDeep,
  forEach,
  get,
  isEmpty,
  keys,
  map,
  reduce,
  set,
  trim,
  uniq,
} from "lodash"
import type { ComparableProduct } from "~/fragments/comparableProduct"

export function createProductParametersCellPricesValue(
  price: string,
  priceType?: string,
  specialType?: boolean
) {
  const value: CellValueObject = {
    suffix: priceType ? _t(`prices.${priceType}`) : "",
  }

  value[specialType ? "strong" : "text"] = price

  return {
    value: value,
    uuid: priceType ? `${price}-${_t(`prices.${priceType}`)}` : price,
  }
}

const createParametersList = (parameters: string): List => {
  const output: List = {}

  const parametersWithoutTooltipLink: string = parameters.replaceAll(
    "://",
    "#//"
  )

  const lines: string[] = trim(parametersWithoutTooltipLink).split("\n")
  let group: string = "_"

  forEach(lines, (line: string) => {
    if (isEmpty(line)) {
      return
    }

    if (line.indexOf(":") === -1 && !isEmpty(line)) {
      group = line
    } else {
      const [name, value] = line.split(":")
      set(output, [trim(group), trim(name)], trim(value))
    }
  })

  return output
}

export const createTooltipContent = (tooltip: RegExpMatchArray): Tooltip => {
  let tooltipContent: string = tooltip[0]
    .replace(/[[\]']+/g, "")
    .replaceAll("#//", "://")
  const tooltipLinks: RegExpMatchArray | null =
    tooltipContent.match(/\<.*?\>+/g)

  if (tooltipLinks) {
    const tooltipContentWithLinks: Tooltip = []

    tooltipLinks.forEach((link: string, index: number) => {
      const linkStartIndex: number = tooltipContent.indexOf(link)
      const linkEndIndex: number = tooltipContent.indexOf(link) + link.length
      const linkHrefReg: RegExpMatchArray | null = link.match(/\$.*?\$+/g)
      const linkHref: string = linkHrefReg
        ? linkHrefReg[0].replace(/[$]+/g, "")
        : ""
      const linkText: string = linkHrefReg
        ? link.replace(linkHrefReg[0], "").replace(/[<>]+/g, "")
        : ""

      const linkJsx = {
        title: linkText,
        text: linkText,
        href: linkHref,
      }

      tooltipContentWithLinks.push(
        tooltipContent.slice(0, linkStartIndex),
        linkJsx
      )

      tooltipContent = tooltipContent.slice(linkEndIndex, tooltipContent.length)

      if (tooltipLinks.length === index + 1) {
        tooltipContentWithLinks.push(tooltipContent)
      }
    })

    return tooltipContentWithLinks
  }

  return tooltipContent
}

export function compareProductParameters(
  products: ComparableProduct[]
): TableGroup[] {
  const list: List[] = []

  forEach(products, (item: ComparableProduct): void => {
    if (item.flatData.parameters === null) {
      item.flatData.parameters = "add content to this parameter"
    }

    list.push(createParametersList(get(item, "flatData.parameters", "")))
  })

  const groupsNames = uniq(flattenDeep(map(list, keys)))

  const items: TableGroup[] = []

  forEach(groupsNames, (group: string) => {
    const groupItems: TableItems[] = []

    const labels = uniq(
      reduce(
        map(list, (item) => keys(item[group])),
        (result: string[], value: string[]) => {
          forEach(value, (itemValue: string) => {
            result.push(itemValue)
          })

          return result
        },
        []
      )
    )

    forEach(labels, (label: string) => {
      const values: TableValue[] = []
      let labelObject

      const tooltip = label.match(/\[.*?\]+/g)
      const [title, items] = label.split(/[([]+/)
      const subtitle = label.match(/\(.*?\)+/g)

      if (items) {
        labelObject = {
          title,
          subtitle: subtitle && subtitle[0].replace(/[()']+/g, ""),
          tooltip: tooltip && createTooltipContent(tooltip),
        }
      }

      for (let i = 0; i < list.length; i += 1) {
        values.push({
          value: get(list, [i, group, label], null),
          uuid: `${i}${group}${label}`,
        })
      }

      groupItems.push({ label: labelObject || label, values })
    })

    items.push({ name: group, items: groupItems })
  })

  return items
}

import _t from "@core/i18n"
import type { Draft } from "immer"
import { produce } from "immer"
import { uniqueId } from "lodash"
import type {
  MessageState,
  NotificationMessage,
} from "@onestore/onestore-store-common"
import { CheckoutActions } from "@gatsby-plugin-checkout/store/constants"
import { MessageActionTypes } from "~/store/messages/actions"

export const initialState: MessageState = {
  global: [],
}

function appendMessage(
  state: MessageState,
  nextState: MessageState,
  message: NotificationMessage
): void {
  const messages = state.global.map((message) => message.message)

  if (!messages.includes(message.message)) {
    nextState.global.push(message)
  }
}

export default function reducer(
  state: MessageState = initialState,
  action: any
): MessageState {
  return produce<MessageState, MessageState>(
    state,
    (nextState: Draft<MessageState>) => {
      switch (action.type) {
        case CheckoutActions.AUTH_UNKNOWN_LOGIN:
          appendMessage(state, nextState, {
            id: uniqueId("msg"),
            type: "warning",
            title: _t("notifications.errorTitle"),
            message: _t("checkout.loginUnknownErrorMessage"),
          })

          return

        case MessageActionTypes.GLOBAL_MESSAGE_ADD:
          appendMessage(state, nextState, action.message)

          return

        case MessageActionTypes.GLOBAL_MESSAGE_REMOVE:
          nextState.global = state.global.filter(
            (message) => message.id !== action.id
          )

          return
        case MessageActionTypes.GLOBAL_MESSAGE_REMOVE_ALL:
          nextState.global = [...state.global].filter(
            (message) => "class" in message && message.class === "CITY_TYPO"
          )
          // eslint-disable-next-line
          return
      }
    }
  )
}

import { ReactElement, useEffect, useRef } from "react"

interface ContactLeaderProps {
  id: string
}

declare global {
  interface Window {
    __cleader?: {
      hide: { (): void }
    }
  }
}

export default function ContactLeader({
  id,
}: ContactLeaderProps): ReactElement<ContactLeaderProps> | null {
  const ref = useRef<HTMLScriptElement>()

  useEffect(() => {
    if (id) {
      const script = document.createElement("script")
      script.type = "text/javascript"
      script.async = true
      script.src = `//widget.contactleader.pl/f/${id}/cl.js`

      document.head.append(script)

      ref.current = script
    }

    return () => {
      if (window.__cleader) {
        window.__cleader.hide()

        document.querySelectorAll("[id$='scc']").forEach((el) => el.remove())

        window.__cleader = undefined
      }

      const currentRef = ref.current

      if (!currentRef) {
        return
      }

      currentRef.remove()
    }
  }, [id])

  return null
}

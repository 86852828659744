import type { SyntheticEvent } from "react"
import { useCallback } from "react"
import { scrollToAnchor } from "~/lib/dom"

export interface AnchorPropertiesHook {
  href: string
  onClick: { (event: SyntheticEvent) }
}

export default function useAnchorProperties(
  anchor: string
): AnchorPropertiesHook {
  const href: string = `#${anchor}`
  const onClick = useCallback(
    (event: SyntheticEvent): void => {
      event.preventDefault()

      scrollToAnchor(anchor)
    },
    [anchor]
  )

  return {
    href,
    onClick,
  }
}

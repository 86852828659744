import type { ReactElement } from "react"
import React from "react"
import type { PeriodsTableSelect } from "@gatsby-plugin-generic-page/fragments/periodsTableSelect"
import getPeriodsByPeriodVariants from "@gatsby-plugin-generic-page/helpers/getPeriodsByPeriodVariants"
import type { ConfigsHookResult } from "@gatsby-plugin-generic-page/hooks/useTableConfigs"
import isEmpty from "~/lib/isEmpty"
import SelectConfigElement from "./SelectConfigElement"

interface Props {
  configLabel: string
  periodsData: PeriodsTableSelect["periods"]
  currentPeriod: string
  onChange: ConfigsHookResult["handlePeriodChange"]
}

export default function PeriodsSelect({
  configLabel,
  periodsData,
  currentPeriod,
  onChange,
}: Props): ReactElement<Props> {
  const periods = periodsData.map((period) => ({
    value: period.value.period,
  }))

  const periodVariants = getPeriodsByPeriodVariants(periods)

  if (isEmpty(periodVariants)) {
    return <></>
  }

  const selectOptions = Object.values(periodVariants).map((variant) => ({
    label: variant.title,
    value: variant.period,
    isSelected: variant.period === currentPeriod,
  }))

  return (
    <SelectConfigElement
      configLabel={configLabel}
      options={selectOptions}
      onChange={(value) => onChange(value)}
    />
  )
}

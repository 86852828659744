import type { OnestoreVars } from "@onestore/onestore-store-common"
const onestoreVars = require("_/res/onestoreVars.json")
const storeSettings = require("_/res/storeSettings.json")

interface Settings extends OnestoreVars {
  taxValue: number
  apiUrl: string
  helpUrl: string
  imgDir: string
  locale: string
  panelUrl: string
  dimensions: string[]
  flags: {
    hideRenewalPrices: boolean
    bonusPriceType: "period" | "monthly"
  }
  domainsPeriodModalIds?: number[]
}

const vars: Settings = Object.freeze({
  apiUrl: process.env.GATSBY_ONESTORE_API_HOST,
  ...onestoreVars,
  ...storeSettings,
})

export default vars

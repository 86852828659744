import type { SaleConfiguration } from "~/fragments/saleConfigurations"
import isEmpty from "~/lib/isEmpty"

export const getDomainsPoolId = (
  saleConfiguration: SaleConfiguration
): string | null => {
  return !isEmpty(saleConfiguration.flatData.domainsPoolId)
    ? saleConfiguration.flatData.domainsPoolId[0].flatData.domainsPoolId
    : null
}

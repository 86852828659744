import styled from "styled-components"
import breakpoint from "@onestore/hel/dist/utils/breakpoint"
import space from "@onestore/hel/dist/utils/space"
import unit from "@onestore/hel/dist/utils/unit"

const ImageContainer = styled.div`
  align-self: stretch;

  & div {
    height: 100%;
  }

  & img {
    border-top-left-radius: ${unit(30)};
    border-bottom-left-radius: ${unit(30)};
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${breakpoint("small", "medium")} {
    & img {
      border-top-left-radius: ${unit(30)};
      border-top-right-radius: ${unit(30)};
      border-bottom-left-radius: 0;
    }
    justify-content: stretch;
    height: ${space(10)};
  }
`

export default ImageContainer

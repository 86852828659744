import type { BasketAction } from "@gatsby-plugin-basket/store/actions"
import { BasketActions } from "@gatsby-plugin-basket/store/constants"
import { getLastItemsFromBasket } from "@gatsby-plugin-basket/store/utils"
import { getHostingHookPlanId } from "~/lib/config"
import { STORAGE } from "~/lib/storage"
import SessionStorage from "~/lib/storage/SessionStorage"

const middleware = () => (next) => (action: BasketAction) => {
  if (action.type === BasketActions.BASKET_SUCCESS) {
    const addedItems = getLastItemsFromBasket(action.result)

    const hasHostingHookProduct = addedItems.some(
      (item) => "plan_id" in item && item.plan_id === getHostingHookPlanId()
    )

    if (hasHostingHookProduct) {
      SessionStorage.setBool(STORAGE.DOMAIN_HOOK_ADDED_TO_BASKET, true)
    }
  }

  return next(action)
}

export default middleware

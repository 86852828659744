import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import type { BundleButtonWithPrice } from "@gatsby-plugin-generic-page/fragments/bundleButtonWithPrice"
import type { ButtonElement } from "@gatsby-plugin-generic-page/fragments/buttonElement"
import type { StaticPrice } from "~/fragments/staticPrice"

export enum SimpleProductBoxPriceType {
  PLAN = "Plan",
  BUNDLE = "Bundle",
  STATIC = "Statyczna",
}

export interface SimpleProductBoxParameter {
  name: string
  value: string
}

export interface SimpleProductBox {
  id: string
  __typename: string
  flatData: {
    title: string
    parameters: SimpleProductBoxParameter[]
    tag?: string
    type: SimpleProductBoxPriceType
    plan: CloudBluePlan[]
    bundlePlan: BundleButtonWithPrice[]
    staticPrice: StaticPrice[]
    additionalText?: string
    button: ButtonElement
  }
}

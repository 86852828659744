import type { ReactElement, ReactNode } from "react"
import React from "react"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import IndicatorMol from "@onestore/hel/dist/components/molecules/IndicatorMol"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"

interface TextWithIndicatorProps {
  title: string
  text: ReactNode
  items: {
    title?: string
    value: number
  }[]
  maximum: number
}

export default function TextWithIndicator({
  title,
  text,
  items,
  maximum,
}: TextWithIndicatorProps): ReactElement<TextWithIndicatorProps> {
  return (
    <div>
      <TextAtm pushSpace={1.5} typography="medium4_s">
        {title}
      </TextAtm>

      {text}

      <PushOrg topSpace={3}>
        {items.map((item, index) => (
          <PushOrg
            bottomSpace={1.5}
            key={`text-with-indicator-item-${title}-${index}`}
          >
            {item.title && (
              <TextAtm typography="tiny1_u" pushSpace={0.5}>
                {item.title}
              </TextAtm>
            )}
            <IndicatorMol value={item.value} maximum={maximum} />
          </PushOrg>
        ))}
      </PushOrg>
    </div>
  )
}

import type { ReactNode } from "react"
import React from "react"
import Tick from "@onestore-icons/tick.svg"
import styled from "styled-components"
import IconAtm from "@onestore/hel/dist/components/atoms/IconAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import FlexItemOrg from "@onestore/hel/dist/components/organisms/FlexItemOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import type Theme from "@onestore/hel/dist/typings/theme"

const StyledLi = styled(PushOrg).attrs({ forwardedAs: "li" })``

export interface ListItemProps {
  typography?: Theme.Typography
  children: ReactNode
}
function ListItem({ children, typography }: ListItemProps) {
  return (
    <StyledLi bottomSpace={1.5}>
      <FlexContainerOrg alignItems="center">
        <FlexItemOrg flexShrink={0}>
          <IconAtm icon={Tick} sizeSpace={2} />
        </FlexItemOrg>

        <PushOrg leftSpace={1.5}>
          <TextAtm typography={typography ?? "medium1_h"}>{children}</TextAtm>
        </PushOrg>
      </FlexContainerOrg>
    </StyledLi>
  )
}

export default ListItem

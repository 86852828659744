import type {
  BasketLastItem,
  BasketResponse,
  BasketResponseItem,
} from "@onestore/api/basket"
import type { Price } from "@onestore/api/types"
import type { HTTP } from "@onestore/onestore-store-common"
import { itemsBasketPrice } from "@onestore/onestore-store-common/api/operations"
import { HTTP_STATUS } from "@onestore/onestore-store-common/http"
import {
  BasketActionSource,
  updateItemsInBasket,
} from "@gatsby-plugin-basket/store/actions"
import { normalizeMaxQuantity } from "@gatsby-plugin-bonus/lib/normalizers"
import type {
  BonusPeriod,
  BonusProduct,
  BonusProductForUpdate,
  BonusResource,
  BonusResourceWithBasketItem,
} from "@gatsby-plugin-bonus/types"
import api from "~/lib/api"
import type { AppState, AppDispatch } from "~/store/reducer"
import type { BonusThunkAction } from "./actions"
import {
  addBonusItemToBasket,
  changeAdvancedBonusItemResourcesGroup,
  changeBonusItemInBasket,
  changeBonusItemPrice,
  changeBonusItemResource,
  createBonusItemPeriod,
  getProductBasketWithResources,
} from "./actions-bonus"
import {
  getProduct,
  getUpsellProduct,
  isBonusParameterFormValid,
} from "./selectors"

export enum BonusUpsellActionType {
  BONUS_GET_UPSELL_PRICE_REQUEST = "@bonus/GET_UPSELL_PRICE_REQUEST",
  BONUS_GET_UPSELL_PRICE_SUCCESS = "@bonus/GET_UPSELL_PRICE_SUCCESS",
  BONUS_GET_UPSELL_PRICE_FAILURE = "@bonus/GET_UPSELL_PRICE_FAILURE",

  BONUS_CHANGE_UPSELL_PRICE = "@bonus/CHANGE_UPSELL_PRICE",

  BONUS_CHANGE_UPSELL_PERIOD_REQUEST = "@bonus/CHANGE_UPSELL_PERIOD_REQUEST",
  BONUS_CHANGE_UPSELL_PERIOD_SUCCESS = "@bonus/CHANGE_UPSELL_PERIOD_SUCCESS",
  BONUS_CHANGE_UPSELL_PERIOD_FAILURE = "@bonus/CHANGE_UPSELL_PERIOD_FAILURE",

  BONUS_UPSELL_ADD_TO_BASKET_REQUEST = "@bonus/UPSELL_ADD_TO_BASKET_REQUEST",
  BONUS_UPSELL_ADD_TO_BASKET_SUCCESS = "@bonus/UPSELL_ADD_TO_BASKET_SUCCESS",
  BONUS_UPSELL_ADD_TO_BASKET_FAILURE = "@bonus/UPSELL_ADD_TO_BASKET_FAILURE",

  BONUS_UPSELL_CHANGE_BASKET_REQUEST = "@bonus/UPSELL_CHANGE_BASKET_REQUEST",
  BONUS_UPSELL_CHANGE_BASKET_SUCCESS = "@bonus/UPSELL_CHANGE_BASKET_SUCCESS",
  BONUS_UPSELL_CHANGE_BASKET_FAILURE = "@bonus/UPSELL_CHANGE_BASKET_FAILURE",

  BONUS_UPSELL_CHANGE_RESOURCE_REQUEST = "@bonus/UPSELL_CHANGE_RESOURCE_REQUEST",
  BONUS_UPSELL_CHANGE_RESOURCE_SUCCESS = "@bonus/UPSELL_CHANGE_RESOURCE_SUCCESS",
  BONUS_UPSELL_CHANGE_RESOURCE_FAILURE = "@bonus/UPSELL_CHANGE_RESOURCE_FAILURE",

  BONUS_UPSELL_CHANGE_ADVANCED_RESOURCE_REQUEST = "@bonus/UPSELL_CHANGE_ADVANCED_RESOURCE_REQUEST",
  BONUS_UPSELL_CHANGE_ADVANCED_RESOURCE_SUCCESS = "@bonus/UPSELL_CHANGE_ADVANCED_RESOURCE_SUCCESS",
  BONUS_UPSELL_CHANGE_ADVANCED_RESOURCE_FAILURE = "@bonus/UPSELL_CHANGE_ADVANCED_RESOURCE_FAILURE",

  BONUS_VALIDATE_UPSELL_REQUEST = "@bonus/VALIDATE_UPSELL_REQUEST",
  BONUS_VALIDATE_UPSELL_SUCCESS = "@bonus/VALIDATE_UPSELL_SUCCESS",
  BONUS_VALIDATE_UPSELL_FAILURE = "@bonus/VALIDATE_UPSELL_FAILURE",

  BONUS_UPSELL_NEEDS_UPDATE = "@bonus/UPSELL_NEEDS_UPDATE",

  BONUS_UPSELL_DOMAINS_ADD_TO_BASKET_REQUEST = "@bonus/BONUS_UPSELL_DOMAINS_ADD_TO_BASKET_REQUEST",
  BONUS_UPSELL_DOMAINS_ADD_TO_BASKET_SUCCESS = "@bonus/BONUS_UPSELL_DOMAINS_ADD_TO_BASKET_SUCCESS",
  BONUS_UPSELL_DOMAINS_ADD_TO_BASKET_FAILURE = "@bonus/BONUS_UPSELL_DOMAINS_ADD_TO_BASKET_FAILURE",

  BONUS_UPSELL_CHANGE_PERIOD_CLICK = "@bonus/BONUS_UPSELL_CHANGE_PERIOD_CLICK",
}

interface BonusGetUpsellPriceRequestAction {
  status: HTTP.Status
  type: BonusUpsellActionType.BONUS_GET_UPSELL_PRICE_REQUEST
  upsell: BonusProduct
}
interface BonusGetUpsellPriceSuccessAction {
  type: BonusUpsellActionType.BONUS_GET_UPSELL_PRICE_SUCCESS
  status: HTTP.Status
  upsell: BonusProduct
}
interface BonusGetUpsellPriceFailureAction {
  error: string
  type: BonusUpsellActionType.BONUS_GET_UPSELL_PRICE_FAILURE
  status: HTTP.Status
  upsell: BonusProduct
}
interface BonusChangeUpsellPriceAction {
  periods: BonusPeriod[]
  type: BonusUpsellActionType.BONUS_CHANGE_UPSELL_PRICE
  upsell: BonusProduct
}
interface BonusChangeUpsellPeriodRequestAction {
  period: BonusPeriod
  type: BonusUpsellActionType.BONUS_CHANGE_UPSELL_PERIOD_REQUEST
  status: HTTP.Status
  upsell: BonusProduct
}
interface BonusChangeUpsellPeriodSuccessAction {
  regularPrice: Price
  status: HTTP.Status
  promoPrice: Price
  type: BonusUpsellActionType.BONUS_CHANGE_UPSELL_PERIOD_SUCCESS
  upsell: BonusProduct
}
interface BonusChangeUpsellPeriodFailureAction {
  error: string
  type: BonusUpsellActionType.BONUS_CHANGE_UPSELL_PERIOD_FAILURE
  status: HTTP.Status
  upsell: BonusProduct
}
interface BonusUpsellAddToBasketRequestAction {
  type: BonusUpsellActionType.BONUS_UPSELL_ADD_TO_BASKET_REQUEST
  status: HTTP.Status
  upsell: BonusProduct
}
interface BonusUpsellAddToBasketSuccessAction {
  resources: Record<string, BonusResource>
  basketItemId: number
  lastItems: BasketLastItem[]
  type: BonusUpsellActionType.BONUS_UPSELL_ADD_TO_BASKET_SUCCESS
  status: HTTP.Status
  upsell: BonusProduct
}
interface BonusUpsellAddToBasketFailureAction {
  error: string
  type: BonusUpsellActionType.BONUS_UPSELL_ADD_TO_BASKET_FAILURE
  status: HTTP.Status
  upsell: BonusProduct
}
interface BonusUpsellChangeBasketRequestAction {
  type: BonusUpsellActionType.BONUS_UPSELL_CHANGE_BASKET_REQUEST
  upsell: BonusProduct
}
interface BonusUpsellChangeBasketSuccessAction {
  type: BonusUpsellActionType.BONUS_UPSELL_CHANGE_BASKET_SUCCESS
  chosenPeriodId: number | null
  changedResources: Record<string, BonusResource>
  upsell: BonusProduct
  lastItems: BasketLastItem[]
}
interface BonusUpsellChangeBasketFailureAction {
  type: BonusUpsellActionType.BONUS_UPSELL_CHANGE_BASKET_FAILURE
  upsell: BonusProduct
}
export interface BonusUpsellChangeResourceRequestAction {
  quantity: number
  resource: BonusResource
  status: HTTP.Status
  type: BonusUpsellActionType.BONUS_UPSELL_CHANGE_RESOURCE_REQUEST
  upsell: BonusProduct
}
interface BonusUpsellChangeResourceSuccessAction {
  resources: Record<string, BonusResource>
  status: HTTP.Status
  type: BonusUpsellActionType.BONUS_UPSELL_CHANGE_RESOURCE_SUCCESS
  upsell: BonusProduct
}
interface BonusUpsellChangeResourceFailureAction {
  type: BonusUpsellActionType.BONUS_UPSELL_CHANGE_RESOURCE_FAILURE
  upsell: BonusProductForUpdate
}
interface BonusUpsellChangeAdvancedResourceRequestAction {
  type: BonusUpsellActionType.BONUS_UPSELL_CHANGE_ADVANCED_RESOURCE_REQUEST
  upsell: BonusProduct
}
interface BonusUpsellChangeAdvancedResourceSuccessAction {
  resources: BonusResource[]
  promoPrice: Price | null
  regularPrice: Price
  type: BonusUpsellActionType.BONUS_UPSELL_CHANGE_ADVANCED_RESOURCE_SUCCESS
  upsell: BonusProduct
}
interface BonusUpsellChangeAdvancedResourceFailureAction {
  type: BonusUpsellActionType.BONUS_UPSELL_CHANGE_ADVANCED_RESOURCE_FAILURE
  upsell: BonusProduct
}
interface BonusValidateUpsellRequestAction {
  type: BonusUpsellActionType.BONUS_VALIDATE_UPSELL_REQUEST
  upsell: BonusProduct
}
interface BonusValidateUpsellSuccessAction {
  type: BonusUpsellActionType.BONUS_VALIDATE_UPSELL_SUCCESS
  isValid: boolean
  upsell: BonusProduct
}
interface BonusValidateUpsellFailureAction {
  type: BonusUpsellActionType.BONUS_VALIDATE_UPSELL_FAILURE
  isValid: boolean
  errors: Record<string, string>
  upsell: BonusProduct
}
interface BonusUpsellNeedsUpdateAction {
  type: BonusUpsellActionType.BONUS_UPSELL_NEEDS_UPDATE
  upsell: BonusProduct
}

interface BonusChangeUpsellPeriodClickAction {
  status: HTTP.Status
  type: BonusUpsellActionType.BONUS_UPSELL_CHANGE_PERIOD_CLICK
  upsell: BonusProduct
}

export type BonusUpsellActions =
  | BonusGetUpsellPriceRequestAction
  | BonusGetUpsellPriceSuccessAction
  | BonusGetUpsellPriceFailureAction
  | BonusChangeUpsellPriceAction
  | BonusChangeUpsellPeriodRequestAction
  | BonusChangeUpsellPeriodSuccessAction
  | BonusChangeUpsellPeriodFailureAction
  | BonusUpsellAddToBasketRequestAction
  | BonusUpsellAddToBasketSuccessAction
  | BonusUpsellAddToBasketFailureAction
  | BonusUpsellChangeBasketRequestAction
  | BonusUpsellChangeBasketSuccessAction
  | BonusUpsellChangeBasketFailureAction
  | BonusUpsellChangeResourceRequestAction
  | BonusUpsellChangeResourceSuccessAction
  | BonusUpsellChangeResourceFailureAction
  | BonusUpsellChangeAdvancedResourceRequestAction
  | BonusUpsellChangeAdvancedResourceSuccessAction
  | BonusUpsellChangeAdvancedResourceFailureAction
  | BonusValidateUpsellRequestAction
  | BonusValidateUpsellSuccessAction
  | BonusValidateUpsellFailureAction
  | BonusUpsellNeedsUpdateAction
  | BonusChangeUpsellPeriodClickAction

/**
 * @param   {object} upsell Obiekt zawierający dane elementu upsella.
 * @returns {array}         Tablica obiektów gotowych do wysłania do api.
 */
function createUpsellItems(upsell: BonusProduct) {
  return upsell.periods.map((upsellPeriod) =>
    createBonusItemPeriod(upsell, upsellPeriod)
  )
}

/**
 * Wysyła request do api o cenę danego produktu w upsellu (bez rodzica).
 *
 * @param {object} upsell
 */
async function getUpsellPrice(
  upsell: BonusProduct
): Promise<BasketResponseItem[]> {
  const items = createUpsellItems(upsell)
  const promises = items.map((item) =>
    api.calculateBasket({ items: itemsBasketPrice([item]) })
  )
  const results = await Promise.all(promises)

  return Promise.resolve(results.map((result) => result.items[0]))
}

/**
 * Wysyła request do api o cenę danego produktu w upsellu z rodzicem.
 */
async function getUpsellPriceWithParent(
  upsell: BonusProduct,
  parent: BonusProduct
): Promise<BasketResponseItem[][]> {
  const items = upsell.periods.map((upsellPeriod) => ({
    ...createBonusItemPeriod(parent, { id: parent.chosenPeriodId }),
    children: [createBonusItemPeriod(upsell, upsellPeriod)],
  }))

  const promises = items.map((item) =>
    api.calculateBasket({ items: itemsBasketPrice([item]) })
  )
  const results = await Promise.all(promises)

  return Promise.resolve(
    results.map((result: BasketResponse) => result.items[0].children)
  )
}

export function changeUpsellPrice(upsell, items) {
  return (
    dispatch: AppDispatch<BonusThunkAction>
  ): Promise<BonusProduct["periods"]> => {
    const periods = changeBonusItemPrice(upsell, items)

    dispatch(<BonusChangeUpsellPriceAction>{
      type: BonusUpsellActionType.BONUS_CHANGE_UPSELL_PRICE,
      upsell,
      periods,
    })

    return Promise.resolve(periods)
  }
}

/**
 * Pobieranie cen dla danego produktu upsella.
 *
 * @param   {object} upsell    Produkt, którego ceny chcemy pobrać
 * @returns {Promise.<object>} Zwraca pobrane ceny
 */
export function requestGetUpsellPrice(upsell: BonusProduct) {
  return async (
    dispatch: AppDispatch<BonusThunkAction>,
    getState: { (): AppState }
  ) => {
    dispatch(<BonusGetUpsellPriceRequestAction>{
      type: BonusUpsellActionType.BONUS_GET_UPSELL_PRICE_REQUEST,
      status: HTTP_STATUS.CONTINUE,
      upsell,
    })

    const parent = getProduct(getState())

    try {
      let items

      if (parent) {
        items = await getUpsellPriceWithParent(upsell, parent)
      } else {
        items = await getUpsellPrice(upsell)
      }

      const periods = await dispatch(changeUpsellPrice(upsell, items))

      dispatch(<BonusGetUpsellPriceSuccessAction>{
        type: BonusUpsellActionType.BONUS_GET_UPSELL_PRICE_SUCCESS,
        status: HTTP_STATUS.OK,
        upsell,
      })

      return Promise.resolve(periods)
    } catch (error) {
      return dispatch(<BonusGetUpsellPriceFailureAction>{
        type: BonusUpsellActionType.BONUS_GET_UPSELL_PRICE_FAILURE,
        status: error.code,
        error,
        upsell,
      })
    }
  }
}

/**
 * Zmiana okresu w danym produkcie upsellu
 *
 * @param   {object} upsell    Produkt, którego okres chcemy zmienić
 * @param   {object} period    Okres na który chcemy zmienić.
 * @returns {Promise.<object>} Zwraca zmodyfikowany element koszyka
 */
export function requestChangeUpsellPeriod(
  upsell: BonusProduct,
  period: BonusPeriod
) {
  return async (dispatch: AppDispatch<BonusThunkAction>) => {
    dispatch(<BonusChangeUpsellPeriodRequestAction>{
      type: BonusUpsellActionType.BONUS_CHANGE_UPSELL_PERIOD_REQUEST,
      status: HTTP_STATUS.CONTINUE,
      upsell,
      period,
    })

    dispatch(<BonusChangeUpsellPeriodClickAction>{
      type: BonusUpsellActionType.BONUS_UPSELL_CHANGE_PERIOD_CLICK,
      status: HTTP_STATUS.OK,
      upsell,
    })

    if (upsell.addedToBasket && upsell.basketItemState?.id) {
      try {
        const changedItems = await dispatch(
          updateItemsInBasket(
            [
              {
                id: upsell.basketItemState?.id,
                planPeriod: period.id,
              },
            ],
            BasketActionSource.BONUS
          )
        )

        const { regularPrice, promoPrice } =
          await getProductBasketWithResources(upsell, period.id)

        dispatch(<BonusChangeUpsellPeriodSuccessAction>{
          type: BonusUpsellActionType.BONUS_CHANGE_UPSELL_PERIOD_SUCCESS,
          status: HTTP_STATUS.OK,
          upsell,
          regularPrice,
          promoPrice,
        })

        return Promise.resolve(changedItems[0])
      } catch (error) {
        return dispatch(<BonusChangeUpsellPeriodFailureAction>{
          type: BonusUpsellActionType.BONUS_CHANGE_UPSELL_PERIOD_FAILURE,
          status: HTTP_STATUS.BAD_REQUEST,
          error,
          upsell,
        })
      }
    }

    const totalPrices = await getProductBasketWithResources(upsell, period.id)

    return dispatch(<BonusChangeUpsellPeriodSuccessAction>{
      type: BonusUpsellActionType.BONUS_CHANGE_UPSELL_PERIOD_SUCCESS,
      status: HTTP_STATUS.OK,
      upsell,
      ...totalPrices,
    })
  }
}

/**
 * Dodanie produktu z upsellu do koszyka.
 *
 * @param   {object} upsell    Upsell, który ma być dodany do koszyka.
 * @returns {Promise.<object>} Ostatnio dodany element koszyka
 */
export function requestAddUpsellToBasket(upsell: BonusProduct) {
  return async (
    dispatch: AppDispatch<BonusThunkAction>,
    getState: { (): AppState }
  ) => {
    const { isValid } = await dispatch(requestValidateUpsell(upsell))

    if (!isValid) {
      return
    }

    dispatch(<BonusUpsellAddToBasketRequestAction>{
      type: BonusUpsellActionType.BONUS_UPSELL_ADD_TO_BASKET_REQUEST,
      status: HTTP_STATUS.CONTINUE,
      upsell,
    })

    try {
      const { addedItem, resources, lastItems } = await addBonusItemToBasket(
        dispatch,
        getState,
        upsell,
        null
      )

      dispatch(<BonusUpsellAddToBasketSuccessAction>{
        type: BonusUpsellActionType.BONUS_UPSELL_ADD_TO_BASKET_SUCCESS,
        basketItemId: addedItem.id,
        lastItems: lastItems,
        status: HTTP_STATUS.OK,
        upsell,
        resources,
      })

      return Promise.resolve(addedItem)
    } catch (error) {
      return dispatch(<BonusUpsellAddToBasketFailureAction>{
        type: BonusUpsellActionType.BONUS_UPSELL_ADD_TO_BASKET_FAILURE,
        status: error.code || HTTP_STATUS.BAD_REQUEST,
        error,
        upsell,
      })
    }
  }
}

export function requestChangeUpsellInBasket(upsell) {
  return async (dispatch: AppDispatch<BonusThunkAction>): Promise<any> => {
    const result = await dispatch(requestValidateUpsell(upsell))

    if (!result.isValid) {
      return
    }

    dispatch(<BonusUpsellChangeBasketRequestAction>{
      type: BonusUpsellActionType.BONUS_UPSELL_CHANGE_BASKET_REQUEST,
      status: HTTP_STATUS.CONTINUE,
      upsell,
    })

    try {
      const { chosenPeriodId, changedResources, lastItems } = await dispatch(
        changeBonusItemInBasket(upsell)
      )

      dispatch(<BonusUpsellChangeBasketSuccessAction>{
        type: BonusUpsellActionType.BONUS_UPSELL_CHANGE_BASKET_SUCCESS,
        status: HTTP_STATUS.OK,
        chosenPeriodId,
        changedResources,
        upsell,
        lastItems,
      })
    } catch (error) {
      dispatch(<BonusUpsellChangeBasketFailureAction>{
        type: BonusUpsellActionType.BONUS_UPSELL_CHANGE_BASKET_FAILURE,
        status: error.code || HTTP_STATUS.BAD_REQUEST,
        error,
        upsell,
      })

      return Promise.reject(error)
    }
  }
}

/**
 * Edytuje dany zasób w upsellu.
 *
 * @param {object} upsell   Obiekt upsella.
 * @param {object} resource Zasób, który jest edytowant.
 * @param {number} quantity Wartość, o którą ma się zwiekszyć/zmniejszyć ilość danego zasobu.
 */
export function requestChangeUpsellResource(
  upsell: BonusProductForUpdate,
  resource: BonusResourceWithBasketItem,
  quantity: number
) {
  return async (
    dispatch: AppDispatch<BonusThunkAction>,
    getState: { (): AppState }
  ) => {
    const newQuantity = resource.quantity + quantity

    if (
      newQuantity > normalizeMaxQuantity(resource.maxQuantity) ||
      newQuantity < resource.minQuantity
    ) {
      return Promise.resolve()
    }

    await dispatch(<BonusUpsellChangeResourceRequestAction>{
      type: BonusUpsellActionType.BONUS_UPSELL_CHANGE_RESOURCE_REQUEST,
      status: HTTP_STATUS.CONTINUE,
      upsell,
      resource,
      quantity,
    })

    const currentUpsell = getUpsellProduct(getState(), upsell.alias)

    try {
      await dispatch(requestGetUpsellPrice(currentUpsell))

      const currentResources = await changeBonusItemResource(
        dispatch,
        {
          addedToBasket: currentUpsell.addedToBasket,
          alias: currentUpsell.alias,
          basketItemState: currentUpsell?.basketItemState,
          resources: currentUpsell.resources,
        },
        resource,
        newQuantity
      )

      dispatch(<BonusUpsellChangeResourceSuccessAction>{
        type: BonusUpsellActionType.BONUS_UPSELL_CHANGE_RESOURCE_SUCCESS,
        status: HTTP_STATUS.OK,
        upsell: currentUpsell,
        resources: currentResources || [],
      })
    } catch (error) {
      dispatch(<BonusUpsellChangeResourceFailureAction>{
        type: BonusUpsellActionType.BONUS_UPSELL_CHANGE_RESOURCE_FAILURE,
        upsell,
        resource,
        quantity,
        error,
      })

      return Promise.reject(error)
    }
  }
}

export function requestValidateUpsell(upsell: BonusProduct) {
  return async (
    dispatch: AppDispatch<BonusThunkAction>,
    getState: { (): AppState }
  ) => {
    if (!upsell) {
      return {
        isValid: true,
      }
    }
    dispatch(<BonusValidateUpsellRequestAction>{
      type: BonusUpsellActionType.BONUS_VALIDATE_UPSELL_REQUEST,
      upsell,
    })
    const state = getState()
    const isValid = isBonusParameterFormValid(upsell.alias)(state)

    if (isValid) {
      dispatch(<BonusValidateUpsellSuccessAction>{
        type: BonusUpsellActionType.BONUS_VALIDATE_UPSELL_SUCCESS,
        upsell,
        isValid,
      })

      return {
        isValid,
      }
    }

    dispatch(<BonusValidateUpsellFailureAction>{
      type: BonusUpsellActionType.BONUS_VALIDATE_UPSELL_FAILURE,
      isValid,
      upsell,
    })

    return {
      isValid,
    }
  }
}

export function upsellNeedsUpdate(upsell: BonusProduct) {
  return (dispatch: AppDispatch<BonusThunkAction>) =>
    dispatch(<BonusUpsellNeedsUpdateAction>{
      type: BonusUpsellActionType.BONUS_UPSELL_NEEDS_UPDATE,
      upsell,
    })
}

/**
 * Edytuje dany zasób w zaawansowaej konfiguracji produktu.
 *
 * @param {object} product   Obiekt głównego produktu.
 * @param {object} resourcesList Lista zasobów które powinny zostać wyzerowane.
 * @param {object} chosenResourceId Zasób, który jest edytowany.
 * @param {number} basketQuantity Wartość, o którą ma się zwiekszyć/zmniejszyć ilość danego zasobu.
 */
export function changeAdvancedUpsellResourcesGroup(
  upsell,
  resourcesList,
  chosenResourceId,
  basketQuantity
) {
  return async (dispatch: AppDispatch<BonusThunkAction>) => {
    dispatch(<BonusUpsellChangeAdvancedResourceRequestAction>{
      type: BonusUpsellActionType.BONUS_UPSELL_CHANGE_ADVANCED_RESOURCE_REQUEST,
      upsell,
    })

    try {
      const result = await dispatch(
        changeAdvancedBonusItemResourcesGroup(
          upsell,
          resourcesList,
          chosenResourceId,
          basketQuantity
        )
      )

      if (result === null) {
        return
      }

      const {
        totalPrice: { regularPrice, promoPrice },
        resources,
      } = result

      return dispatch(<BonusUpsellChangeAdvancedResourceSuccessAction>{
        type: BonusUpsellActionType.BONUS_UPSELL_CHANGE_ADVANCED_RESOURCE_SUCCESS,
        upsell,
        resources,
        regularPrice,
        promoPrice,
      })
    } catch (error) {
      return dispatch(<BonusUpsellChangeAdvancedResourceFailureAction>{
        type: BonusUpsellActionType.BONUS_UPSELL_CHANGE_ADVANCED_RESOURCE_FAILURE,
        upsell,
      })
    }
  }
}

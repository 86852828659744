import { orderBy } from "lodash"
import type { CategoryWithSorting } from "@gatsby-plugin-generic-page/fragments/marketplaceCategoryProductCardSection"
import type { ProductCardItem } from "@gatsby-plugin-marketplace/fragments/marketplaceProductCard"

export default function sortProductsInCategory(
  category: CategoryWithSorting,
  products: ProductCardItem[]
): ProductCardItem[] {
  const sortBy = category.flatData.sortBy || "order"
  const sortOrder = category.flatData.sortOrder || "desc"

  return orderBy<ProductCardItem>(
    products.map((product) => {
      if (product.flatData[sortBy] || product.flatData[sortBy] === 0) {
        return product
      }
      const productSortBy = sortOrder === "asc" ? 99999 : -99999

      return {
        id: product.id,
        flatData: {
          ...product.flatData,
          order: productSortBy,
        },
      }
    }),
    ["flatData." + sortBy],
    [sortOrder]
  )
}

/* eslint-disable no-param-reassign */
import type { Draft } from "immer"
import { produce } from "immer"
import { get } from "lodash"
import type { BonusUpsellActions } from "@gatsby-plugin-bonus/store/actions-upsell"
import { BonusUpsellActionType } from "@gatsby-plugin-bonus/store/actions-upsell"
import {
  getBasketItemStateFromLastItems,
  handleProductResourceRequest,
} from "@gatsby-plugin-bonus/store/utils"
import type { BonusState } from "@gatsby-plugin-bonus/types"
import { createFormValidationErrorsFromResponse } from "~/lib/forms"
import { initialState } from "./selectors"

export default function reducer(
  state: BonusState = initialState,

  action: BonusUpsellActions
) {
  return produce(state, (nextState: Draft<BonusState>) => {
    if (!action.upsell) {
      return
    }

    const { alias } = action.upsell

    switch (action.type) {
      case BonusUpsellActionType.BONUS_UPSELL_CHANGE_BASKET_REQUEST:
        if (!nextState.upsell) {
          return
        }
        nextState.upsell[alias].isPriceLoading = true

        return

      case BonusUpsellActionType.BONUS_UPSELL_CHANGE_BASKET_SUCCESS:
        if (!nextState.upsell) {
          return
        }
        nextState.upsell[alias].isPriceLoading = false
        nextState.upsell[alias].configurationSaved = true

        if (action.changedResources) {
          Object.keys(action.changedResources).forEach((key) => {
            if (nextState.upsell && nextState.upsell[alias]) {
              nextState.upsell[alias].resources[key] = {
                ...nextState.upsell[alias].resources[key],
                ...action.changedResources[key],
              }
            }
          })
        }

        return
      case BonusUpsellActionType.BONUS_CHANGE_UPSELL_PERIOD_REQUEST:
        if (!nextState.upsell) {
          return
        }
        nextState.upsell[alias].previousPeriodId = get(state, [
          "upsell",
          alias,
          "chosenPeriod",
        ])
        nextState.upsell[alias].chosenPeriodId = action.period.id
        nextState.upsell[alias].changePeriodStatus = action.status
        nextState.upsell[alias].isChangePeriodLoading = true

        return

      case BonusUpsellActionType.BONUS_CHANGE_UPSELL_PERIOD_SUCCESS:
        if (!nextState.upsell) {
          return
        }

        // TODO - przekazywać w każdym dispatchu totalPrices ...
        if (action.regularPrice) {
          nextState.upsell[alias].totalPrices.promoPrice = action.promoPrice
          nextState.upsell[alias].totalPrices.regularPrice = action.regularPrice
        }

        nextState.upsell[alias].changePeriodStatus = action.status
        nextState.upsell[alias].isChangePeriodLoading = false

        return

      case BonusUpsellActionType.BONUS_CHANGE_UPSELL_PERIOD_FAILURE:
        if (!nextState.upsell) {
          return
        }
        nextState.upsell[alias].chosenPeriodId = get(state, [
          "upsell",
          alias,
          "previousPeriod",
        ])
        nextState.upsell[alias].changePeriodStatus = action.status
        nextState.upsell[alias].isChangePeriodLoading = false
        nextState.upsell[alias].changePeriodError = action.error

        return

      case BonusUpsellActionType.BONUS_UPSELL_ADD_TO_BASKET_REQUEST:
        if (!nextState.upsell) {
          return
        }
        nextState.upsell[alias].addToBasketStatus = action.status
        nextState.upsell[alias].isAddToBasketLoading = true
        nextState.upsell[alias].addToBasketError = undefined

        return

      case BonusUpsellActionType.BONUS_UPSELL_ADD_TO_BASKET_SUCCESS:
        if (!nextState.upsell) {
          return
        }
        nextState.upsell[alias].addedToBasket = true
        nextState.upsell[alias].addToBasketStatus = action.status
        nextState.upsell[alias].basketItemState =
          getBasketItemStateFromLastItems(action.basketItemId, action.lastItems)
        nextState.upsell[alias].isAddToBasketLoading = false
        nextState.upsell[alias].resources = action.resources
        nextState.upsell[alias].configurationSaved = true

        return

      case BonusUpsellActionType.BONUS_UPSELL_ADD_TO_BASKET_FAILURE:
        if (!nextState.upsell) {
          return
        }
        nextState.upsell[alias].addToBasketStatus = action.status
        nextState.upsell[alias].isAddToBasketLoading = false
        nextState.upsell[alias].addToBasketError =
          createFormValidationErrorsFromResponse({
            form: get(action.error, `form.children.parameters`, undefined),
          })

        return

      case BonusUpsellActionType.BONUS_GET_UPSELL_PRICE_REQUEST:
        if (!nextState.upsell) {
          return
        }
        nextState.upsell[alias].getPriceStatus = action.status
        nextState.upsell[alias].isPriceLoading = true

        return

      case BonusUpsellActionType.BONUS_GET_UPSELL_PRICE_SUCCESS:
        if (!nextState.upsell) {
          return
        }
        nextState.upsell[alias].getPriceStatus = action.status
        nextState.upsell[alias].isPriceLoading = false

        return

      case BonusUpsellActionType.BONUS_GET_UPSELL_PRICE_FAILURE:
        if (!nextState.upsell) {
          return
        }
        nextState.upsell[alias].getPriceStatus = action.status
        nextState.upsell[alias].isPriceLoading = false
        nextState.upsell[alias].getPriceError = action.error

        return

      case BonusUpsellActionType.BONUS_CHANGE_UPSELL_PRICE:
        if (!nextState.upsell) {
          return
        }

        nextState.upsell[alias].periods = action.periods

        return

      case BonusUpsellActionType.BONUS_UPSELL_CHANGE_RESOURCE_REQUEST:
        if (!nextState.upsell) {
          return
        }

        handleProductResourceRequest(
          action,
          state.upsell[alias],
          nextState.upsell[alias]
        )

        return

      case BonusUpsellActionType.BONUS_UPSELL_CHANGE_RESOURCE_SUCCESS:
        if (!nextState.upsell) {
          return
        }
        nextState.upsell[alias].isChangeResourceLoading = false
        nextState.upsell[alias].changeResourceStatus = action.status
        nextState.upsell[alias].resources = action.resources

        return

      case BonusUpsellActionType.BONUS_UPSELL_NEEDS_UPDATE:
        if (!nextState.upsell) {
          return
        }
        nextState.upsell[alias].configurationSaved = false
        nextState.upsell[alias].addToBasketError = undefined

        return
      case BonusUpsellActionType.BONUS_VALIDATE_UPSELL_SUCCESS:
      case BonusUpsellActionType.BONUS_VALIDATE_UPSELL_FAILURE:
        if (!nextState.upsell) {
          return
        }
        nextState.upsell[alias].isValid = action.isValid
        // TODO, raczej nie używane sprawdzić
        // if ("errors" in action) {
        //   nextState.upsell[alias].errors = action.errors
        // }
        return

      case BonusUpsellActionType.BONUS_UPSELL_CHANGE_ADVANCED_RESOURCE_REQUEST:
        if (!nextState.upsell) {
          return
        }
        nextState.upsell[alias].isPriceLoading = true

        return

      case BonusUpsellActionType.BONUS_UPSELL_CHANGE_ADVANCED_RESOURCE_FAILURE:
        if (!nextState.upsell) {
          return
        }
        nextState.upsell[alias].isPriceLoading = false

        return

      case BonusUpsellActionType.BONUS_UPSELL_CHANGE_ADVANCED_RESOURCE_SUCCESS: {
        if (!nextState.upsell) {
          return
        }
        nextState.upsell[alias].isPriceLoading = false
        nextState.upsell[alias].configurationSaved = false

        if (action.regularPrice) {
          nextState.upsell[alias].totalPrices = {
            promoPrice: action.promoPrice,
            regularPrice: action.regularPrice,
          }
        }

        action.resources.forEach((resource) => {
          if (!nextState.upsell || !state.upsell) {
            return
          }
          nextState.upsell[alias].resources[resource.id] = {
            ...state.upsell[alias].resources[resource.id],
            id: resource.id,
            quantity: resource.quantity,
            basketQuantity: resource.basketQuantity,
          }
        })
      }
    }
  })
}

import type { ReactElement } from "react"
import React from "react"
import type { AxiosError } from "axios"
import axios from "axios"
import { Form as FinalForm } from "react-final-form"
import type { Props as ButtonAtmProps } from "@onestore/hel/dist/components/atoms/ButtonAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import type Theme from "@onestore/hel/dist/typings/theme"
import FieldsRenderer from "~/components/Form/FieldsRenderer"
import FormFooter from "~/components/Form/FormFooter"
import useRecaptcha from "~/hooks/useRecaptcha"
import {
  handleFormSubmitErrors,
  createFormValidationErrors,
  parseFormData,
  getValuesWithoutFormTypeElements,
} from "~/lib/forms"
import { EventCategory, sendGAFormEvent } from "~/lib/ga4"
import type { FormResponse, FormValues } from "../../../lib/api/forms"

export interface FormProps {
  data: any | null
  buttonTitle?: string
  buttonText?: string
  buttonColor?: Theme.AccentColor
  buttonSize?: ButtonAtmProps["size"]
}

interface FormBuilderProps {
  name: string
  form: FormProps
  additionalData?: Record<string, string>
  onSuccess?: { (): void }
}

/**
 * @deprecated replaced by Formily
 */
export default function FormBuilder({
  name,
  additionalData = {},
  form,
  onSuccess,
}: FormBuilderProps): ReactElement<FormBuilderProps> {
  const { fields, required, target, captchaField } = parseFormData(form.data)
  const {
    loaded: recaptchaLoaded,
    getCaptchaCode,
    shouldResetRecaptcha,
    setShouldResetRecaptcha,
    captchaNode,
  } = useRecaptcha(!captchaField, (info) => (
    <PushOrg topSpace={2}>
      <TextAtm typography="small2">{info}</TextAtm>
    </PushOrg>
  ))

  const onFormSubmit = async (values: FormValues) => {
    const valuesWithoutFormTypeElements =
      getValuesWithoutFormTypeElements(values)

    const filesList = Object.entries(valuesWithoutFormTypeElements).filter(
      ([, value]): boolean => value instanceof FileList
    )
    const textEntries = Object.entries(valuesWithoutFormTypeElements).filter(
      ([, value]): boolean => !(value instanceof FileList)
    )

    const payload = new FormData()

    textEntries.forEach(([key, value]) => {
      payload.append(key, value)
    })

    for (const item in additionalData) {
      payload.append(item, additionalData[item])
    }

    filesList.forEach(([key, value]) => {
      for (let i = 0; i < value.length; i++) {
        payload.append(`${key}[${i}]`, value[i])
      }
    })
    payload.append("form_referer", document.location.toString())

    if (captchaField) {
      payload.append(captchaField, await getCaptchaCode())
    }

    const settings =
      filesList.length > 0
        ? {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        : undefined

    return axios
      .post(target, payload, settings)
      .then((response) => {
        if (response.status === 204) {
          sendGAFormEvent({
            event: EventCategory.FORM_SUBMIT,
            form_id: name,
          })
          onSuccess && onSuccess()

          return
        }
      })
      .catch((error: AxiosError<FormResponse>) => {
        return handleFormSubmitErrors(error)
      })
  }

  let dirtySent = false

  return (
    <FinalForm
      onSubmit={onFormSubmit}
      validate={createFormValidationErrors(required)}
      render={({
        handleSubmit,
        submitting,
        submitSucceeded,
        dirtySinceLastSubmit,
        submitErrors,
        dirty,
      }) => {
        if (dirtySinceLastSubmit && !shouldResetRecaptcha) {
          setShouldResetRecaptcha(true)
        }

        if (dirty && !dirtySent) {
          sendGAFormEvent({
            event: EventCategory.FORM_START,
            form_id: name,
          })
          dirtySent = true
        }

        return (
          <>
            <FieldsRenderer items={fields} />
            <FormFooter
              submitErrors={
                dirtySinceLastSubmit ? [] : Object.values(submitErrors || [])
              }
              captchaField={captchaField !== null ? captchaNode : null}
              handleSubmit={handleSubmit}
              submitting={!recaptchaLoaded || submitting}
              dirty={dirtySinceLastSubmit}
              submitSucceeded={submitSucceeded}
              buttonTitle={form.buttonTitle}
              buttonText={form.buttonText}
              buttonSize={form.buttonSize}
              buttonColor={form.buttonColor}
            />
          </>
        )
      }}
    />
  )
}

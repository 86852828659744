import type { ReactNode } from "react"
import React, { useEffect } from "react"
import { createContext, useState } from "react"
import type { PeriodSwitcher } from "@gatsby-plugin-generic-page/fragments/periodsSwitcher"
import isEmpty from "~/lib/isEmpty"

interface SwitchContentType {
  currentKey: string
  setCurrentKey: { (key: string): void }
  periodVariants: PeriodSwitcher["periods"]
  setPeriodVariants: { (periodVariants: PeriodSwitcher["periods"]): void }
}

interface SwitchContentContextProviderProps {
  children: ReactNode
  initial?: string
}

export const SwitchContentContext = createContext<SwitchContentType | null>(
  null
)

export function SwitchContentContextProvider({
  children,
  initial,
}: SwitchContentContextProviderProps) {
  const [currentKey, setCurrentKey] = useState("")
  const [periodVariants, setPeriodVariants] = useState<
    PeriodSwitcher["periods"]
  >([])

  useEffect(() => {
    if (currentKey) {
      return
    } else if (!isEmpty(periodVariants)) {
      setCurrentKey(periodVariants[0].value)
    } else if (initial) {
      setCurrentKey(initial)
    }
  }, [periodVariants, initial, currentKey])

  return (
    <SwitchContentContext.Provider
      value={{
        currentKey,
        setCurrentKey,
        periodVariants,
        setPeriodVariants,
      }}
    >
      {children}
    </SwitchContentContext.Provider>
  )
}

export default SwitchContentContext

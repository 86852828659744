import type { Draft } from "immer"
import { produce } from "immer"
import { every } from "lodash"
import type { Term } from "@onestore/api/types"
import type { CheckoutState } from "@onestore/onestore-store-common"
import { EXISTING_PAYTOOL_TYPE } from "@onestore/onestore-store-common/api/payments"
import { HTTP_STATUS } from "@onestore/onestore-store-common/http"
import type { CheckoutAction } from "@gatsby-plugin-checkout/store/actions"
import { STORAGE } from "~/lib/storage"
import LocalStorage from "~/lib/storage/LocalStorage"
import { CheckoutActions } from "./constants"

const userLogin = LocalStorage.get(STORAGE.USER_LOGIN)
const userExists = LocalStorage.getBool(STORAGE.LOGIN_EXISTS)

export const initialState: CheckoutState = {
  user: undefined,
  user_request: HTTP_STATUS.NONE,
  login: userLogin,

  request_state: HTTP_STATUS.NONE,
  order_number: undefined,
  showRegistration: false,
  userRegistered: false,
  userUpdated: false,
  isEditModalOpen: false,

  // Dane konta.
  accountExists: null === userExists ? false : userExists,
  authUserRequest: HTTP_STATUS.NONE,

  // Informacje o płatności.
  order_id: undefined,
  redirect_data: undefined,
  modal_form: undefined,
  is_modal_based_payment: false,
  bank_transfer_data: undefined,
  is_existing_payment_tool: undefined,
  total_value: 0,
  total_vat_value: 0,

  // regulaminy wymagane do zaakceptowania dla usług w aktualnym koszyku
  terms: [],
  termsValid: undefined,
  terms_request: HTTP_STATUS.NONE,

  // status requestu akceptującego regulaminy
  order_request: HTTP_STATUS.NONE,

  // Parametry elementów koszyka potrzebne do wypełnienia przez użytkownika
  params: {},
  get_params_request: HTTP_STATUS.NONE,
  save_params_request: HTTP_STATUS.NONE,

  modal: {
    confirmAddress: {
      isOpen: false,
      user: undefined,
    },
  },
}

export default function reducer(
  state: CheckoutState = initialState,
  action: CheckoutAction
): CheckoutState {
  return produce<CheckoutState, CheckoutState>(
    state,
    (nextState: Draft<CheckoutState>): void => {
      switch (action.type) {
        // Uwierzytelnienie konta użytkownika

        case CheckoutActions.AUTH_PENDING:
          nextState.authUserRequest = action.status

          return
        case CheckoutActions.AUTH_SUCCESS:
          nextState.login = action.login
          nextState.accountExists = action.accountExists || state.accountExists
          nextState.authUserRequest = action.status

          return
        case CheckoutActions.AUTH_LOGIN_RESET:
          nextState.login = action.login
          nextState.accountExists = action.accountExists
          nextState.authUserRequest = action.authUserRequest
          nextState.showRegistration = false

          return

        case CheckoutActions.AUTH_LOGIN_SKIP:
          nextState.login = action.login
          nextState.accountExists = action.accountExists
          nextState.authUserRequest = action.authUserRequest
          nextState.showRegistration = true

          return

        case CheckoutActions.USER_EDIT_DATA_MODAL_OPEN:
          nextState.isEditModalOpen = true

          return

        case CheckoutActions.USER_EDIT_DATA_MODAL_CLOSE:
          nextState.isEditModalOpen = false

          return
        case CheckoutActions.AUTH_FAILURE:
          nextState.authUserRequest = action.status

          return

        case CheckoutActions.BASKET_PENDING:
          nextState.request_state = HTTP_STATUS.CONTINUE

          return
        case CheckoutActions.ORDER_PENDING:
          nextState.order_request = HTTP_STATUS.CONTINUE

          return
        case CheckoutActions.PAYMENT_FAILURE:
          nextState.request_state = HTTP_STATUS.BAD_REQUEST
          nextState.order_request = HTTP_STATUS.BAD_REQUEST

          return

        case CheckoutActions.BASKET_FAILURE:
          nextState.request_state = HTTP_STATUS.BAD_REQUEST

          return
        case CheckoutActions.ORDER_SUCCESS:
          LocalStorage.setBool(STORAGE.LOGIN_EXISTS, true)

          nextState.order_request = action.response_status_code
          nextState.accountExists = true

          return
        case CheckoutActions.ORDER_FAILURE:
          nextState.order_request = action.response_status_code

          return
        // Składanie zamówienia

        case CheckoutActions.PAYMENT_PENDING:
          nextState.request_state = HTTP_STATUS.CONTINUE

          return
        case CheckoutActions.PAYMENT_SUCCESS:
          nextState.is_modal_based_payment =
            action.result.modal_form &&
            Object.values(action.result.modal_form).length !== 0
          nextState.order_id = action.result.id
          nextState.order_number = action.result.number
          nextState.order_request = action.status
          nextState.redirect_data = action.result.redirect_data
          nextState.modal_form = action.result.modal_form
          nextState.bank_transfer_data = action.result.bank_transfer_data
          nextState.total_value = action.result.total_value
          nextState.total_vat_value = action.result.total_vat_value
          nextState.is_existing_payment_tool =
            EXISTING_PAYTOOL_TYPE === action.result.pay_tool_type

          const selectedPaymentMethod = action.paymentMethods.filter(
            (item) => item.id === action.paymentMethod
          )

          if (selectedPaymentMethod.length === 1) {
            nextState.payment_system_id = selectedPaymentMethod[0].system_id
          }

          return

        case CheckoutActions.USER_REGISTER_SUCCESS:
          nextState.userRegistered = true
          nextState.user = {
            ...action.result,
            tax: "tax" in action.result ? action.result.tax : [],
            company_name:
              "company_name" in action.result ? action.result.company_name : "",
          }

          nextState.login = action.result.login

          return

        case CheckoutActions.USER_UPDATE_SUCCESS:
          nextState.userUpdated = true
          nextState.user = {
            ...action.result,
            tax: "tax" in action.result ? action.result.tax : [],
            company_name:
              "company_name" in action.result ? action.result.company_name : "",
          }

          nextState.login = action.result.login

          return

        case CheckoutActions.USER_PENDING:
          nextState.user_request = HTTP_STATUS.CONTINUE

          return
        case CheckoutActions.USER_FAILURE:
          nextState.user_request = action.response_status_code

          return
        case CheckoutActions.USER_SUCCESS:
          nextState.user_request = action.response_status_code
          nextState.user = action.result
          nextState.email = action.result.email
          nextState.is_after_login = action.is_after_login

          return
        case CheckoutActions.BASKET_SUCCESS:
          nextState.request_state = HTTP_STATUS.OK

          return
        case CheckoutActions.RESET:
          LocalStorage.remove(STORAGE.OLD_ORDER)

          nextState = {
            ...state,
            ...initialState,
          }

          if (!LocalStorage.get(STORAGE.USER_LOGIN)) {
            nextState.login = undefined
          }

          return
        case CheckoutActions.CHECKOUT_RESET:
          nextState.terms_request = HTTP_STATUS.NONE
          nextState.order_request = HTTP_STATUS.NONE
          nextState.request_state = HTTP_STATUS.NONE
          nextState.user_request = HTTP_STATUS.NONE

          return
        case CheckoutActions.BASKET_TERMS_PENDING:
          nextState.terms_request = HTTP_STATUS.CONTINUE

          return
        case CheckoutActions.BASKET_TERMS_VALIDATION:
          nextState.termsValid = action.value

          return

        case CheckoutActions.BASKET_TERMS_FAILURE:
          nextState.terms_request = action.response_status_code
          nextState.terms = []

          return
        case CheckoutActions.BASKET_TERMS_SUCCESS:
          nextState.terms_request = action.response_status_code
          const checkedTerms = state.terms
            .filter((term: Term) => term.checked)
            .reduce(
              (reduction, term) => {
                reduction[term.type][term.id] = term.checked

                return reduction
              },
              { internal: {}, external: {} }
            )

          nextState.terms = action.result.map((term: Term): Term => {
            if (!checkedTerms[term.type][term.id]) {
              return term
            }

            return {
              ...term,
              checked: checkedTerms[term.type][term.id],
            }
          })

          return
        case CheckoutActions.BASKET_TERMS_TOGGLE_ALL_TERMS:
          const allTermsChecked = every(state.terms, (term) => term.checked)

          nextState.terms = state.terms.map((term: Term) => ({
            ...term,
            checked: !allTermsChecked,
          }))

          nextState.termsValid = undefined

          return
        case CheckoutActions.BASKET_TERMS_TOGGLE_TERM:
          nextState.terms = state.terms.map((term: Term) => {
            if (term.id === action.id && term.type === action.termType) {
              return {
                ...term,
                checked: !term.checked,
              }
            }

            return term
          })
          nextState.termsValid = undefined

          return
        case CheckoutActions.BASKET_PARAMS_GET_PENDING:
          nextState.get_params_request = HTTP_STATUS.CONTINUE

          return
        case CheckoutActions.BASKET_PARAMS_GET_FAILURE:
          nextState.get_params_request = action.response_status_code
          nextState.params = {}

          return
        case CheckoutActions.BASKET_PARAMS_GET_SUCCESS:
          nextState.get_params_request = action.response_status_code
          nextState.params = action.params

          return
        case CheckoutActions.BASKET_PARAMS_SAVE_PENDING:
          nextState.save_params_request = HTTP_STATUS.CONTINUE

          return
        case CheckoutActions.BASKET_PARAMS_SAVE_FAILURE:
          nextState.save_params_request = action.response_status_code
          nextState.params = {}

          return
        case CheckoutActions.BASKET_PARAMS_SAVE_SUCCESS:
          nextState.save_params_request = action.response_status_code

          return

        case CheckoutActions.ADDRESS_CONFIRMATION_CLOSE:
          nextState.modal.confirmAddress.isOpen = false
          nextState.modal.confirmAddress.user = undefined

          return

        case CheckoutActions.ADDRESS_CONFIRMATION_OPEN:
          nextState.modal.confirmAddress.isOpen = true
          nextState.modal.confirmAddress.user = action.user

          return
      }
    }
  )
}

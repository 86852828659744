import { useMemo, useRef } from "react"
import type { BasicPageSearchData } from "@onestore-graphql/Model"
import Fuse from "fuse.js"
import type { IFuseOptions } from "fuse.js"
import type { CoreSearchResults } from "@gatsby-plugin-domain-search/hooks/useAllCoreProductsSearchQuery"
import useAllCoreProductsSearchQuery from "@gatsby-plugin-domain-search/hooks/useAllCoreProductsSearchQuery"

export function createFuse(
  products: CoreSearchResults,
  options: IFuseOptions<BasicPageSearchData>
) {
  return new Fuse<BasicPageSearchData>(products, options)
}

export default function useFilteredCoreProducts(
  query: string,
  options: IFuseOptions<BasicPageSearchData>
): CoreSearchResults {
  const allProducts = useAllCoreProductsSearchQuery()

  const fuse = useRef(createFuse(allProducts, options))

  return useMemo(() => {
    if (!query) {
      return []
    }

    return (
      fuse.current
        .search<BasicPageSearchData>(query)
        .map((item) => item.item) || []
    )
  }, [query])
}

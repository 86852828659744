import { graphql } from "gatsby"
import type { CustomBackgroundColor } from "~/types/squidex"

export enum CustomTextColors {
  jasny = "customLight",
  ciemny = "customDark",
}

export interface CustomColor {
  __typename: string
  customBackground: CustomBackgroundColor
  text: keyof typeof CustomTextColors
}

export const query = graphql`
  fragment CustomColor on GraphCmsSquidex_CustomColorComponent {
    __typename
    customBackground: background {
      ... on GraphCmsSquidex_BackgroundColorComponent {
        __typename
        color
      }
      ... on GraphCmsSquidex_GradientComponent {
        __typename
        start
        end
      }
    }
    text
  }
`

export const FRAGMENT_CUSTOM_COLOR = `
  fragment CustomColor on CustomColorComponent {
    __typename
    customBackground: background {
      ... on BackgroundColorComponent {
        __typename
        color
      }
      ... on GradientComponent {
        __typename
        start
        end
      }
    }
    text
  }
`

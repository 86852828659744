import React from "react"
import { Link } from "gatsby"
import Hel from "@onestore/hel/dist/Hel"
import { default as homeTheme } from "@onestore/hel/dist/theme/home"
import "./src/fonts/azo-sans.css"

export const wrapPageElement = ({ element }) => (
  <Hel brand="home" theme={homeTheme} env="production" linkComponent={Link}>
    {element}
  </Hel>
)

import { DOMAIN_SEARCH_VIEW } from "@gatsby-plugin-domain-search/store/constants"
import { scrollToAnchor } from "~/lib/dom"
import isServer from "~/lib/isServer"
import type { AppState } from "~/store/reducer"
import { COMPONENT_ID } from "~/types/component"
import { overwriteDomainHash } from "../lib/domains"

let viewTypeValue
const watchAdvancedModeEnabled = (domainSearch: AppState["domainSearch"]) => {
  if (
    viewTypeValue !== domainSearch.pageType &&
    domainSearch.pageType === DOMAIN_SEARCH_VIEW.PAGE_TYPE_FULL
  ) {
    scrollToAnchor(COMPONENT_ID.DOMAIN_SEARCH_SECTION_SECONDARY)
  }
  viewTypeValue = domainSearch.pageType
}

let phrase

const watchPhraseChange = (domainSearch: AppState["domainSearch"]) => {
  if (phrase === domainSearch.currentCheck.phrase) {
    return
  }

  phrase = domainSearch.currentCheck.phrase
  const hash = overwriteDomainHash(document.location.hash.substr(1), {
    d: domainSearch.currentCheck.phrase,
    p: domainSearch.currentCheck.poolId
      ? domainSearch.currentCheck.poolId + ""
      : null,
    e: domainSearch.currentCheck.extension,
  })

  if (hash && hash !== document.location.hash.substr(1)) {
    document.location.replace(`#${hash}`)
  }
}

export function domainSearchUrlHandler(store) {
  if (isServer()) {
    return () => {}
  }

  return () => {
    const { domainSearch }: AppState = store.getState()
    watchAdvancedModeEnabled(domainSearch)
    watchPhraseChange(domainSearch)
  }
}

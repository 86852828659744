import * as Sentry from "@sentry/browser"
import { apiUrl } from "~/lib/api"
import type { FormResponse } from "../../lib/api/forms"
import useCachedAjaxRequest from "./useCachedAjaxRequest"

export function useFormHook(name: string) {
  if (Sentry) {
    Sentry.captureMessage(
      `Deprecated Classic form displayed: "${name}"`,
      "warning"
    )
  }
  const { isLoading, result, fetchData } = useCachedAjaxRequest<FormResponse>(
    `${apiUrl}/forms/${name}`,
    "form-" + name
  )

  return {
    isLoading,
    result,
    fetchData,
  }
}

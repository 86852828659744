import type { ReactElement } from "react"
import React from "react"
import ItemDescriptionMol from "@onestore/hel/dist/components/molecules/ItemDescriptionMol"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import GridOrg from "@onestore/hel/dist/components/organisms/GridOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import useBreakpoint from "@onestore/hel/dist/hooks/useBreakpoint"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import CtaElement from "@gatsby-plugin-generic-page/components/CtaElement"
import Header from "@gatsby-plugin-generic-page/components/Header"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import type { StepByStepSectionFlatData } from "@gatsby-plugin-generic-page/fragments/stepByStepSection"
import assetsSquidexToImageObject from "~/lib/assetsSquidexToImageObject"
import isEmpty from "~/lib/isEmpty"

export default function StepByStepSection({
  header,
  steps,
  columns,
  isCentered,
  hasMobileBottomText,
  ctaElements,
  footer,
  sectionSettings,
}: StepByStepSectionFlatData): ReactElement<StepByStepSectionFlatData> {
  const isSmallScreen = useBreakpoint("small", "medium")

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        {header ? (
          <AlignContentOrg
            horizontalAlign={{
              small: "left",
              medium: "center",
              large: "center",
            }}
          >
            <Header {...header} />
          </AlignContentOrg>
        ) : null}

        <PushOrg topSpace={10}>
          <GridOrg
            columns={{ small: 1, medium: 2, large: columns }}
            gutterSpace={{
              small: { x: 5, y: 5 },
              medium: { x: 3, y: 7 },
              large: { x: 20, y: 10 },
            }}
            isCentered={!isSmallScreen && isCentered}
          >
            {steps.map((step, index) => {
              const image = !isEmpty(step.images)
                ? assetsSquidexToImageObject(step.images, step.title)
                : undefined

              const icon = !isEmpty(step.icon)
                ? assetsSquidexToImageObject(step.icon, step.title)
                : undefined

              return (
                <ItemDescriptionMol
                  key={`${step.title}-${index}`}
                  image={image}
                  icon={icon}
                  title={step.title}
                  text={
                    <MarkdownText
                      typography={{ default: "medium1_h", bold: "medium4_h" }}
                    >
                      {step.text}
                    </MarkdownText>
                  }
                  isCentered={!isSmallScreen && isCentered}
                  hasMobileBottomText={hasMobileBottomText}
                />
              )
            })}
          </GridOrg>
        </PushOrg>

        {!isEmpty(ctaElements) && (
          <PushOrg topSpace={10}>
            <AlignContentOrg
              horizontalAlign={{
                small: "left",
                medium: "center",
                large: "center",
              }}
            >
              <CtaElement ctaElement={ctaElements} />
            </AlignContentOrg>
          </PushOrg>
        )}

        {footer ? (
          <PushOrg topSpace={10}>
            <AlignContentOrg
              horizontalAlign={{
                small: "left",
                medium: "center",
                large: "center",
              }}
            >
              <MarkdownText>{footer}</MarkdownText>
            </AlignContentOrg>
          </PushOrg>
        ) : null}
      </BaseContainer>
    </BaseSection>
  )
}

import _t from "@core/i18n"
import type { PeriodInfo } from "@core/period-info"
import { PriceType } from "@core/types"

const getProductCardPeriodText = (
  periodInfo?: PeriodInfo,
  priceType?: PriceType
): string | undefined => {
  if (!periodInfo || !priceType) {
    return undefined
  }

  const priceTypeText =
    priceType === PriceType.GROSS ? _t("prices.gross") : _t("prices.netto")

  const periodText = periodInfo.getPeriodType()
    ? `${priceTypeText} ${_t(
        `periodName.searchDomainPrice.${periodInfo.getPeriodType()}`,
        {
          smart_count: periodInfo.getPeriodLength(),
        }
      )}`
    : undefined

  const oneTimeFeeText = `${priceTypeText} ${_t("periodName.oneTimeFee")}`

  if (periodInfo.isOneTimeFee()) {
    return oneTimeFeeText
  }

  return periodText
}

export default getProductCardPeriodText

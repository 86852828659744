import type { ReactElement } from "react"
import React from "react"
import HelpSectionTemplate from "@onestore/hel/dist/components/templates/HelpSectionTemplate"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import markdownOverrides from "@gatsby-plugin-generic-page/markdown/contactSection"
import type { ContactSectionFlatData } from "@gatsby-plugin-generic-page/types/sectionType"
import assetsSquidexToImageObject from "~/lib/assetsSquidexToImageObject"
import isEmpty from "~/lib/isEmpty"

export default function ContactSection({
  title,
  subtitle,
  contactDetails,
  buttons,
  images,
  infoBoxes,
}: ContactSectionFlatData): ReactElement {
  const { number, prefix, text } = contactDetails[0]

  const props = {
    title: title,
    subtitle: subtitle,
    phone: {
      title: text,
      prefix: prefix,
      number: number,
    },
    image: assetsSquidexToImageObject(images, title),
    contactButtons: [
      ...buttons.map((button) => {
        const { id, flatData } = button
        const { images, text, href, pages, title, isVisibleOnSmallOnly } =
          flatData

        return {
          key: id,
          image: assetsSquidexToImageObject(images, ""),
          text: text,
          link: {
            href: href,
            to: !isEmpty(pages) ? pages[0].flatData.url : undefined,
            title: title,
            openInNewTab: !isEmpty(href),
          },
          isVisibleOnSmallOnly: !!isVisibleOnSmallOnly,
        }
      }),
    ],
    infoBoxes: infoBoxes
      ? infoBoxes.map((box) => {
          const { id, flatData } = box
          const { images, text, href, pages, title } = flatData

          return {
            key: id,
            image: assetsSquidexToImageObject(images, ""),
            title: title,
            text: (
              <MarkdownText forceBlock={false} overrides={markdownOverrides}>
                {text}
              </MarkdownText>
            ),
            link: {
              title: title,
              href: href,
              to: !isEmpty(pages) ? pages[0].flatData.url : undefined,
              openInNewTab: true,
            },
          }
        })
      : undefined,
  }

  return <HelpSectionTemplate {...props} data-test-id="help" />
}

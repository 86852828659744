import React from "react"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import { MapField } from "~/components/Form/FieldsRenderer"
import type { FormField } from "../../../lib/api/forms"

interface FormSectionProps {
  item: FormField
}

export default function FormSection({ item }: FormSectionProps) {
  return (
    <>
      <TextAtm typography="big4" pushSpace={1}>
        {item.label}
      </TextAtm>

      {Object.values(item.children || {}).map((item) => MapField({ item }))}
    </>
  )
}

import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import LabelWithTooltipMol from "@onestore/hel/dist/components/molecules/LabelWithTooltipMol"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"

interface LimitTooltipLabelProps {
  tooltipText: string
}

function LimitTooltipLabel({
  tooltipText,
}: LimitTooltipLabelProps): ReactElement<LimitTooltipLabelProps> | null {
  return (
    <LabelWithTooltipMol
      size="small"
      text={_t("limitTooltip.title")}
      typography="nano2"
      tooltip={
        <MarkdownText typography={{ bold: "small3", default: "small2" }}>
          {tooltipText}
        </MarkdownText>
      }
      variant="lightBlue"
    />
  )
}

export default LimitTooltipLabel

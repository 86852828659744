import domainsPools from "_/res/domainsPools.json"
import type { DomainCheck } from "@onestore/api/domainSearch"

interface DomainPoolPlan {
  id: number
  name: DomainCheck.Extension
}

export interface DomainPool {
  plans_count: number
  plans: DomainPoolPlan[]
  id: number
  name: string
  type: string
  upsell: boolean
  priority: number
}

/**
 * @deprecated
 */
export function getDomainPool(poolId: number): DomainPool | undefined {
  return (<DomainPool[]>domainsPools).find((item) => poolId === item.id)
}

export function getDomainPoolsByType(): DomainPool[] {
  return (<DomainPool[]>domainsPools)
    .filter((pool) => 0 < pool.plans_count && pool.type === "category")
    .sort((a, b) => b.priority - a.priority)
}

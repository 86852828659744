import _t from "@core/i18n"
import { uniqueId } from "lodash"
import type { ThunkAction } from "redux-thunk"
import type { NotificationMessage } from "@onestore/onestore-store-common/index"
import type { AppState, AppDispatch } from "~/store/reducer"
import type { AccountMessage } from "../../../lib/api/types"

export enum MessageActionTypes {
  GLOBAL_MESSAGE_ADD = "ACTIONS.GLOBAL_MESSAGE.ADD",
  GLOBAL_MESSAGE_REMOVE = "ACTIONS.GLOBAL_MESSAGE.REMOVE",
  GLOBAL_MESSAGE_REMOVE_ALL = "ACTIONS.GLOBAL_MESSAGE.REMOVE_ALL",
}

export interface MessageRemoveAllAction {
  type: MessageActionTypes.GLOBAL_MESSAGE_REMOVE_ALL
}

export interface MessageAddAction {
  type: MessageActionTypes.GLOBAL_MESSAGE_ADD
  message: NotificationMessage | AccountMessage
}

export interface MessageRemoveAction {
  type: MessageActionTypes.GLOBAL_MESSAGE_REMOVE
  id: string
}

export type MessageAction =
  | MessageRemoveAllAction
  | MessageAddAction
  | MessageRemoveAction

export type MessageThunkAction = ThunkAction<
  void,
  AppState,
  undefined,
  MessageAction
>

export const removeAllGlobalMessages = (): MessageRemoveAllAction => {
  return {
    type: MessageActionTypes.GLOBAL_MESSAGE_REMOVE_ALL,
  }
}
export const addMessageAction = (
  type: "error" | "success" | "info" | "warning",
  title: string,
  message: string
): MessageAddAction => {
  return {
    type: MessageActionTypes.GLOBAL_MESSAGE_ADD,
    message: {
      id: uniqueId("msg"),
      type,
      title,
      message,
    },
  }
}

export const addAccountMessageAction = (
  message: AccountMessage
): MessageAddAction => {
  return {
    type: MessageActionTypes.GLOBAL_MESSAGE_ADD,
    message: {
      ...message,
      title: _t("notifications.infoTitle"),
    },
  }
}

export const addRequestErrorGlobalMessage = (
  message: string,
  removeAllMessageBeforeAdd = false
) => {
  return (dispatch: AppDispatch<MessageThunkAction>) => {
    if (removeAllMessageBeforeAdd) {
      dispatch(removeAllGlobalMessages())
    }

    dispatch(addMessageAction("error", _t("notifications.errorTitle"), message))
  }
}

export const removeGlobalMessage = (id: string): MessageRemoveAction => {
  return {
    type: MessageActionTypes.GLOBAL_MESSAGE_REMOVE,
    id,
  }
}

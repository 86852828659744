import { useState } from "react"
import { navigate } from "gatsby"
import { uniqueId } from "lodash"
import type { DomainCheck } from "@onestore/api/domainSearch"
import type { PlanId } from "@onestore/api/types"
import { addTransferToBasket } from "@gatsby-plugin-domain-search/store/actions"
import StoreAPI from "~/lib/api"
import { getTransferPricing } from "~/lib/price-transfer"
import url from "~/lib/url"
import { useAppDispatch } from "~/store/hooks"

export enum DomainTransferState {
  UNKNOWN = "UNKNOWN",
  LOADING = "LOADING",
  CAN_REGISTER = "CAN_REGISTER",
  CAN_TRANSFER = "CAN_TRANSFER",
  UNKNOWN_EXTENSION = "UNKNOWN_EXTENSION",
  EXTERNAL_ERROR = "EXTERNAL_ERROR",
  HAS_ERROR = "HAS_ERROR",
  NEEDS_AUTHINFO = "NEEDS_AUTHINFO",
}

interface Hook {
  transferState: DomainTransferState
  fqdn: DomainCheck.FQDN | null
  extension: DomainCheck.FQDN | null
  planId: PlanId | null
  onSubmit: { (phrase: DomainCheck.FQDN): void }
  onCloseModal: { (): void }
}

export function useDomainTransferActions(): Hook {
  const [buttonId] = useState(uniqueId("TransferBtn"))
  const dispatch = useAppDispatch()
  const [transferState, setTransferState] = useState<DomainTransferState>(
    DomainTransferState.UNKNOWN
  )
  const [fqdn, setFqdn] = useState<DomainCheck.FQDN | null>(null)
  const [extension, setExtension] = useState<DomainCheck.Extension | null>(null)
  const [planId, setPlanId] = useState<PlanId | null>(null)

  const navigateToBasket = () => {
    navigate(url.getBasketUrl())
  }

  const onSubmit = (phrase: DomainCheck.FQDN) => {
    setTransferState(DomainTransferState.LOADING)
    StoreAPI.checkMainDomain(phrase, [])
      .catch(() => {
        setTransferState(DomainTransferState.HAS_ERROR)
      })
      .then((searchResult) => {
        if (searchResult === undefined) {
          return
        }

        const { extension, fqdn: remoteFqdn } = searchResult.domain
        const { authinfo_required, id } = getTransferPricing(extension)

        const canBeTransferred = searchResult.domain.status === "Unavailable"
        const canBeRegistered = searchResult.domain.status === "Available"

        if (searchResult.domain.fqdn !== searchResult.phrase) {
          setTransferState(DomainTransferState.UNKNOWN_EXTENSION)

          return
        }

        if (canBeRegistered) {
          setTransferState(DomainTransferState.CAN_REGISTER)
          setFqdn(remoteFqdn)
          setExtension(searchResult.domain.extension)
          setPlanId(searchResult.domain.plan_id)
        } else if (canBeTransferred && !authinfo_required) {
          try {
            dispatch(
              addTransferToBasket(
                {
                  fqdn: remoteFqdn,
                  plan: id,
                },
                "",
                buttonId,
                navigateToBasket
              )
            )
          } catch (error) {
            setTransferState(DomainTransferState.HAS_ERROR)
          }
        } else {
          setTransferState(DomainTransferState.NEEDS_AUTHINFO)
          setFqdn(remoteFqdn)
          setExtension(searchResult.domain.extension)
          setPlanId(searchResult.domain.plan_id)
        }
      })
  }

  const onCloseModal = () => {
    setTransferState(DomainTransferState.UNKNOWN)
    setExtension(null)
    setPlanId(null)
    setFqdn(null)
  }

  return {
    transferState,
    fqdn,
    planId,
    extension,
    onSubmit,
    onCloseModal,
  }
}

import _t from "@core/i18n"
import type { PeriodSwitcher } from "@gatsby-plugin-generic-page/fragments/periodsSwitcher"
import { parsePeriod } from "../../../../lib/api"

export interface PeriodVariants {
  [key: string]: {
    title: string
    period: string
    periodDescription?: string
  }
}

function getPeriodsByPeriodVariants(
  periodVariants?: PeriodSwitcher["periods"]
): PeriodVariants | null {
  if (!periodVariants) {
    return null
  }
  const periods: PeriodVariants = {}

  periodVariants.forEach((periodVariant) => {
    const period = parsePeriod(periodVariant.value)

    periods[periodVariant.value] = {
      period: periodVariant.value,
      title: _t(`periodName.long.${period.periodType}`, {
        smart_count: period.periodValue,
      }),
      periodDescription: periodVariant.periodDescription,
    }
  })

  return periods
}

export default getPeriodsByPeriodVariants

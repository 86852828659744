import { useCallback, SyntheticEvent } from "react"
import { navigate } from "gatsby"
import { scrollToAnchor } from "~/lib/dom"
import useInternalSitesQuery from "./useInternalSitesQuery"

export interface LinkPropertiesHook {
  href: string
  onClick: { (event: SyntheticEvent): void }
}

/**
 * @deprecated
 */
export default function useLinkProperties(url: string): LinkPropertiesHook {
  const href: string = url
  const internalSites = useInternalSitesQuery()
  const isInternal = internalSites.includes(url.replace(/#.*$/, ""))
  const onClick = useCallback(
    async (event: SyntheticEvent<Element, KeyboardEvent>): Promise<void> => {
      if (
        event.isDefaultPrevented() ||
        event.nativeEvent.metaKey ||
        event.nativeEvent.ctrlKey
      ) {
        return
      }
      event.preventDefault()

      if (isInternal) {
        await navigate(url)
        const hash = url.slice(url.indexOf("#"))

        if (!hash) {
          return
        }

        scrollToAnchor(hash)
      } else {
        window.location.assign(url)
      }
    },
    [url, isInternal]
  )

  return {
    href,
    onClick,
  }
}

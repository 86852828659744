import type { ReactNode, ReactElement } from "react"
import React, { useRef, useEffect, useState } from "react"
import { createPortal } from "react-dom"

export interface ModalProps {
  children: ReactNode
}

export default function ModalContainer({
  children,
}: ModalProps): ReactElement<ModalProps> | null {
  const modalRef = useRef<HTMLElement>()
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    const modal = document.getElementById("___modal")

    if (modal) {
      modalRef.current = modal
      setIsLoaded(true)
    }
  }, [])

  if (!modalRef.current) {
    return null
  }

  return createPortal(<>{isLoaded ? children : null}</>, modalRef.current)
}

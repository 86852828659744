import pmailDefinition from "_/res/pmailDefinition.json"
import productsDefinitions from "_/res/productsDefinitions.json"
import type { ProductAlias } from "@onestore/api/types"
import type { RemoteProduct } from "../../lib/api/product"

const allProducts: Record<ProductAlias, RemoteProduct> = {
  ...productsDefinitions,
  ...pmailDefinition,
}

function cleanAlias(alias: string): string {
  return alias.split(".").shift() || ""
}

/**
 * Metoda zwraca definicję poszukiwanego produktu o podanym aliasie planu, sprawdzane są wyłącznie definicje produktów.
 *
 * @deprecated
 *
 * Informacje o produktach powinny być pobierane przy pomocy GraphQl lub z OnestoreAPI
 */
export const getProductPlanByAlias = (alias: string): RemoteProduct => {
  const cleanedAlias = cleanAlias(alias)

  if (!allProducts[cleanedAlias]) {
    throw new Error(`Product "${cleanedAlias}" is not supported`)
  }

  return allProducts[cleanedAlias]
}

import type { ReactElement } from "react"
import React from "react"
import PulseButtonAtm from "@onestore/hel/dist/components/atoms/PulseButtonAtm"
import type { PulseButtonType } from "@gatsby-plugin-generic-page/fragments/pulseButton"
import getBackground from "@gatsby-plugin-generic-page/helpers/getBackground"
import getLinkProps from "@gatsby-plugin-generic-page/helpers/getLinkProps"

export default function PulseButton({
  link,
  color,
}: PulseButtonType["flatData"]): ReactElement<PulseButtonType["flatData"]> {
  const { text, ...restLinkProps } = getLinkProps(link)

  let { customColor, backgroundThemeColor, themeAccentColor } =
    getBackground(color)

  return (
    <PulseButtonAtm
      {...restLinkProps}
      color={customColor || backgroundThemeColor || themeAccentColor || "white"}
    >
      {text}
    </PulseButtonAtm>
  )
}

import _t from "@core/i18n"
import { some } from "lodash"
import { DOMAIN_REGEX } from "@gatsby-plugin-domain-search/lib/domains" // TODO: Do wyprostowania zależności między paczkami

export interface ValidationResult {
  valid: boolean
  errors: string[]
}

export const validatePhrase = (inputPhrase: string): ValidationResult => {
  const result: ValidationResult = {
    valid: true,
    errors: [],
  }

  const phrase = String(inputPhrase)

  if (phrase.length > 0) {
    if (null === phrase.match(DOMAIN_REGEX.VALIDATE)) {
      result.valid = false
      result.errors.push(_t("validation.phraseHasSpecialChars"))

      return result
    }

    if (some(phrase.split("."), (part) => part.length > 63)) {
      result.valid = false
      result.errors.push(_t("validation.phraseTooLong"))
    }
  }

  return result
}

export const getDomainParts = (domain: string): [string, string] => {
  const separator = "."
  const domainParts = domain.split(separator)

  const name = domainParts.shift() as string
  const extension = domainParts.join(separator)

  return [name, extension]
}

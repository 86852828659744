import type { ReactElement } from "react"
import React, { Fragment } from "react"
import { get } from "lodash"
import { Field } from "react-final-form"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import CheckboxRowField from "~/components/Form/CheckboxRowField"
import ChoiceTypeRow from "~/components/Form/ChoiceTypeRow"
import FormSection from "~/components/Form/FormSection"
import TextAreaRowField from "~/components/Form/TextAreaRowField"
import TextRowField from "~/components/Form/TextRowField"
import type { FormField } from "../../../lib/api/forms"
import HiddenRowField from "./HiddenRowField"
import UploadRowField from "./UploadRowField"

interface MapFieldProps {
  item: FormField
}

export function MapField({ item }: MapFieldProps): ReactElement {
  const placeholder = get(item.attr, "placeholder")
  const choiceTypeGroups = get(item.attr, "data.groups", undefined)
  const hideCheckbox = get(item.attr, "hideCheckbox")
  switch (item.type) {
    case "FormType":
      return <FormSection item={item} />

    case "CheckboxType":
      return (
        <PushOrg bottomSpace={2}>
          {!hideCheckbox ? (
            <Field
              required={item.required}
              name={item.name}
              type="checkbox"
              label={item.label}
              component={CheckboxRowField}
              key={item.name}
            />
          ) : (
            <>
              <Field
                name={item.name}
                type="hidden"
                label={item.label}
                component={HiddenRowField}
                key={item.name}
                defaultValue={"1"}
              />
            </>
          )}
        </PushOrg>
      )

    case "EmailType":
      return (
        <PushOrg bottomSpace={2}>
          <Field
            required={item.required}
            name={item.name}
            key={item.name}
            placeholder={placeholder}
            type="email"
            component={TextRowField}
          />
        </PushOrg>
      )
    case "TextType":
      return (
        <PushOrg bottomSpace={2}>
          <Field
            required={item.required}
            placeholder={placeholder}
            name={item.name}
            key={item.name}
            type="text"
            component={TextRowField}
          />
        </PushOrg>
      )
    case "ChoiceType":
      return (
        <PushOrg bottomSpace={2}>
          <Field
            required={item.required}
            name={item.name}
            key={item.name}
            placeholder={placeholder}
            choices={item.choices || {}}
            groups={choiceTypeGroups}
            component={ChoiceTypeRow}
          />
        </PushOrg>
      )
    case "TextareaType":
      return (
        <PushOrg bottomSpace={2}>
          <Field
            required={item.required}
            name={item.name}
            key={item.name}
            placeholder={placeholder}
            component={TextAreaRowField}
          />
        </PushOrg>
      )
    case "FileType":
      return (
        <PushOrg bottomSpace={2}>
          <Field
            required={item.required}
            name={item.name}
            key={item.name}
            placeholder={placeholder}
            type="file"
            component={UploadRowField}
          />
        </PushOrg>
      )
    case "HiddenType":
      if (item.name === "captcha") {
        return <Fragment key={item.name} />
      }
      break
  }

  return <></>
}

interface FieldsRendererProps {
  items: FormField[]
}

export default function FieldsRenderer({
  items,
}: FieldsRendererProps): ReactElement<FieldsRendererProps> {
  return (
    <>
      {items.map((item: FormField) => (
        <MapField key={item.name} item={item} />
      ))}
    </>
  )
}

export enum BasketActions {
  BASKET = "BASKET.ACTIONS.BASKET",
  BASKET_ERROR = "BASKET.ACTIONS.BASKET_ERROR",
  BASKET_SUCCESS = "BASKET.ACTIONS.BASKET_SUCCESS",
  BASKET_PROMO_CODE_ERROR = "BASKET_PROMO_CODE_ERROR",
  BASKET_RESET_BUTTON = "BASKET.ACTIONS.BASKET_RESET_BUTTON",
  BASKET_ITEM_REMOVED = "BASKET.ACTIONS.BASKET_ITEM_REMOVED",
  BASKET_CHANGE_PAYMENT = "BASKET.ACTIONS.BASKET_CHANGE_PAYMENT",
  REMOVE_ITEM_CONFIRM_MODAL_SHOW = "REMOVE_ITEM_CONFIRM_MODAL_SHOW",
  REMOVE_ITEM_CONFIRM_MODAL_CLOSE = "REMOVE_ITEM_CONFIRM_MODAL_CLOSE",
  REMOVE_ITEM_WITH_CONFIRMATION = "REMOVE_ITEM_WITH_CONFIRMATION",
  MANUAL_DOMAIN_HOOK_ADD = "MANUAL_DOMAIN_HOOK_ADD",
}

export const BASKET_STATUS = {
  NONE: "none",
  PENDING: "pending",
  HAS_USER_DATA: "has_user_data",
  ACTIVE: "active",
  ARCHIVED: "archived",
  ASSIGNED: "assigned",
}

import type { FC } from "react"
import React, { useState, useContext } from "react"
import _t from "@core/i18n"
import { PriceType } from "@core/types"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import ToggleWithLabelsAtm from "@onestore/hel/dist/components/atoms/ToggleWithLabelsAtm"
import WrapOrg from "@onestore/hel/dist/components/organisms/WrapOrg"
import { useIsomorphicLayoutEffect } from "@gatsby-plugin-generic-page/hooks/useIsomorphicLayoutEffect"
import { PriceTypeContext } from "~/context/PriceTypeContext"
import useSiteMetadataQuery from "~/hooks/useSiteMetadataQuery"

interface Props {
  text?: string
  hasDarkMode?: boolean
}

const TogglePriceType: FC<Props> = ({ text, hasDarkMode }: Props) => {
  const { defaultPriceType } = useSiteMetadataQuery()
  const [activeValue, setActiveValue] = useState<PriceType>(defaultPriceType)
  const context = useContext(PriceTypeContext)

  const handleToggleSwitch = () => {
    if (activeValue === PriceType.NETTO) {
      context?.setPriceType(PriceType.GROSS)
      setActiveValue(PriceType.GROSS)
    } else {
      context?.setPriceType(PriceType.NETTO)
      setActiveValue(PriceType.NETTO)
    }
  }

  useIsomorphicLayoutEffect()(() => {
    if (context?.currentPriceType === PriceType.NETTO) {
      setActiveValue(PriceType.NETTO)
    } else {
      setActiveValue(PriceType.GROSS)
    }
  }, [context])

  return (
    <WrapOrg gutterSpace={2} alignCenter="vertical">
      <TextAtm typography="nano1">{text || _t("priceTypeToggleLabel")}</TextAtm>

      <ToggleWithLabelsAtm
        id="toggle-price-type"
        values={[_t("prices.netto"), _t("prices.gross")]}
        activeIndex={activeValue === PriceType.NETTO ? 0 : 1}
        onClick={handleToggleSwitch}
        hasDarkMode={hasDarkMode}
      />
    </WrapOrg>
  )
}

export default TogglePriceType

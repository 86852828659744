import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import ContentWithImageOrg from "@onestore/hel/dist/components/organisms/ContentWithImageOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import useBreakpoint from "@onestore/hel/dist/hooks/useBreakpoint"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import MarkdownCaption from "@gatsby-plugin-generic-page/components/Markdown/Caption"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import MarkdownTitle from "@gatsby-plugin-generic-page/components/Markdown/Title"
import Media from "@gatsby-plugin-generic-page/components/Media"
import type { DomainTransferSectionFlatData } from "@gatsby-plugin-generic-page/types/sectionType"
import DomainTransferInputField from "~/components/DomainTransferInputField"
import Footnote from "./components/Footnote"

function DomainTransferSection({
  caption,
  captionIcon,
  title,
  text,
  footnoteText,
  footnoteLink,
  footnoteModalText,
  searchButtonColor,
  responsiveImages,
  isReversed,
  hasImageStickyToBottom,
  sectionSettings,
}: DomainTransferSectionFlatData): ReactElement<DomainTransferSectionFlatData> {
  const isSmallOnly = useBreakpoint("small", "medium")

  return (
    <BaseSection {...sectionSettings} hasHorizontalGradient>
      <BaseContainer {...sectionSettings?.containerSettings}>
        <ContentWithImageOrg
          image={<Media media={responsiveImages} />}
          content={
            <>
              {captionIcon ? (
                <PushOrg bottomSpace={1.5}>
                  <Media media={captionIcon} />
                </PushOrg>
              ) : null}

              <PushOrg bottomSpace={6}>
                {caption ? <MarkdownCaption>{caption}</MarkdownCaption> : null}

                <MarkdownTitle>{title}</MarkdownTitle>

                {text ? <MarkdownText>{text}</MarkdownText> : null}
              </PushOrg>

              <PushOrg bottomSpace={3}>
                <DomainTransferInputField
                  submitText={_t("transfer.authinfoButton")}
                  placeholderText={
                    isSmallOnly
                      ? _t("transfer.mobileInputPlaceholder")
                      : _t("transfer.inputPlaceholder")
                  }
                  buttonColor={searchButtonColor?.color}
                />
              </PushOrg>

              <Footnote
                footnoteText={footnoteText}
                footnoteLink={footnoteLink}
                footnoteModalText={footnoteModalText}
              />
            </>
          }
          hasImageStickyToBottom={hasImageStickyToBottom}
          isReversedOnLarge={isReversed}
          isImageHiddenOnSmall
        />
      </BaseContainer>
    </BaseSection>
  )
}

export default DomainTransferSection

import type { SyntheticEvent } from "react"
import { useState } from "react"
import type { GaEvent } from "~/fragments/gaEvent"
import { sendToplayerShowEvent, sendEvent } from "~/lib/ga4"

function useSectionsModal(gaEvent?: GaEvent) {
  const [isOpen, setIsOpen] = useState(false)

  const handleOpenOverlay = (event?: SyntheticEvent) => {
    if (event) {
      event.preventDefault()
    }

    sendToplayerShowEvent()

    if (gaEvent) {
      sendEvent(gaEvent)
    }
    setIsOpen((value) => !value)
  }

  const handleCloseOverlay = () => setIsOpen(false)

  return {
    isOpen,
    handleOpenOverlay,
    handleCloseOverlay,
  }
}

export default useSectionsModal

import React from "react"
import type {
  TableGroup,
  TableItems,
  TableValue,
} from "@onestore-graphql/Parameters"
import { uniqBy, concat } from "lodash"
import NestedLinkAtm from "@onestore/hel/dist/components/atoms/NestedLinkAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"

export default function useContent(
  items: TableGroup[],
  showOnlyDifferences?: boolean,
  hasHiddenGroupLabel?: boolean,
  showOnlyTwoColumns?: boolean
) {
  const priceItems = items[0]
  const parameters = items.slice(1)

  parameters
    .map((parameter) => {
      parameter.items.map((item) => {
        let uniqValues
        let values = item.values

        if (showOnlyDifferences) {
          if (showOnlyTwoColumns) {
            values = values.splice(0, 2)
          }

          uniqValues = uniqBy(values, (value) => {
            return value.value
          })
        }

        item.isHighlighted =
          uniqValues && uniqValues.length === 1 ? false : true

        item.values = values

        return item
      })
    })
    .filter((item) => item)

  const itemsGroup = concat(priceItems, parameters)

  return itemsGroup.map((group: TableGroup) => {
    return {
      groupLabel:
        !hasHiddenGroupLabel && group.name && group.name !== "_"
          ? group.name
          : undefined,
      rows: group.items.map((item: TableItems) => {
        return {
          label:
            typeof item.label === "string"
              ? item.label
              : {
                  ...item.label,
                  tooltip:
                    item.label.tooltip &&
                    typeof item.label.tooltip !== "string" ? (
                      <TextAtm typography="small1">
                        {item.label.tooltip.map((tooltip, index) => {
                          if (typeof tooltip !== "string") {
                            return (
                              <NestedLinkAtm
                                key={`${tooltip.title}-${index}`}
                                title={tooltip.title}
                                href={tooltip.href}
                                openInNewTab
                              >
                                {tooltip.text}
                              </NestedLinkAtm>
                            )
                          }

                          return tooltip
                        })}
                      </TextAtm>
                    ) : (
                      item.label.tooltip || undefined
                    ),
                },
          values: item.values.map((value: TableValue) => {
            switch (value.value) {
              case "[v]":
              case "[V]":
                return true
              case "[x]":
              case "[X]":
                return false
              default:
                return value.value
            }
          }),
          isHighlighted: showOnlyDifferences ? item.isHighlighted : undefined,
          dataTestId: item.dataTestId,
        }
      }),
    }
  })
}

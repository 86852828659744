import type { ReactElement } from "react"
import React from "react"
import ListMol from "@onestore/hel/dist/components/molecules/ListMol"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import FlexItemOrg from "@onestore/hel/dist/components/organisms/FlexItemOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import SurfaceOrg from "@onestore/hel/dist/components/organisms/SurfaceOrg"
import WidthOrg from "@onestore/hel/dist/components/organisms/WidthOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import BundleButtonWithPrice from "@gatsby-plugin-generic-page/components/BundleButtonWithPrice"
import Header from "@gatsby-plugin-generic-page/components/Header"
import ResponsiveImage from "@gatsby-plugin-generic-page/components/ResponsiveImage"
import Texts from "@gatsby-plugin-generic-page/components/Texts"
import type { BundleSectionFlatData } from "@gatsby-plugin-generic-page/fragments/bundleSection"
import getBaseMarkdownOverrides from "@gatsby-plugin-generic-page/helpers/getBaseMarkdownOverrides"
import isEmpty from "~/lib/isEmpty"
import ImageContainer from "./components/ImageContainer"

function BundleSection({
  header,
  texts,
  buttonWithPrice,
  responsiveImages,
  sectionSettings,
}: BundleSectionFlatData): ReactElement<BundleSectionFlatData> | null {
  const { calculatedPrice } = buttonWithPrice[0].flatData

  if (isEmpty(calculatedPrice)) {
    return null
  }

  const imageJsx = (
    <ImageContainer>
      <BreakpointVisibilityOrg from="medium">
        <WidthOrg widthSpace={16}>
          <ResponsiveImage images={responsiveImages} />
        </WidthOrg>
      </BreakpointVisibilityOrg>

      <BreakpointVisibilityOrg from="small" to="medium">
        <ResponsiveImage images={responsiveImages} />
      </BreakpointVisibilityOrg>
    </ImageContainer>
  )

  const textMarkdownOverrides = {
    ...getBaseMarkdownOverrides({
      typography: "small1",
      boldTypography: "small3",
      pushSpace: 1,
    }),
    ul: {
      component: ({ children }) => (
        <PushOrg bottomSpace={50}>
          <ListMol
            items={children.map((child: ReactElement) => child.props.children)}
            type="tick"
            size={"small"}
            iconRightSpace={3}
            iconVerticalCenter
            reverseIconMobile
          />
        </PushOrg>
      ),
    },
  }

  const contentDefaultSpace = {
    large: 5,
    medium: 5,
    small: 3,
  }

  const bundleContentJsx = (
    <>
      {imageJsx}

      <PushOrg
        topSpace={contentDefaultSpace}
        bottomSpace={{
          large: 5,
          medium: 5,
          small: 4,
        }}
        rightSpace={contentDefaultSpace}
        leftSpace={contentDefaultSpace}
        hasNoReset
      >
        <BreakpointVisibilityOrg from="medium">
          <FlexContainerOrg justifyContent="space-between" alignItems="center">
            <FlexItemOrg flexBasis={0} flexGrow={1} flexShrink={1}>
              <Texts {...texts} textMarkdownOverrides={textMarkdownOverrides} />
            </FlexItemOrg>

            <PushOrg leftSpace={5}>
              <BundleButtonWithPrice {...buttonWithPrice[0]} />
            </PushOrg>
          </FlexContainerOrg>
        </BreakpointVisibilityOrg>

        <BreakpointVisibilityOrg from="small" to="medium">
          <Texts {...texts} textMarkdownOverrides={textMarkdownOverrides} />

          <PushOrg topSpace={3}>
            <BundleButtonWithPrice {...buttonWithPrice[0]} />
          </PushOrg>
        </BreakpointVisibilityOrg>
      </PushOrg>
    </>
  )

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        {!isEmpty(header) ? (
          <PushOrg bottomSpace={4}>
            <AlignContentOrg
              horizontalAlign={{
                small: "left",
                medium: "center",
                large: "center",
              }}
            >
              <Header {...header} />
            </AlignContentOrg>
          </PushOrg>
        ) : null}

        <AlignContentOrg horizontalAlign="center" horizontalAlignType="flex">
          <WidthOrg widthSpace={110.5}>
            <SurfaceOrg
              borderRadiusSize="xlarge"
              color="backgroundLight"
              shadow="small"
              hasBorder
              borderEmphasis="extraLow"
              borderWidth={1}
              spacingSpace={0}
            >
              <BreakpointVisibilityOrg from="medium">
                <FlexContainerOrg alignItems="center">
                  {bundleContentJsx}
                </FlexContainerOrg>
              </BreakpointVisibilityOrg>

              <BreakpointVisibilityOrg from="small" to="medium">
                {bundleContentJsx}
              </BreakpointVisibilityOrg>
            </SurfaceOrg>
          </WidthOrg>
        </AlignContentOrg>
      </BaseContainer>
    </BaseSection>
  )
}

export default BundleSection

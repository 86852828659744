import { get } from "lodash"
import type { AppState } from "~/store/reducer"
import type { Account, NullableAccountType } from "../../index"

export const getAccount = (state: AppState): Account | null => {
  return state.account.account
}
export const getAccountType = (state: AppState): NullableAccountType => {
  return get(state, "account.account.type")
}

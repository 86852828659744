import type { ReactElement, ReactNode } from "react"
import React, { useEffect } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import NestedLinkAtm from "@onestore/hel/dist/components/atoms/NestedLinkAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import { getReCaptchaSiteKey } from "~/lib/config"

export interface CaptchaProps {
  asyncScriptOnLoad: { (): void }
  renderInfo?: { (info: ReactNode): ReactNode } | null
  hideBadge?: boolean
}

//https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
const Captcha = React.forwardRef<ReCAPTCHA, CaptchaProps>(
  (
    { asyncScriptOnLoad, renderInfo, hideBadge },
    ref
  ): ReactElement<CaptchaProps> => {
    const info = (
      <TextAtm typography="tiny1" emphasis="medium">
        This site is protected by reCAPTCHA and the Google{" "}
        <NestedLinkAtm
          openInNewTab
          title="Privacy Policy"
          href="https://policies.google.com/privacy"
        >
          Privacy Policy
        </NestedLinkAtm>{" "}
        and{" "}
        <NestedLinkAtm
          title="Terms of Service"
          openInNewTab
          href="https://policies.google.com/terms"
        >
          Terms of Service
        </NestedLinkAtm>{" "}
        apply.
      </TextAtm>
    )

    useEffect(() => {
      const styles = document.createElement("style")
      styles.innerHTML = `.grecaptcha-badge { visibility: ${
        hideBadge ? "hidden" : "initial"
      }; }`

      document.body.append(styles)
    }, [hideBadge])

    return (
      <>
        {renderInfo ? renderInfo(info) : null}

        <ReCAPTCHA
          badge="bottomright"
          size="invisible"
          sitekey={getReCaptchaSiteKey()}
          ref={ref}
          asyncScriptOnLoad={asyncScriptOnLoad}
        />
      </>
    )
  }
)

export default Captcha

import React, { ReactElement } from "react"
import DomainSearchModal from "../DomainSearchModal"

export default function ModalHandler(): ReactElement {
  return (
    <>
      <DomainSearchModal />
    </>
  )
}

import type { ReactElement } from "react"
import React from "react"
import BreadcrumbsMol from "@onestore/hel/dist/components/molecules/BreadcrumbsMol"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import type { BreadcrumbsSectionFlatData } from "@gatsby-plugin-generic-page/types/sectionType"

function BreadcrumbsSection({
  currentItem,
  sectionSettings,
  breadcrumbs,
}: BreadcrumbsSectionFlatData): ReactElement<BreadcrumbsSectionFlatData> {
  const items = breadcrumbs.map((breadcrumb) => {
    return {
      text: breadcrumb.text,
      title: breadcrumb.title,
      to: breadcrumb.pages[0].flatData.url,
    }
  })

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        <BreadcrumbsMol items={items} currentItem={currentItem} />
      </BaseContainer>
    </BaseSection>
  )
}

export default BreadcrumbsSection

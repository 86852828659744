import { graphql, useStaticQuery } from "gatsby"

interface Query {
  GraphCmsSquidex: {
    findDomainSearchPageSingleton: {
      id: string
      flatData: {
        mobileTitle: string
        text: string
        desktopPrefix?: string
      }
    }
  }
}

export interface DomainHookContent {
  mobileTitle: string
  text: string
  desktopPrefix?: string
}

export default function useDomainHookContentQuery(): DomainHookContent {
  const {
    GraphCmsSquidex: { findDomainSearchPageSingleton },
  } = useStaticQuery<Query>(graphql`
    {
      GraphCmsSquidex {
        findDomainSearchPageSingleton {
          id
          flatData {
            mobileTitle
            text
            desktopPrefix
          }
        }
      }
    }
  `)

  return {
    mobileTitle: findDomainSearchPageSingleton.flatData.mobileTitle,
    text: findDomainSearchPageSingleton.flatData.text,
    desktopPrefix: findDomainSearchPageSingleton.flatData.desktopPrefix,
  }
}

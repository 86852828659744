import React, { useState } from "react"
import type { ReactElement, ReactNode } from "react"
import ToggleSwitchAtm from "@onestore/hel/dist/components/atoms/ToggleSwitchAtm"
import ConfigBoxMol from "@onestore/hel/dist/components/molecules/ConfigBoxMol"

interface Props {
  elementId: string
  configLabel: string
  expandedContent: ReactNode
  handleToggleSwith: (isActive: boolean) => void
}

const ToggleConfigElement = ({
  elementId,
  configLabel,
  expandedContent,
  handleToggleSwith,
}: Props): ReactElement<Props> => {
  const [isActive, setIsActive] = useState(false)

  const toggleElement = (
    <ToggleSwitchAtm
      id={elementId}
      isChecked={isActive}
      onClick={() => {
        handleToggleSwith(!isActive)
        setIsActive(!isActive)
      }}
    />
  )

  return (
    <ConfigBoxMol
      label={configLabel}
      configElement={toggleElement}
      isExpanded={isActive}
      expandedContent={expandedContent}
    />
  )
}

export default ToggleConfigElement

import React from "react"
import type { ReactElement } from "react"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import SurfaceOrg from "@onestore/hel/dist/components/organisms/SurfaceOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import CtaElement from "@gatsby-plugin-generic-page/components/CtaElement"
import Header from "@gatsby-plugin-generic-page/components/Header"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import type { HeaderWithTextSectionFlatData } from "@gatsby-plugin-generic-page/fragments/headerWithTextSection"
import isEmpty from "~/lib/isEmpty"
import markdownOverrides from "../markdown/headerWithText"

export default function HeaderWithTextSection({
  header,
  content,
  ctaElements,
  sectionSettings,
}: HeaderWithTextSectionFlatData): ReactElement<HeaderWithTextSectionFlatData> {
  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        {!isEmpty(header) ? (
          <PushOrg bottomSpace={10}>
            <AlignContentOrg
              horizontalAlign={{
                small: "left",
                medium: "center",
                large: "center",
              }}
            >
              <Header titleTypography="giant2" {...header} />
            </AlignContentOrg>
          </PushOrg>
        ) : null}

        <SurfaceOrg
          color="backgroundLight"
          spacingSpace={4}
          shadow="small"
          borderRadiusSize="large"
        >
          <MarkdownText
            typography={{ default: "medium1_h", bold: "medium3_h" }}
            overrides={markdownOverrides}
          >
            {content}
          </MarkdownText>

          {!isEmpty(ctaElements) ? (
            <PushOrg topSpace={2}>
              <AlignContentOrg horizontalAlign="center">
                <CtaElement ctaElement={ctaElements} />
              </AlignContentOrg>
            </PushOrg>
          ) : null}
        </SurfaceOrg>
      </BaseContainer>
    </BaseSection>
  )
}

import { v4 as uuidv4 } from "uuid"
import DurableStorage from "~/lib/storage/DurableStorage"
import SessionStorage from "~/lib/storage/SessionStorage"

export const METRICS_DEVICE_ID = "_pdi"
export const METRICS_SESSION_ID = "_sdi"
export const METRICS_SESSION_START = "_sst"

interface MetricsData {
  d: string | undefined
  s: string | undefined
  t: string | undefined
}

function getData(): MetricsData {
  return {
    d: DurableStorage.get(METRICS_DEVICE_ID) || undefined,
    s: SessionStorage.get(METRICS_SESSION_ID) || undefined,
    t: SessionStorage.get(METRICS_SESSION_START) || undefined,
  }
}

export function initMetrics() {
  const { d, s, t } = getData()

  if (!d) {
    DurableStorage.keep(METRICS_DEVICE_ID, uuidv4)
  }

  if (!s) {
    SessionStorage.keep(METRICS_SESSION_ID, uuidv4)
  }

  if (!t) {
    SessionStorage.keep(
      METRICS_SESSION_START,
      () => `${Math.round(Date.now() / 1000)}`
    )
  }
}

export function getMetricsPayload(): string {
  return btoa(JSON.stringify(getData()))
}

import type { ReactElement } from "react"
import React from "react"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import WrapOrg from "@onestore/hel/dist/components/organisms/WrapOrg"
import ConditionalWrapper from "@onestore/hel/dist/helpers/ConditionalWrapper"
import type Theme from "@onestore/hel/dist/typings/theme"
import type { CtaElement } from "@gatsby-plugin-generic-page/fragments/ctaElement"
import UnderlineLink from "../UnderlineLink"

interface Props {
  links: CtaElement[]
  typography?: Theme.Typography
}

function UnderlineLinks({ links, typography = "small2" }: Props): ReactElement {
  const numberOfLinks = links.length

  return (
    <PushOrg topSpace={3}>
      <ConditionalWrapper
        condition={numberOfLinks > 1}
        wrapper={(children) => <WrapOrg gutterSpace={4.5}>{children}</WrapOrg>}
      >
        {links.map((link) => (
          <UnderlineLink key={link.id} link={link} typography={typography} />
        ))}
      </ConditionalWrapper>
    </PushOrg>
  )
}

export default UnderlineLinks

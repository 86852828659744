import { useMemo, useState } from "react"
import React from "react"
import _t from "@core/i18n"
import type { Form, IFormFeedback } from "@formily/core"
import { createForm, onFormSubmit, onFormValuesChange } from "@formily/core"
import axios from "axios"
import type { AxiosError, AxiosResponse } from "axios"
import { get } from "lodash"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import type { GaEvent } from "~/fragments/gaEvent"
import useRecaptcha from "~/hooks/useRecaptcha"
import { getLocale } from "~/lib/config"
import { getValuesWithoutFormTypeElements } from "~/lib/forms"
import { EventCategory, sendGaEvent, sendGAFormEvent } from "~/lib/ga4"
import isEmpty from "~/lib/isEmpty"
import log from "~/lib/log"

interface ValidationResponse {
  [key: string]: string[]
}

export default function useFormilyForm(
  text: string,
  target: string,
  formId: string,
  additionalData: Record<string, string>,
  captchaEnabled: boolean,
  gaEvent: GaEvent | undefined
) {
  const [buttonText, setButtonText] = useState<string>(text)
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false)
  const [isSubmitSuccess, setIsSubmitSuccess] = useState<boolean>(false)
  const [formErrorMessage, setFormErrorMessage] = useState<string | null>(null)

  const {
    loaded: recaptchaLoaded,
    getCaptchaCode,
    setShouldResetRecaptcha,
    captchaNode,
  } = useRecaptcha(!captchaEnabled, (info) => (
    <PushOrg topSpace={2}>
      <TextAtm typography="small2">{info}</TextAtm>
    </PushOrg>
  ))

  const handleOnSubmit = async (data) => {
    if (gaEvent) {
      sendGaEvent(gaEvent)
    }
    setIsFormLoading(true)

    const formData = {
      ...data,
      extra: {
        ...additionalData,
        form_referer: document.location.toString(),
      },
    }

    const filesList = Object.entries(
      getValuesWithoutFormTypeElements(data)
    ).filter(([, value]): boolean => value instanceof File)

    if (captchaEnabled) {
      setShouldResetRecaptcha(true)
      formData["captcha"] = await getCaptchaCode()
    }

    log(formData, "Form")

    if (!isEmpty(filesList)) {
      const payload = new FormData()
      // When sending files with multipart content type, json payload needs to be sent as body field

      filesList.forEach(([key, value]) => {
        payload.append(key, value)
      })

      payload.append("body", JSON.stringify(formData))

      log(payload, "Form")

      return axios.post(target, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Accept-Language": getLocale(),
        },
      })
    }

    return axios.post(target, formData, {
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": getLocale(),
      },
    })
  }

  const handleOnSubmitSuccess = (response: AxiosResponse) => {
    setIsFormLoading(false)

    if (response.status === 200) {
      setButtonText(_t("forms.submitNotification"))
      sendGAFormEvent({
        event: EventCategory.FORM_SUBMIT,
        form_id: formId,
      })
      setIsSubmitSuccess(true)
    } else {
      handleOnSubmitFailed(response.data)
    }
  }

  const handleOnSubmitFailed = (data: AxiosError<ValidationResponse>) => {
    const dataValue = get(data, "response.data")
    const status = get(data, "response.status")

    if (dataValue) {
      Object.entries(dataValue).forEach(([fieldName, errorMessageArray]) => {
        form.setFieldState(fieldName, (formState) => {
          formState.selfErrors = [errorMessageArray.join(", ")]
        })
      })
    }

    if (status === 413) {
      setFormErrorMessage(_t("form.upload.summaryFilesSizeError"))
    } else if ("code" in data && data.code === "ERR_NETWORK") {
      setFormErrorMessage(_t("form.errors.networkError"))
    }

    setIsFormLoading(false)
    setButtonText(_t("forms.errorButton"))
  }

  const form = useMemo(
    () =>
      createForm({
        effects() {
          onFormSubmit(() => {
            form.setSubmitting(true)
          })

          onFormValuesChange((formState: Form) => {
            const effectErrors = formState.errors.filter(
              (error: IFormFeedback) => error.code === "EffectError"
            )

            if (formErrorMessage) {
              setFormErrorMessage(null)
            }

            if (!isEmpty(effectErrors)) {
              effectErrors.forEach((error: IFormFeedback) => {
                if (error.path) {
                  const fieldState = formState.getFieldState(error.path)

                  if (
                    !isEmpty(fieldState.component) &&
                    fieldState.component[0] === "Upload.Dragger"
                  ) {
                    const maxFilesCount = get(
                      fieldState,
                      "component[1].maxCount"
                    )
                    const filesCount = get(fieldState, "inputValue")

                    if (
                      !isEmpty(filesCount) &&
                      !!maxFilesCount &&
                      filesCount.length > maxFilesCount
                    ) {
                      return
                    }

                    if (fieldState.required) {
                      if (!isEmpty(Array.from(fieldState.value))) {
                        formState.clearErrors(error.path)
                      }
                    } else {
                      formState.clearErrors(error.path)
                    }

                    return
                  }

                  if (!isEmpty(fieldState.value && fieldState.required)) {
                    formState.clearErrors(error.path)
                  }
                }
              })
            }

            if (formState) {
              setIsSubmitSuccess(false)
              setButtonText(text)
            }
          })
        },
      }),
    []
  )

  return {
    form,
    buttonText,
    isSubmitSuccess,
    isFormLoading,
    recaptchaLoaded,
    captchaNode,
    formErrorMessage,
    handleOnSubmit,
    handleOnSubmitSuccess,
    handleOnSubmitFailed,
  }
}

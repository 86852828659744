import { isEmpty as _isEmpty } from "lodash"

/**
 * @description Wrapper to type `value` as `undefined` if `isEmpty()` returns `true`.
 * More: https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
 */
const isEmpty = <T>(value: T | undefined | null): value is undefined | null => {
  return _isEmpty(value)
}

export default isEmpty

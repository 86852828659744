import React from "react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { Provider } from "react-redux"
import { SearchUrlParametersContextProvider } from "../context/SearchUrlParametersContext"
import { createWrapperWithStore } from "."

const queryClient = new QueryClient()

export default createWrapperWithStore((store) => ({ element }) => (
  <QueryClientProvider client={queryClient}>
    <SearchUrlParametersContextProvider>
      <Provider store={store}>{element}</Provider>
    </SearchUrlParametersContextProvider>
  </QueryClientProvider>
))

import type { ReactElement, SyntheticEvent } from "react"
import React, { useState } from "react"
import NestedLinkAtm from "@onestore/hel/dist/components/atoms/NestedLinkAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import BaseMarkdown from "@gatsby-plugin-generic-page/components/Markdown/Base"
import modalTextOverrides from "@gatsby-plugin-generic-page/sections/DomainTransferSection/markdown/modalTextOverrides"
import type { DomainTransferSectionFlatData } from "@gatsby-plugin-generic-page/types/sectionType"
import ModalTemplateWrapper from "~/components/ModalTemplateWrapper"

interface FootnoteProps
  extends Pick<
    DomainTransferSectionFlatData,
    "footnoteText" | "footnoteLink" | "footnoteModalText"
  > {}

function Footnote({
  footnoteText,
  footnoteLink,
  footnoteModalText,
}: FootnoteProps): ReactElement<FootnoteProps> {
  const [isOpen, setIsOpen] = useState(false)

  const { title, text } = footnoteLink[0]

  const handleOpen = (event: SyntheticEvent): void => {
    event.preventDefault()

    setIsOpen((state) => !state)
  }

  return (
    <>
      <TextAtm typography="small1">
        {
          <>
            {footnoteText}{" "}
            <NestedLinkAtm title={title} onClick={handleOpen}>
              {text}
            </NestedLinkAtm>
          </>
        }
      </TextAtm>

      <ModalTemplateWrapper
        content={
          <BaseMarkdown options={{ overrides: modalTextOverrides }}>
            {footnoteModalText}
          </BaseMarkdown>
        }
        isOpen={isOpen}
        onCloseButtonClick={handleOpen}
      />
    </>
  )
}

export default Footnote

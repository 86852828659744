import type { Store } from "@reduxjs/toolkit"
import { uniq } from "lodash"
import { isResponseSuccessful } from "@onestore/onestore-store-common/http"
import { getFlatBasketItems } from "@gatsby-plugin-basket/store/selectors"
import Storage from "~/lib/storage"
import type { AppState } from "~/store/reducer"

export default function basketSubscriber(store: Store<AppState>): void {
  store.subscribe(() => {
    const state = store.getState()

    if (isResponseSuccessful(state.basket.request_state)) {
      Storage.setDomainsCount(state.basket.domains.length)
      const basketItems = uniq(
        getFlatBasketItems(state)
          .map((item): string => `${item.plan_id}`)
          .filter((item): boolean => !!item)
      )

      Storage.setBasketProducts(basketItems)
    }
  })
}

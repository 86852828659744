import type { ReactNode } from "react"
import React from "react"
import type { ProductCardItem } from "@gatsby-plugin-marketplace/fragments/marketplaceProductCard"
import AdvancedProductCard from "~/components/AdvancedProductCard"
import useLinkPropertiesCollection from "~/hooks/useLinkPropertiesCollection"

export default function useAdvancedProductCards(
  products: ProductCardItem[]
): ReactNode[] {
  const filteredProducts = products.filter(
    (product) =>
      product && product.flatData.pages && product.flatData.pages.length
  )

  const linkProps = useLinkPropertiesCollection(
    filteredProducts.map((product) => product.flatData.pages[0].flatData.url)
  )

  return filteredProducts.map((productItem) => (
    <AdvancedProductCard
      productData={productItem}
      link={linkProps}
      key={productItem.id}
    />
  ))
}

import { useContext } from "react"
import _t from "@core/i18n"
import type { PeriodInfo } from "@core/period-info"
import { getSelectedPeriodInfo } from "@core/period-info"
import { hasNoTaxRate } from "@core/pricing"
import { PriceType } from "@core/types"
import type { TableGroup, TableValue } from "@onestore-graphql/Parameters"
import { forEach, get } from "lodash"
import type { CloudBluePeriod } from "@gatsby-plugin-definitions/fragments/CloudBluePeriod"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import type { OptionMapping } from "@gatsby-plugin-marketplace/fragments/optionMapping"
import useCurrentPlanSettings from "@gatsby-plugin-marketplace/hooks/useCurrentPlanSettings"
import { PriceTypeContext } from "~/context/PriceTypeContext"
import type { ComparableProduct } from "~/fragments/comparableProduct"
import type { SaleConfiguration } from "~/fragments/saleConfigurations"
import isEmpty from "~/lib/isEmpty"
import { createProductParametersCellPricesValue } from "~/lib/parameters"
import type { ProductPriceNested } from "~/lib/pricing"
import { getProductPricing } from "~/lib/pricing"
import type { PeriodName } from "~/types/squidex"
import findProductOptions from "./findProductOptions"

const tableLabel = (periodInfo: PeriodInfo, type, specialType) => {
  if (periodInfo.hasMultipleCommitments() && !periodInfo.isOneTimeFee()) {
    const oneTimeText = _t("marketplace.tablePrice.oneTime", {
      type,
    })

    return `${oneTimeText} / ${periodInfo.getMultipleCommitmentsPeriodText()}`
  }

  if (periodInfo.isOneTimeFee()) {
    _t("marketplace.tablePrice.oneTime", {
      type,
    })
  }

  return specialType
    ? _t(
        `marketplace.tablePrice.${specialType}.long.${periodInfo.getPeriodType()}`,
        {
          type,
          smart_count: periodInfo.getPeriodLength(),
        }
      )
    : _t(`marketplace.tablePrice.long.${periodInfo.getPeriodType()}`, {
        type,
        smart_count: periodInfo.getPeriodLength(),
      })
}

const getPricePlaceholder = (uuid: string) => ({
  value: "-",
  uuid,
})

export default function useProductPrices(
  products: ComparableProduct[],
  saleConfiguration?: SaleConfiguration,
  hasPromoPrice?: boolean,
  hasRenewalPrice?: boolean,
  hasHiddenProductPrice?: boolean,
  selectedPeriod?: PeriodName
): TableGroup {
  const currentPlanSettings = useCurrentPlanSettings(saleConfiguration)
  const priceValuesNetto: TableValue[] = []
  const priceValuesGross: TableValue[] = []
  const priceRenewalValuesNetto: TableValue[] = []
  const priceRenewalValuesGross: TableValue[] = []
  const context = useContext(PriceTypeContext)
  const contextTogglePriceType = context?.currentPriceType
  const hasNettoPriceType = contextTogglePriceType === PriceType.NETTO

  let periodInfo: PeriodInfo | undefined

  let labels: { gross?: string; netto?: string } = {}

  const currentPlanSettingsPeriods: CloudBluePeriod[] = get(
    currentPlanSettings,
    "cloudBluePlan[0].flatData.periods",
    []
  )

  const currentPlanPeriodInfo = !isEmpty(currentPlanSettingsPeriods)
    ? getSelectedPeriodInfo(currentPlanSettingsPeriods, selectedPeriod)
    : undefined

  const currentProductHasMultipleCommitments =
    currentPlanPeriodInfo?.hasMultipleCommitments()

  const currentProductPeriod =
    selectedPeriod ||
    currentPlanSettings?.period?.period ||
    currentPlanSettingsPeriods.find((period) => period.default)?.period_name

  forEach(products, (item, index) => {
    const {
      flatData: {
        saleConfigurations,
        priceGross,
        priceLabelGross,
        priceLabelNetto,
        priceNetto,
      },
    } = item

    const hasPlan = !!get(
      item,
      "flatData.saleConfigurations[0].flatData.defaultCloudBluePlan[0].id"
    )

    if (priceLabelNetto && priceLabelGross) {
      labels.gross = priceLabelGross
      labels.netto = priceLabelNetto
    }

    if (priceNetto && priceGross) {
      priceValuesNetto.push(
        createProductParametersCellPricesValue(
          priceNetto,
          PriceType.NETTO,
          !!hasNettoPriceType
        )
      )
      priceValuesGross.push(
        createProductParametersCellPricesValue(
          priceGross,
          PriceType.GROSS,
          true
        )
      )

      return
    }

    if (isEmpty(saleConfigurations) || !hasPlan) {
      priceValuesNetto.push(createProductParametersCellPricesValue(`-`))

      priceValuesGross.push(createProductParametersCellPricesValue(`-`))

      return
    }

    let product: CloudBluePlan["flatData"] | undefined
    let productOptions: OptionMapping | null | undefined

    if (
      !isEmpty(currentPlanSettings) &&
      !isEmpty(saleConfiguration) &&
      saleConfiguration.id === saleConfigurations[0].id
    ) {
      product = get(currentPlanSettings, "cloudBluePlan[0].flatData")
    } else {
      productOptions = findProductOptions(
        currentPlanSettings,
        saleConfigurations[0]
      )

      const productOptionsPlanData: CloudBluePlan["flatData"] | undefined = get(
        productOptions,
        "cloudBluePlan[0].flatData"
      )

      const productDefaultPlanData: CloudBluePlan["flatData"] | undefined = get(
        saleConfigurations[0],
        "flatData.defaultCloudBluePlan[0].flatData"
      )

      product = productOptionsPlanData || productDefaultPlanData
    }

    if (!product) {
      priceValuesNetto.push(createProductParametersCellPricesValue(`-`))

      priceValuesGross.push(createProductParametersCellPricesValue(`-`))

      return
    }

    periodInfo = getSelectedPeriodInfo(
      product.periods,
      selectedPeriod || productOptions?.period?.period
    )

    const periodEqualCurrentProductPeriod =
      currentProductPeriod === periodInfo?.period.period_name

    const productHasMultipleCommitments =
      !isEmpty(periodInfo) &&
      periodInfo.hasMultipleCommitments() ===
        currentProductHasMultipleCommitments

    if (
      (!isEmpty(currentPlanSettings) && !periodEqualCurrentProductPeriod) ||
      (typeof currentProductHasMultipleCommitments === "boolean" &&
        !productHasMultipleCommitments)
    ) {
      priceValuesNetto.push(createProductParametersCellPricesValue(`-`))

      priceValuesGross.push(createProductParametersCellPricesValue(`-`))

      return
    }

    const priceValues: Pick<
      ProductPriceNested,
      "price" | "period" | "regularPrice" | "renewPrice"
    > | null = periodInfo
      ? {
          price: periodInfo.getRegisterPriceValue(),
          period: periodInfo.getPeriodName(),
          regularPrice: periodInfo.getRegularPriceValue(false),
          renewPrice: periodInfo.getRenewalPriceValue(1),
        }
      : null

    const grossPrice = priceValues
      ? getProductPricing(priceValues, PriceType.GROSS)
      : null
    const nettoPrice = priceValues
      ? getProductPricing(priceValues, PriceType.NETTO)
      : null

    const customPriceText: string | undefined = get(
      saleConfigurations[0],
      "flatData.customPriceBox.priceText"
    )

    if (nettoPrice) {
      priceValuesNetto.push(
        customPriceText
          ? createProductParametersCellPricesValue(customPriceText)
          : createProductParametersCellPricesValue(
              `${nettoPrice.priceDecimals},${nettoPrice.priceCents} ${nettoPrice.currency}`,
              PriceType.NETTO,
              !!hasNettoPriceType
            )
      )
    } else {
      priceValuesNetto.push(getPricePlaceholder(`${product.name}-${index}`))
    }

    if (grossPrice) {
      priceValuesGross.push(
        customPriceText
          ? createProductParametersCellPricesValue(customPriceText)
          : createProductParametersCellPricesValue(
              `${grossPrice.priceDecimals},${grossPrice.priceCents} ${grossPrice.currency}`,
              PriceType.GROSS,
              true
            )
      )
    } else {
      priceValuesGross.push(getPricePlaceholder(`${product.name}-${index}`))
    }

    if (hasRenewalPrice) {
      if (nettoPrice) {
        customPriceText
          ? createProductParametersCellPricesValue(customPriceText)
          : priceRenewalValuesNetto.push(
              createProductParametersCellPricesValue(
                `${nettoPrice.renewPriceDecimals},${nettoPrice.renewPriceCents} ${nettoPrice.currency}`,
                PriceType.NETTO,
                !!hasNettoPriceType
              )
            )
      } else {
        priceRenewalValuesNetto.push(
          getPricePlaceholder(`${product.name}-${index}`)
        )
      }

      if (!hasNoTaxRate()) {
        if (grossPrice) {
          customPriceText
            ? createProductParametersCellPricesValue(customPriceText)
            : priceRenewalValuesGross.push(
                createProductParametersCellPricesValue(
                  `${grossPrice.renewPriceDecimals},${grossPrice.renewPriceCents} ${grossPrice.currency}`,
                  PriceType.GROSS,
                  true
                )
              )
        } else {
          priceRenewalValuesGross.push(
            getPricePlaceholder(`${product.name}-${index}`)
          )
        }
      }
    }

    return
  })

  const parametersItemPriceValues: TableGroup = {
    name: "_",
    items: [],
  }

  if (hasHiddenProductPrice) {
    return {
      name: "",
      items: [],
    }
  }

  if (hasNoTaxRate() || hasNettoPriceType) {
    parametersItemPriceValues.items.push({
      label: periodInfo
        ? tableLabel(periodInfo, _t("prices.netto"), hasPromoPrice && "promo")
        : labels && labels.netto
          ? labels.netto
          : _t("marketplace.tablePrice.netto"),
      values: priceValuesNetto,
      dataTestId: "tabel-price-net",
    })

    if (priceRenewalValuesNetto.length) {
      parametersItemPriceValues.items.push({
        label: periodInfo
          ? tableLabel(periodInfo, _t("prices.netto"), "renewal")
          : labels && labels.netto
            ? labels.netto
            : _t("marketplace.tablePrice.renewal.netto"),
        values: priceRenewalValuesNetto,
        dataTestId: "tabel-price-net",
      })
    }
  } else {
    parametersItemPriceValues.items.unshift({
      label: periodInfo
        ? tableLabel(periodInfo, _t("prices.gross"), hasPromoPrice && "promo")
        : labels && labels.gross
          ? labels.gross
          : _t("marketplace.tablePrice.gross"),
      values: priceValuesGross,
      dataTestId: "tabel-price-gross",
    })

    if (priceRenewalValuesGross.length) {
      parametersItemPriceValues.items.push({
        label: periodInfo
          ? tableLabel(periodInfo, _t("prices.gross"), "renewal")
          : labels && labels.gross
            ? labels.gross
            : _t("marketplace.tablePrice.renewal.gross"),
        values: priceRenewalValuesGross,
        dataTestId: "tabel-price-gross",
      })
    }
  }

  return parametersItemPriceValues
}

import type { BasketViewItem } from "@gatsby-plugin-basket/types"
import { getHookPlans } from "~/lib/config"
import isEmpty from "~/lib/isEmpty"

const getSortedHackItems = (hackItems: BasketViewItem[]): BasketViewItem[] => {
  if (isEmpty(hackItems) || hackItems.length <= 1) {
    return hackItems
  }

  const hackPlansIds = getHookPlans()

  const sortedHackItems = hackItems.sort((a, b) => {
    const indexA = hackPlansIds.indexOf(a.planId)
    const indexB = hackPlansIds.indexOf(b.planId)

    if (indexA === -1 && indexB === -1) {
      return 0
    }

    if (indexA === -1) {
      return -1
    }

    if (indexB === -1) {
      return 1
    }

    return indexA - indexB
  })

  return sortedHackItems
}

export default getSortedHackItems

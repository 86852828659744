import type { ReactElement } from "react"
import React, { useState } from "react"
import Picture from "@onestore/hel/dist/components/quarks/Picture"
import PageFooterTemplate from "@onestore/hel/dist/components/templates/PageFooterTemplate"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import type { ModalWithButtonsData } from "~/fragments/modalWithButtons"
import LanguageModal from "../LanguageModal"

interface AdditionalFooterProps {
  additionalFooterData: ModalWithButtonsData
}
export default function AdditionalFooter({
  additionalFooterData,
}: AdditionalFooterProps): ReactElement<AdditionalFooterProps> {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const { modalItems, modalContent } = additionalFooterData

  const items = modalItems.map((item) => ({
    icon: (
      <Picture
        srcData={{
          src: item.image[0].url,
          alt: item.image[0].alt || "",
          width: item.image[0].width,
          height: item.image[0].height,
        }}
      />
    ),
    title: (
      <MarkdownText
        pushSpace={0}
        typography={{ bold: "small4", default: "small3" }}
      >
        {item.text}
      </MarkdownText>
    ),
  }))

  return (
    <>
      <PageFooterTemplate.AdditionalFooter
        languageButton={{
          title: additionalFooterData.title,
          subtitle: additionalFooterData.text,
          arrowColor: "secondaryLight",
          onClick: () => setIsOpen(true),
        }}
        items={items}
      />

      <LanguageModal
        isOpen={isOpen}
        onCloseButtonClick={() => setIsOpen(false)}
        modalContentData={modalContent}
      />
    </>
  )
}

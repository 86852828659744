// import { maxBy } from "lodash"
import type { PriceValue } from "@onestore/api/basket"

export class Price {
  static DEFAULT_TAX = 1.23

  static sum(
    a: PriceValue,
    b: PriceValue,
    c: PriceValue = Price.zero()
  ): PriceValue {
    return a + b + c
  }

  static subtract(a: PriceValue, b: PriceValue): PriceValue {
    return a - b
  }

  static divide(a: PriceValue, b: number): PriceValue {
    return a / b
  }

  static multiply(a: PriceValue, b: number): PriceValue {
    return a * b
  }

  static zero(): PriceValue {
    return 0
  }

  static isZero(value: PriceValue): boolean {
    return value === 0
  }

  static makeWithDefaultTax(net: number): PriceValue {
    return net
  }
}

/* CBCUPDATE-116 tymczasowo zakomentowane w tej wersji
interface PriceValue {
  netto: number
  gross: number
  tax: number
}

export class Price {
  static DEFAULT_TAX = 1.23

  static sum(
    a: PriceValue,
    b: PriceValue,
    c: PriceValue = Price.zero()
  ): PriceValue {
    return {
      netto: a.netto + b.netto + c.netto,
      gross: a.gross + b.gross + c.gross,
      tax: a.tax + b.tax + c.tax,
    }
  }

  static subtract(a: PriceValue, b: PriceValue): PriceValue {
    return {
      netto: a.netto - b.netto,
      gross: a.gross - b.gross,
      tax: a.tax - b.tax,
    }
  }

  static divide(a: PriceValue, b: number): PriceValue {
    return {
      netto: a.netto / b,
      gross: a.gross / b,
      tax: a.tax / b,
    }
  }

  static multiply(a: PriceValue, b: number): PriceValue {
    return {
      netto: a.netto * b,
      gross: a.gross * b,
      tax: a.tax * b,
    }
  }

  static max(values: PriceValue[]): PriceValue {
    const max = maxBy<PriceValue>(values, "gross")

    return max ? max : values[0]
  }

  static createFromPriceWithTaxRate(
    price: number,
    taxRate: number
  ): PriceValue {
    return {
      netto: price,
      tax: Math.round(price * (taxRate / 100)),
      gross: price + Math.round(price * (taxRate / 100)),
    }
  }

  static zero(): PriceValue {
    return {
      netto: 0,
      gross: 0,
      tax: 0,
    }
  }

  static makeWithDefaultTax(net: number): PriceValue {
    const vat = Math.round(net * Price.DEFAULT_TAX)

    return {
      netto: net,
      gross: net + vat,
      tax: vat,
    }
  }

  static isZero(value:PriceValue):boolean {
    return value.netto === 0;
  }
}
*/

import type { Draft } from "immer"
import { produce } from "immer"
import type { AccountState } from "../../index"
import type * as actions from "./actions"
import { AccountActionTypes } from "./actions"

export const initialState: AccountState = {
  account: null,
}

const accountReducer = (
  state: AccountState = initialState,
  action: actions.AccountAction
): AccountState =>
  produce<AccountState, AccountState>(
    state,
    (nextState: Draft<AccountState>) => {
      switch (action.type) {
        case AccountActionTypes.ACTION_ACCOUNT_UPDATE:
          nextState.account = action.account
          break
        case AccountActionTypes.ACTION_ACCOUNT_LOGOUT:
          nextState.account = null
          break
      }
    }
  )

export default accountReducer

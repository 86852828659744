import type { ReactElement } from "react"
import React, { useState } from "react"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import AnimatedLinkIndicatorMol from "@onestore/hel/dist/components/molecules/AnimatedLinkIndicatorMol"
import ContentToggleMol from "@onestore/hel/dist/components/molecules/ContentToggleMol"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import ContentWithImageOrg from "@onestore/hel/dist/components/organisms/ContentWithImageOrg"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import Picture from "@onestore/hel/dist/components/quarks/Picture"
import SliderTemplate from "@onestore/hel/dist/components/templates/SliderTemplate"
import useBreakpoint from "@onestore/hel/dist/hooks/useBreakpoint"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import type { LinksAnimatedImageSectionFlatData } from "@gatsby-plugin-generic-page/types/sectionType"
import assetsSquidexToImageObject from "~/lib/assetsSquidexToImageObject"

export default function LinksAnimatedImageSection({
  title,
  linksWithImage,
  linksTitle,
  sectionSettings,
}: LinksAnimatedImageSectionFlatData): ReactElement<LinksAnimatedImageSectionFlatData> {
  const [activeLink, setActiveLink] = useState(0)
  const isSmallOnlyScreen = useBreakpoint("small", "medium")

  const imageContents = linksWithImage.map((links) => {
    const { image, text } = links

    return (
      <Picture
        key={`image-${text}`}
        srcData={assetsSquidexToImageObject(image, text)}
      />
    )
  })

  const slides = linksWithImage.map((links) => {
    const { image, text } = links

    return {
      title: text,
      image: <Picture srcData={assetsSquidexToImageObject(image, text)} />,
    }
  })

  const imagesJsx = (
    <ContentToggleMol activeIndex={activeLink} content={imageContents} />
  )

  const titleJsx = title ? (
    <TextAtm htmlTag="h2" typography="xhuge" pushSpace={6}>
      {title}
    </TextAtm>
  ) : undefined

  const contentJsx = (
    <div>
      {linksTitle ? (
        <TextAtm typography="medium3_h" pushSpace={3}>
          {linksTitle}
        </TextAtm>
      ) : null}

      {linksWithImage.map((link, index) => (
        <PushOrg bottomSpace={5} key={`link-${link.text}`}>
          <AnimatedLinkIndicatorMol
            isActive={activeLink === index}
            text={link.text}
            onClick={() => {
              setActiveLink(index)
            }}
          />
        </PushOrg>
      ))}
    </div>
  )

  return (
    <BaseSection hasOverflowHidden={isSmallOnlyScreen} {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        <BreakpointVisibilityOrg from="large">
          <ContentWithImageOrg
            gutter="medium"
            content={
              <>
                {titleJsx}

                {contentJsx}
              </>
            }
            image={imagesJsx}
          />
        </BreakpointVisibilityOrg>

        <BreakpointVisibilityOrg from="medium" to="large">
          <PushOrg bottomSpace={5}>
            <AlignContentOrg horizontalAlign="center">
              {titleJsx}

              {imagesJsx}
            </AlignContentOrg>
          </PushOrg>

          <FlexContainerOrg justifyContent="center">
            {contentJsx}
          </FlexContainerOrg>
        </BreakpointVisibilityOrg>

        <BreakpointVisibilityOrg from="small" to="medium">
          <PushOrg bottomSpace={5}>
            <AlignContentOrg horizontalAlign="center">
              {titleJsx}
            </AlignContentOrg>
          </PushOrg>

          <SliderTemplate slides={slides} ratio="original" hasArrowsOnMobile />
        </BreakpointVisibilityOrg>
      </BaseContainer>
    </BaseSection>
  )
}

import log from "~/lib/log"

export const CM_PURPOSE_ESSENTIAL = "c51" // Essential scripts, in polish: Niezbędne
export const CM_PURPOSE_MARKETING = "c52" // Marketing scripts, in polish: "Kierunkowe i reklamowe"
export const CM_PURPOSE_ANALITYCS = "c54" // Analytics scripts, in polish: "Wydajnościowe i analityczne"
export const CM_PURPOSE_SOCIAL = "c56" // Social media scripts, in polish: "Media społecznościowe"

export const CM_VENDOR_1AND1 = "s1669"
export const CM_VENDOR_HOMEPL = "c32038"

interface ConsentManagerCMPData {
  purposeConsents: Record<string | number, boolean>
  vendorConsents: Record<string | number, boolean>
}

declare global {
  interface Window {
    __cmp: {
      (
        command: string,
        parameter?: any,
        callback?: any,
        async?: any
      ): ConsentManagerCMPData
    }
  }
}

function registerConsentManagerEvents(
  initTacos: { (): void },
  initOaO: { (): void }
) {
  function makeConsentCheck() {
    const result = window.__cmp("getCMPData")
    log(["purposeConsents", result.purposeConsents], "consentManager")
    log(["vendorConsents", result.vendorConsents], "consentManager")

    if (result.vendorConsents[CM_VENDOR_1AND1]) {
      initOaO()
    }

    if (
      result.vendorConsents[CM_VENDOR_HOMEPL] ||
      result.vendorConsents[CM_VENDOR_1AND1]
    ) {
      initTacos()
    }
  }

  function consentManagerEventListener(eventname: string) {
    log(["cmp:event", eventname], "consentManager")
    makeConsentCheck()
  }

  //event cmpload is trigger by gtm tag "CMP load event", example in container GTM-TT8M6V4
  window.addEventListener("cmpload", () => {
    window.__cmp(
      "addEventListener",
      ["consent", consentManagerEventListener, false],
      null
    )

    makeConsentCheck()
  })
}

export function initConsentManager(
  initTacos: { (): void },
  initOaO: { (): void }
) {
  try {
    registerConsentManagerEvents(initTacos, initOaO)
  } catch (error) {
    log(["error", error], "consentManager")
  }
}

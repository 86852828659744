import { useContext } from "react"
import { replaceLinkValues } from "@gatsby-plugin-generic-page/helpers/linkParser"
import { SearchUrlParametersContext } from "~/context/SearchUrlParametersContext"
import isEmpty from "~/lib/isEmpty"

export function useLinkReplacer(href: string | undefined): string | null {
  const currentSearchUrlParameters = useContext(SearchUrlParametersContext)

  if (isEmpty(href)) {
    return null
  }

  return replaceLinkValues(href, currentSearchUrlParameters)
}

import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import {
  isResponsePending,
  isResponseSuccessful,
} from "@onestore/onestore-store-common/http"
import Content from "@gatsby-plugin-bonus/components/DomainsBonusBox/Content"
import SubmitField from "@gatsby-plugin-bonus/components/DomainsBonusBox/SubmitField"
import ResponsiveMessageBox from "@gatsby-plugin-domain-search/components/ResponsiveMessageBox"
import useDomainHookContentQuery from "@gatsby-plugin-domain-search/hooks/useDomainHookContentQuery"
import { COMPONENT_ID } from "~/types/component"
import type { CloudBlueDomain } from "~/types/squidex"
import { MainResultBox } from "../MainResultBox/MainResultBox"
import { MainResultLoader } from "../MainResultBox/MainResultLoader"
import useEmbeddedDomainSearchActions from "./useEmbeddedDomainSearchActions"

interface EmbeddedDomainSearchProps {
  domains: CloudBlueDomain[]
}

function EmbeddedDomainSearch({
  domains,
}: EmbeddedDomainSearchProps): ReactElement {
  const {
    phrase,
    setPhrase,
    mainDomain,
    results,
    requestStatus,
    onSearchClicked,
    basketDomains,
    onBasketAdd,
    hasContent,
  } = useEmbeddedDomainSearchActions(domains, true)
  const { mobileTitle, text, desktopPrefix } = useDomainHookContentQuery()
  const hasMessageBox = mobileTitle && text

  return (
    <PushOrg topSpace={2}>
      <SubmitField
        onSearchClicked={onSearchClicked}
        phrase={phrase}
        setPhrase={setPhrase}
        disabled={false}
        requestStatus={requestStatus}
        hideText
      />
      {hasContent ? (
        <PushOrg topSpace={hasMessageBox ? 0 : 3} hasNoReset>
          <PushOrg bottomSpace={6}>
            {isResponsePending(requestStatus) ? <MainResultLoader /> : null}

            {isResponseSuccessful(requestStatus) ? (
              <AlignContentOrg
                horizontalAlignType="text"
                horizontalAlign="left"
              >
                {hasMessageBox ? (
                  <PushOrg bottomSpace={3}>
                    <div id={COMPONENT_ID.MESSAGE_BOX}>
                      <ResponsiveMessageBox
                        mobileTitle={mobileTitle}
                        text={text}
                        desktopPrefix={desktopPrefix}
                        backgroundColor="backgroundDarkLight"
                      />
                    </div>
                  </PushOrg>
                ) : null}
                <MainResultBox
                  mainDomainResult={mainDomain}
                  sortingTooltipContent={_t("preBasket.searchSortInfoTooltip")}
                  hasScrollToMessageBox={!!hasMessageBox}
                />
              </AlignContentOrg>
            ) : null}
          </PushOrg>

          <PushOrg bottomSpace={3} hasNoReset>
            <Content
              results={results}
              onBasketAdd={onBasketAdd}
              basketDomains={basketDomains}
              hasProduct={false}
              requestStatus={requestStatus}
              phrase={phrase}
            />
          </PushOrg>
        </PushOrg>
      ) : null}
    </PushOrg>
  )
}

export default EmbeddedDomainSearch

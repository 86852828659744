import { graphql } from "gatsby"
import type { Header } from "@gatsby-plugin-generic-page/fragments/header"
import type { Video } from "@gatsby-plugin-generic-page/fragments/video"
import type { SectionSettings } from "./sectionSettings"

export interface VideoSectionFlatData {
  video?: Video[]
  header?: Header
  sectionSettings?: SectionSettings
  type: SquidexVideoType
  url?: string
  iframeName?: string
  iframeWidth?: number
}

export enum SquidexVideoType {
  FILE = "plik",
  URL = "url",
}

export const query = graphql`
  fragment VideoSection on GraphCmsSquidex_VideoSection {
    __typename
    id
    url
    flatData {
      name
      header {
        ...Header
      }
      type
      url
      iframeName
      iframeWidth
      sectionSettings {
        ...SectionSettings
      }
      video {
        ...Video
      }
    }
  }
`
export const FRAGMENT_VIDEO_SECTION = `
  fragment VideoSection on VideoSection {
    __typename
    id
    url
    flatData {
      name
      header {
        ...Header
      }
      type
      url
      iframeName
      iframeWidth
      sectionSettings {
        ...SectionSettings
      }
      video {
        ...Video
      }
    }
  }
`

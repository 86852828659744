import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import LabelAtm from "@onestore/hel/dist/components/atoms/LabelAtm"
import PackageBoxSlimMol from "@onestore/hel/dist/components/molecules/PackageBoxSlimMol"
import type { CtaElement } from "@gatsby-plugin-generic-page/fragments/ctaElement"
import getBackground from "@gatsby-plugin-generic-page/helpers/getBackground"
import { getCtaButtonFlatData } from "@gatsby-plugin-generic-page/helpers/getCtaElementFlatData"
import { getStaticPrices } from "@gatsby-plugin-generic-page/helpers/staticPrices"
import ProductBoxPrice from "~/components/ProductBoxPrice"
import type { StaticPrice } from "~/fragments/staticPrice"
import { sendEvent } from "~/lib/ga4"
import isEmpty from "~/lib/isEmpty"
import ProductBoxSlimFooter from "./ProductBoxFooter"

export interface ProductBoxWithCtaProps {
  product
  cta: CtaElement[]
  staticPrices?: StaticPrice[]
  isTrialBox?: boolean
  isDisabled?: boolean
}

export default function ProductBoxWithCta({
  product,
  cta,
  staticPrices,
  isTrialBox,
  isDisabled,
}: ProductBoxWithCtaProps): ReactElement<ProductBoxWithCtaProps> {
  const prices = staticPrices
    ? getStaticPrices(staticPrices, product.priceType)
    : undefined

  if (isEmpty(cta)) {
    return <></>
  }

  const { text, title, variant, gaEvent, href, openInNewTab, pages } =
    getCtaButtonFlatData(cta[0])

  const period =
    prices && Object.keys(prices).length === 1
      ? Object.keys(prices)[0]
      : product.period || (prices && Object.keys(prices)[0])

  const { customOnColorText, imageBackground } = getBackground(
    product.backgroundImage
  )

  return (
    <PackageBoxSlimMol
      {...product}
      button={
        !isDisabled
          ? {
              text: product.buttonText || text,
              title,
              href,
              to: pages?.length && pages[0].flatData.url,
              color: product.buttonColor,
              variant,
              openInNewTab,
              onClick: gaEvent ? () => sendEvent(gaEvent) : undefined,
            }
          : { text: product.buttonText || text, isDisabled: true }
      }
      price={prices ? <ProductBoxPrice {...prices[period].price} /> : null}
      renewalPayment={
        (prices &&
          prices[period].renewPrice &&
          _t("packageBoxSlim.renewalPrice", {
            price: prices[period].renewPrice?.value,
            suffix: prices[period].renewPrice?.suffix,
          })) ||
        product.additionalText ||
        undefined
      }
      label={
        !isEmpty(product.discountLabel) ? (
          <LabelAtm
            size="small"
            variant={product.discountLabelVariant ?? "notify"}
            wrapLabel
          >
            {product.discountLabel}
          </LabelAtm>
        ) : null
      }
      parameters={product.content.parameters}
      footer={
        product.content?.footer ? (
          <ProductBoxSlimFooter {...product.content.footer} />
        ) : undefined
      }
      customTextColor={
        isTrialBox && customOnColorText ? customOnColorText : undefined
      }
      backgroundImage={
        isTrialBox && imageBackground ? imageBackground : undefined
      }
      isTrialBox={isTrialBox}
    />
  )
}

import React from "react"
import type { MarkdownToJSX } from "markdown-to-jsx"
import ListItem from "./components/ListItem"
import Paragraph from "./components/Paragraph"

const markdownOverrides: MarkdownToJSX.Overrides = {
  p: {
    component: Paragraph,
    props: {
      typography: "medium1_h",
      pushSpace: 1,
    },
  },
  strong: {
    component: ({ children, index }) =>
      children.map((child) => (
        <strong key={`contact-section-text-bold-${index}`}>{child}</strong>
      )),
  },
  li: {
    component: ListItem,
  },
}

export default markdownOverrides

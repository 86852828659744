import React from "react"
import type { MarkdownToJSX } from "markdown-to-jsx"
import LinkAtm from "@onestore/hel/dist/components/atoms/LinkAtm"
import NestedLinkAtm from "@onestore/hel/dist/components/atoms/NestedLinkAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import type { TextAtmProps } from "@onestore/hel/dist/components/atoms/TextAtm/TextAtm.props"
import ListMol from "@onestore/hel/dist/components/molecules/ListMol"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import Picture from "@onestore/hel/dist/components/quarks/Picture"
import Paragraph from "@gatsby-plugin-generic-page/markdown/components/Paragraph"

const pushSpace = 1

const markdownOverrides: MarkdownToJSX.Overrides = {
  h1: {
    component: TextAtm,
    props: {
      typography: "large3",
      pushSpace: 4,
      htmlTag: "h1",
    } as TextAtmProps,
  },
  h2: {
    component: TextAtm,
    props: {
      typography: "large3",
      pushSpace: 4,
      htmlTag: "h2",
    } as TextAtmProps,
  },
  h3: {
    component: TextAtm,
    props: {
      typography: "large3",
      pushSpace: 2,
      htmlTag: "h3",
    } as TextAtmProps,
  },
  h4: {
    component: TextAtm,
    props: {
      typography: "big3",
      pushSpace,
      htmlTag: "h4",
    } as TextAtmProps,
  },
  h5: {
    component: TextAtm,
    props: {
      typography: "medium3_s",
      pushSpace,
      htmlTag: "h5",
    } as TextAtmProps,
  },
  h6: {
    component: TextAtm,
    props: {
      typography: "small3",
      pushSpace,
      htmlTag: "h6",
    } as TextAtmProps,
  },
  p: {
    component: Paragraph,
    props: {
      typography: "medium1_h",
      pushSpace: 2,
    },
  },
  span: {
    component: TextAtm,
    props: {
      typography: "medium1_h",
      pushSpace,
    } as TextAtmProps,
  },
  ul: {
    component: ({ children }) => (
      <PushOrg bottomSpace={2} topSpace={2}>
        <ListMol
          items={children.map((child) => (
            <TextAtm typography="medium2_h">{child.props.children}</TextAtm>
          ))}
          type="dot"
          size="medium"
        />
      </PushOrg>
    ),
  },
  ol: {
    component: ({ children }) => (
      <PushOrg bottomSpace={2} topSpace={2}>
        <ListMol
          items={children.map((child) => (
            <TextAtm typography="medium2_h">
              {{
                ...child,
                type: "span",
              }}
            </TextAtm>
          ))}
          type="number"
          size="medium"
        />
      </PushOrg>
    ),
  },
  img: {
    component: (image) => (
      <PushOrg topSpace={4} bottomSpace={4} hasNoReset>
        <Picture srcData={image} />
      </PushOrg>
    ),
  },
  Link: {
    component: LinkAtm,
  },
  NestedLink: {
    component: ({ children }) => (
      <TextAtm typography="medium1_h">
        <NestedLinkAtm {...children} />
      </TextAtm>
    ),
  },
}

export default markdownOverrides

import _t from "@core/i18n"
import type { ComparableProduct } from "~/fragments/comparableProduct"

export default function useHeader(
  products: ComparableProduct[],
  tableHeaderTitle,
  headerColumns,
  generateHeaderLink
) {
  return {
    label: tableHeaderTitle
      ? tableHeaderTitle
      : _t("marketplace.pricingParameters"),
    columns: headerColumns.length
      ? headerColumns
      : products.map((item: ComparableProduct) =>
          generateHeaderLink(item, products)
        ),
  }
}

import React from "react"
import type { ReactElement } from "react"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import type { ConfigsHookResult } from "@gatsby-plugin-generic-page/hooks/useTableConfigs"
import { getResourceValueByLimits } from "@gatsby-plugin-generic-page/hooks/useTableConfigs"
import isEmpty from "~/lib/isEmpty"

interface Props {
  productId: string
  resourceCategoryId: string
  configsHookResult: ConfigsHookResult
}

export default function ResourceCategoryCell({
  productId,
  resourceCategoryId,
  configsHookResult,
}: Props): ReactElement<Props> {
  const {
    getResourceCategory,
    getProductResource,
    getProductPeriodResource,
    getConfigResource,
  } = configsHookResult

  const resourceCategoryData = getResourceCategory(
    resourceCategoryId,
    productId
  )

  if (isEmpty(resourceCategoryData)) {
    return resourceCategoryId === "150" ? (
      <TextAtm typography="small3">1/1</TextAtm>
    ) : (
      <>-</>
    )
  }

  const resourcesElements = resourceCategoryData.resources.map((resource) => {
    const { value } = getConfigResource(resource.id as string) || {}

    const resourceData = getProductResource(
      productId,
      resourceCategoryId,
      resource.id as string
    )

    const resourcePeriodData = getProductPeriodResource(
      productId,
      resource.id as string
    )

    if (isEmpty(resourceData) || isEmpty(resourcePeriodData)) {
      return resourceCategoryId === "150" ? (
        <TextAtm typography="small3">1/1</TextAtm>
      ) : (
        <>-</>
      )
    }

    const resourceValue = getResourceValueByLimits(
      resourcePeriodData.lower_limit,
      resourcePeriodData.upper_limit,
      value
    )

    return (
      <TextAtm
        key={`resource-${resourceData.name}-${productId}`}
        typography="small2"
      >
        {/* Fix later - ONESTORE-623 */}
        {resourceCategoryId === "150" || resourceCategoryId === "1136" ? (
          <TextAtm typography="small3">{`${resourceValue + 1}/${resourcePeriodData.upper_limit + 1}`}</TextAtm>
        ) : (
          <TextAtm typography="small3">{`${resourceValue}/${resourcePeriodData.upper_limit}`}</TextAtm>
        )}
      </TextAtm>
    )
  })

  return <>{resourcesElements}</>
}

import { ReactElement } from "react"

interface DisplayProps {
  from: string
  children: ReactElement
  to?: string | null
}

function Display({
  to,
  from,
  children,
}: DisplayProps): ReactElement<DisplayProps> | null {
  const currentDate = Date.now()

  if (
    (to && Date.parse(from) <= currentDate && currentDate <= Date.parse(to)) ||
    (!to && Date.parse(from) <= currentDate)
  ) {
    return children
  }

  return null
}

export default Display

import type { KeyboardEvent, SyntheticEvent } from "react"
import React, { useCallback, useEffect, useState } from "react"
import _t from "@core/i18n"
import Arrow from "@onestore-icons/nav-arrow-right.svg"
import { useLocation } from "@reach/router"
import type { DomainCheck } from "@onestore/api/domainSearch"
import type { IconAtmProps } from "@onestore/hel/dist/components/atoms/IconAtm"
import SubmitInputMol from "@onestore/hel/dist/components/molecules/SubmitInputMol"
import type Theme from "@onestore/hel/dist/typings/theme"
import { DomainErrorModal } from "~/components/DomainTransferInputField/DomainErrorModal"
import { DomainIsAvailableModal } from "~/components/DomainTransferInputField/DomainIsAvailableModal"
import { DomainUnknownModal } from "~/components/DomainTransferInputField/DomainUnknownModal"
import { TransferAuthInfoModal } from "~/components/DomainTransferInputField/TransferAuthInfoModal"
import {
  DomainTransferState,
  useDomainTransferActions,
} from "~/components/DomainTransferInputField/useDomainTransferActions"
import type { ValidationResult } from "~/lib/domain"
import { validatePhrase } from "~/lib/domain"

export const validateTransferPhrase = (
  fqdn: DomainCheck.FQDN
): ValidationResult => {
  const result = validatePhrase(fqdn)

  if (result.valid && !fqdn.includes(".")) {
    return {
      valid: false,
      errors: [_t("transfer.invalidFqdn")],
    }
  }

  return result
}

export interface LocationTransferState {
  state?: {
    domainTransfer?: DomainCheck.Result
  }
}

export interface DomainTransferInputFieldProps {
  isFancy?: boolean
  submitText: string
  buttonColor?: Theme.AccentColor
  placeholderText: string
  autoFocus?: boolean
  icon?: IconAtmProps["icon"]
}

interface DomainValidatorHook {
  valid: boolean
  value: string
  errorText: string | undefined
  setValue: { (currentValue: string): void }
}

function useDomainValidator(): DomainValidatorHook {
  const [valid, setValid] = useState(true)
  const [value, setValue] = useState("")
  const [errorText, setError] = useState<string | undefined>(undefined)

  return {
    valid,
    value,
    errorText,
    setValue: (currentValue: string): void => {
      const validation = validateTransferPhrase(currentValue)

      setValid(validation.valid)
      setValue(currentValue)
      setError(validation.valid ? undefined : validation.errors[0])
    },
  }
}

export default function DomainTransferInputField({
  isFancy = true,
  submitText,
  buttonColor,
  placeholderText,
  icon,
  autoFocus = false,
}: DomainTransferInputFieldProps) {
  const { value, valid, errorText, setValue } = useDomainValidator()
  const location = useLocation() as LocationTransferState

  const { state } = location
  const stateDomain = state?.domainTransfer
  const stateFqdn = state?.domainTransfer?.fqdn

  useEffect(() => {
    if (stateFqdn && stateDomain) {
      setValue(stateDomain?.fqdn)
      onSubmit(stateDomain?.fqdn)
    }
  }, [stateFqdn])

  const { onSubmit, onCloseModal, fqdn, extension, planId, transferState } =
    useDomainTransferActions()

  const onChange = (event: SyntheticEvent<HTMLInputElement>): void => {
    setValue(event.currentTarget.value)
  }

  const handleOnSubmit = useCallback((): void => {
    if (value.length === 0 || !valid) {
      return
    }
    onSubmit(value)
  }, [value, valid])

  const onKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      handleOnSubmit()
    }
  }

  return (
    <>
      <SubmitInputMol
        isLoading={transferState === DomainTransferState.LOADING}
        isFancy={isFancy}
        buttonText={submitText}
        buttonTitle={submitText}
        buttonMobileIcon={Arrow}
        onKeyPress={onKeyPress}
        value={value}
        buttonColor={buttonColor}
        placeholder={placeholderText}
        onChange={onChange}
        onSubmit={handleOnSubmit}
        hasError={!valid}
        errorMessage={errorText}
        data-test-id="domain-search-box"
        hasAutofocus={autoFocus}
        icon={icon}
      />

      <TransferAuthInfoModal
        isOpen={transferState === DomainTransferState.NEEDS_AUTHINFO}
        onCloseButtonClick={() => {
          onCloseModal()
          setValue("")
        }}
        domain={fqdn}
        extension={extension}
        planId={planId}
      />

      <DomainIsAvailableModal
        isOpen={transferState === DomainTransferState.CAN_REGISTER}
        onCloseButtonClick={() => {
          onCloseModal()
          setValue("")
        }}
        domain={fqdn as DomainCheck.FQDN}
      />

      <DomainUnknownModal
        isOpen={transferState === DomainTransferState.UNKNOWN_EXTENSION}
        onCloseButtonClick={() => {
          onCloseModal()
          setValue("")
        }}
        domain={fqdn as DomainCheck.FQDN}
      />

      <DomainErrorModal
        isOpen={transferState === DomainTransferState.HAS_ERROR}
        onCloseButtonClick={onCloseModal}
      />
    </>
  )
}

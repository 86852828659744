import type { ReactElement } from "react"
import React from "react"
import GridOrg from "@onestore/hel/dist/components/organisms/GridOrg"
import useBreakpoint from "@onestore/hel/dist/hooks/useBreakpoint"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import type { CoreProductsSectionFlatData } from "@gatsby-plugin-generic-page/fragments/coreProductsSection"
import ProductTile from "./components/ProductTile"

const productsToShow = { small: 6, medium: 6, large: 5 }

export default function CoreProductsSection({
  coreProducts,
  sectionSettings,
}: CoreProductsSectionFlatData): ReactElement<CoreProductsSectionFlatData> {
  const isSmallScreen = useBreakpoint("small", "medium")
  const isMediumScreen = useBreakpoint("medium", "large")

  const someProductHasLabel = coreProducts.some(({ labelText }) => !!labelText)

  let productsToShowNumber

  if (isSmallScreen) {
    productsToShowNumber = productsToShow.small
  } else if (isMediumScreen) {
    productsToShowNumber = productsToShow.medium
  } else {
    productsToShowNumber = productsToShow.large
  }

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        <GridOrg
          columns={{
            small: 1,
            medium: 2,
            large: productsToShow.large ? productsToShow.large : 4,
          }}
          gutterSpace={{ small: 1, medium: 3, large: 4 }}
        >
          {coreProducts.slice(0, productsToShowNumber).map((product) => (
            <ProductTile
              key={`product-tile-${product.title}`}
              {...product}
              hasSiblingWithLabel={
                someProductHasLabel ? !product.labelText : undefined
              }
            />
          ))}
        </GridOrg>
      </BaseContainer>
    </BaseSection>
  )
}

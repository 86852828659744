import type { SyntheticEvent } from "react"
import { useRef, useState } from "react"
import { useForm } from "react-final-form"

export default function useSendFile(
  onChange: (event: any) => void,
  fieldName: string
) {
  const inputRef = useRef<HTMLInputElement>(null)

  const [uploadedFilesNames, setUploadedFilesNames] = useState("")
  const field = useForm()

  const handleFileEvent = (event: SyntheticEvent): void => {
    const target = event.target as HTMLInputElement
    const chosenFiles = Array.prototype.slice.call(target.files)
    const names: string[] = chosenFiles.map((file) => file.name)
    const text: string = names.join(", ")

    setUploadedFilesNames(text)
    onChange(target.files)
  }

  const resetFiles = (): void => {
    field.change(fieldName, "")

    if (inputRef.current) {
      inputRef.current.value = ""
      inputRef.current.files = null
    }
  }

  return {
    inputRef,
    uploadedFilesNames,
    handleFileEvent,
    resetFiles,
    setUploadedFilesNames,
  }
}

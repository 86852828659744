import React, { ReactNode, useCallback, useMemo } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import Captcha, { CaptchaProps } from "~/components/Form/Captcha"

interface RecaptchaHook {
  loaded: boolean
  getCaptchaCode: { (): Promise<string> }
  shouldResetRecaptcha: boolean
  setLoaded: { (): void }
  setShouldResetRecaptcha: { (value: boolean): void }
  captchaNode: ReactNode
}

export default function useRecaptcha(
  initialState: boolean,
  renderInfo?: CaptchaProps["renderInfo"],
  hideBadge: boolean = true
): RecaptchaHook {
  const ref = React.useRef<ReCAPTCHA>(null)
  const [loaded, setLoaded] = React.useState(initialState)
  const [shouldResetRecaptcha, setShouldResetRecaptcha] = React.useState(false)

  const getCaptchaCode: RecaptchaHook["getCaptchaCode"] = useCallback(
    () =>
      new Promise((resolve) => {
        const currentRef = ref.current

        if (!currentRef) {
          return
        }

        if (shouldResetRecaptcha) {
          currentRef.reset()
        }
        currentRef.execute()

        let counter = 0
        // Czekamy az użytkownik wypełni captche.
        const checker = setInterval(() => {
          if (currentRef !== null) {
            // @ts-ignore
            const value = currentRef.getValue()
            counter++

            if (value) {
              clearInterval(checker)
              resolve(value)
            }
          }

          // Po 5 minutach, przerywamy (600 * 500ms)
          if (counter > 600) {
            clearInterval(checker)
            resolve("")
          }
        }, 500)
      }),
    [shouldResetRecaptcha]
  )

  const setRecaptchaLoaded = () => setLoaded(true)

  const captchaNode = useMemo(
    () => (
      <Captcha
        ref={ref}
        asyncScriptOnLoad={setRecaptchaLoaded}
        renderInfo={renderInfo}
        hideBadge={hideBadge}
      />
    ),
    [ref, renderInfo, hideBadge]
  )

  return {
    loaded,
    getCaptchaCode,
    setLoaded: setRecaptchaLoaded,
    shouldResetRecaptcha,
    setShouldResetRecaptcha,
    captchaNode,
  }
}

import type { ReactElement } from "react"
import React from "react"
import ConfigBoxMol from "@onestore/hel/dist/components/molecules/ConfigBoxMol"
import type { SelectOption } from "@onestore/hel/dist/components/molecules/SelectMol/SelectMol"
import { OptionsAlign } from "@onestore/hel/dist/components/molecules/SelectMol/SelectMol"
import SelectMol from "@onestore/hel/dist/components/molecules/SelectMol/SelectMol"
import FlexItemOrg from "@onestore/hel/dist/components/organisms/FlexItemOrg"
import space from "@onestore/hel/dist/utils/space"
import isEmpty from "~/lib/isEmpty"

interface Props {
  configLabel?: string
  options: SelectOption[]
  isExpandedContent?: boolean
  isDisable?: boolean
  onChange: (value: string) => void
}

export default function SelectConfigElement({
  configLabel,
  options,
  isExpandedContent,
  isDisable,
  onChange,
}: Props): ReactElement<Props> {
  if (isEmpty(options)) {
    return <></>
  }

  const configElement = (
    <FlexItemOrg flexBasis={space(17.5)}>
      <SelectMol
        size="small"
        borderRadius="mlarge"
        options={options}
        isInactive={isDisable}
        onChange={(value) => onChange(value)}
        optionsAlign={{
          small: OptionsAlign.RIGHT,
          medium: OptionsAlign.RIGHT,
          large: OptionsAlign.LEFT,
        }}
      />
    </FlexItemOrg>
  )

  const configBoxElement = (
    <ConfigBoxMol
      label={configLabel || ""}
      configElement={configElement}
      hasSelect
    />
  )

  return isExpandedContent ? configElement : configBoxElement
}

import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Picture from "@onestore/hel/dist/components/quarks/Picture"
import type { Props as FooterProps } from "@onestore/hel/dist/components/templates/PageFooterTemplate/PageFooterTemplate.props"
import type { FooterText } from "@gatsby-plugin-generic-page/fragments/footerText"
import getLinkProps from "@gatsby-plugin-generic-page/helpers/getLinkProps"
import type { CategoryData } from "~/fragments/category"
import type { EmbeddedAssetSquidexMetadata } from "~/fragments/image"
import type { ModalWithButtonsData } from "~/fragments/modalWithButtons"
import type { SocialData } from "~/fragments/social"
import isEmpty from "~/lib/isEmpty"

export interface FooterFlatData {
  logo: EmbeddedAssetSquidexMetadata[]
  socials: SocialData[]
  categories: CategoryData[]
  partners: {
    partner: EmbeddedAssetSquidexMetadata[]
  }[]
  additionalFooter: ModalWithButtonsData | FooterText
}

export interface FooterDataQuery {
  GraphCmsSquidex: {
    footer: {
      id: string
      __typename: string
      flatData: FooterFlatData
    }
  }
}

export interface FooterPropsData extends FooterProps {
  additionalFooterData?: ModalWithButtonsData | FooterText
}

export default function useFooterData(): FooterPropsData {
  const {
    GraphCmsSquidex: { footer },
  } = useStaticQuery<FooterDataQuery>(graphql`
    {
      GraphCmsSquidex {
        footer: findFooterTemplateSingleton {
          id
          __typename
          flatData {
            logo {
              ...Image
            }
            socials {
              ...Social
            }
            categories {
              ...Category
            }
            partners {
              partner {
                ...Image
              }
            }
            additionalFooter {
              ... on GraphCmsSquidex_ModalWithButtonsComponent {
                ...ModalWithButtons
              }
              ... on GraphCmsSquidex_FooterTextComponent {
                ...FooterText
              }
            }
          }
        }
      }
    }
  `)

  const logoFlatData = footer.flatData.logo[0]
  const logo = {
    src: logoFlatData.url,
    alt: logoFlatData.alt || "logo",
    width: logoFlatData.width || 124,
    height: logoFlatData.height || 23,
  }

  const socialsFlatData = footer.flatData.socials
  const socials = !isEmpty(socialsFlatData)
    ? socialsFlatData.map((social) => {
        const { image, link } = social

        const { to, href, title, openInNewTab } = getLinkProps(link)

        return {
          image: (
            <Picture
              srcData={{
                src: image[0].url,
                alt: image[0].alt || "",
                width: image[0].width,
              }}
            />
          ),
          title,
          href: href || to,
          openInNewTab,
        }
      })
    : undefined

  const categoriesFlatData = footer.flatData.categories
  const categories = categoriesFlatData.map((category) => {
    let categoryTo,
      categoryHref,
      categoryTitle,
      categoryOpenInNewTab,
      categoryText

    if (!isEmpty(category.categoryLink)) {
      const { to, href, title, openInNewTab, text } = getLinkProps(
        category.categoryLink
      )

      categoryTo = to
      categoryHref = href
      categoryTitle = title
      categoryOpenInNewTab = openInNewTab
      categoryText = text
    }

    return {
      title: category.title,
      links: category.links.map((link) => {
        const { to, href, title, openInNewTab } = getLinkProps(link)

        return {
          text: link.text,
          title,
          href: href || to,
          openInNewTab,
        }
      }),
      categoryLink: !isEmpty(category.categoryLink)
        ? {
            text: categoryText,
            title: categoryTitle,
            href: categoryTo || categoryHref,
            openInNewTab: categoryOpenInNewTab,
          }
        : undefined,
      isHiddenOnSmall: category.isHiddenOnSmall,
      isHiddenOnMedium: category.isHiddenOnMedium,
    }
  })

  const partnersFlatData = footer.flatData.partners
  const partners = partnersFlatData.map((partner) => ({
    src: partner.partner[0].url,
    alt: partner.partner[0].alt || "",
  }))

  const additionalFooterData = footer.flatData.additionalFooter

  return {
    logo,
    socials,
    categories,
    partners,
    additionalFooterData,
  }
}

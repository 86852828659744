import type { ReactElement } from "react"
import React from "react"
import type { ImagesTileLinkSectionFlatData } from "@onestore-graphql/Model"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import WidthOrg from "@onestore/hel/dist/components/organisms/WidthOrg"
import Picture from "@onestore/hel/dist/components/quarks/Picture"
import ConditionalWrapper from "@onestore/hel/dist/helpers/ConditionalWrapper"
import useBreakpoint from "@onestore/hel/dist/hooks/useBreakpoint"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import ImagesTileLink from "@gatsby-plugin-generic-page/components/ImagesTileLink"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import assetsSquidexToImageObject from "~/lib/assetsSquidexToImageObject"

export default function ImagesTileLinkSection({
  captionIcon,
  caption,
  title,
  text,
  imagesTileLink,
  isExpandable,
  expandableText,
  paragraph,
  sectionSettings,
}: ImagesTileLinkSectionFlatData): ReactElement<ImagesTileLinkSectionFlatData> {
  const captionIconProps = captionIcon
    ? assetsSquidexToImageObject(captionIcon, "")
    : undefined

  const isSmallScreen = useBreakpoint("small", "medium")
  const isSmallAndMediumScreen = useBreakpoint("small", "large")
  const isHugeScreen = useBreakpoint("huge")

  return (
    <BaseSection {...sectionSettings} hasOverflowHidden={isSmallScreen}>
      <ConditionalWrapper
        condition={isSmallAndMediumScreen || isHugeScreen}
        wrapper={(children) => (
          <BaseContainer {...sectionSettings?.containerSettings}>
            {children}
          </BaseContainer>
        )}
      >
        <AlignContentOrg
          horizontalAlign={{
            small: "left",
            medium: "center",
            large: "center",
          }}
        >
          <WidthOrg widthSpace={120}>
            {captionIconProps ? (
              <BreakpointVisibilityOrg from="medium">
                <PushOrg bottomSpace={3}>
                  <AlignContentOrg
                    horizontalAlign={{
                      small: "left",
                      medium: "center",
                      large: "center",
                    }}
                  >
                    <Picture srcData={captionIconProps} />
                  </AlignContentOrg>
                </PushOrg>
              </BreakpointVisibilityOrg>
            ) : null}

            {caption ? (
              <TextAtm typography="xsmall2_u" pushSpace={1.5}>
                {caption}
              </TextAtm>
            ) : null}

            {title ? (
              <TextAtm htmlTag="h2" typography="xhuge" pushSpace={3}>
                {title}
              </TextAtm>
            ) : null}

            {text ? (
              <MarkdownText
                typography={{ default: "medium1_h", bold: "medium4_h" }}
              >
                {text}
              </MarkdownText>
            ) : null}
          </WidthOrg>
        </AlignContentOrg>

        <PushOrg topSpace={8}>
          <ImagesTileLink
            imagesTileLink={imagesTileLink}
            isExpandable={isExpandable}
            expandableText={expandableText}
          />
        </PushOrg>

        {paragraph ? (
          <PushOrg topSpace={8}>
            <AlignContentOrg
              horizontalAlign={{
                small: "left",
                medium: "center",
                large: "center",
              }}
            >
              <WidthOrg widthSpace={120}>
                <MarkdownText
                  typography={{ default: "small1", bold: "small4" }}
                >
                  {paragraph}
                </MarkdownText>
              </WidthOrg>
            </AlignContentOrg>
          </PushOrg>
        ) : null}
      </ConditionalWrapper>
    </BaseSection>
  )
}

import { useMemo } from "react"
import type { MarketplaceCategoryWithProducts } from "@onestore-graphql/Model"
import type { MarketplaceCategory } from "@gatsby-plugin-generic-page/fragments/marketplaceCategoryProductCardSection"
import sortProductsInCategory from "~/lib/sortProductsInCategory"
import { PageStatus } from "~/types/squidex"
import useAllMarketplaceProductsQuery from "./useAllMarketplaceProductsQuery"

export default function useMarketplaceCategoryBySlug(
  category: MarketplaceCategory,
  limit: number = 4
): MarketplaceCategoryWithProducts | null {
  const queryMarketplaceProductContents = useAllMarketplaceProductsQuery()

  return useMemo<MarketplaceCategoryWithProducts | null>(() => {
    const products = sortProductsInCategory(
      category,
      queryMarketplaceProductContents.filter((product) => {
        if (!product.flatData.pages || product.flatData.pages.length === 0) {
          return false
        }

        const { status, flatData } = product.flatData.pages[0]

        return (
          (flatData.url &&
            status &&
            status === PageStatus.PUBLISHED &&
            product.flatData?.primaryCategory.filter(
              (primaryCategory) => primaryCategory.id === category.id
            ).length) ||
          (product.flatData?.additionalCategories !== null &&
            product.flatData?.additionalCategories.filter(
              (additionalCategory) => additionalCategory.id === category.id
            ).length)
        )
      })
    )

    return {
      ...category,
      products: products.slice(0, limit),
    }
  }, [queryMarketplaceProductContents, limit, category.id])
}

import type { FC } from "react"
import { useEffect } from "react"
import React, { useState, useContext } from "react"
import _t from "@core/i18n"
import { PriceType } from "@core/types"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import ToggleWithLabelsAtm from "@onestore/hel/dist/components/atoms/ToggleWithLabelsAtm"
import WrapOrg from "@onestore/hel/dist/components/organisms/WrapOrg"
import DomainPriceTypeContext from "@gatsby-plugin-domain-search/context/DomainPriceTypeContext"
import { useIsomorphicLayoutEffect } from "@gatsby-plugin-generic-page/hooks/useIsomorphicLayoutEffect"
import useSiteMetadataQuery from "~/hooks/useSiteMetadataQuery"

interface Props {
  defaultActiveValue?: PriceType
  onClick?: (PriceType: PriceType) => void
}

const DomainTogglePriceType: FC<Props> = ({
  onClick,
  defaultActiveValue,
}: Props) => {
  const { defaultPriceType } = useSiteMetadataQuery()
  const [activeValue, setActiveValue] = useState<PriceType>(defaultPriceType)
  const context = useContext(DomainPriceTypeContext)

  const handleToggleSwitch = () => {
    let priceType: PriceType

    if (activeValue === PriceType.NETTO) {
      context?.setPriceType(PriceType.GROSS)
      priceType = PriceType.GROSS
      setActiveValue(PriceType.GROSS)
    } else {
      context?.setPriceType(PriceType.NETTO)
      priceType = PriceType.NETTO
      setActiveValue(PriceType.NETTO)
    }

    if (onClick) {
      onClick(priceType)
    }
  }

  useIsomorphicLayoutEffect()(() => {
    if (context?.currentPriceType === PriceType.NETTO) {
      setActiveValue(PriceType.NETTO)
    } else {
      setActiveValue(PriceType.GROSS)
    }
  }, [context])

  useEffect(() => {
    if (defaultActiveValue === PriceType.NETTO) {
      setActiveValue(PriceType.NETTO)
      context?.setPriceType(PriceType.NETTO)
    } else if (defaultActiveValue === PriceType.GROSS) {
      setActiveValue(PriceType.GROSS)
      context?.setPriceType(PriceType.GROSS)
    }
  }, [])

  return (
    <WrapOrg gutterSpace={2} alignCenter="vertical">
      <TextAtm typography="nano1">{_t("priceTypeToggleLabel")}</TextAtm>

      <ToggleWithLabelsAtm
        id="toggle-domian-price-type"
        values={[_t("prices.netto"), _t("prices.gross")]}
        activeIndex={activeValue === PriceType.NETTO ? 0 : 1}
        onClick={handleToggleSwitch}
      />
    </WrapOrg>
  )
}

export default DomainTogglePriceType

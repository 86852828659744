import type { ReactElement } from "react"
import React from "react"
import styled, { css } from "styled-components"
import AbsoluteContainerOrg from "@onestore/hel/dist/components/organisms/AbsoluteContainerOrg"
import SurfaceOrg from "@onestore/hel/dist/components/organisms/SurfaceOrg"
import breakpoint from "@onestore/hel/dist/utils/breakpoint"
import space from "@onestore/hel/dist/utils/space"
import type {
  ImageBoxType,
  Position,
} from "@gatsby-plugin-generic-page/fragments/imageBox"
import { FlexVerticalPosition } from "@gatsby-plugin-generic-page/fragments/imageBox"
import { FlexHorizontalPosition } from "@gatsby-plugin-generic-page/fragments/imageBox"
import getBaseMarkdownOverrides from "@gatsby-plugin-generic-page/helpers/getBaseMarkdownOverrides"
import Texts from "../Texts"

const StyledAbsoluteContainerOrg = styled(AbsoluteContainerOrg)<{
  $position: Position
}>(
  ({ $position }) => css`
    display: flex;
    width: 100%;
    height: 100%;
    padding: ${space(4)};
    justify-content: start;
    align-items: start;

    ${$position.horizontal === FlexHorizontalPosition.CENTER &&
    css`
      justify-content: center;
    `}

    ${$position.horizontal === FlexHorizontalPosition.RIGHT &&
    css`
      justify-content: end;
    `}

    ${$position.vertical === FlexVerticalPosition.CENTER &&
    css`
      align-items: center;
    `}
    
    ${$position.vertical === FlexVerticalPosition.BOTTOM &&
    css`
      align-items: end;
    `}

    ${breakpoint("small", "large")} {
      display: none;
    }
  `
)

function ImageBox({
  texts,
  position,
}: ImageBoxType): ReactElement<ImageBoxType> {
  const titleMarkdownOverrides = getBaseMarkdownOverrides({
    typography: "medium4_s",
    pushSpace: 1,
  })

  const textMarkdownOverrides = getBaseMarkdownOverrides({
    typography: "medium1_h",
    pushSpace: 0,
  })

  return (
    <StyledAbsoluteContainerOrg
      leftUnit={0}
      topUnit={0}
      $position={position[0]}
    >
      <div>
        <SurfaceOrg
          color="backgroundLight"
          spacingSpace={4}
          borderRadiusSize="large"
          display="inline-block"
        >
          <Texts
            {...texts}
            titleMarkdownOverrides={titleMarkdownOverrides}
            textMarkdownOverrides={textMarkdownOverrides}
            textTopSpace={0}
          />
        </SurfaceOrg>
      </div>
    </StyledAbsoluteContainerOrg>
  )
}

export default ImageBox

import type { ReactElement } from "react"
import React from "react"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import Picture from "@onestore/hel/dist/components/quarks/Picture"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import getBaseMarkdownOverrides from "@gatsby-plugin-generic-page/helpers/getBaseMarkdownOverrides"
import type { EmbeddedAssetSquidexMetadata } from "~/fragments/image"
import assetsSquidexToImageObject from "~/lib/assetsSquidexToImageObject"
import isEmpty from "~/lib/isEmpty"

interface Props {
  value: string
  icon?: EmbeddedAssetSquidexMetadata[]
}

export default function TextCell({ value, icon }: Props): ReactElement<Props> {
  const textOverrides = {
    ...getBaseMarkdownOverrides({
      typography: "small2",
      boldTypography: "small3",
    }),
  }

  const brOverrides = {
    br: {
      component: () => <> </>,
    },
  }

  const textElement = (
    <FlexContainerOrg wrap="wrap">
      <BreakpointVisibilityOrg from="large">
        <MarkdownText overrides={textOverrides} pushSpace={0}>
          {value}
        </MarkdownText>
      </BreakpointVisibilityOrg>

      <BreakpointVisibilityOrg from="small" to="large">
        <MarkdownText
          overrides={{
            ...textOverrides,
            ...brOverrides,
          }}
          pushSpace={0}
        >
          {value}
        </MarkdownText>
      </BreakpointVisibilityOrg>
    </FlexContainerOrg>
  )

  if (!isEmpty(icon)) {
    return (
      <FlexContainerOrg alignItems="center" gapSpace={0.5}>
        <BreakpointVisibilityOrg from="large">
          <Picture srcData={assetsSquidexToImageObject(icon, "")} />
        </BreakpointVisibilityOrg>

        {textElement}
      </FlexContainerOrg>
    )
  }

  return textElement
}

import type { ReactElement } from "react"
import React from "react"
import { typeMatches } from "@onestore-graphql"
import { get } from "lodash"
import GridOrg from "@onestore/hel/dist/components/organisms/GridOrg"
import type { CloudblueResourceCategoryElement } from "@gatsby-plugin-generic-page/fragments/cloudblueResourceCategoryElement"
import type { DataCenterElement } from "@gatsby-plugin-generic-page/fragments/dataCenterElement"
import type { PeriodsTableSelect } from "@gatsby-plugin-generic-page/fragments/periodsTableSelect"
import type { TableConfig } from "@gatsby-plugin-generic-page/fragments/tableConfig"
import type { ConfigsHookResult } from "@gatsby-plugin-generic-page/hooks/useTableConfigs"
import { ResourceCategoryDisplayType } from "@gatsby-plugin-generic-page/hooks/useTableConfigs"
import { SquidexComponentType } from "@gatsby-plugin-generic-page/types/componentType"
import DataCenterSelect from "./elements/DataCenterSelect"
import PeriodsSelect from "./elements/PeriodsSelect"
import ResourceCounters from "./elements/ResourceCounters"
import ResourceSelect from "./elements/ResourceSelect"

export interface Props {
  configs: TableConfig[]
  configsHookResult: ConfigsHookResult
}

export default function ConfigBoxes({
  configs,
  configsHookResult,
}: Props): ReactElement<Props> {
  const {
    configsValues,
    handlePeriodChange,
    handleParamChange,
    getResourceCategory,
  } = configsHookResult

  const configsElements = configs.map((config) => {
    if (
      typeMatches(
        config.type.__typename,
        SquidexComponentType.PERIODS_TABLE_SELECT
      )
    ) {
      const configTypeData = config.type as PeriodsTableSelect

      return configsValues.period ? (
        <PeriodsSelect
          key={config.label}
          configLabel={config.label}
          periodsData={configTypeData.periods}
          currentPeriod={configsValues.period}
          onChange={handlePeriodChange}
        />
      ) : null
    } else if (
      typeMatches(
        config.type.__typename,
        SquidexComponentType.DATA_CENTER_ELEMENT
      )
    ) {
      const configTypeData = config.type as DataCenterElement
      const currentLocation = configsValues.parameters.find(
        (param) => param.paramUrl === configTypeData.urlParameter
      )

      return currentLocation ? (
        <DataCenterSelect
          key={config.label}
          configLabel={config.label}
          currentLocation={currentLocation.value}
          onChange={(value: string) =>
            handleParamChange(configTypeData.urlParameter, value)
          }
        />
      ) : null
    } else if (
      typeMatches(
        config.type.__typename,
        SquidexComponentType.RESOURCE_CATEGORY_ELEMENT
      )
    ) {
      const configTypeData = config.type as CloudblueResourceCategoryElement

      const resourceCategoryId = get(
        configTypeData,
        "resourceCategory[0].flatData.remoteId"
      )

      const resourceCategory = resourceCategoryId
        ? getResourceCategory(resourceCategoryId.toString())
        : null

      if (!resourceCategory) {
        return null
      }

      if (
        resourceCategory.display_type === ResourceCategoryDisplayType.COUNTERS
      ) {
        return (
          <ResourceCounters
            key={config.label}
            resourceCategoryId={resourceCategoryId.toString()}
            configLabel={config.label}
            configsHookResult={configsHookResult}
          />
        )
      }

      if (
        resourceCategory.display_type === ResourceCategoryDisplayType.SELECT
      ) {
        return (
          <ResourceSelect
            key={config.label}
            resourceCategoryId={resourceCategoryId.toString()}
            configLabel={config.label}
            configsHookResult={configsHookResult}
          />
        )
      }
    }

    return null
  })

  return (
    <GridOrg
      columns={{
        small: 1,
        medium: 1,
        large: configs.length,
      }}
      gutterSpace={{
        small: 1,
        medium: 1,
        large: 3,
      }}
    >
      {configsElements}
    </GridOrg>
  )
}

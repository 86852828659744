import type { ReactElement } from "react"
import React from "react"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import FixedBottomContainerOrg from "@onestore/hel/dist/components/organisms/FixedBottomContainerOrg"
import type { CtaElement } from "@gatsby-plugin-generic-page/fragments/ctaElement"
import type { FloatingCtaElementSectionFlatData } from "@gatsby-plugin-generic-page/fragments/floatingCtaElementSection"
import isEmpty from "~/lib/isEmpty"
import Button from "../Button"

function FixedBottomCtaElement({
  ctaButton,
}: FloatingCtaElementSectionFlatData): ReactElement<FloatingCtaElementSectionFlatData> | null {
  if (isEmpty(ctaButton)) {
    return null
  }

  return (
    <FixedBottomContainerOrg>
      <AlignContentOrg horizontalAlign="center">
        <Button button={ctaButton[0] as CtaElement} isWider />
      </AlignContentOrg>
    </FixedBottomContainerOrg>
  )
}

export default FixedBottomCtaElement

import type { ReactElement } from "react"
import { useContext } from "react"
import React from "react"
import _t from "@core/i18n"
import { hasNoTaxRate } from "@core/pricing"
import { PriceType } from "@core/types"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import TooltipMol from "@onestore/hel/dist/components/molecules/TooltipMol"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import ConditionalWrapper from "@onestore/hel/dist/helpers/ConditionalWrapper"
import useBreakpoint from "@onestore/hel/dist/hooks/useBreakpoint"
import DomainPriceTypeContext from "@gatsby-plugin-domain-search/context/DomainPriceTypeContext"
import CtaSectionsModal from "@gatsby-plugin-generic-page/components/CtaSectionsModal"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import type { CtaSectionsModal as CtaSectionsModalProps } from "@gatsby-plugin-generic-page/fragments/ctaSectionsModal"
import isEmpty from "~/lib/isEmpty"

interface FooterProps {
  sortingTooltipContent?: string | null
  ctaSectionsModal?: CtaSectionsModalProps
}

export function Footer({
  sortingTooltipContent,
  ctaSectionsModal,
}: FooterProps): ReactElement | null {
  const isMediumUp = useBreakpoint("medium")
  const { currentPriceType } = useContext(DomainPriceTypeContext) || {}

  return (
    <PushOrg topSpace={1}>
      <ConditionalWrapper
        condition={
          !isEmpty(ctaSectionsModal) || !isEmpty(sortingTooltipContent)
        }
        wrapper={(children) => (
          <FlexContainerOrg
            alignItems="center"
            justifyContent="space-between"
            direction={isMediumUp ? undefined : "column"}
          >
            {!isEmpty(ctaSectionsModal) ? (
              <CtaSectionsModal {...ctaSectionsModal[0]} />
            ) : null}

            {!isEmpty(sortingTooltipContent) ? (
              <TooltipMol
                labelTypography="nano1"
                labelEmphasis="high"
                iconSize="small"
                labelText={_t("domainSearch.mainDomainSortInfoLabel")}
              >
                <MarkdownText
                  typography={{
                    bold: "small3",
                    default: "small2",
                  }}
                >
                  {sortingTooltipContent}
                </MarkdownText>
              </TooltipMol>
            ) : null}

            {children}
          </FlexContainerOrg>
        )}
      >
        <TextAtm
          typography="nano1"
          emphasis="medium"
          align={isMediumUp ? "right" : "center"}
        >
          {hasNoTaxRate() || currentPriceType === PriceType.NETTO
            ? _t("domainSearch.mainDomainNoTaxFooter")
            : _t("domainSearch.mainDomainTaxFooter")}
        </TextAtm>
      </ConditionalWrapper>
    </PushOrg>
  )
}

/* eslint-disable no-param-reassign */
import type { Draft } from "immer"
import { produce } from "immer"
import { get } from "lodash"
import {
  getBasketItemStateFromLastItems,
  updatePeriodPriceFromExternalSource,
} from "@gatsby-plugin-bonus/store/utils"
import type { BonusState } from "@gatsby-plugin-bonus/types"
import type { BonusBundleActions } from "./actions-bundle"
import { BonusBundleActionType } from "./actions-bundle"
import { initialState } from "./selectors"

export default function reducer(
  state = initialState,
  action: BonusBundleActions
) {
  return produce(state, (nextState: Draft<BonusState>) => {
    switch (action.type) {
      case BonusBundleActionType.BONUS_PRODUCT_WITH_BUNDLED_CALCULATE_BASKET_REQUEST:
        nextState.calculation.product = undefined
        nextState.calculation.bundled = undefined

        return

      case BonusBundleActionType.BONUS_PRODUCT_WITH_BUNDLED_CALCULATE_BASKET_SUCCESS:
        nextState.calculation.product = action.product
        nextState.calculation.bundled = action.bundled

        return

      case BonusBundleActionType.BONUS_PRODUCT_WITH_BUNDLED_ADD_TO_BASKET_REQUEST:
        if (nextState.product) {
          nextState.product.addToBasketStatus = action.status
          nextState.product.isAddToBasketLoading = true
          nextState.product.autoAddedToBasket = action.automaticAdd
        }

        if (nextState.bundled) {
          nextState.bundled.addToBasketStatus = action.status
          nextState.bundled.isAddToBasketLoading = true
        }

        return
      case BonusBundleActionType.BONUS_PRODUCT_WITH_BUNDLED_ADD_TO_BASKET_SUCCESS:
        if (nextState.product) {
          nextState.product.addedToBasket = true
          nextState.product.addToBasketStatus = action.status
          nextState.product.isAddToBasketLoading = false

          nextState.calculation.product = action.lastItems.find(
            (item) => item.id === action.basketItemId
          )

          nextState.product.periods = nextState.product.periods.map(
            (period) => {
              if (period.id === nextState.product?.chosenPeriodId) {
                return updatePeriodPriceFromExternalSource(
                  period,
                  action.basketItemPrice,
                  nextState.product.minQuantity
                )
              }

              return period
            }
          )

          nextState.product.basketItemState = getBasketItemStateFromLastItems(
            action.basketItemId,
            action.lastItems
          )
        }

        if (nextState.bundled) {
          nextState.calculation.bundled = action.lastItems.find(
            (item) => item.id === action.bundledBasketItemId
          )

          nextState.bundled.addedToBasket = true
          nextState.bundled.addToBasketStatus = action.status
          nextState.bundled.isAddToBasketLoading = false

          nextState.bundled.periods = nextState.bundled.periods.map(
            (period) => {
              if (period.id === nextState.bundled?.chosenPeriodId) {
                return updatePeriodPriceFromExternalSource(
                  period,
                  action.bundledBasketItemPrice,
                  nextState.bundled?.minQuantity || 1
                )
              }

              return period
            }
          )

          nextState.bundled.basketItemState = getBasketItemStateFromLastItems(
            action.bundledBasketItemId,
            action.lastItems
          )
        }

        return

      case BonusBundleActionType.BONUS_PRODUCT_WITH_BUNDLED_ADD_TO_BASKET_FAILURE:
        if (nextState.product) {
          nextState.product.addToBasketStatus = action.status
          nextState.product.isAddToBasketLoading = false
          nextState.product.autoAddedToBasket = false
        }

        if (nextState.bundled) {
          nextState.bundled.addToBasketStatus = action.status
          nextState.bundled.isAddToBasketLoading = false
          nextState.bundled.autoAddedToBasket = false
        }

        return

      case BonusBundleActionType.BONUS_CHANGE_BUNDLED_PERIOD_REQUEST:
        if (!nextState.bundled) {
          return
        }
        nextState.bundled.previousPeriodId = get(state, [
          "bundled",
          "chosenPeriod",
        ])
        nextState.bundled.chosenPeriodId = action.period.id
        nextState.bundled.changePeriodStatus = action.status
        nextState.bundled.isChangePeriodLoading = true

        return

      case BonusBundleActionType.BONUS_CHANGE_BUNDLED_PERIOD_SUCCESS:
        if (!nextState.bundled) {
          return
        }
        nextState.bundled.changePeriodStatus = action.status
        nextState.bundled.isChangePeriodLoading = false

        return

      case BonusBundleActionType.BONUS_CHANGE_BUNDLED_PERIOD_FAILURE:
        if (!nextState.bundled) {
          return
        }
        nextState.bundled.chosenPeriodId = get(state, [
          "bundled",
          "previousPeriod",
        ])
        nextState.bundled.changePeriodStatus = action.status
        nextState.bundled.isChangePeriodLoading = false
        nextState.bundled.changePeriodError = action.error

        return

      case BonusBundleActionType.BONUS_REMOVE_BUNDLED_FROM_BASKET_REQUEST:
        if (!nextState.bundled) {
          return
        }
        nextState.bundled.removeFromBasketStatus = action.status

        return

      case BonusBundleActionType.BONUS_REMOVE_BUNDLED_FROM_BASKET_SUCCESS:
        if (!nextState.bundled) {
          return
        }
        nextState.bundled.addedToBasket = false
        nextState.bundled.basketItemState = undefined
        nextState.bundled.addToBasketStatus = 0
        nextState.bundled.removeFromBasketStatus = 0

        return

      case BonusBundleActionType.BONUS_REMOVE_BUNDLED_FROM_BASKET_FAILURE:
        if (!nextState.bundled) {
          return
        }
        nextState.bundled.removeFromBasketStatus = 0

        return

      case BonusBundleActionType.BONUS_ADD_BUNDLED_TO_PRODUCT_IN_BASKET_REQUEST:
        if (!nextState.bundled) {
          return
        }
        nextState.bundled.addToBasketStatus = action.status

        return

      case BonusBundleActionType.BONUS_ADD_BUNDLED_TO_PRODUCT_IN_BASKET_SUCCESS:
        if (!nextState.bundled) {
          return
        }
        nextState.bundled.addedToBasket = true
        nextState.bundled.addToBasketStatus = action.status
        nextState.bundled.basketItemState = getBasketItemStateFromLastItems(
          action.basketItemId,
          action.lastItems
        )

        nextState.bundled.periods = nextState.bundled.periods.map((period) => {
          if (period.id === nextState.bundled?.chosenPeriodId) {
            return updatePeriodPriceFromExternalSource(
              period,
              action.bundledBasketItemPrice,
              nextState.bundled.minQuantity
            )
          }

          return period
        })

        return

      case BonusBundleActionType.BONUS_ADD_BUNDLED_TO_PRODUCT_IN_BASKET_FAILURE:
        if (!nextState.bundled) {
          return
        }

        nextState.bundled.addToBasketStatus = action.status

        return

      // Bundle z domeną na Black Friday 2020
      case BonusBundleActionType.BONUS_GET_BUNDLED_PRICE_REQUEST:
        if (!nextState.bundled) {
          return
        }
        nextState.bundled.getPriceStatus = action.status
        nextState.bundled.isPriceLoading = true

        return

      case BonusBundleActionType.BONUS_GET_BUNDLED_PRICE_SUCCESS:
        if (!nextState.bundled) {
          return
        }
        nextState.bundled.getPriceStatus = action.status
        nextState.bundled.isPriceLoading = false

        return

      case BonusBundleActionType.BONUS_GET_BUNDLED_PRICE_FAILURE:
        if (!nextState.bundled) {
          return
        }
        nextState.bundled.getPriceStatus = action.status
        nextState.bundled.isPriceLoading = false
        nextState.bundled.getPriceError = action.error

        return

      case BonusBundleActionType.BONUS_CHANGE_BUNDLED_PRICE:
        if (!nextState.bundled) {
          return
        }
        nextState.bundled.periods = action.periods

        return
      //
    }
  })
}

import { createApi } from "@onestore/onestore-store-common/api"
import { parse } from "~/lib/semver"
import vars from "./onestoreVars"

const api = createApi(
  vars.apiUrl,
  {
    useSession: vars.isSessionEnabled,
  },
  (response: Response) => {
    const version = response.headers.get("x-app-version")

    if (version) {
      const event = new CustomEvent("api_version_changed", {
        detail: {
          version: parse(version),
        },
      })

      window.document.dispatchEvent(event)
    }
  }
)

export const apiUrl = vars.apiUrl
export default api

import type { ReactElement } from "react"
import React from "react"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import BarOrg from "@onestore/hel/dist/components/organisms/BarOrg"
import WidthOrg from "@onestore/hel/dist/components/organisms/WidthOrg"
import type Theme from "@onestore/hel/dist/typings/theme"
import type { NotificationMessage } from "@onestore/onestore-store-common"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { removeGlobalMessage } from "~/store/messages/actions"
import { getGlobalMessages } from "~/store/messages/selectors"
import type { AccountMessage } from "../../../lib/api/types"

const getColor = (
  status: "success" | "error" | "info" | "warning" | string
): Theme.Color => {
  if (status === "success") {
    return "successLight"
  }

  if (status === "error") {
    return "errorLight"
  }

  return "notifyLight"
}

export default function Notification(): ReactElement | null {
  const dispatch = useAppDispatch()

  const messages =
    useAppSelector<(NotificationMessage | AccountMessage)[]>(getGlobalMessages)

  return (
    <>
      {messages.map((message) => (
        <BarOrg
          color={getColor(message.type)}
          spacingSpace={{ x: 5, y: 1 }}
          hasCloseButton
          onCloseClick={() => {
            dispatch(removeGlobalMessage(message.id))
          }}
          key={message.id}
        >
          <AlignContentOrg
            verticalAlign="center"
            horizontalAlign="center"
            horizontalAlignType="both"
          >
            <WidthOrg widthSpace={160}>
              <TextAtm typography="tiny2">
                {"title" in message ? (
                  <TextAtm typography="tiny3">{message.title}: </TextAtm>
                ) : null}
                {message.message}
              </TextAtm>
            </WidthOrg>
          </AlignContentOrg>
        </BarOrg>
      ))}
    </>
  )
}

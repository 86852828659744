import React from "react"
import type { ReactElement } from "react"
import type { Align } from "@onestore/hel/dist/components/atoms/TextAtm/TextAtm.types"
import HorizontalTableMol from "@onestore/hel/dist/components/molecules/HorizontalTableMol"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import GridOrg from "@onestore/hel/dist/components/organisms/GridOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import SurfaceOrg from "@onestore/hel/dist/components/organisms/SurfaceOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import type { Column } from "@gatsby-plugin-generic-page/fragments/tableHeader"
import type { TableHorizontalBoxesSectionFlatData } from "@gatsby-plugin-generic-page/fragments/tableHorizontalBoxesSection"
import useTableConfigs from "@gatsby-plugin-generic-page/hooks/useTableConfigs"
import TogglePriceType from "~/components/TogglePriceType"
import isEmpty from "~/lib/isEmpty"
import ConfigBoxes from "./components/ConfigBoxes/ConfigBoxes"
import TableRow from "./components/TableRow/TableRow"

export default function TableHorizontalBoxesSection({
  configs,
  header,
  products,
  tableBackgroundColor,
  darkPriceTypeSwitcher,
  sectionSettings,
}: TableHorizontalBoxesSectionFlatData): ReactElement<TableHorizontalBoxesSectionFlatData> {
  const configsHookResult = useTableConfigs(configs, products)

  const configsElements = !isEmpty(configs) ? (
    <ConfigBoxes configs={configs} configsHookResult={configsHookResult} />
  ) : null

  const tableBar = {
    columns: header.columns.map((column: Column, index) => ({
      title: column.label,
      widthPercent: column.widthPercent,
      align:
        index === header.columns.length - 1
          ? ("right" as Align)
          : ("left" as Align),
    })),
  }

  const rowsElements = (
    <GridOrg columns={1} gutterSpace={3}>
      {products.map((product, index) => (
        <TableRow
          key={`${product.id}-${index}`}
          header={header}
          productData={product}
          configsHookResult={configsHookResult}
        />
      ))}
    </GridOrg>
  )

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        <PushOrg
          bottomSpace={3}
          rightSpace={{ small: 3, medium: 3, large: 0 }}
          hasNoReset
        >
          <AlignContentOrg horizontalAlign="right" horizontalAlignType="flex">
            <TogglePriceType hasDarkMode={darkPriceTypeSwitcher} />
          </AlignContentOrg>
        </PushOrg>

        <SurfaceOrg
          color={
            !isEmpty(tableBackgroundColor)
              ? tableBackgroundColor.background
              : "backgroundLight"
          }
          spacingSpace={{
            small: 3,
            medium: 3,
            large: 6,
          }}
          borderRadiusSize="xlarge"
          shadow="medium"
        >
          <HorizontalTableMol
            configs={configsElements}
            tableBar={tableBar}
            rows={rowsElements}
          />
        </SurfaceOrg>
      </BaseContainer>
    </BaseSection>
  )
}

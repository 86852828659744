import type { ChangeEvent } from "react"
import { useEffect } from "react"
import React from "react"
import { useState } from "react"
import _t from "@core/i18n"
import type { FeedbackMessage, GeneralField } from "@formily/core"
import { connect, mapProps } from "@formily/react"
import { get } from "lodash"
import UploadMol from "@onestore/hel/dist/components/molecules/UploadMol"
import isEmpty from "~/lib/isEmpty"

type UploadDraggableProps = {
  hasError?: boolean
  errorMessage?: string
  onChange: (files: File[]) => void
  maxSize?: number
  maxCount?: number
  accept?: string
}

type UploadDraggableField = {
  errors?: {
    messages?: FeedbackMessage
  }[]
  setSelfErrors?: (error: string[]) => void
}

type ComposedUpload = React.FC & {
  Dragger?: React.FC
}

const Upload: ComposedUpload = () => null

const UploadDraggerLayout = ({
  hasError,
  errorMessage,
  onChange,
  setSelfErrors,
  maxSize,
  maxCount,
  accept,
}: UploadDraggableProps & UploadDraggableField) => {
  const [files, setFiles] = useState<File[]>([])

  const handleFilesValidation = (selectedFiles: File[]): boolean => {
    if (setSelfErrors) {
      if (accept) {
        const incorrectFiles = Array.from(selectedFiles).filter((file) => {
          const fileExtension = "." + file.name.split(".").pop()

          return !accept.includes(fileExtension.toLowerCase())
        })

        if (!isEmpty(incorrectFiles)) {
          setSelfErrors([_t("form.upload.extensionError")])

          return false
        }
      }

      if (maxSize) {
        const tooBigFiles = Array.from(selectedFiles).filter(
          (file) => file.size > maxSize
        )

        if (!isEmpty(tooBigFiles)) {
          setSelfErrors([_t("form.upload.sizeError")])

          return false
        }
      }

      if (maxCount) {
        const filesCount = files.length
        const selectedFilesCount = Array.from(selectedFiles).length

        if (filesCount + selectedFilesCount > maxCount) {
          setSelfErrors([_t("form.upload.countError")])
        }
      }
    }

    return true
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles: FileList | null = event.target.files

    if (!isEmpty(selectedFiles)) {
      const selectedFilesArray = Array.from(selectedFiles)

      const hasError = !handleFilesValidation(selectedFilesArray)

      if (!hasError) {
        setFiles((prevFiles) => [...prevFiles, ...selectedFilesArray])
      }
    }
  }

  const handleDrop = (event) => {
    event.preventDefault()
    const droppedFiles: FileList = event.dataTransfer.files

    if (!isEmpty(droppedFiles)) {
      const droppedFilesArray = Array.from(droppedFiles)

      const hasError = !handleFilesValidation(droppedFilesArray)

      if (!hasError) {
        setFiles((prevFiles) => [...prevFiles, ...droppedFilesArray])
      }
    }
  }

  const handleRemoveFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index))
  }

  useEffect(() => {
    onChange(files)
  }, [files])

  return (
    <>
      <UploadMol
        uploadedFiles={
          files.map((file) => ({ name: file.name, isLoading: false })) || []
        }
        hasError={hasError}
        errorMessage={errorMessage}
        buttonText={_t("form.upload.draggerText")}
        separatorText={_t("form.upload.separator")}
        draggerText={_t("form.upload.buttonText")}
        onDrop={handleDrop}
        onChange={handleFileChange}
        handleRemoveFile={handleRemoveFile}
      />
    </>
  )
}

Upload.Dragger = connect(
  UploadDraggerLayout,
  mapProps(
    {},
    (
      props: UploadDraggableProps,
      field: UploadDraggableField & GeneralField
    ) => {
      return {
        hasError: !isEmpty(field.errors),
        errorMessage: get(field, "errors[0].messages[0]", ""),
        onChange: props.onChange,
        setSelfErrors: field.setSelfErrors,
        maxSize: props.maxSize,
        maxCount: props.maxCount,
        accept: props.accept,
      }
    }
  )
)

export default Upload

import type { ReactElement } from "react"
import React from "react"
import FloatingBoxMol from "@onestore/hel/dist/components/molecules/FloatingBoxMol"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import FlexItemOrg from "@onestore/hel/dist/components/organisms/FlexItemOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import CtaElement from "@gatsby-plugin-generic-page/components/CtaElement"
import MarkdownTitle from "@gatsby-plugin-generic-page/components/Markdown/Title"
import Media from "@gatsby-plugin-generic-page/components/Media"
import type { FloatingCtaElementSectionFlatData } from "@gatsby-plugin-generic-page/fragments/floatingCtaElementSection"
import isEmpty from "~/lib/isEmpty"

type FloatingBoxProps = Pick<FloatingCtaElementSectionFlatData, "ctaButton"> &
  Required<Pick<FloatingCtaElementSectionFlatData, "additionalTexts">> & {
    isVisible: boolean
  }

function FloatingBox({
  isVisible,
  ctaButton,
  additionalTexts,
}: FloatingBoxProps): ReactElement<FloatingBoxProps> {
  return (
    <FloatingBoxMol isVisible={isVisible}>
      <FlexItemOrg flexGrow={1} flexShrink={1} flexBasis={0}>
        <FlexContainerOrg gapSpace={{ rowGap: 1.5, columnGap: 4 }} wrap="wrap">
          {additionalTexts.map((textItem) => (
            <FlexContainerOrg
              key={textItem.text}
              gapSpace={1}
              alignItems="center"
            >
              {!isEmpty(textItem.textIcon) ? (
                <Media media={textItem.textIcon} />
              ) : null}

              <MarkdownTitle typography="small2" hasNotPushSpace>
                {textItem.text}
              </MarkdownTitle>
            </FlexContainerOrg>
          ))}
        </FlexContainerOrg>
      </FlexItemOrg>

      <PushOrg leftSpace={3}>
        <CtaElement ctaElement={ctaButton} />
      </PushOrg>
    </FloatingBoxMol>
  )
}

export default FloatingBox

import { typeMatches } from "@onestore-graphql"
import type { ColorFromPicker } from "@onestore/hel/dist/utils/getCustomColor"
import { SquidexComponentType } from "@gatsby-plugin-generic-page/types/componentType"
import type {
  BackgroundColor,
  CustomBackgroundColor,
  Gradient,
} from "~/types/squidex"

function getCustomBackgroundColor(
  data: CustomBackgroundColor
): ColorFromPicker | undefined {
  if (typeMatches(data.__typename, SquidexComponentType.BACKGROUND_COLOR)) {
    const { color } = data as BackgroundColor

    return color
  }

  if (typeMatches(data.__typename, SquidexComponentType.GRADIENT)) {
    return data as Gradient
  }

  return
}

export default getCustomBackgroundColor

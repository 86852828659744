import { getPriceAsFloat } from "@core/pricing"
import { difference, keys, pick, values } from "lodash"
import type { CompanyData, PersonData } from "@onestore/api/account"
import type { PriceValue } from "@onestore/api/basket"
import type { AccountType, ProductAlias } from "@onestore/api/types"
import type {
  BasketState,
  BasketStateItem,
} from "@onestore/onestore-store-common"
import type { AppState } from "~/store/reducer"

declare global {
  interface Window {
    oneStoreDisplayed: ProductAlias[]
  }
}

export enum EVENT {
  REGISTER = "ONESTORE.REGISTER",
  BASKET_SHOW = "ONESTORE.BASKET_SHOW",
  BASKET_ORDER = "ONESTORE.BASKET_ORDER",
  BASKET_ITEM_REMOVED = "ONESTORE.BASKET_ITEM_REMOVED",
  BASKET_CHANGE_PAYMENT = "ONESTORE.BASKET_CHANGE_PAYMENT",
  ORDER_CHECKOUT_USERNAME = "ONESTORE.ORDER_CHECKOUT_USERNAME",
  ORDER_CHECKOUT_ANY_CREDENTIALS = "ONESTORE.ORDER_CHECKOUT_ANY_CREDENTIALS",
  ORDER_CHECKOUT_REGISTER = "ONESTORE.ORDER_CHECKOUT_REGISTER",
  ORDER_CHECKOUT_PASSWORD = "ONESTORE.ORDER_CHECKOUT_PASSWORD",
  ORDER_CHECKOUT_SUMMARY = "ONESTORE.ORDER_CHECKOUT_SUMMARY",
  ORDER_PROCEED = "ONESTORE.ORDER_PROCEED",
}

export const EVENT_PROPS = {}
EVENT_PROPS[EVENT.REGISTER] = ["user"]
EVENT_PROPS[EVENT.ORDER_PROCEED] = ["basket"]
EVENT_PROPS[EVENT.BASKET_ORDER] = ["basket"]
EVENT_PROPS[EVENT.ORDER_CHECKOUT_USERNAME] = ["basket"]

function validateEventData(eventName, data) {
  if (values(EVENT).indexOf(eventName) === -1) {
    throw new Error(`Niewspierane zdarzenie: ${eventName}`)
  }

  const props = EVENT_PROPS[eventName]
  const dataKeys = keys(data)

  if (difference(props, dataKeys).length > 0) {
    throw new Error(
      `Dane zdarzenia ${eventName} powinny zawierać klucze: ${dataKeys.join(
        ", "
      )}`
    )
  }

  return pick(data, dataKeys)
}

/**
 * Stary sposób wysylania eventów, obecnie komunikacje z datalayerami implementujemy bezpośrednio w kodzie
 *
 * @deprecated
 */
export const send = (eventName, data): Promise<void> => {
  return new Promise((resolve) => {
    try {
      const eventData = validateEventData(eventName, data)
      const event = new CustomEvent(eventName, {
        detail: eventData,
      })
      document.dispatchEvent(event)
    } catch (error) {
      console.error("Nie udało sie wysłać eventu", error)
    }
    resolve()
  })
}

export const sendRegisterEvent = (user: CompanyData | PersonData) =>
  send(EVENT.REGISTER, { user })

export const sendBasketItemRemovedEvent = (item) => {
  send(EVENT.BASKET_ITEM_REMOVED, {
    item_id: item.item_id,
    name: item.name,
    price: item.price,
    quantity: item.quantity,
    index: item.index,
    affiliation: item.affiliation,
    item_category: item.item_category,
    item_variant: item.item_variant,
    cart_id: item.cart_id,
  })
}

export const sendBasketChangePaymentEvent = (item) => {
  send(EVENT.BASKET_CHANGE_PAYMENT, {
    paymentMethodId: item.paymentMethodId,
    item_id: item.item_id,
    name: item.name,
    price: item.price,
    quantity: item.quantity,
    index: item.index,
    affiliation: item.affiliation,
    item_category: item.item_category,
    item_variant: item.item_variant,
    cart_id: item.cart_id,
  })
}

export const sendBasketShowEvent = (basket: BasketState) =>
  send(EVENT.BASKET_SHOW, { basket })

export const sendCheckoutRegisterFormEvent = (basket: AppState["basket"]) =>
  send(EVENT.ORDER_CHECKOUT_REGISTER, { basket })

export const sendCheckoutPasswordFormEvent = () =>
  send(EVENT.ORDER_CHECKOUT_PASSWORD, {})

export const sendBasketOrderEvent = (basket: BasketState): void => {
  send(EVENT.BASKET_ORDER, {
    basket: {
      ...basket,
      totalValue: getPriceAsFloat(basket.totalValue),
    },
  })
}

export const sendCheckoutUsernameFormEvent = (basket: BasketState) => {
  send(EVENT.ORDER_CHECKOUT_USERNAME, {
    basket: {
      ...basket,
      totalValue: getPriceAsFloat(basket.totalValue),
    },
  })
}

export const sendCheckoutCredentialsFormEvent = (basket: BasketState) => {
  send(EVENT.ORDER_CHECKOUT_ANY_CREDENTIALS, {
    basket: {
      ...basket,
      totalValue: getPriceAsFloat(basket.totalValue),
    },
  })
}

export const sendCheckoutSummaryEvent = (basket: BasketState) => {
  send(EVENT.ORDER_CHECKOUT_SUMMARY, {
    basket: {
      ...basket,
      totalValue: getPriceAsFloat(basket.totalValue),
    },
  })
}

interface OrderProceedEventData {
  totalValue: PriceValue
  accountType: AccountType
  items: BasketStateItem[]
  vatValue: number
  savings: number
  status: string
  orderId: number
  orderNumber: string
}

export const sendOrderProceedEvent = (basket: OrderProceedEventData) => {
  const result = {
    ...basket,
    totalValue: getPriceAsFloat(basket.totalValue),
  }

  send(EVENT.ORDER_PROCEED, {
    basket: result,
  })
}

import type { ReactElement, ReactNode } from "react"
import React from "react"
import _t from "@core/i18n"
import get from "lodash/get"
import type { HeaderProps } from "@onestore/hel/dist/components/molecules/TableMol/Header"
import type { ComparableProduct } from "~/fragments/comparableProduct"
import type { SaleConfiguration } from "~/fragments/saleConfigurations"
import isEmpty from "~/lib/isEmpty"
import type { PeriodName } from "~/types/squidex"
import TableMolWrapper from "../TableMolWrapper"
import useContent from "./useContent"
import useHeader from "./useHeader"
import useProductParameters from "./useProductParameters"
import useProductPrices from "./useProductPrices"

export type ParametersTableProps = {
  products: ComparableProduct[]
  headerColumns: HeaderProps["columns"]
  tableHeaderTitle?: string
  saleConfiguration?: SaleConfiguration
  generateHeaderLink: {
    (item: ComparableProduct, comparableProducts: ComparableProduct[]): any
  }
  showOnlyDifferences?: boolean
  hasPromoPrice?: boolean
  hasRenewalPrice?: boolean
  hasHiddenGroupLabel?: boolean
  footer?: ReactNode[]
  showOnlyTwoColumns?: boolean
  hasHiddenProductPrice?: boolean
  hasHiddenParametersName?: boolean
  hasTogglePriceType?: boolean
  selectedPeriod?: PeriodName
}

export default function ParametersTable({
  products,
  headerColumns,
  generateHeaderLink,
  tableHeaderTitle,
  saleConfiguration,
  showOnlyDifferences,
  hasPromoPrice,
  hasRenewalPrice,
  hasHiddenGroupLabel,
  footer,
  showOnlyTwoColumns,
  hasHiddenProductPrice,
  hasHiddenParametersName,
  hasTogglePriceType,
  selectedPeriod,
}: ParametersTableProps): ReactElement<ParametersTableProps> | null {
  const parameters = useProductParameters(products, hasHiddenParametersName)

  const productPrices = useProductPrices(
    products,
    saleConfiguration,
    hasPromoPrice,
    hasRenewalPrice,
    hasHiddenProductPrice,
    selectedPeriod
  )

  const productPricesValues = get(productPrices, "items[0].values", [])

  const footerButtonsFiltered = !isEmpty(footer)
    ? footer.map((footerItem, index) =>
        isEmpty(productPricesValues[index]) ||
        productPricesValues[index].uuid === "-"
          ? null
          : footerItem
      )
    : []

  const header = useHeader(
    products,
    tableHeaderTitle,
    headerColumns,
    generateHeaderLink
  )

  const content = useContent(
    [productPrices, ...(parameters || [])],
    showOnlyDifferences,
    hasHiddenGroupLabel,
    showOnlyTwoColumns
  )

  if (!parameters) {
    return null
  }

  let headerUpdate = header
  let contentUpdate = content

  if (showOnlyTwoColumns) {
    contentUpdate = content.map((items) => {
      const rows = items.rows.map((row) => {
        return {
          ...row,
          values: row.values.slice(0, 2),
        }
      })

      return {
        ...items,
        rows,
      }
    })

    headerUpdate = {
      ...header,
      columns: header.columns.slice(0, 2),
    }
  }

  return (
    <TableMolWrapper
      header={headerUpdate}
      content={contentUpdate}
      showMoreDetails={{
        text: _t("showMore"),
        title: _t("showMore"),
      }}
      footer={footerButtonsFiltered}
    />
  )
}

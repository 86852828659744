import queryString from "query-string"
import type {
  AliasParameters,
  AliasProductData,
  DomainData,
} from "@onestore/onestore-store-common"
import { parseProductData } from "@onestore/onestore-store-common/products"

export const BONUS_CHECKOUT_MODE_DEFAULT = "basket"
export const BONUS_CHECKOUT_MODE_FAST = "finalize"

/**
 * Zbiór zarezerwowanych nazw dla specjalnie obsługiwanych Boxów.
 */
const SPECIAL_BONUS_BOXES = new Set(["DOMAINS", "PMAIL"])

function parseArrayProductData(
  data: (string | null)[] | null | string
): AliasProductData[] {
  const parsed: AliasProductData[] = []

  if (Array.isArray(data)) {
    data.forEach((item) => {
      if (typeof item === "string") {
        parsed.push(parseProductData(item))
      }
    })
  } else if (typeof data === "string") {
    parsed.push(parseProductData(data))
  }

  return parsed
}

interface QueryConfigData {
  special: string[]
  promoCode: string
  chosenPeriod: null | string
  allPeriod: null | string
  hidePeriods: boolean
  checkoutMode: "basket" | "finalize"
  domainBundle?: string
  domainSearch?: string
  product?: AliasProductData
  update?: AliasProductData[]
  bundled?: AliasProductData
  upsell?: AliasProductData[]
  forms?: AliasProductData[]
  domain?: DomainData
  nextStep?: boolean
}

export function parseDomainData(data: string): DomainData {
  const domainData: DomainData = {}

  data
    .replace(/[()]/g, "")
    .split(";")
    .forEach((value) => {
      const parts = value.split(":")

      if (!parts.length || !["e", "p"].includes(parts[0])) {
        return
      }

      if (parts[0] === "e") {
        domainData.extension = parts[1]
      }

      if (parts[0] === "p") {
        domainData.pool = parts[1]
      }
    })

  return domainData
}

export default function parseQuery(query: string): QueryConfigData {
  const parsed = queryString.parse(query, {
    arrayFormat: "index",
  })
  const configData: QueryConfigData = {
    special: [],
    promoCode: "",
    chosenPeriod: null,
    allPeriod: null,
    hidePeriods: false,
    checkoutMode: BONUS_CHECKOUT_MODE_DEFAULT,
    domainSearch: undefined,
  }
  Object.entries(parsed).forEach(([key, value]) => {
    if (SPECIAL_BONUS_BOXES.has(value as string)) {
      configData.special.push(value as string)
    } else {
      switch (key) {
        case "code":
          configData.promoCode = value as string
          configData.hidePeriods = true
          break
        case "mode":
          configData.checkoutMode = BONUS_CHECKOUT_MODE_FAST
          break
        case "period":
          configData.chosenPeriod = value as string
          configData.hidePeriods = true
          break
        case "checkedPeriod":
          configData.chosenPeriod = value as string
          break
        case "allPeriod":
          configData.allPeriod = value as string
          break

        case "d":
          configData.domain = parseDomainData(value as string)
          break
        case "p":
          configData.product = parseProductData(value as string)
          break
        case "u":
          configData.update = parseArrayProductData(value)
          break
        case "b":
          configData.bundled = parseProductData(value as string)
          break
        case "ax":
          configData.upsell = parseArrayProductData(value)
          break
        case "f":
          configData.forms = parseArrayProductData(value)
          break
        case "domainSearch":
          configData.domainSearch = value as string
          break
      }
    }
  })

  return configData
}

export function getParamsOfType(type: string, params: AliasParameters) {
  const initialParamFormData = {}

  for (const [key, value] of Object.entries(params)) {
    if (key.startsWith(`${type}[`)) {
      const paramKey = key.replace(`${type}[`, "").replace("]", "")
      initialParamFormData[paramKey] = value
    }
  }

  return initialParamFormData
}

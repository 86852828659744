import React from "react"
import PlainLinkAtm from "@onestore/hel/dist/components/atoms/PlainLinkAtm"

export default function PlainLink({ children, ...props }) {
  const { href, title } = props

  const to = href.startsWith("/") ? href : undefined

  return (
    <PlainLinkAtm
      typography="large1"
      to={to}
      href={href}
      title={title || children}
      type="underline"
    >
      {children}
    </PlainLinkAtm>
  )
}

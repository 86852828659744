import React from "react"
import type { ReactElement, SyntheticEvent } from "react"
import _t from "@core/i18n"
import { PriceType } from "@core/types"
import Tick from "@onestore-icons/tick.svg"
import ButtonAtm from "@onestore/hel/dist/components/atoms/ButtonAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import ConditionalWrapper from "@onestore/hel/dist/helpers/ConditionalWrapper/index"
import useBasketActions from "@gatsby-plugin-basket/hooks/useBasketActions"
import type { BundleButtonWithPrice as BundleButtonWithPriceProps } from "@gatsby-plugin-generic-page/fragments/bundleButtonWithPrice"
import useSiteMetadataQuery from "~/hooks/useSiteMetadataQuery"
import { sendEvent } from "~/lib/ga4"
import { getBundlePricesFromDefinitionForType } from "~/lib/pricing"

function BundleButtonWithPrice({
  flatData: {
    calculatedPrice,
    plan,
    bundlePlan,
    code,
    text,
    hidePrice,
    title,
    gaEvent,
    buttonColor,
    buttonVariant,
  },
}: BundleButtonWithPriceProps): ReactElement<BundleButtonWithPriceProps> | null {
  const siteMetadata = useSiteMetadataQuery()
  const priceType =
    siteMetadata.defaultPriceType === "netto"
      ? PriceType.NETTO
      : PriceType.GROSS

  const bundlePrices = getBundlePricesFromDefinitionForType(
    calculatedPrice[0],
    priceType
  )

  const { addProductToBasket, isLoading, isDone, goToBasket } =
    useBasketActions(plan[0], bundlePlan[0], code ?? undefined)

  const handleOnClick = (event: SyntheticEvent) => {
    event.preventDefault()

    if (gaEvent) {
      sendEvent(gaEvent)
    }

    addProductToBasket()
  }

  const price = bundlePrices.primaryTypePromoPrice
    ? bundlePrices.primaryTypePromoPrice.formatted
    : bundlePrices.primaryTypeRegularPrice.formatted

  const periodText =
    priceType === PriceType.NETTO ? _t("prices.netto") : _t("prices.gross")

  const buttonObject = {
    icon: isDone ? Tick : undefined,
    isLoading: isLoading,
    color: buttonColor?.color,
    variant: buttonVariant?.variant,
    title: isDone ? _t("button.genericPageSuccess") : title,
    onClick: isDone ? goToBasket : handleOnClick,
  }

  return (
    <div data-testid="bundle-button-with-price">
      <ConditionalWrapper
        condition={!hidePrice}
        wrapper={(children) => (
          <div>
            <TextAtm typography="sgiant">
              <TextAtm color="primary">{price}</TextAtm>{" "}
            </TextAtm>

            <PushOrg
              bottomSpace={{
                large: 4,
                medium: 4,
                small: 3,
              }}
            >
              <FlexContainerOrg alignItems="center">
                <TextAtm typography="xsmall1" emphasis="medium">
                  {periodText}
                </TextAtm>
              </FlexContainerOrg>
            </PushOrg>

            {children}
          </div>
        )}
      >
        <BreakpointVisibilityOrg from="medium">
          <ButtonAtm {...buttonObject} isWider>
            {isDone ? _t("button.genericPageSuccess") : text}
          </ButtonAtm>
        </BreakpointVisibilityOrg>

        <BreakpointVisibilityOrg from="small" to="medium">
          <ButtonAtm {...buttonObject} isExpanded>
            {isDone ? _t("button.genericPageSuccess") : text}
          </ButtonAtm>
        </BreakpointVisibilityOrg>
      </ConditionalWrapper>
    </div>
  )
}

export default BundleButtonWithPrice

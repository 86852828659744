import { graphql } from "gatsby"
import type { Texts } from "./texts"

export enum FlexVerticalPosition {
  TOP = "top",
  CENTER = "center",
  BOTTOM = "bottom",
}

export enum FlexHorizontalPosition {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
}

export interface Position {
  vertical: FlexVerticalPosition
  horizontal: FlexHorizontalPosition
}

export interface ImageBoxType {
  texts: Texts
  position: Position
}

export const query = graphql`
  fragment ImageBox on GraphCmsSquidex_ImageBoxComponent {
    __typename
    texts {
      ...Texts
    }
    position {
      vertical
      horizontal
    }
  }
`

export const FRAGMENT_IMAGE_BOX = `
  fragment ImageBox on ImageBoxComponent  {
    __typename
    texts {
      ...Texts
    }
    position {
      vertical
      horizontal
    }
  }
`

/**
 * Check wheather code is executed on server or in browser
 */
export default function isServer(): boolean {
  const canUseDOM: boolean = !!(
    typeof window !== "undefined" &&
    window.document &&
    window.document.createElement
  )

  return !canUseDOM
}

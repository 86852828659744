/* eslint-disable no-param-reassign */
import { produce } from "immer"
import type { Draft } from "immer"
import { has } from "lodash"
import { HTTP_STATUS } from "@onestore/onestore-store-common/http"
import type {
  BasketItemRemovedAction,
  BasketSuccessAction,
} from "@gatsby-plugin-basket/store/actions"
import { BasketActions } from "@gatsby-plugin-basket/store/constants"
import type {
  BonusState,
  BonusResources,
  BonusProduct,
} from "@gatsby-plugin-bonus/types"
import { initialState } from "./selectors"
import {
  applyResourceChangesFromBasket,
  defaultCheckedResources,
} from "./utils"

function resetBundleState(state, nextState): void {
  if (has(state, "bundled") && state.bundled) {
    nextState.bundled.addedToBasket = false
    nextState.bundled.basketItemState = undefined
    nextState.bundled.addToBasketStatus = HTTP_STATUS.NONE
  }
}

function resetDomainsUpsellSearchState(state, nextState: BonusState) {
  if (has(state, ["upsellDomains"])) {
    delete nextState.upsellDomains.results
    nextState.upsellDomains.status = HTTP_STATUS.NONE
  }
}

export function applyBonusResourceState(
  productState: BonusProduct,
  action: BasketSuccessAction
) {
  const defaultChecked: BonusResources = defaultCheckedResources(
    productState.resourceCategories,
    productState.chosenPeriodId,
    productState.periods
  )

  return applyResourceChangesFromBasket(
    action.result.items,
    productState.resources,
    defaultChecked,
    productState.resourceCategories,
    productState.basketItemState?.id
  )
}

export default function reducer(
  state: BonusState = initialState,
  action: BasketItemRemovedAction | BasketSuccessAction
) {
  return produce(state, (nextState: Draft<BonusState>) => {
    switch (action.type) {
      case BasketActions.BASKET_SUCCESS:
        // Resetowanie powiązań do pozycji koszyka po wyczyszczeniu koszyka

        if (action.result.items_count === 0) {
          if (nextState.product) {
            nextState.product.basketItemState = undefined
            nextState.product.addToBasketStatus = HTTP_STATUS.NONE
            nextState.product.isAddToBasketLoading = false
            nextState.product.addedToBasket = false
            nextState.product.autoAddedToBasket = false

            if (state.product?.advancedConfiguration) {
              nextState.product.resources = defaultCheckedResources(
                nextState.product.resourceCategories,
                nextState.product.chosenPeriodId,
                nextState.product.periods,
                state.product?.resources
              )
            }
          }

          if (nextState.upsell) {
            Object.keys(state.upsell).forEach((alias) => {
              nextState.upsell[alias].basketItemState = undefined
              nextState.upsell[alias].addToBasketStatus = HTTP_STATUS.NONE
              nextState.upsell[alias].isAddToBasketLoading = false
              nextState.upsell[alias].addedToBasket = false
              nextState.upsell[alias].autoAddedToBasket = false

              if (state.upsell[alias].advancedConfiguration) {
                nextState.upsell[alias].resources = defaultCheckedResources(
                  nextState.upsell[alias].resourceCategories,
                  nextState.upsell[alias].chosenPeriodId,
                  nextState.upsell[alias].periods,
                  state.upsell[alias].resources
                )
              }
            })
          }
        } else {
          if (
            nextState.product &&
            state.product &&
            state.product?.advancedConfiguration
          ) {
            const { resources, configurationChanged } = applyBonusResourceState(
              state.product,
              action
            )

            nextState.product.resources = resources

            if (configurationChanged) {
              nextState.product.configurationSaved = false
            }
          }

          if (nextState.upsell && state.upsell) {
            Object.keys(state.upsell).forEach((alias) => {
              if (nextState.upsell[alias].advancedConfiguration) {
                const { resources, configurationChanged } =
                  applyBonusResourceState(state.upsell[alias], action)

                nextState.upsell[alias].resources = resources

                if (configurationChanged) {
                  nextState.upsell[alias].configurationSaved = false
                }
              }
            })
          }
        }

        return

      case BasketActions.BASKET_ITEM_REMOVED:
        // Sprawdzamy czy główny produkt nie został usunięty
        if (
          nextState.product &&
          nextState?.product?.basketItemState?.id === action.id
        ) {
          nextState.product.addedToBasket = false
          nextState.product.removedFromBasket = true
          nextState.product.basketItemState = undefined
          nextState.product.resources = action.resources
          nextState.product.autoAddedToBasket = false

          resetBundleState(state, nextState)
          resetDomainsUpsellSearchState(state, nextState)

          return
        }

        // Sprawdzamy czy kasowany nie jest produkt bundlowany

        if (nextState.bundled?.basketItemState?.id === action.id) {
          resetBundleState(state, nextState)

          return
        }

        if (state.upsell && Object.keys(state.upsell).length) {
          // Sprawdzamy czy produkt z upsellu usunięty z koszyka
          Object.keys(state.upsell).forEach((key) => {
            if (
              nextState.upsell &&
              nextState.upsell[key] &&
              state.upsell &&
              "id" in action &&
              state.upsell[key]?.basketItemState?.id === action.id
            ) {
              nextState.upsell[key].addedToBasket = false
              nextState.upsell[key].addToBasketStatus = HTTP_STATUS.NONE
              nextState.upsell[key].basketItemState = undefined
              nextState.upsell[key].isAddToBasketLoading = false
              nextState.upsell[key].autoAddedToBasket = false
            }
          })
        }
        break
      default:
        break
    }
  })
}

import { createSchemaField } from "@formily/react"
import Card from "./Card"
import Checkbox from "./Checkbox"
import FormGrid from "./FormGrid"
import FormItem from "./FormItem"
import Input from "./Input"
import Radio from "./Radio"
import Select from "./Select"
import Text from "./Text"
import TreeSelect from "./TreeSelect"
import Upload from "./Upload"

const SchemaFields = createSchemaField({
  components: {
    Card,
    FormItem,
    Input,
    Select,
    Radio,
    Checkbox,
    FormGrid,
    Text,
    Upload,
    TreeSelect,
  },
})

export default SchemaFields

import type { ThunkAction } from "redux-thunk"
import type { AppState } from "~/store/reducer"

export enum CoreActionTypes {
  UPDATE_API_VERSION = "ACTIONS.CORE.UPDATE_API_VERSION",
}

export type UpdateApiVersionAction = {
  type: CoreActionTypes.UPDATE_API_VERSION
  version: string | null
}

export type CoreAction = UpdateApiVersionAction

export type CoreThunkAction = ThunkAction<void, AppState, undefined, CoreAction>

export const updateApiVersionFromRequest = (
  version: string | null
): UpdateApiVersionAction => {
  return {
    type: CoreActionTypes.UPDATE_API_VERSION,
    version,
  }
}

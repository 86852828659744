import { graphql, useStaticQuery } from "gatsby"
import { get } from "lodash"

export interface PlanTooltip {
  flatData: {
    id: number
    promotionPriceTooltip?: {
      flatData: {
        text: string
      }
    }
    limitTooltip?: {
      flatData: {
        text: string
      }
    }
  }
}

interface Query {
  GraphCmsSquidex: {
    queryCloudbluePlanContents: PlanTooltip[]
  }
}

interface Result {
  promotionPriceTooltip: string | undefined
  limitTooltip: string | undefined
}

export default function useCloudbluePlanTooltip(planId: number | null): Result {
  const {
    GraphCmsSquidex: { queryCloudbluePlanContents },
  } = useStaticQuery<Query>(graphql`
    {
      GraphCmsSquidex {
        queryCloudbluePlanContents {
          id
          flatData {
            id
            promotionPriceTooltip {
              id
              flatData {
                text
              }
            }
            limitTooltip {
              id
              flatData {
                text
              }
            }
          }
        }
      }
    }
  `)

  const currentPlan = queryCloudbluePlanContents?.find(
    (plan) => planId === plan.flatData.id && plan
  )

  return {
    promotionPriceTooltip: get(
      currentPlan,
      "flatData.promotionPriceTooltip[0].flatData.text",
      undefined
    ),
    limitTooltip: get(
      currentPlan,
      "flatData.limitTooltip[0].flatData.text",
      undefined
    ),
  }
}

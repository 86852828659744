import type { ReactElement } from "react"
import React from "react"
import IconAvailable from "@onestore-icons/domain-search/icon_available.svg"
import IconError from "@onestore-icons/domain-search/icon_error.svg"
import IconRecommended from "@onestore-icons/domain-search/icon_recommended.svg"

interface MainResultImageProps {
  available: boolean
  isPhraseIdenticalToFqdn: boolean
}
export function MainResultImage({
  available,
  isPhraseIdenticalToFqdn,
}: MainResultImageProps): ReactElement {
  const availableIcon = isPhraseIdenticalToFqdn ? (
    <IconAvailable />
  ) : (
    <IconRecommended />
  )

  return available ? availableIcon : <IconError />
}

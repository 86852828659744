import React from "react"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import TooltipMol from "@onestore/hel/dist/components/molecules/TooltipMol"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import type { FormItemProps } from "@gatsby-plugin-generic-page/components/Formily/FormItem"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"

export const FormItemLayout = ({
  label,
  description,
  tooltip,
  required,
  children,
}: FormItemProps) => {
  const requiredJSX = required ? (
    <TextAtm typography="small2" color="primary" pushSpace={{ right: 0.5 }}>
      *
    </TextAtm>
  ) : null

  const labelJSX = tooltip ? (
    <PushOrg bottomSpace={description ? 0 : 1}>
      <FlexContainerOrg>
        {requiredJSX}
        <TooltipMol
          labelText={label}
          labelTypography="small2"
          labelEmphasis="high"
        >
          <MarkdownText typography={{ bold: "xsmall3", default: "xsmall1" }}>
            {tooltip}
          </MarkdownText>
        </TooltipMol>
      </FlexContainerOrg>
    </PushOrg>
  ) : (
    <TextAtm typography="small2" pushSpace={description ? 0 : 1}>
      {requiredJSX}
      {label}
    </TextAtm>
  )

  const descriptionJSX = description ? (
    <PushOrg bottomSpace={1}>
      <TextAtm typography="tiny1" emphasis="low">
        {description}
      </TextAtm>
    </PushOrg>
  ) : null

  return (
    <PushOrg bottomSpace={2} hasNoReset>
      {label ? labelJSX : null}

      {label && description ? descriptionJSX : null}

      {children}
    </PushOrg>
  )
}

import type { ReactElement } from "react"
import React from "react"
import WrapOrg from "@onestore/hel/dist/components/organisms/WrapOrg"
import ConditionalWrapper from "@onestore/hel/dist/helpers/ConditionalWrapper"
import type { CtaElement } from "@gatsby-plugin-generic-page/fragments/ctaElement"
import Button from "../Button"

interface Props {
  buttons: CtaElement[]
  isOverlappingRelativeContainer?: boolean
}

function Buttons({
  buttons,
  isOverlappingRelativeContainer,
}: Props): ReactElement {
  const numberOfButtons = buttons.length

  return (
    <ConditionalWrapper
      condition={numberOfButtons > 1}
      wrapper={(children) => <WrapOrg gutterSpace={4.5}>{children}</WrapOrg>}
    >
      {buttons.map((button) => (
        <Button
          key={button.id}
          button={button}
          isOverlappingRelativeContainer={isOverlappingRelativeContainer}
        />
      ))}
    </ConditionalWrapper>
  )
}

export default Buttons

import type { ReactElement } from "react"
import React from "react"
import useIonosCloudConfigurationQuery from "~/hooks/useIonosCloudConfigurationQuery"
import isEmpty from "~/lib/isEmpty"
import SelectConfigElement from "./SelectConfigElement"

interface Props {
  configLabel: string
  currentLocation: string
  onChange: (value: string) => void
}

export default function DataCenterSelect({
  configLabel,
  currentLocation,
  onChange,
}: Props): ReactElement<Props> {
  const locations = useIonosCloudConfigurationQuery()

  if (isEmpty(locations)) {
    return <></>
  }

  const selectOptions = locations.map((variant) => ({
    label: variant.label,
    value: variant.id,
    isSelected: variant.id === currentLocation,
  }))

  return (
    <SelectConfigElement
      configLabel={configLabel}
      options={selectOptions}
      onChange={(value) => onChange(value)}
    />
  )
}

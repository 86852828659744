import type { ReactElement } from "react"
import React from "react"
import type { ResponsiveImages } from "~/types/squidex"
import ResponsiveImage from "../ResponsiveImage"
import ResponsiveVideo from "../ResponsiveVideo"

interface ResponsiveMediaProps {
  media: ResponsiveImages
}

enum FileType {
  MP4 = "mp4",
  WEBM = "webm",
  MOV = "mov",
}

function ResponsiveMedia({ media }: ResponsiveMediaProps): ReactElement | null {
  let videoAsset: ResponsiveImages = {
    large: [],
  }
  let imageAsset: ResponsiveImages = {
    large: [],
  }

  const validFileTypes = new Set(Object.values(FileType))

  for (let breakpoint of Object.keys(media)) {
    videoAsset = {
      ...videoAsset,
      [breakpoint]: media[breakpoint]
        ? media[breakpoint].filter((asset) =>
            validFileTypes.has(asset.fileType)
          )
        : null,
    }
  }

  for (let breakpoint of Object.keys(media)) {
    imageAsset = {
      ...imageAsset,
      [breakpoint]: media[breakpoint]
        ? media[breakpoint].filter(
            (asset) => !validFileTypes.has(asset.fileType)
          )
        : null,
    }
  }

  if (videoAsset.large.length > 0) {
    return <ResponsiveVideo video={videoAsset} />
  }

  if (imageAsset.large.length > 0) {
    return <ResponsiveImage images={imageAsset} />
  }

  return null
}

export default ResponsiveMedia
